import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { IPoint } from '@trent/models/product-search/interfaces';
import { ITelematics, ITelematicsTruck } from '@trent/models/product/i-telematics';
import { tap } from 'rxjs/operators';
import { BaseHttpService } from './base-http.service';
import { FirestoreService } from './firestore.service';

@Injectable()
export class AssetLocService extends BaseHttpService {
  constructor(store: Store, private db: FirestoreService, private http: HttpClient) {
    super(store);
    this.apiName = 'api';
  }

  public getLoc(data: {unitNames: string[], cid: string, bidId?: string}) {
    const headers = this.addBearerToken();
    return this.http.post<ITelematicsTruck[]>(this.getApiUrl('/asset-loc/get-loc'),
      {
        data
      },
      { headers: headers })
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }
  public getOdometer(data: {cid: string, iId: string}) {
    const headers = this.addBearerToken();
    return this.http.post<{ name: string, odometer: number, vehicleIdentificationNumber: string }[]>(this.getApiUrl('/asset-loc/get-odometer'),
      {
        data
      },
      { headers: headers })
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }
}
