import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { logger } from '@trent/models/log/logger';
import { IMaintenanceParam } from '@trent/models/maintenance';

@Component({
  selector: 'trent-sales-options-search-dialog',
  templateUrl: './sales-options-search-dialog.component.html',
})
export class SalesOptionsSearchDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<any, any>,
    @Inject(MAT_DIALOG_DATA) public data: {
      param: IMaintenanceParam
    }) { }

  ngOnInit() {
    logger.info('nothing to init');
  }
  sendParam(e: {param: IMaintenanceParam}) {
    this.dialogRef.close({param: e.param});
  }
}
