import { AddOnWarranty } from "./add-on-warranty";
import { AddOnBase, AddOnCategory } from "./add-on-base";
import { AddOnMisc } from "./add-on-misc";
import { AddOnSafetyCertificate } from "./add-on-safety-cert";
import { AddOnTires } from "./add-on-tires";

export const parseAddOn = (obj: any): AddOnBase => {
	if (obj == null) { return null; }
	const r = <AddOnBase>obj;// cast it to base, remember it is still a javascript obj at run time.
	switch (r.category) {
		case AddOnCategory.Misc:
			return AddOnMisc.parse(r);
		case AddOnCategory.Warranty:
			return AddOnWarranty.parse(r);
		case AddOnCategory.Tires:
			return AddOnTires.parse(r);
		case AddOnCategory.SafetyCertification:
			return AddOnSafetyCertificate.parse(r);

		default:
			throw new Error(`Invalid AddOn Category in the AddOn. Category: ${r.category}
        is invalid or not yet implemented.`);
	}
};

export const parseAddOnArray = (obj: any[]): AddOnBase[] => {
	return obj.map(o => <AddOnBase>parseAddOn(o));
};