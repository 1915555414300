<mat-card>
    <mat-card-header>
        <mat-card-title>
            <ng-container>Bulk Upload Sales Options</ng-container>
        </mat-card-title>
        <mat-card-subtitle>
            Upload JSON data file.
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="upload-parent">
            <div class="d-flex d-a-center d-j-end" *ngIf="sOFile">
                <trent-file-upload #sOUploaderCtrl #sOFile2="ngModel" [(ngModel)]="sOFile" name="sOFile2"
                    [isEditMode]="true" [defaultFName]="'sOFile.json'" [refFName]="'Sales Options File'" 
                    (change)="fileInput($event)" >
                </trent-file-upload>
                <span matSuffix>
                    <button class="file-suffix-btn" mat-icon-button matSuffix color="primary" type="button"
                         (click)="resetFileInfo()">
                        <mat-icon>remove_circle</mat-icon>
                    </button>
                </span>
            </div>
            <mat-hint *ngIf="fileRecords.length" align="center">Total records of upload : {{fileRecords.length}}</mat-hint>
            <mat-hint *ngIf="bulkSORecd.length" align="center">Total valid records of upload : {{bulkSORecd.length}}</mat-hint>
            <div class="buttons">
                <button type="button" color="primary" mat-raised-button [disabled]="disableUpload" (click)="uploadFile()">
                    Upload & Validate
                </button>
                <button type="button" color="primary" mat-raised-button *ngIf="reUploadAgain" (click)="reUpload()">
                    Reupload Again
                </button>
                <button type="button" mat-stroked-button (click)="cancel()" class="ml-4">
                    Cancel
                </button>
            </div>
        <!-- <span *ngIf="errMessage" class="err">{{ errMessage }}</span> -->
        <mat-progress-bar *ngIf="showFileUpload" mode="indeterminate"></mat-progress-bar>
        <mat-hint *ngIf="processMessage" align="center">{{processMessage}}</mat-hint>
        
        <div class="error-message-parent">
            <ng-container *ngFor="let item of duplicateRecords"> 
                <span class="err">Duplicate record - vin - {{ item.vin }}</span>
            </ng-container>
            <ng-container *ngFor="let item of errorRecords"> 
                <span class="err">Error in record -  {{ item.vin }}</span>
            </ng-container>
        </div>
        
        
        
        <div style="display: none;">
            <input matInput appGooglePlaces id="addressCheck" type="text" name="address" placeholder="Search Address"
                autocomplete="off">
        </div>
        
        </div>
        
    </mat-card-content>
</mat-card>
