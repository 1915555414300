// import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ValidationError, validateSync, ValidatorOptions, Length } from 'class-validator';
import { PropertyUtils } from './property-utils';
import { IValidate, FormValidationData, IValidationMsg } from '../error-handling/validation-info';

/** character used as a control seperator. */
export const valCtrlSeperator = '_';



/** Helper Method  to convert validation errors to easy display messages messages */
export const toValidationError = (ve: ValidationError[], prefix = ''): IValidationMsg => {
  let r: IValidationMsg = {};
  const n = (!!prefix) ? `${prefix} : ` : ``;
  for (const err of ve) {
    const pKey = (prefix !== '') ? `${prefix}${valCtrlSeperator}${err.property}` : err.property;
    r[pKey] = [];
    /** For immidiate properties of the class, go through constraints. */
    for (const key in err.constraints) {  // iterate object properties
      if (err.constraints.hasOwnProperty(key)) {
        r[pKey].push(err.constraints[key]);
      }
    }

    // for nested validations, go through children
    if (!!err.children && err.children.length > 0) {
      let cResult: IValidationMsg = {};
      err.children.forEach(ele => {
        cResult = {
          ...cResult,
          ...toValidationError(err.children, pKey)
        };
      });

      if (Object.keys(cResult).length > 0) {
        r = {
          ...r,
          ...cResult
        };
      }

      // for (const cKey in cResult) {
      //   if (cResult.hasOwnProperty(cKey)) {
      //     const cKeyArray = cResult[cKey];
      //     cKeyArray.forEach(ele => {
      //       // r[err.property].push(`${cKey}-${ele}`);
      //       r[err.property].push(`${ele}`);
      //     });

      //   }
      // }

    }
  }
  return r;
};

export const toHtmlList = (list: string[], css?: string): string => {
  if (typeof (list) !== 'object' || list.length <= 0) {
    return '';
  }
  let h = (!!css) ? `<ul class="${css}">'` : '<ul>';
  for (const txt of list) {
    if (!!txt) {
      h += `<li>${txt}</li>`;
    }
  }
  return h + '</ul>';
};
// tslint:disable-next-line:max-line-length
export const phoneRegexString = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
export const phoneRegex = (phone: string): boolean => {
  if (!phone || typeof phone !== 'string') {
    return false;
  }
  // tslint:disable-next-line:max-line-length
  const r = phoneRegexString;
  // logger.log('phone.match(r)', phone.match(r));
  if (phone.length < 10) {
    return false;
  }
  // logger.log('phone.match(r)', phone.match(r));
  if (phone.match(r) && phone.match(r).length > 1) {
    return true;
  } else {
    return false;
  }
};
export const emailRegexString = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const emailRegex = (email: string): boolean => {
  if (!email || typeof email !== 'string') {
    return false;
  }
  const r = emailRegexString;
  if (email.match(r)) {
    return true;
  } else {
    return false;
  }
};
export const trackingNumberLength = 18;

export const trackingNoRegex = (trackingNo: string): boolean => {
  const t = /^[0-9]{5}-[0-9]{5}-[0-9]{6}$/;
  if (!trackingNo) {
    return false;
  }
  if (trackingNo.match(t)) {
    return true;
  } else {
    return false;
  }
};
export const validateStr = (s: string | number, maxLength: number): string | number => {
  if (!!s && typeof (s) !== 'string' && `${s}`.length > maxLength) {
    return undefined;
  }
  return s;
};

export const phoneCodeRegexString = /^\d{6}$/;
export const postalCodeRegex = /^[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]$/;
