<div>
    <div mat-dialog-title style="background: var(--accent);">
        <span class="closebtn" (click)="close()">&times;</span>
        Sales Logs
    </div>
    <mat-dialog-content>
		<ng-container *ngIf="logs?.length > 0">
			<table class="price-log__table">
				<thead>
					<tr>
						<th> Price </th>
						<th> Updated At </th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let l of logs">
						<td> {{l?.price}} </td>
						<td> {{l?.date | date:'medium'}} </td>
					</tr>
				</tbody>
			</table>
		</ng-container>
		<ng-container *ngIf="logs?.length === 0">
			No Price Change
        </ng-container>
    </mat-dialog-content>
</div>