export enum CompanyType {

  /** Just added a standalone driver */
  DriverProvider = 1,

  /** Company with a fleet of Trucks and/or trailers  */
  Fleet = 2,

  /** For promo app, carrier that create and track trips. */
  Carrier = 3,

  /** Company which rents out trucks and trailers. */
  Lessor = 400,

  /** Company(External) which rents out trucks and trailers. */
  EquipmentVendor = 5,

}
