import { Exclude, Expose, instanceToInstance, plainToInstance } from 'class-transformer';
import { IsDefined, ValidateIf } from 'class-validator';
import { AddOnBase, AddOnCategory, AddOnValidationGroup } from './add-on-base';

const misc: AddOnValidationGroup = 'misc';

@Exclude()
export class AddOnMisc extends AddOnBase {

	constructor() {
		super();
	}

	sanitize() {
		super.sanitize();
	}

	clone() {
		const t = instanceToInstance(this);
		t.sanitize();
		return t;
	}

	updatePriceQuote(s: AddOnMisc) {
		this.price = s.price;
	}

	public static parse(obj) {
		if (obj == null) { return null; }
		const m = plainToInstance<AddOnMisc, any>(AddOnMisc, obj);
		m.sanitize();
		return m;
	}
}