import { ValidateNested, ValidatorOptions, IsDefined, IsEnum, IsOptional, IsBoolean, Length, IsNotEmpty, ValidateIf } from 'class-validator';
import { Type, Expose, instanceToInstance, Exclude, plainToInstance } from 'class-transformer';
import { Address } from '../address/address';
import { BaseModel } from '../base';
import { IValDbStatusResult, IValidationMsg } from '../error-handling';
import { StoreTimings } from './store-timings';
import { sanitizeDateIPoint, sanitizePhone } from '../utility';
import { UserProfile } from '../user';

export type StoreLocationValidationGroup = 'general' | 'timings' | 'types' | 'managers' | 'alternateStore' | 'netsuite';
const general: StoreLocationValidationGroup = 'general';
const timings: StoreLocationValidationGroup = 'timings';
const types: StoreLocationValidationGroup = 'types';
const managers: StoreLocationValidationGroup = 'managers';
const alternateStore: StoreLocationValidationGroup = 'alternateStore';
const netsuite: StoreLocationValidationGroup = 'netsuite';

export enum CanadaZones {
	Pacific = 'Pacific',
	Mountain = 'Mountain',
	Central = 'Central',
	Eastern = 'Eastern',
	Atlantic = 'Atlantic',
}

export const cZones = [
	{ label: 'Pacific', value: CanadaZones.Pacific },
	{ label: 'Mountain', value: CanadaZones.Mountain },
	{ label: 'Central', value: CanadaZones.Central },
	{ label: 'Eastern', value: CanadaZones.Eastern },
	{ label: 'Atlantic', value: CanadaZones.Atlantic },
];

export enum StoreType {
	PTS = 'PTS',
	DPC = 'DPC',
	PrideEV = 'PrideEV',
	TTR = 'TTR',
	PFS = 'PFS',
}

export interface StoreManager {
	displayName: string,
	email: string,
	phoneNumber?: string,
	uid: string | number,
	type?: StoreType,
	roles?: any
}

export interface StoreSalesPerson {
	displayName?: string,
	email?: string,
	phoneNumber?: string,
	uid?: string | number,
	roles?: any,
	assignedStoreLocations?: any
}

export interface StoreLocationSummary {
	address?: Address;
	phone?: string;
	name?: string;//MKN - maintain name in store location summary
	sid: string | number;
	zone?: CanadaZones;
	lots?: string[];
}

@Exclude()
export class StoreLocation extends BaseModel {
	public static readonly collectionName = 'store-location';

	// Store Address
	@Expose()
	@ValidateNested({ message: 'Address information is required', groups: [general, Address.gName] })
	@IsDefined({ message: 'Address information is required', groups: [general, Address.gName] })
	@Type(() => Address)
	address: Address;

	// Store managers
	@Expose()
	@IsDefined({ message: 'Manager is required', groups: [managers] })
	managers: { [key: string]: StoreManager };

	// Store sales team user
	@Expose()
	salesPersons: { [key: string]: StoreSalesPerson };

	// Store timings
	@Expose()
	@ValidateNested({ message: 'Timings are required', groups: [timings] })
	@IsDefined({ message: 'Timings are required', groups: [timings] })
	@Type(() => StoreTimings)
	timings: StoreTimings;

	// NetSuite ref for store
	@Expose()
	netSuiteRef: string | number;

	email: string;

	@Expose()
	@Length(2, 200, { message: 'Name needs to be $constraint1-$constraint2 chars', groups: [general] })
	@IsDefined({ message: 'Store location name is required', groups: [general] })
	name: string;

	// Store Type
	@Expose()
	@IsBoolean({ groups: [types] })
	isPTS: boolean;

	@Expose()
	@IsBoolean({ groups: [types] })
	isDPC: boolean;

	@Expose()
	@IsBoolean({ groups: [types] })
	isPrideEV: boolean;

	@Expose()
	@IsBoolean({ groups: [types] })
	isTTR: boolean;

	@Expose()
	@IsBoolean({ groups: [types] })
	isPFS: boolean;

	// Store phone number
	@Expose()
	@IsNotEmpty({ message: 'Phone number is required', groups: [general] })
	@IsDefined({ message: 'Phone number is required', groups: [general] })
	phone: string;

	// Alternate store location 1
	@Expose()
	@IsOptional()
	altStoreRef1: string | number;

	// Alternate store location 1
	@Expose()
	@IsOptional()
	altStoreRef2: string | number;


	// Zone (only for CA)
	@Expose()
	@IsOptional()
	@IsEnum(CanadaZones)
	zone: CanadaZones;

	@Expose()
	lots: string[];

	// Getter for PTS Managers
	@Expose({ toPlainOnly: true })
	get ptsManagers(): string[] {
		let r: string[];
		for (const key in this.managers) {
			if (this.managers.hasOwnProperty(key)) {
				const ele = this.managers[key];
				if (ele.type === StoreType.PTS) {
					if (r === undefined) {
						r = [];
					}
					r.push(key);
				}
			}
		}
		return r;
	}

	// Getter for DPC Managers
	@Expose({ toPlainOnly: true })
	get dpcManagers(): string[] {
		let r: string[];
		for (const key in this.managers) {
			if (this.managers.hasOwnProperty(key)) {
				const ele = this.managers[key];
				if (ele.type === StoreType.DPC) {
					if (r === undefined) {
						r = [];
					}
					r.push(key);
				}
			}
		}
		return r;
	}

	// Getter for PrideEV Managers
	@Expose({ toPlainOnly: true })
	get prideEvManagers(): string[] {
		let r: string[];
		for (const key in this.managers) {
			if (this.managers.hasOwnProperty(key)) {
				const ele = this.managers[key];
				if (ele.type === StoreType.PrideEV) {
					if (r === undefined) {
						r = [];
					}
					r.push(key);
				}
			}
		}
		return r;
	}

	// Getter for TTR Managers
	@Expose({ toPlainOnly: true })
	get ttrManagers(): string[] {
		let r: string[];
		for (const key in this.managers) {
			if (this.managers.hasOwnProperty(key)) {
				const ele = this.managers[key];
				if (ele.type === StoreType.TTR) {
					if (r === undefined) {
						r = [];
					}
					r.push(key);
				}
			}
		}
		return r;
	}

	// Getter for PFS Managers
	@Expose({ toPlainOnly: true })
	get pfsManagers(): string[] {
		let r: string[];
		for (const key in this.managers) {
			if (this.managers.hasOwnProperty(key)) {
				const ele = this.managers[key];
				if (ele.type === StoreType.PFS) {
					if (r === undefined) {
						r = [];
					}
					r.push(key);
				}
			}
		}
		return r;
	}

	get storeLocationName() {
		return !this.name ? this.address?.streetName + ', ' + this.address?.city : this.name;
	}

	constructor() {
		super();
		this.address = new Address();
		this.managers = {};
		this.salesPersons = {};
		this.timings = new StoreTimings();
		this.netSuiteRef = '';
		this.phone = '';
		this.isPTS = true;
		this.isDPC = false;
		this.isPrideEV = false;
		this.isTTR = false;
		this.isPFS = false;
		this.lots = [''];
	}

	clone() {
		const t = instanceToInstance(this);
		t.sanitize();
		return t;
	}

	/** when read from jason or from plain to class, the date objects may be stored as string. convert them to date. */
	sanitize() {
		// if data was received from firebase, date is stored as snapshot.
		super.sanitize();
		this.phone = sanitizePhone(this.phone, true);
	}

	validateSync(options?: ValidatorOptions): IValidationMsg {
		// for nested entry for address, add address group in it.
		if (!!options && !!options.groups && options.groups.indexOf(general) > -1) {
			options.groups.push(Address.gName);
		}
		let r = this.validateSyncBase(this, options);
		// Validate store managers
		if (!!options && !!options.groups && options.groups.indexOf(managers) > -1) {
			if (Object.keys(this.managers).length == 0) {
				r['managers'] = ['Please add a manager'];
			}
		}
		// Validate alternate stores
		if (!!options && !!options.groups && options.groups.indexOf(alternateStore) > -1) {
			if ((this.altStoreRef1 || this.altStoreRef2) && this.altStoreRef1 == this.altStoreRef2) {
				r['altStoreRef1'] = ['Alternate stores cannot be the same'];
			}
		}
		// Validate alternate stores
		if (!!options && !!options.groups && options.groups.indexOf(general) > -1) {
			if ((this.altStoreRef1 || this.altStoreRef2) && this.altStoreRef1 == this.altStoreRef2) {
				r['altStoreRef1'] = ['Alternate stores cannot be the same'];
			}
		}

		// Validate timings
		if (!!options && !!options.groups && options.groups.indexOf(timings) > -1) {
			return this.timings.validateDays(r);
		}
		return r;
	}

	validateSyncGroup(group: StoreLocationValidationGroup): IValidationMsg {
		return this.validateSync({ groups: [group] });
	}

	/** Update Company Contact or Officer contact, called when user roles is added or updated */
	public updateStoreLocation(s: StoreLocation) {
		this.address = s.address;
		this.managers = s.managers;
		this.salesPersons = s.salesPersons;
		this.timings = s.timings;
		this.netSuiteRef = s.netSuiteRef;
		this.phone = s.phone;
		this.altStoreRef1 = s.altStoreRef1;
		this.altStoreRef2 = s.altStoreRef2;
		this.isPTS = s.isPTS;
		this.isDPC = s.isDPC;
		this.isPrideEV = s.isPrideEV;
		this.isTTR = s.isTTR;
		this.isPFS = s.isPFS;
		this.zone = s.zone;
		this.lots = s.lots;
	}

	public static parse(obj) {
		if (obj == null) { return null; }
		const m = plainToInstance<StoreLocation, any>(StoreLocation, sanitizeDateIPoint(obj));
		m.sanitize();
		return m;
	}

	getValDbStatus(): IValDbStatusResult {
		const result: IValDbStatusResult = {
			pass: false,
			message: undefined,
			groupResult: {}
		};
		let x: { groupPass: boolean; message: string; };
		// general
		let r = this.validateSyncGroup(general);
		x = { groupPass: null, message: '' };
		x.groupPass = Object.keys(r).length === 0;
		x.message = (x.groupPass) ? 'Manage store information' : 'Store information is required';
		result.groupResult[general] = x;

		// timings
		r = this.validateSyncGroup(timings);
		x = { groupPass: null, message: '' };
		x.groupPass = Object.keys(r).length === 0;
		x.message = (x.groupPass) ? 'Manage store timings' : 'Store timings are required';
		result.groupResult[timings] = x;

		// types
		r = this.validateSyncGroup(types);
		x = { groupPass: null, message: '' };
		x.groupPass = (this.isPTS || this.isDPC || this.isPrideEV || this.isTTR || this.isPFS) ? true : false;
		x.message = (x.groupPass) ? 'Set store type' : 'Store type is required';
		result.groupResult[types] = x;

		// managers
		r = this.validateSyncGroup(managers);
		x = { groupPass: null, message: '' };
		x.groupPass = Object.keys(r).length === 0;
		x.message = (x.groupPass) ? 'Set store managers' : 'Store manager is required';
		result.groupResult[managers] = x;

		// is it passed all the tests? (True means it failed here)
		result.pass = !Object.keys(result.groupResult).some((k) => !result.groupResult[k].groupPass);

		return result;
	}

	createSummary(): StoreLocationSummary {
		let summary = {
			address: this.address,
			phone: this.phone,
			name: this.name,
			sid: this.id,
			lots: this.lots,
		};
		return summary;
	}



	addSalesPerson(user: UserProfile) {
		if (!this.salesPersons) {
			this.salesPersons = {};
		}
		this.salesPersons[user.id] = {
			email: user.email,
			displayName: user.displayName,
			uid: user.id,
			phoneNumber: user.phoneNumber,
			roles: user.roles,
			assignedStoreLocations: user.assignedStoreLocations
		};
	}
}