import { UserProfile } from './user-profile';
import { IUserClaim, IUserAuthClaim } from './user-claim';
import { RoleGroupLevel, RoleGroupLevelRank } from '../role-group/role-group-level';
/** Keep it in sync with Role Names. IMPORTANT */

export enum RoleEnum {
  admin = 'admin',
  editor = 'editor',
  credit = 'credit',// MKN - Credit approval, Modify/Terminate Contract, final audit approval
  purchasing = 'purchasing',// MKN - Create/Approve/Modify/Cancel PO's
  billing = 'billing',// MKN - Sales order, Bill of sale
  marketing = 'marketing',// MKN -
  sales = 'sales',// MKN - Register customer company, Collect sales deposit, Complete documentation, Contract signOff, Collect down payment, work order, PDI
  leasingSales = 'leasingSales', //PT - for leasing sales
  salesOperations = 'salesOperations',// MKN - Price approval
  salesOperationsDpc = 'salesOperationsDpc',// Cm - For dpc
  service = 'service',// MKN - Final contract, Post down payment
  branchMgr = 'branchMgr',
  regionMgr = 'regionMgr',
  countryMgr = 'countryMgr',
  enterpriseMgr = 'enterpriseMgr',
  yardPerson = 'yardPerson',
  priceApprover = 'priceApprover',
}

export interface RoleLevel {
  l: RoleGroupLevelRank;
}

/** Site Control Roles */
export interface Role {

  /** Admin */
  admin?: boolean;

  /** editor */
  editor?: boolean;

  /** MKN - Other Roles */
  /** credit - Credit approval, Modify/Terminate Contract, final audit approval*/
  credit?: RoleLevel;

  /** purchasing - Create/Approve/Modify/Cancel PO's */
  purchasing?: RoleLevel;

  /** billing - Sales order, Bill of sale*/
  billing?: RoleLevel;

  /** marketing */
  marketing?: RoleLevel;

  /** KS | Role level Test */
  sales?: RoleLevel;

  /** sales Operations - Price approval */
  salesOperations?: RoleLevel;

  /** sales Operations for Dpc */
  salesOperationsDpc?: RoleLevel;

  /** service - Final contract, Post down payment*/
  service?: RoleLevel;

  /** branch Manager - Manage branch level operations */
  branchMgr?: RoleLevel;

  /** Region Manager - Manage region level operations  */
  regionMgr?: RoleLevel;

  /** Country Manager */
  countryMgr?: RoleLevel;

  /** Enterprise Manager */
  enterpriseMgr?: RoleLevel;

  /** Yard Person */
  yardPerson?: RoleLevel;

  /** Yard Person */
  priceApprover?: RoleLevel;

  /** role group */
  rGrp?: { [key: string]: RoleGroupLevel };  // MKN - For Account Manager, Credit Manager role group info

  leasingSales?: RoleLevel, //PT - for leasing sales
}

// #region Role-based Authorization - From User Profile

/**  determines if user has any of the matching role */
const checkAuthP = (ua: UserProfile, allowedRoles: string[]): boolean => {
  if (!ua || !ua.roles) { return false; }

  for (const role of allowedRoles) {
    if (typeof ua.roles[role] === 'boolean') {
      if (ua.roles[role] === true) {
        return true;
      }
    } else {
      if (ua.roles[role]) {
        return true;
      }
    }

  }
  return false;
};

/**
 * Is User Admin
 * @param ua User Profile (interface OR Class)
 */
export const isAdminP = (ua: UserProfile): boolean => {
  return checkAuthP(ua, [RoleEnum.admin]);
};

/**
 * Is Editor
 * @param ua User Profile (interface OR Class)
 *
 */
export const isEditorP = (ua: UserProfile): boolean => {
  const allowed = [RoleEnum.editor];
  return checkAuthP(ua, allowed);
};

/**
 * Is Credit
 * @param ua User Profile (interface OR Class)
 *
 */
export const isCreditP = (ua: UserProfile): boolean => {
  const allowed = [RoleEnum.credit];
  return checkAuthP(ua, allowed);
};

/**
 * Is Sales
 * @param ua User Profile (interface OR Class)
 *
 */
export const isSalesP = (ua: UserProfile): boolean => {
  const allowed = [RoleEnum.sales];
  return checkAuthP(ua, allowed);
};

/**
 * Is Service
 * @param ua User Profile (interface OR Class)
 *
 */
export const isServiceP = (ua: UserProfile): boolean => {
  const allowed = [RoleEnum.service];
  return checkAuthP(ua, allowed);
};

/**
 * Is Purchasing
 * @param ua User Profile (interface OR Class)
 *
 */
export const isPurchasingP = (ua: UserProfile): boolean => {
  const allowed = [RoleEnum.purchasing];
  return checkAuthP(ua, allowed);
};

/**
 * Is Billing
 * @param ua User Profile (interface OR Class)
 *
 */
export const isBillingP = (ua: UserProfile): boolean => {
  const allowed = [RoleEnum.billing];
  return checkAuthP(ua, allowed);
};

/**
 * Is Marketing
 * @param ua User Profile (interface OR Class)
 *
 */
export const isMarketingP = (ua: UserProfile): boolean => {
  const allowed = [RoleEnum.marketing];
  return checkAuthP(ua, allowed);
};

/**
 * Is SalesOperations
 * @param ua User Profile (interface OR Class)
 *
 */
export const isSalesOperationsP = (ua: UserProfile): boolean => {
  const allowed = [RoleEnum.salesOperations];
  return checkAuthP(ua, allowed);
};

/**
 * Is SalesOperationsDpc
 * @param ua User Profile (interface OR Class)
 *
 */
export const isSalesOperationsDpcP = (ua: UserProfile): boolean => {
  const allowed = [RoleEnum.salesOperationsDpc];
  return checkAuthP(ua, allowed);
};

/**
 * Is BranchMgr
 * @param ua User Profile (interface OR Class)
 *
 */
export const isBranchMgrP = (ua: UserProfile): boolean => {
  const allowed = [RoleEnum.branchMgr];
  return checkAuthP(ua, allowed);
};

/**
 * Is RegionMgr
 * @param ua User Profile (interface OR Class)
 *
 */
export const isRegionMgrP = (ua: UserProfile): boolean => {
  const allowed = [RoleEnum.regionMgr];
  return checkAuthP(ua, allowed);
};

/**
 * Is CountryMgr
 * @param ua User Profile (interface OR Class)
 *
 */
export const isCountryMgrP = (ua: UserProfile): boolean => {
  const allowed = [RoleEnum.countryMgr];
  return checkAuthP(ua, allowed);
};

/**
 * Is EnterpriseMgr
 * @param ua User Profile (interface OR Class)
 *
 */
export const isEnterpriseMgrP = (ua: UserProfile): boolean => {
  const allowed = [RoleEnum.enterpriseMgr];
  return checkAuthP(ua, allowed);
};

/**
 * Is Yard Person
 * @param ua User Profile (interface OR Class)
 *
 */
export const isYardPersonP = (ua: UserProfile): boolean => {
  const allowed = [RoleEnum.yardPerson];
  return checkAuthP(ua, allowed);
};

/**
 * Is Price Approver
 * @param ua User Profile (interface OR Class)
 *
 */
export const isPriceApproverP = (ua: UserProfile): boolean => {
  const allowed = [RoleEnum.priceApprover];
  return checkAuthP(ua, allowed);
};

// #region Role-based Authorization - From User Claim

const checkAuthC = (c: IUserAuthClaim, allowedRoles: string[]): boolean => {
  if (!c || !c.roles) {
    return false;
  }
  for (const role of allowedRoles) {
    if (c.roles[role] === true) {
      return true;
    }
  }
  return false;
};

/**
 * Is User Admin
 * @param c User Claim (interface)
 */
export const isAdminC = (c: IUserAuthClaim): boolean => {
  return checkAuthC(c, [RoleEnum.admin]);
};

/**
 * Is Editor
 * @param c User Claim (interface)
 *
 */
export const isEditorC = (c: IUserAuthClaim): boolean => {
  const allowed = [RoleEnum.editor];
  return checkAuthC(c, allowed);
};
/**
 * Is Credit
 * @param c User Claim (interface)
 *
 */
export const isCreditC = (c: IUserAuthClaim): boolean => {
  const allowed = [RoleEnum.credit];
  return checkAuthC(c, allowed);
};
/**
 * Is Sales
 * @param c User Claim (interface)
 *
 */
export const isSalesC = (c: IUserAuthClaim): boolean => {
  const allowed = [RoleEnum.sales];
  return checkAuthC(c, allowed);
};
/**
 * Is Service
 * @param c User Claim (interface)
 *
 */
export const isServiceC = (c: IUserAuthClaim): boolean => {
  const allowed = [RoleEnum.service];
  return checkAuthC(c, allowed);
};
/**
 * Is Purchasing
 * @param c User Claim (interface)
 *
 */
export const isPurchasingC = (c: IUserAuthClaim): boolean => {
  const allowed = [RoleEnum.purchasing];
  return checkAuthC(c, allowed);
};
/**
 * Is Billing
 * @param c User Claim (interface)
 *
 */
export const isBillingC = (c: IUserAuthClaim): boolean => {
  const allowed = [RoleEnum.billing];
  return checkAuthC(c, allowed);
};
/**
 * Is Marketing
 * @param c User Claim (interface)
 *
 */
export const isMarketingC = (c: IUserAuthClaim): boolean => {
  const allowed = [RoleEnum.marketing];
  return checkAuthC(c, allowed);
};
/**
 * Is SalesOperations
 * @param c User Claim (interface)
 *
 */
export const isSalesOperationsC = (c: IUserAuthClaim): boolean => {
  const allowed = [RoleEnum.salesOperations];
  return checkAuthC(c, allowed);
};

/**
 * Is SalesOperationsDpc
 * @param c User Claim (interface)
 *
 */
export const isSalesOperationsDpcC = (c: IUserAuthClaim): boolean => {
  const allowed = [RoleEnum.salesOperationsDpc];
  return checkAuthC(c, allowed);
};

/**
 * Is BranchMgr
 * @param c User Claim (interface)
 *
 */
export const isBranchMgrC = (c: IUserAuthClaim): boolean => {
  const allowed = [RoleEnum.branchMgr];
  return checkAuthC(c, allowed);
};
/**
 * Is RegionMgr
 * @param c User Claim (interface)
 *
 */
export const isRegionMgrC = (c: IUserAuthClaim): boolean => {
  const allowed = [RoleEnum.regionMgr];
  return checkAuthC(c, allowed);
};
/**
 * Is CountryMgr
 * @param c User Claim (interface)
 *
 */
export const isCountryMgrC = (c: IUserAuthClaim): boolean => {
  const allowed = [RoleEnum.countryMgr];
  return checkAuthC(c, allowed);
};
/**
 * Is EnterpriseMgr
 * @param c User Claim (interface)
 *
 */
export const isEnterpriseMgrC = (c: IUserAuthClaim): boolean => {
  const allowed = [RoleEnum.enterpriseMgr];
  return checkAuthC(c, allowed);
};
/**
 * Is Yard Person
 * @param c User Claim (interface)
 *
 */
export const isYardPersonC = (c: IUserAuthClaim): boolean => {
  const allowed = [RoleEnum.yardPerson];
  return checkAuthC(c, allowed);
};
/**
 * Is Price Approver
 * @param c User Claim (interface)
 *
 */
export const isPriceApproverC = (c: IUserAuthClaim): boolean => {
  const allowed = [RoleEnum.priceApprover];
  return checkAuthC(c, allowed);
};
// #endregion
