import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BasePage } from '@trent/models/UI/base.page';
import { AddOnBase, AddOnCategory } from '@trent/models/addon/add-on-base';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { EventService } from '@trent/services/event.service';
import { UtilityService } from '@trent/services/utility.service';
import { Observable, map } from 'rxjs';
import { startCase } from 'lodash';
import { AddOnState, AllAddOnsRequested } from '@trent/store/addOn-store';
import { logger } from '@trent/models/log/logger';
import { toSentence } from '@trent/models/utility';
import { EnumHelper } from '@trent/models/utility/enum-helper';
import { AddOnParam } from '@trent/models/addon/add-on-param';

@Component({
	selector: 'trent-pts-add-on',
	templateUrl: './pts-add-on.component.html',
	styleUrls: ['./pts-add-on.component.scss']
})
export class PtsAddOnComponent extends BasePage<AddOnBase[]> implements OnInit, OnDestroy {

	isHandset$: Observable<boolean>;
	isHandset: boolean;
	showFilter: boolean;
	displayedColumns: string[] = ['category', 'name', 'description', 'status','actions'];
	dataSource: MatTableDataSource<{}>;
	param: AddOnParam;

	constructor(
		store: Store,
		ds: DialogService,
		es: EventService,
		private router: Router,
		public us: UtilityService,
		public dialog: MatDialog

	) {
		super(store, ds, es);
	}
	ngOnDestroy(): void {
		this.cleanListeners();
	}

	ngOnInit() {
		this.isHandset$ = this.us.isHandset$;
		this.mSubscription.push(this.us.isHandset$.subscribe(r => this.isHandset = r));
		this.getStoreData();
		this.title = 'AddOns';
	}

	/**
	 * Get addon data from store
	 */
	getStoreData() {
		this.param = {};
		const pData = { size: 10, offset: 0, full: false };
		// Dispatch new request.
		this.store.dispatch(new AllAddOnsRequested({ pData: pData, param: { ...this.param } }));
		const addOns$ = this.store.select(AddOnState.selectAllAddOns).pipe(map(clientFilterFn => clientFilterFn({ ...this.param })));
		this.mSubscription.push(addOns$.subscribe(d => {
			logger.log('addons', d);
			if (d && d.length > 0) {
				this.m = d;
				this.dataSource = new MatTableDataSource(d);
				this.setupMobileTopMenuEvents();
			}
		}));
	}

	/**
	 * Display addon sortByCategory on UI
	 */
	getSortByCategoryName(sortByCategory: number) {
		return toSentence(EnumHelper.getName(AddOnCategory, sortByCategory));
	}

	/**
	* Get Category name
	*/
	getCategoryName(category: number) {
		return toSentence(EnumHelper.getName(AddOnCategory, category));
	}

	setupMobileTopMenuEvents() {
		this.setUpMobileButtons();
		this.eventListeners.push(
			this.es.listen<boolean>(this.es.menuFireSearch, (search) => {
				if (search) {
					this.showFilter = true;
				}
			}));
	}

	/**
	 * setup Mobile Buttons
	 * @returns 
	 */
	setUpMobileButtons() {
		if (!this.isHandset) {
			return;
		}
		this.showHideSearch(true);

	}

	/**
	 * Search data from table
	 * @param filterValue 
	 */
	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue?.trim().toLowerCase();
	}


	/**
	 * Clear search data
	 * @param filterValue 
	 */
	clearFilter() {
		this.showFilter = false;
		this.dataSource.filter = null;
	}

	/**
	 * 
	 * @param id Edit record
	 */
	editRecord(id: string | number) {
		this.router.navigate(['/sales-options/addons/detail', id]);
	}

	/**
	 * Add new addOn
	 */
	createAddOn() {
		this.router.navigate(['/sales-options/addons/detail']);
	}

	/**
	 * Convert text to title format
	 * @param s 
	 * @returns 
	 */
	toTitleCase(s: string) {
		return startCase(s);
	}
}