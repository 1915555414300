import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseHttpService } from "./base-http.service";
import { Store } from "@ngxs/store";
import { FirestoreService } from "./firestore.service";

@Injectable({ providedIn: "root" })

export class GpsPointerService extends BaseHttpService {
    
  constructor(store: Store, private db: FirestoreService, private http: HttpClient) {
    super(store);
    this.apiName = "api";
  }

  /**
   * @author - Cm
   * @purpose - Here all vehicles data fetch from powerfleet pointer gps
   */

  async getFleetView() {
    // Prepare the post data
    const headers = new HttpHeaders({ Authorization: "Bearer " + this.token });
    return this.http.post<any>(this.getApiUrl("/pointer-gps/get-fleet"), { }, { headers: headers }).toPromise();
  }

  async getVehicleDetails(vehicleId: string) {
    // Prepare the post data
    const headers = new HttpHeaders({ Authorization: "Bearer " + this.token });
    return this.http.post<any>(this.getApiUrl("/pointer-gps/get-vehicle-details"), { vehicleId }, { headers: headers }).toPromise();
  }

  /**
   * @author - Cm
   * @purpose - Through vin number vehicle data fetch from powerfleet pointer gps
   */

  async getFleetSearch(query: string) {
    // Prepare the post data
    const headers = new HttpHeaders({ Authorization: "Bearer " + this.token });
    return this.http.post<any>(this.getApiUrl("/pointer-gps/get-fleet-search"), { query }, { headers: headers }).toPromise();
  }
}
