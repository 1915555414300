import { cleanListeners, trackingNoRegex, toSentence } from '@trent/models/utility';
import { PageHtml, PageHtmlTemplate, PageHtmlType } from '@trent/models/cms/page-html';
import { UtilityService } from '@trent/services/utility.service';
import { MessageInfo } from '@trentm/error-handling/message-info';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, AfterViewInit, inject } from '@angular/core';
import { EventService, IEventListener } from '@trent/services/event.service';
import { Subscription, Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { TransferState, makeStateKey, DomSanitizer } from '@angular/platform-browser';
import { SettingComponent } from '../shared/setting/setting.component';
import { AppState1, ScreenDisplayMode } from '@trent/store/root-store';
import { Router } from '@angular/router';
import { IAuthCoreService } from '@trent/services/auth/iauth-core.service';
import { AuthState } from '@trent/store/auth-store/auth.state';
import { UserProfile } from '@trent/models/user/user-profile';
import { isEqual } from 'lodash';
import { UserType } from '../../../../../libs/models/user/user-profile';
import { NgForm } from '@angular/forms';
import { UserSetting } from '@trent/models/setting/user-setting';
import { SubPageComponent } from '../shared/sub-page/sub-page.component';
import { logger } from '@trent/models/log/logger';
import { toQueryStr } from '@trent/models/utility/query-helper';
import { RentOptionParam, rentOptionParamInit } from '@trent/models/rental/rent-option-param';
import { ProductType } from '@trent/models/product/product-type';
import { MatIconRegistry } from '@angular/material/icon';
import { SeoService } from '@trent/services/seo.service';
import { PageMetaTag } from '@trent/models/cms/page-meta-tag';
import { CompanyRequested, CompanyState } from '@trent/store/company-store';
import { CompanyStatus } from '@trent/models/company/company-status';
import { getSafeHtml } from '../../models/utility';
import { TprHomePageHtml } from '@trent/models/cms/tpr-home-page-html';
import { CmsState } from '@trent/store/cms-store';

const MY_DATA = makeStateKey('my_data');
@Component({
  selector: 'trent-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  isEditMode = false;
  seo: SeoService = inject(SeoService);

  page: TprHomePageHtml = new TprHomePageHtml();
  pageTemplate: PageHtmlTemplate;
  // htmlId = 'home';

  @ViewChild('uTypeForm', { static: false })
  uTypeForm: NgForm;

  settingSubMenuLoaded = false;
  subMenuData = [];

  isLessor: boolean = false; //PT  check if the user is a lessor or a lessee. So, we can render the user to particular page when clicked on Contracts

  cId: number | string; //PT- store the company Id

  sub: Subscription[] = [];


  /** Event Listener container. Only need to store so that ngDistroy can remove the linkages of events. */
  public eventListners: IEventListener[] = [];
  private subs: Subscription[] = [];

  alertMsg: MessageInfo;
  successMsg: MessageInfo;

  imageUrl: string = null;

  serverTime: any;
  clientTime: Date;
  isHandset$: Observable<boolean>;
  isHandset: boolean;
  browserWidth: number;
  isFocus = false;
  showVideo: boolean;
  userProfile: UserProfile;
  defaultUType: UserType;
  buttons: {
    one: { text: string, fn?: () => void, icon?: string, isMatIcon?: boolean };
    two: { text: string, fn?: () => void, icon?: string, isMatIcon?: boolean };
    three: { text: string, fn?: () => void, icon?: string, isMatIcon?: boolean };
    four: { text: string, fn?: () => void, icon?: string, isMatIcon?: boolean };
  };
  userTypes: UserType[] = ['carrier', 'driver', 'tracker'];
  // selectedUserType: UserType = 'anonymous';
  setting: UserSetting;
  keybOpenAndroid: boolean;
  constructor(
    private store: Store,
    private es: EventService,
    public ds: DialogService,
    public us: UtilityService,
    private state: TransferState,
    private auth: IAuthCoreService,
    /** DO NOT FORGET TO REMOVE CameraModule from app.module once debugging is done. */
    // public camera: CameraBaseService,
    public router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private cd: ChangeDetectorRef) {
    // Debug
    if (us.isPlatformServer) {
      this.serverTime = new Date();
      this.state.set(MY_DATA, this.serverTime);
    }
    if (us.isPlatformBrowser) {
      this.clientTime = new Date();
      this.serverTime = this.state.get(MY_DATA, null); // 'Server-data-Not-Found');
      if (this.serverTime == null) {
        this.serverTime = 'NOT FOUND';
      }
    }
    /** sanitizer fails at SSR. the icon will be re-requested at the client any way. */
    this.matIconRegistry.addSvgIcon('truckIcon', this.domSanitizer.bypassSecurityTrustResourceUrl(this.us.getFullUrl('assets/truck-semi.svg')));
    this.matIconRegistry.addSvgIcon('trailerIcon', this.domSanitizer.bypassSecurityTrustResourceUrl(this.us.getFullUrl('assets/dry_van.svg')));
  }

  ngOnInit() {
    this.loadPageHtml();
    this.subs.push(this.store
      .select(CmsState.selectPageEditMode)
      .subscribe(m => {
        this.isEditMode = m;
        // this.cdr.detectChanges();
      }));

    // Add menu upon login only.
    this.subs.push(this.auth.isLoggedin$.subscribe(loggedIn => {
      if (loggedIn) {
        if (!this.settingSubMenuLoaded) {
          this.subMenuData.push({
            type: 'button',
            title: 'Communication',
            icon: 'settings',
            click: () => {
              this.ds.alert(new MessageInfo({
                msgCss: 'primary',
                header: 'Option Clicked',
                description: 'Click function handler in home page'
              }), this.ds.getFullScreenConfig(this.isHandset), undefined, SettingComponent);
            }
          });
        }
        this.addSubMenu();
      } else {
        this.subMenuData = [];
        this.addSubMenu();
      }
    }));

    this.isHandset$ = this.us.isHandset$;
    const b = this.store.select(AppState1.currentScreenDisplay)
      .subscribe(x => {
        if (x.screen === ScreenDisplayMode.mobile) {
          this.browserWidth = x.width;

        }
        // logger.log('browserInfo', x);
      });
    const hSub = this.isHandset$.subscribe(h => {
      this.isHandset = h;
    });
    this.es.emit(this.es.loadPageOptions, this.subMenuData);
    this.subs.push(hSub, b);
    this.getLoginType();
    this.setupAlertData();
    // DEBUG ONLY.
    // const hdr = new HttpHeaders();
    // hdr.set('Access-Control-Allow-Origin', '*');

    // this.http
    //   // .post("http://localhost:8080/location/create", { a: "aa" })
    //   .post("http://192.168.0.11:8080/location/create", { a: "aa" }, { headers: hdr })
    //   .toPromise()
    //   .then(r => logger.log("[Home] Response from server: ", r))
    //   .catch(err => logger.error("[Home ERROR]", err));

    //PT - code to check if the user is a lessor or a lessee
    // new Promise((resolve, reject) => {
    //   // Make an asynchronous call and either resolve or reject
    //   this.sub.push(this.store.select(AuthState.user).subscribe(u => {
    //    if(
    //    u.setting.defaultCompany && Object.keys(u.setting.defaultCompany).length > 0){
    //     this.cId = u.setting.defaultCompany.cid;
    //     if (this.cId) {
    //       resolve(true);
    //     } else {
    //       reject(false);
    //     }
    //    }
    //   }));
    // }).then((res) => {
    //   this.store.dispatch(new CompanyRequested({ id: `${this.cId}` }));
    //   // Also dispatch root as required.
    //   const comp$ = this.store.select(CompanyState.selectCompanyById)
    //   .pipe(map(filterFn => filterFn(this.cId)));
    //   this.sub.push(comp$.subscribe(_data=>{
    //     if(_data.lessorStatus > CompanyStatus.notApproved){
    //       this.isLessor = true;
    //     }else if(_data.lesseeStatus > CompanyStatus.notApproved){
    //       this.isLessor = false;
    //     }
    //   }));
    // }).catch((rej) => {
    //   logger.log('error occurred');
    // });
  }

  ngAfterViewInit() {
    /** to address angular SSR binding issue. */
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    cleanListeners(this.eventListners, this.subs, this.sub);
  }

  /** Dynamic SEO meta tags for the current page. This function will only be used in angular universal
   * These keywords are dynamic, i.e based upon the current url and these will be added to the static tags
   * already defined in the database for this page (PageHtml) collection.
   */
  dynamicMetaTags<T extends PageHtml>(p: T): PageMetaTag[] {
    return [
      { content: 'text/html; charset=utf-8', 'http-equiv': 'Content-Type' },
      { keyword: 'Nauru Pride truck, PgeNm, pride group, toronto' }
    ];
  }

  loadPageHtml<T extends PageHtml>() {
    // Setup the ID and page Html type.
    this.pageTemplate = {
      pid: 'home',
      pageType: PageHtmlType.tprHome //  PageHtmlType.pageHtml
    };
    // Fire the page html edit event so that edit html can be utilized by admin on the top menu.
    this.es.emit<PageHtmlTemplate>(this.es.menuShowHtmlEdit, {...this.pageTemplate});

    // Download the page html
    const s = this.seo.loadPageHtml(this.pageTemplate.pid, (p: T) => this.dynamicMetaTags(p), TprHomePageHtml)
      .subscribe(p => {
        this.page = p;
        if (!!this.page) {
          this.es.emit(this.es.app_setTitle, this.page.title);
        }
      });
    this.subs.push(s);
  }

  showBusy() {
    this.es.emit(this.es.app_showBusy, 0);
  }
  hideBusy() {
    this.es.emit(this.es.app_hideBusy, null);
  }

  setupAlertData() {
    this.alertMsg = new MessageInfo({
      msgCss: 'primary',
      header: 'Alert',
      description: 'Alert description goes here',
      list: ['Error 1', 'Error 2', 'Error 3']
    });

    this.successMsg = new MessageInfo({
      msgCss: 'primary',
      header: 'Success',
      description: 'Alert description goes here'
    });
  }

  confirm() {
    this.ds.confirm(this.successMsg, null, result => {
      logger.log('confirm box results: ', result);
    });
  }

  snakBar() {
    this.ds.openSnackBar(
      new MessageInfo({
        msgCss: 'warn',
        description: 'Test description'
      })
    );
  }

  // async takePic(o: number) {
  //   this.camera.takePicture({ destinationType: o })
  //     .then(res => {
  //       logger.log('camra image url: ', res);
  //       this.imageUrl = res.webPath;

  //       // this.imageUrl = (o !== 0) ? res :
  //       //   'data:image/jpeg;base64,' + res;
  //     })
  //     .catch(err => {
  //       logger.error('camera Error', err);
  //     });

  //   // const res = await promiseWraper(this.camera.takePicture(o));
  //   // if (res.success) {
  //   //   this.imageUrl = (o !== 0) ? res.data :
  //   //   'data:image/jpeg;base64,' + res.data
  //   // } else {
  //   //   logger.log('Error during taking picture', res.error);
  //   // }
  // }
  get bWidth(): number {
    if (!this.isHandset) {
      return 200;
    } else {
      return this.browserWidth / 2 - 50;
    }
  }
  get bHeight(): number {
    if (!this.isHandset) {
      return 200;
    } else {
      return this.browserWidth / 2 - 50;
    }
  }
  get sbWidth(): number {
    if (!this.isHandset) {
      return 500;
    } else {
      return this.browserWidth - 20;
    }
  }
  searchByTno(tno: string) {
    this.isFocus = false;
    if (!!tno && !!trackingNoRegex(tno)) {
      this.router.navigate(['track-delivery/list', tno], { queryParams: { rb: true } });
    }
  }
  allShipments() {
    this.router.navigate(['track-delivery/notifier-list'], { queryParams: { rb: true } });
  }
  advancedSearch() {
    this.router.navigate(['track-delivery/notifier-list'], { queryParams: { rb: true, advSearch: true } });
  }
  toAppLink() {
    logger.log('toAppLink fired');

    this.router.navigate(['download-app'], { queryParams: { rb: true } });
  }
  // registerAs(as: 'Driver' | 'Carrier') {
  //   let returnUrl: string;
  //   switch (as) {
  //     case 'Driver':
  //       returnUrl = '/account?userRef=driver-sign';
  //       break;
  //     case 'Carrier':
  //       returnUrl = '/company/carrier-detail';
  //       break;
  //     default:
  //       break;

  //   }
  //   if (!this.userProfile) {
  //     this.router.navigate(['/login', { returnUrl }]);
  //   } else {
  //     this.router.navigateByUrl(returnUrl);
  //   }

  // }
  focusOnSearch(): void {
    this.isFocus = true;
  }
  toHowTo() {
    this.showVideo = true;
    this.ds.openInfoPage({ url: 'home#help-video', styleInfo: 'center-video' }, SubPageComponent);
  }
  getLoginType() {
    if (this.us.isPlatformServer) {
      // this.selectedUserType = 'anonymous';
      this.getButtonTitles();
    } else {
      this.subs.push(this.store.select(AuthState.user).subscribe(u => {
        if (!!u) {
          if (!isEqual(u, this.userProfile)) {
            this.userProfile = u;
            this.setting = { ...u.setting };
            // if (this.setting.defaultUserType) {
            //   this.selectedUserType = this.setting.defaultUserType;
            // }
          }
        } else {
          this.setting = null;
        }
        this.getButtonTitles();
      }));
    }
  }


  offerRentals() {
    const url = (this.auth.isLoggedin && !!this.setting.defaultCompany) ? '/list/trailer' : '/landing-page?q=offer-rental';
    this.router.navigateByUrl(url);
  }
  trackShipments() {
    const url = (this.auth.isLoggedin) ? '/track-delivery/notifier-list' : '/landing-page?q=track-shipment';
    this.router.navigateByUrl(url);
  }
  get selectedUserType() { return UserProfile.getUserType(this.userProfile); }

  getButtonTitles() {
    this.buttons = {
      one: { text: 'Rent Trucks', fn: () => this.router.navigateByUrl('rent-option/truck/list'), icon: 'assets/white-truck-icon.svg', isMatIcon: false },
      two: { text: 'Rent Trailers', fn: () => this.router.navigateByUrl('rent-option/trailer/list'), icon: 'assets/white-trailer-icon.svg', isMatIcon: false },
      three: { text: 'Register', fn: () => this.router.navigateByUrl('login'), icon: 'business', isMatIcon: true },
      four: { text: 'How To?', fn: () => this.toHowTo(), icon: 'help', isMatIcon: true }
    };


    logger.log('auth', this.auth);
    switch (this.selectedUserType) {
      case 'anonymous':
        this.buttons = {
          one: { text: 'Rent Trucks', fn: () => this.router.navigateByUrl('rent-option/truck/list'), icon: 'assets/white-truck-icon.svg', isMatIcon: false },
          two: { text: 'Rent Trailers', fn: () => this.router.navigateByUrl('rent-option/trailer/list'), icon: 'assets/white-trailer-icon.svg', isMatIcon: false },
          three: { text: 'Register', fn: () => this.router.navigateByUrl('login'), icon: 'business', isMatIcon: true },
          four: { text: 'How To?', fn: () => this.toHowTo(), icon: 'help', isMatIcon: true }
        };
        break;
      case 'logedIn':
        this.buttons = {
          one: { text: 'Rent Trucks', fn: () => this.router.navigateByUrl('rent-option/truck/list'), icon: 'assets/white-truck-icon.svg', isMatIcon: false },
          // eslint-disable-next-line max-len
          two: { text: 'Rent Trailers', fn: () => this.router.navigateByUrl('rent-option/trailer/list'), icon: 'assets/white-trailer-icon.svg', isMatIcon: false },
          three: { text: 'Register Company', fn: () => this.router.navigateByUrl('company/lessee'), icon: 'business', isMatIcon: true },
          four: { text: 'How To?', fn: () => this.toHowTo(), icon: 'true', isMatIcon: true }
        };

        break;
      case 'vendor':
        this.buttons = {
          one: { text: 'Rent Trucks', fn: () => this.router.navigateByUrl('rent-option/truck/list'), icon: 'assets/white-truck-icon.svg', isMatIcon: false },
          // eslint-disable-next-line max-len
          two: { text: 'Rent Trailers', fn: () => this.router.navigateByUrl('rent-option/trailer/list'), icon: 'assets/white-trailer-icon.svg', isMatIcon: false },
          three: { text: 'Bids', fn: () => this.router.navigateByUrl('bid/list-vendor'), icon: 'handshake', isMatIcon: true }, // this.advancedSearch()
          four: { text: 'How To?', fn: () => this.toHowTo(), icon: 'help', isMatIcon: true }
        };
        break;
      case 'driver':
        this.buttons = {
          one: { text: 'Rent Trucks', fn: () => this.router.navigateByUrl('rent-option/truck/list'), icon: 'assets/white-truck-icon.svg', isMatIcon: false },
          // eslint-disable-next-line max-len
          two: { text: 'Rent Trailers', fn: () => this.router.navigateByUrl('rent-option/trailer/list'), icon: 'assets/white-trailer-icon.svg', isMatIcon: false },
          // one: { text: 'Track Shipment', fn: () => this.focusOnSearch() },
          // two: { text: 'Current Trip', fn: () => this.getDriverTrips('current'), icon: 'swap_calls' },
          // three: { text: 'Trip History', fn: () => this.getDriverTrips(), icon: 'restore' },
          three: { text: 'Register', fn: () => this.router.navigateByUrl('company/lessee'), icon: 'business', isMatIcon: true },
          four: { text: 'How To?', fn: () => this.toHowTo(), icon: 'help', isMatIcon: true }
        };
        break;
      case 'customer':
        this.buttons = {
          one: { text: 'Rent Trucks', fn: () => this.router.navigateByUrl('rent-option/truck/list'), icon: 'assets/white-truck-icon.svg', isMatIcon: false },
          // eslint-disable-next-line max-len
          two: { text: 'Rent Trailers', fn: () => this.router.navigateByUrl('rent-option/trailer/list'), icon: 'assets/white-trailer-icon.svg', isMatIcon: false },
          // three: { text: 'Contracts', fn: () => this.isLessor ? this.router.navigateByUrl('bid/list-vendor') : this.router.navigateByUrl('bid/list-customer'), icon: 'handshake', isMatIcon: true }, // this.addTrip()
          three: { text: 'Contracts', fn: () => this.router.navigateByUrl('bid/list-customer'), icon: 'handshake', isMatIcon: true }, // this.addTrip()
          four: { text: 'How To?', fn: () => this.toHowTo(), icon: 'assets/white-how-to-icon.svg', isMatIcon: false }
        };
        break;

      default:
        break;
    }
  }
  toSentence(s: string) {
    return toSentence(s);
  }
  // updateUType(ut?: UserType) {
  //   if (!!ut) {
  //     this.selectedUserType = ut;
  //   }
  //   logger.log('selectedUserType', this.selectedUserType);
  //   if (this.setting.defaultUserType !== this.selectedUserType) {
  //     this.setting.defaultUserType = this.selectedUserType;
  //     this.store.dispatch(new SetSettings({
  //       setting: this.setting,
  //       updateLocalDb: true
  //     }));
  //   }
  // }
  getCarrierTrips() {
    this.router.navigate(['/trip/carrier'], { queryParams: { rb: true } });
  }
  getDriverTrips(c?: 'current') {
    if (!!c) {
      this.router.navigate(['/trip/driver-current'], { queryParams: { rb: true } });
    } else {
      this.router.navigate(['/trip/driver'], { queryParams: { rb: true } });
    }
  }
  addTrip() {
    this.router.navigate(['/trip/detail'], { queryParams: { rb: true } });
  }
  recommend() {
    logger.log('implement share');
  }
  addSubMenu() {
    if (this.subMenuData.findIndex(f => f.title === 'Help') === -1) {
      this.subMenuData.push({
        type: 'button',
        title: 'Help',
        icon: 'help',
        click: () => {
          this.ds.openInfoPage({ url: 'home#help-video', styleInfo: 'center-video' }, SubPageComponent);
        }
      });

    }
    this.es.emit(this.es.loadPageOptions, this.subMenuData);
  }
  searchRental(param: RentOptionParam) {
    console.log('[home] search param', { param });
    let q: string;
    if (param.type === ProductType.trailer) {
      q = toQueryStr({ type: param.type, center: param.center, isReefer: param.isReefer, nAxle: param.nAxle }, rentOptionParamInit());
      this.router.navigate(['rent-option/list'], { queryParams: { q } });
    } else if (param.type === ProductType.truck) {
      q = toQueryStr({ type: param.type, center: param.center, isDayCab: param.isDayCab, make: param.make }, rentOptionParamInit());
      this.router.navigate(['rent-option/truck/list'], { queryParams: { q } });
    }
    console.log('[home] search quary', { q });

    // const url = '/rent-option/list';
    // this.router.navigateByUrl(url);
  }

  getSafeHtml(txt: string) {
    return getSafeHtml(txt, this.domSanitizer);
  }


  // handleKeybOpen(b: boolean) {
  //   if (this.us.isPlatformBrowser) {
  //     if (this.platform.is('android') || this.platform.is('cordova')) {
  //       this.keybOpenAndroid = b;
  //       logger.log('[home] keybOpenAndroid', this.keybOpenAndroid);
  //     }
  //   }
  // }
}