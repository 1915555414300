import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductPictureGridComponent } from './product-picture-grid.component';
import { MaterialModule } from '../share-module/material.module';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { CustomPipeModule } from '@trent/custom-pipe/custom-pipe.module';



@NgModule({
  declarations: [
    ProductPictureGridComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    MatSliderModule,
    MatStepperModule,
    MatSlideToggleModule,
    CustomPipeModule
  ],
  exports: [
    ProductPictureGridComponent
  ]
})
export class ProductPictureGridModule { }
