<ng-container>
  <!-- <header class="header1">
    <p>Connecting Carriers, Drivers and Shippers</p> 
  </header> -->
  <trent-gps-control [menuData]="subMenuData" *ngIf="!!subMenuData && !!isHandset && !!userProfile"></trent-gps-control>

  <div class='home-container'>
    <!-- <mat-card *ngIf="!!isHandset">
      <mat-card-content class="search-container">
        <trent-search-shipment (searchByTno)="searchByTno($event)" [isFocusC]="isFocus"></trent-search-shipment>
      </mat-card-content>
    </mat-card> -->
    <div class='boundary'>
      <mat-card appearance="outlined" class="search-container">
        <!-- <trent-search-shipment (searchByTno)="searchByTno($event)" [isFocusC]="isFocus" ></trent-search-shipment> -->
        <trent-rental-search (paramEmit)="searchRental($event)" ></trent-rental-search>
        <!-- (focusEvent)="handleKeybOpen($event)" -->
        <!-- <div *ngIf="selectedUserType !=='anonymous'" class="dashboard-type float-right">
          View Dashboard as a
          <button [color]="selectedUserType === 'carrier' ? 'primary' : ''" mat-raised-button
            (click)="updateUType('carrier')">Carrier</button>
          <button [color]="selectedUserType === 'driver' ? 'primary' : ''" mat-raised-button
            (click)="updateUType('driver')">Driver</button>
          <button [color]="selectedUserType === 'tracker' ? 'primary' : ''" mat-raised-button
            (click)="updateUType('tracker')">Shipper</button>
        </div> -->
      </mat-card>
      <div  class="quad-container" [ngClass]="{'keyb-open-android': keybOpenAndroid}" *ngIf="!!buttons">
        <div>
          <button mat-raised-button mat-stroked-button color="primary" class="quad-button" (click)="buttons.one.fn()">
            <p>
              <mat-icon color="primary" svgIcon="truckIcon" color="white" ></mat-icon>
              <!-- <mat-icon *ngIf="buttons.one.isMatIcon" >{{buttons.one.icon}}</mat-icon>
              <img *ngIf="!buttons.one.isMatIcon"  [src]="buttons.one.icon" class="icon-class"/> -->
            </p>
            <p>{{buttons.one.text}}</p>
          </button>
        </div>

        <div>
          <button mat-raised-button mat-raised-button color="primary" class="quad-button" (click)="buttons.two.fn()">
            <p>
              <mat-icon svgIcon="trailerIcon" color="white" ></mat-icon>
              <!-- <mat-icon *ngIf="buttons.two.isMatIcon" >{{buttons.two.icon}}</mat-icon>
              <img *ngIf="!buttons.two.isMatIcon" [src]="buttons.two.icon"  class="icon-class"/> -->
              <!-- <ng-container *ngIf="setting?.defaultUserType !== 'driver'">
                <mat-icon>local_shipping</mat-icon>
              </ng-container> -->
              <!-- <mat-icon class="offset-icon" >local_shipping</mat-icon> -->
              <!-- <ng-container *ngIf="setting?.defaultUserType === 'driver'">
                <mat-icon>{{buttons.two.icon}}</mat-icon>
              </ng-container> -->
              
            </p>
            <p>{{buttons.two.text}}</p>
          </button></div>

        <div>
          <button mat-raised-button mat-raised-button color="primary" class="quad-button" (click)="buttons.three.fn()">
            <p>
              <mat-icon *ngIf="buttons.three.isMatIcon">{{buttons.three.icon}}</mat-icon>
              <img *ngIf="!buttons.three.isMatIcon" [src]="buttons.three.icon"  class="icon-class"/>

            </p>
            <p>{{buttons.three.text}}</p>
          </button></div>

        <div>
          <button mat-raised-button mat-raised-button color="primary" class="quad-button" (click)="toHowTo()">
            <p>
              <mat-icon>help_outline</mat-icon>
            </p>
            <p>{{buttons.four.text}}</p>
          </button>
        </div>
      </div>

    </div>
  </div>

</ng-container>
<video width="400" controls *ngIf="!isHandset && false">
  <source
    src="https://firebasestorage.googleapis.com/v0/b/lloop-debug.appspot.com/o/site-media%2Fexportlocusloop.2.mp4?alt=media&token=06f513c6-7b76-4573-b159-0327ceb461a3"
    type="video/mp4">
  Your browser does not support HTML5 video.
</video>
<ng-container *ngIf="!isHandset && false">
  <header class="header">
    <p>We Connect Carriers, Drivers and Shippers</p>
    <button mat-raised-button color="accent">Get Started</button>
  </header>
  <div class="container">
    <div class="carrier-write-up">
      <h1>Values for the carrier</h1>
      <h4>Shipment Tracking</h4>
      <ul>Enable your customers to track pickups and deliveries using unique Tracking Number</ul>
      <ul>Real time load tracking notifications</ul>
      <h4>Get paid for the waiting time</h4>
      <ul>GPS based waiting time report delivered to carrier and shipper in real time</ul>
      <h4>Invoice Faster</h4>
      <ul>Enable drivers to take picture of the POD</ul>
      <ul>POD automatically to shipper in real time</ul>
    </div>
    <div class="carrier-visual">
      <img width="400px"
        src="https://firebasestorage.googleapis.com/v0/b/lloop-debug.appspot.com/o/site-media%2FtripSchedule.JPG?alt=media&token=9caa6f91-0101-40cc-b70c-7e0c0513322d">
    </div>
    <div class="driver-write-up">
      <h1>Values for the driver</h1>
      <h4>Claim your waiting time</h4>
      <ul>GPS based waiting time report automatically delivered to carrier and shipper</ul>
      <h4>Detailed schedule available on the app </h4>
      <ul>Get notification as soon as the trip is assigned or updated</ul>
    </div>
    <div class="driver-visual">
      <img width="400px"
        src="https://firebasestorage.googleapis.com/v0/b/lloop-debug.appspot.com/o/site-media%2FwaitingTimeR.JPG?alt=media&token=91c55a6c-9008-4e17-859a-edf73af1aea7">
      <!-- <img width="400px" src="https://firebasestorage.googleapis.com/v0/b/lloop-debug.appspot.com/o/site-media%2FtripMap.JPG?alt=media&token=566e7325-acc6-4b78-a261-21635f430c76"> -->
    </div>
    <div class="shipper-write-up">
      <h1>Values for the shipper</h1>
      <h4>Track your shippment</h4>
      <ul>Check shipment status using unique Tracking Number</ul>
      <h4>Realtime notifications </h4>
      <ul>Get notified by email or app messaging as soon as driver reaches shippment location</ul>
      <h4>Proof of deliveries</h4>
      <ul>Get proof deliveries as soon as driver snaps a picture</ul>
    </div>
    <div class="shipper-visual">
      <img width="400px"
        src="https://firebasestorage.googleapis.com/v0/b/lloop-debug.appspot.com/o/site-media%2FtrackingNoSearch.JPG?alt=media&token=0a5faaf0-bef5-4093-ab30-abe18e7ab0dd">

    </div>
  </div>

</ng-container>


<ng-container *ngIf="true">
  <div *ngIf="false">
    <p>LocusLoop HOME Page cache</p>
    <p>App Start Time: {{ cms.initTime | date: "medium" }}</p>
    <p>App Load URL: {{ cms.initUrl }}</p>
    <p class="test">Server Time : {{ serverTime }}</p>
    <p class="test">Client Time : {{ clientTime | date: "medium" }}</p>
    <div>
      <button (click)="showBusy()">busy-show</button>
      <button (click)="hideBusy()">busy-hide</button>
    </div>
    <div>
      <button (click)="ds.alert(alertMsg)">alert</button>
      <button (click)="confirm()">confirm</button>
    </div>

    <div>
      <button (click)="snakBar()">Snack</button>
    </div>

    <div ck-inline [page]="page" [sectionName]="'body1'">

    </div>

    <div ck-inline [page]="page" [sectionName]="'body2'">

    </div>

  </div>
  <!-- <div>
    <img style="width: 350px; height: auto;" *ngIf="!!imageUrl" [src]="imageUrl" alt="" />
    <button type="button" (click)="takePic(0)" class="md-18 right" mat-stroked-button>
      <mat-icon>camera</mat-icon>camera-data-url
    </button>
    <button type="button" (click)="takePic(1)" class="md-18 right" mat-stroked-button>
      <mat-icon>camera</mat-icon>camera-file-uri
    </button>
    <button type="button" (click)="takePic(2)" class="md-18 right" mat-stroked-button>
      <mat-icon>camera</mat-icon>camera-native-file-url
    </button>
  </div> -->

</ng-container>
