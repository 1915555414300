// import { ProductSearchParam, productOptionInit } from '../product/product-search-option';
import { IDefaultCompanyData } from '../user/company-user-profile';
import { tripParamInit, TripParam } from '../trip/trip-param';
// import { RentOptionParam, rentOptionParamInit, myRentalParamInit } from '../rental/rent-option-param';
// import { BidParam, bidParamInit } from '../bid/bid-param';
// import { TripParam, tripParamInit } from '../trip/trip-param';
// import { PickDropParam, pickDropParamInit } from '../pickup-dropoff/pick-drop-param';
import { ITripTrackerParam, tripTrackerParamInit } from '../trip/trip-tracker-param';
import { UserType } from '../user/user-profile';
import { ICommPreference } from './comm-preference';
import { commPreferenceInit } from './setting-helper';
import { ProductSearchParam, productOptionInit } from '../product/product-search-option';
import { RentOptionParam, rentOptionParamInit } from '../rental/rent-option-param';
import { TripType } from '../trip/trip-type';

/**
 * User Level Settings. Will be stored in the client cache as well as
 * in the database UserProfile page.
 */
export interface UserSetting {

  /**
   * Default Company that is currently active.
   */
  defaultCompany: IDefaultCompanyData;
  defaultUserType: UserType;

  /**
   * Product List, Search Option.
   */
  prodOption: ProductSearchParam;
  rentOptionParam: RentOptionParam;
  // myRentalParam: RentOptionParam;
  tripParam: TripParam;
  // bidParam: BidParam;
  // pickDropParam: PickDropParam;
  commPreference: ICommPreference;
  trackerParam: ITripTrackerParam;
  tripOptions: { isHOS: boolean, tripType?: TripType };

}
export enum UserSettingType {
  defaultCompany = 1,
  prodOption = 2,
  rentOptionParam = 3,
  myRentalParam = 4,
  tripParam = 5,
  bidParam = 6,
  pickDropParam = 7,
  commParam = 8, // comm Preference setting
  /** trip search parameters */
  trackerParam = 9,
  /** trip configration parameters e.g. Does HOS rule apply */
  tripOptions = 10
}

export const initUserSetting = (): UserSetting => {
  return {
    defaultCompany: null,
    defaultUserType: 'tracker',
    prodOption: productOptionInit(),
    rentOptionParam: rentOptionParamInit(),
    tripParam: tripParamInit(),
    // bidParam: bidParamInit(),
    // myRentalParam: myRentalParamInit(),
    // pickDropParam: pickDropParamInit()
    commPreference: commPreferenceInit(),
    trackerParam: tripTrackerParamInit(),
    tripOptions: { isHOS: true }

  };
};
