<ng-container *ngIf="mapView || isHandset">
  <trent-slide-panel [activePane]="activePane" (activePanChg)="activePane = $event" [heightRequire]="false">
    <div leftPane class="left">
      <ng-container>
        <ng-container *ngTemplateOutlet="vehCards"></ng-container>
      </ng-container>
    </div>
    <div rightPane class="h-100">
      <ng-container *ngIf="mapLabels">
        <trent-map-wrapper [mLabels]="mapLabels"></trent-map-wrapper>
      </ng-container>
    </div>
  </trent-slide-panel>
</ng-container>

<div *ngIf="!isHandset && !mapView" class="list-container-desktop">
  <ng-container>
    <div class="table-area">
      <div class="table_header">
        <div class="table-toolbar">
            <mat-form-field style="margin-bottom: -1.25em">
              <mat-label>Filter by Vin, Make, Model</mat-label>
              <input type="text" autocomplete="off" style="display:none"> <!-- Auto complete off in browser in load the page-->
              <input matInput type="text" name="search" [formControl]="searchText" autocomplete="off">
            </mat-form-field>
        </div>
        <div>
          <button *ngIf="!userRole?.sales" mat-flat-button [matMenuTriggerFor]="menu" (click)="generateList()">Export
            CSV</button>
          <mat-menu #menu="matMenu">
            <a mat-menu-item *ngFor="let marPartner of marketingPartner"
              (click)="downloadFile(marPartner.id)">{{marPartner.name}}</a>
          </mat-menu>
        </div>
      </div>
      <div class="table" *ngIf="m && m?.length > 0;">
        <ng-container>
          <table mat-table [dataSource]="dataSource">
            <!-- VIN Column -->
            <ng-container matColumnDef="vin">
              <th mat-header-cell *matHeaderCellDef> VIN </th>
              <td mat-cell *matCellDef="let element">
                {{element.productSummary.vin}}
              </td>
            </ng-container>

            <!-- Model Column -->
            <ng-container matColumnDef="model">
              <th mat-header-cell *matHeaderCellDef> Model </th>
              <td mat-cell *matCellDef="let element">
                {{element.productSummary.model}}
              </td>
            </ng-container>

            <!-- Make Column -->
            <ng-container matColumnDef="make">
              <th mat-header-cell *matHeaderCellDef> Make </th>
              <td mat-cell *matCellDef="let element">
                {{element.productSummary.make}} {{element.productSummary.modelYear}}
              </td>
            </ng-container>

            <!-- Store Location Column -->
            <ng-container matColumnDef="storeLocation">
              <th mat-header-cell *matHeaderCellDef> Marketing Location </th>
              <td mat-cell *matCellDef="let element">
                {{element.storeLocationSummary.name}}
              </td>
            </ng-container>

            <!-- Odometer Column -->
            <ng-container matColumnDef="odometer">
              <th mat-header-cell *matHeaderCellDef> Odometer </th>
              <td mat-cell *matCellDef="let element">
                {{element.productSummary?.odometer}} {{element.productSummary?.odometerUnits}}
              </td>
            </ng-container>

            <!-- Wholesale Column -->
            <ng-container matColumnDef="wholesalePrice">
              <th mat-header-cell *matHeaderCellDef style="width: 12% !important"> Wholesale Price </th>
							<td mat-cell *matCellDef="let element" style="width: 12% !important">
                <div class="table-icon-wrapper">
                  <span>{{toCurrency(element?.wholesalePrice, element.currency)}}</span>  
                <button mat-icon-button color="secondary" (click)="showPriceLog(element)">
                  <mat-icon aria-label="log">price_change</mat-icon>
                </button>
                </div>
              </td>
            </ng-container>

            <!-- Retail Price Column -->
            <ng-container matColumnDef="retailPrice">
              <th mat-header-cell *matHeaderCellDef> Retail Price </th>
              <td mat-cell *matCellDef="let element">
                {{toCurrency(element.price, element.currency)}}
              </td>
            </ng-container>

            <!-- Inventory Age Column -->
            <ng-container matColumnDef="age">
              <th mat-header-cell *matHeaderCellDef> Inventory Age </th>
              <td mat-cell *matCellDef="let element">
                {{ changeInvAge(element?.inventoryAge) }}
              </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let element">
                {{ getStatus(element?.status) }}
                <span *ngIf="(element?.status === salesStatusEnum.hold) && !!element?.holdDays">
                  {{em?.holdDays}}
                </span>
              </td>
            </ng-container>

            <!-- Action -->
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Action</th>
              <td mat-cell *matCellDef="let element">
                <div style="gap: 5px" class="d-flex d-a-center">
                  <!-- Edit -->
                  <button mat-icon-button color="primary" (click)="showOnMap(element)"
                    [disabled]="gpsIconDisable(element)">
                    <mat-icon aria-label="Edit">gps_fixed</mat-icon>
                  </button>
                  <button mat-icon-button [disabled]="!isRoleValid" color="primary" (click)="setupSales(element.id)">
                    <mat-icon aria-label="Edit">edit</mat-icon>
                  </button>
                  <div class="transfer-button" *ngIf="isRoleValid">
                    <!-- Transfer -->
                    <span [matTooltip]="getToolTipInfo(element.productSummary.pid)" matTooltipPosition="above">
                      <button mat-button [disabled]="!isRoleValid"
                        [color]="!!getToolTipInfo(element.productSummary.pid)?'primary':'warning'"
                        (click)="transfer(element.productSummary.pid)">
                        {{ !!getToolTipInfo(element.productSummary.pid) ? 'In Transit' : 'Transfer'}}
                      </button>
                    </span>
                  </div>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
          </table>
          <mat-paginator class="pagination" aria-label="Select page" [pageSize]="pagination.size"
            [length]="pagination.count" [pageIndex]="pagination.offset" (page)="pageEvents($event)"></mat-paginator>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <div *ngIf="m?.length === 0" class="no-data-wrapper">
    <mat-card class="no-data-card">
      <p class="no-data-text">No Sales Option found.</p>
    </mat-card>
  </div>
</div>

<ng-template #vehCards>
  <ng-container>
    <mat-sidenav-container class="mobile-sideNav-container">
      <mat-sidenav-content>
        <div class="card-list-container" *ngIf="!!dataSource && dataSource?.data?.length > 0">
          <div *ngIf="!mapView && isHandset">
            <mat-card appearance="outlined" class="filter-container">
              <div>
                <mat-form-field>
                  <mat-label>Filter</mat-label>
                  <input type="text" autocomplete="off" style="display:none"> <!-- Auto complete off in browser in load the page-->
                  <input matInput type="text" name="search" [formControl]="searchText" autocomplete="off">
                  <button style="font-size: 25px" type="button" color="primary" mat-icon-button matSuffix
                    (click)="clearFilter();">
                    <mat-icon>search_off</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <div *ngIf="!mapView" class="d-flex d-j-center">
                <button mat-mini-fab color="primary" [matMenuTriggerFor]="menu">
                  <mat-icon class="e-icon">ios_share</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <a mat-menu-item *ngFor="let marPartner of marketingPartner"
                    (click)="downloadFile(marPartner.id)">{{marPartner.name}}</a>
                </mat-menu>
              </div>
            </mat-card>
          </div>
          <div>
            <ng-container *ngIf="mapView && !isHandset">
              <button type="button" color="primary" mat-flat-button matSuffix (click)="backToList()">
                <mat-icon>arrow_back</mat-icon>Back
              </button>
            </ng-container>
            <ng-container *ngIf="!mapView">
              <ng-container *ngFor="let d of dataSource.filteredData">
                <ng-container *ngTemplateOutlet="vCard context:{ d:d }"></ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="mapView && selectedProduct">
              <ng-container *ngTemplateOutlet="vCard context:{ d:selectedProduct }"></ng-container>
            </ng-container>
          </div>
          <mat-paginator class="pagination" aria-label="Select page" [pageSize]="pagination.size"
            [length]="pagination.count" [pageIndex]="pagination.offset" (page)="pageEvents($event)"
            *ngIf="!mapView"></mat-paginator>
        </div>
        <div *ngIf="dataSource?.data?.length === 0" class="no-data-wrapper">
          <mat-card class="no-data-card">
            <p class="no-data-text">No Sales Option found.</p>
          </mat-card>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>

  </ng-container>
</ng-template>


<ng-template #vCard let-d="d">
  <div class="mobile-card-container">
    <mat-card appearance="outlined" class="card-mobile">
      <div class="card-list">
        <h6>Vin</h6>
        <div> {{d.productSummary.vin}} </div>
        <div class="divider">
          <mat-divider></mat-divider>
        </div>
        <h6>Make</h6>
        <div> {{d.productSummary.make}} {{d.productSummary.modelYear}}</div>
        <div class="divider">
          <mat-divider></mat-divider>
        </div>
        <h6>Model</h6>
        <div> {{d.productSummary.model}}</div>
        <div class="divider">
          <mat-divider></mat-divider>
        </div>
        <h6>Marketing Location</h6>
        <div> {{d.storeLocationSummary.name}} </div>
        <div class="divider">
          <mat-divider></mat-divider>
        </div>
        <h6>Odometer</h6>
        <div>{{d.productSummary?.odometer}} {{d.productSummary?.odometerUnits}}</div>
        <div class="divider">
          <mat-divider></mat-divider>
        </div>
        <h6>Wholesale Price</h6>
        <div class="w-break">
          <div class="table-icon-wrapper">
            {{toCurrency(d.wholesalePrice, d.currency)}}
            <!-- Price Log -->
            <button mat-icon-button color="secondary" (click)="showPriceLog(d)">
              <mat-icon aria-label="log">price_change</mat-icon>
            </button>
          </div>
        </div>
        <div class="divider">
          <mat-divider></mat-divider>
        </div>
        <h6>Retail Price</h6>
        <div class="w-break">
          {{toCurrency(d.price, d.currency)}}
        </div>
        <div class="divider">
          <mat-divider></mat-divider>
        </div>
        <h6>Inventory Age</h6>
        <div class="w-break">{{ d.inventoryAge }}</div>
        <div class="divider">
          <mat-divider></mat-divider>
        </div>
        <ng-container>
          <h6>Status </h6>
          <div>{{ getStatus(d.status) }}</div>
        </ng-container>
      </div>
      <div class="list-action-bottom d-flex d-a-center">
        <div class="transfer-button" *ngIf="isRoleValid">
          <!-- Transfer -->
          <span [matTooltip]="getToolTipInfo(d.productSummary.pid)" matTooltipPosition="above">
            <button mat-button [disabled]="!isRoleValid"
              [color]="!!getToolTipInfo(d.productSummary.pid)?'primary':'warning'"
              (click)="transfer(d.productSummary.pid)">
              Transfer
            </button>
          </span>
        </div>
        <button mat-icon-button color="primary" (click)="showOnMap(d)" [disabled]="gpsIconDisable(d)">
          <mat-icon aria-label="Edit">gps_fixed</mat-icon>
        </button>
        <!-- Edit -->
        <button mat-icon-button [disabled]="!isRoleValid" type="button" color="primary" (click)="setupSales(d.id)">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </mat-card>
  </div>
</ng-template>