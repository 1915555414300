import { StateBase } from '../state-base';
import { State, StateContext, Action, Selector } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { noop } from 'rxjs';
import * as entity from '../entity.state';
import { Injectable } from '@angular/core';
import { getObjKey, Paging } from '@trent/models/observable-util/paging';
import { logger } from '@trentm/log/logger';
import { buildDataRequest, getRootLevelChildren, IDataLoadStatus, LoadStatus, updatePaging } from '@trent/models/observable-util/data-status';
import { PagingContainer } from '@trent/models/observable-util/paging-container';
import { MinimumSellingPrice } from '@trent/models/minimum-selling-price/minimum-selling-price';
import { parseMinimumSellingPrice } from '@trent/models/minimum-selling-price/minimum-selling-price-helper';
import { getMinimumSellingPriceOptionOrChildren, MinimumSellingPriceParam, minimumSellingPriceSearchClientFilter } from '@trent/models/minimum-selling-price/minimum-selling-price-param';
import { MinimumSellingPriceService } from '@trent/services/minimum-selling-price.service';

// #region State Model
export interface MinimumSellingPriceStateModel {

	// New approach with paging
	minimumSellingPriceByUserLoaded: boolean;
	minimumSellingPriceByUser: { [id: string]: MinimumSellingPrice };  // changed

	/** Which client side queries are complete */
	dataLoadStatus: {
		[key: string]: IDataLoadStatus<MinimumSellingPriceParam>;
	};
	allminimumSellingPricesLoaded: boolean;
	minimumSellingPrices: { [id: string]: MinimumSellingPrice }; // entity.EntityState<BidBase>;
}

function initminimumSellingPriceState() {
	return {
		minimumSellingPriceByUserLoaded: false,
		minimumSellingPriceByUser: {},
		allminimumSellingPricesLoaded: false,
		dataLoadStatus: {},
		minimumSellingPrices: {},
	};
}

// #endregion

// #region Actions for new data methods with paging

export class GetMinimumSellingPriceByUserRequested {
	static readonly type = '[MinimumSellingPrices] Get Minimum Selling Prices for user - requested ( initial page)';
	constructor(public payload: { pData: Paging; param: any }) { }
}

export class MinimumSellingPriceUpdated {
	static readonly type = '[MinimumSellingPrices] Minimum Selling Price Updated';
	constructor(public payload: { sid: string | number, minimumSellingPrice: MinimumSellingPrice }) { }
}

export class MinimumSellingPriceRequested {
	static readonly type = '[MinimumSellingPrice] Request a single Minimum Selling Price';
	constructor(public payload: { id: string | number }) { }
}
export class MinimumSellingPriceLoaded {
	static readonly type = '[MinimumSellingPrice] Load a single Minimum Selling Price entity';
	constructor(public payload: { id: string | number, data: MinimumSellingPrice }) { }
}

export class MinimumSellingPriceStateReset {
	static readonly type = '[MinimumSellingPrice] Reset State';
}
export class MinimumSellingPriceRemoveById {
	static readonly type = '[MinimumSellingPrice] Remove by Id';
	constructor(public payload: { sid: string | number }) { }
}
export class MinimumSellingPricesRequested {
	static readonly type = '[MinimumSellingPrices] All Minimum Selling Prices Requested';
	constructor(public payload: { pData: Paging, param: MinimumSellingPriceParam }) { }
}
export class MinimumSellingPricesLoaded {
	static readonly type = '[MinimumSellingPrice] All Minimum Selling Prices Loaded';
	constructor(public payload: {
		data: { [id: string]: MinimumSellingPrice }, // Data
		key: string,
	}) { }
}
export class MinimumSellingPricesNextPageRequested {
	static readonly type = '[MinimumSellingPrice] All Minimum Selling Prices requested - Next Page';
	constructor(public payload: { option: MinimumSellingPriceParam }) { }
}
export class MinimumSellingPricesUpdated {
	static readonly type = '[MinimumSellingPrice] Minimum Selling Prices Updated';
	constructor(public payload: { id: string | number, data: MinimumSellingPrice }) { }
}
export class GetMinimumSellingPriceByUserLoaded {
	static readonly type = '[MinimumSellingPrice] Get Minimum Selling Prices for user - loaded (paged)';
	constructor(public payload: { data: { [id: string]: MinimumSellingPrice } }) { }
}

// #endregion

@State<MinimumSellingPriceStateModel>({
	name: 'minimumSellingPrice',
	defaults: initminimumSellingPriceState()
})
@Injectable()
export class MinimumSellingPriceState extends StateBase {

	/** Container that keep all of the subscription related to gettting data. */
	dataSubData: PagingContainer<MinimumSellingPrice, MinimumSellingPriceParam> = new PagingContainer();

	constructor(private MinimumSellingPriceService: MinimumSellingPriceService) {
		super();
	}

	// #region Selectors

	/** Get minimumSellingPrice list (no draft/rev entires are not included) */
	@Selector()
	static selectAllminimumSellingPricesOld(state: MinimumSellingPriceStateModel) {

		// remove keys that have revision/draft ids, i.e that contain '~' in the id.
		const keys = Object.keys(state.minimumSellingPriceByUser).filter(k =>
			k.indexOf('/') === -1 && state.minimumSellingPriceByUser[k] != null);

		// object without the rev/draft.
		const filtered = keys.reduce((obj, k) => {
			obj[k] = state.minimumSellingPriceByUser[k];
			return obj;
		}, {});

		const output = Object.values(filtered).map(x => parseMinimumSellingPrice(x));

		return output;
	}

	@Selector()
	static selectminimumSellingPriceById(state: MinimumSellingPriceStateModel) {
		// new approach, use dictionary of company.
		return entity.getByIdFn_new(state.minimumSellingPriceByUser, parseMinimumSellingPrice);
	}

	@Selector()
	static selectAllminimumSellingPrices(state: MinimumSellingPriceStateModel) {
		return (o: MinimumSellingPriceParam): MinimumSellingPrice[] => {


			if (state?.minimumSellingPrices == null || Object.keys(state.minimumSellingPrices).length === 0) {
				return [];
			}

			// remove keys that have revision/draft ids, i.e that contain '~' in the id.
			const keys = Object.keys(state.minimumSellingPrices).filter(k =>
				k.indexOf('/') === -1 && state.minimumSellingPrices[k] != null);

			// object without the rev/draft.
			const filtered = keys.reduce((obj, k) => {
				obj[k] = state.minimumSellingPrices[k];
				return obj;
			}, {});

			let output = Object.values(filtered).map(x => parseMinimumSellingPrice(x));
			output = minimumSellingPriceSearchClientFilter(output, o);

			return output;
		};
	}

	// #endregion

	@Action(GetMinimumSellingPriceByUserLoaded)
	getminimumSellingPriceByUserLoaded(context: StateContext<MinimumSellingPriceStateModel>, action: GetMinimumSellingPriceByUserLoaded) {
		const state = context.getState();
		context.patchState({
			minimumSellingPriceByUserLoaded: true,
			minimumSellingPriceByUser: { ...state.minimumSellingPriceByUser, ...action.payload.data },
		});
	}

	@Action(MinimumSellingPriceRequested)
	minimumSellingPriceRequested(context: StateContext<MinimumSellingPriceStateModel>, action: MinimumSellingPriceRequested) {
		const state = context.getState();
		const id = action.payload.id;
		if (state.minimumSellingPriceByUser[action.payload.id] == null) {
			// logger.log('comp by id, not found in the store, gettting from server....');
			const s = this.MinimumSellingPriceService.gedminimumSellingPriceById(action.payload.id)
				.pipe(
					map(data => {
						return context.dispatch(new MinimumSellingPriceLoaded({ id, data }));
					}
					));
			this.subscribe(s, (x) => noop(), MinimumSellingPriceRequested.type);
		}
	}

	@Action(MinimumSellingPriceLoaded)
	minimumSellingPriceLoaded(context: StateContext<MinimumSellingPriceStateModel>, action: MinimumSellingPriceLoaded) {
		const state = context.getState();
		const c = {};
		c[action.payload.data?.id] = action.payload.data;
		context.patchState({
			minimumSellingPriceByUser: { ...state.minimumSellingPriceByUser, ...c }
		});
	}


	@Action(MinimumSellingPriceUpdated)
	minimumSellingPriceUpdated(context: StateContext<MinimumSellingPriceStateModel>, action: MinimumSellingPriceUpdated) {
		const state = context.getState();
		let c = action.payload.minimumSellingPrice;
		if (typeof (c.toFirebaseObj()) === 'function') {
			c = c.toFirebaseObj();
		}
		context.patchState({
			minimumSellingPriceByUser: { ...state.minimumSellingPriceByUser, ...{ [action.payload.sid]: c } }
		});
	}


	@Action(MinimumSellingPriceStateReset)
	MinimumSellingPriceStateReset(context: StateContext<MinimumSellingPriceStateModel>, action: MinimumSellingPriceStateReset) {
		// unsubscribe the data
		this.clearSubscriptions();
		context.setState(initminimumSellingPriceState());
	}

	@Action(MinimumSellingPriceRemoveById)
	dataByIdRemoved(context: StateContext<MinimumSellingPriceStateModel>, action: MinimumSellingPriceRemoveById) {
		const state = context.getState();
		if (Object.keys(state.minimumSellingPriceByUser).indexOf(`${action.payload.sid}`) > -1) {
			const currState = context.getState();
			const newData = { ...currState.minimumSellingPriceByUser };
			delete newData[action.payload.sid];
			context.patchState({ minimumSellingPriceByUser: newData });
			const state1 = context.getState();
			logger.log('[minimumSellingPrice-State], item removed by id', action.payload.sid, state1);

		} else { logger.log('[minimumSellingPrice-State], item to be removed id is not available in the store'); }
	}
	// #region All Records
	@Action(MinimumSellingPricesRequested)
	dataRequested(context: StateContext<MinimumSellingPriceStateModel>, action: MinimumSellingPricesRequested) {

		const oKey = getObjKey(action.payload.param);

		// Define function that return the data status object from the state.
		const getDataStatusStateFn = () => context.getState().dataLoadStatus;

		/** custom build the OR children query. */
		const buildOrQueryChildrenFn = (o: MinimumSellingPriceParam) => getMinimumSellingPriceOptionOrChildren(o);

		// if data requested now, is already partially loaded by another query's child previously
		// but the number of the items are not enough (ex. as a child, it loaded only 5, but current
		// request ask for more, then next page of that query should be called instead.)
		const nextPageFn = (option: MinimumSellingPriceParam) => {
			context.dispatch(new MinimumSellingPricesNextPageRequested({ option }));
		};

		buildDataRequest(
			oKey, action.payload.param, action.payload.pData,
			getDataStatusStateFn,
			buildOrQueryChildrenFn,
			nextPageFn,
			(
				obj: { [key: string]: IDataLoadStatus<MinimumSellingPriceParam> },
				set: { key: string, node: IDataLoadStatus<MinimumSellingPriceParam> }[]
			) => {

				if (!!obj) {
					// Patch the state.
					const state = context.getState();
					context.patchState({
						dataLoadStatus: { ...state.dataLoadStatus, ...obj }
					});
				}

				// Process the query.
				set.forEach((val) => {
					// some of the nodes are already loaded. Only process that are loading... status.
					if (val.node.loadStatus !== LoadStatus.Loading) {
						return;
					}
					// if this request is just overwriting a stall or pending request, unsubscribe that observable
					this.dataSubData.unsubscribe(val.key);

					// create the paging observable and call db.
					const p = this.MinimumSellingPriceService.getAllminimumSellingPrices_PagingObservable();
					const prod$ = p.getData(action.payload.pData, val.node.param)
						.pipe(
							map(pickDrops => {
								context.dispatch(new MinimumSellingPricesLoaded({
									data: pickDrops as any,
									key: val.key
								}));
								return pickDrops;
							}));
					const sub = this.subscribe(prod$, () => noop(), MinimumSellingPriceRequested.type);
					// save the observable call
					this.dataSubData.addData(val.key, sub, p);
				});
			}
		);
	}

	@Action(MinimumSellingPricesLoaded)
	minimumSellingPricesLoaded(context: StateContext<MinimumSellingPriceStateModel>, action: MinimumSellingPricesLoaded) {
		const state = context.getState();
		const subData = this.dataSubData.getData(action.payload.key);
		const updatedLoadStatus = updatePaging(action.payload.key, state.dataLoadStatus, subData);
		context.patchState({
			allminimumSellingPricesLoaded: true,
			dataLoadStatus: updatedLoadStatus,
			minimumSellingPrices: { ...state.minimumSellingPrices, ...action.payload.data } // entity.addMany(state.bids, action.payload.bids)
		});
	}

	@Action(MinimumSellingPricesNextPageRequested)
	allCompaniesNextPageRequested(context: StateContext<MinimumSellingPriceStateModel>, action: MinimumSellingPricesNextPageRequested) {
		const oKey = getObjKey(action.payload.option);
		const state = context.getState();
		// find the node. can be parent or child
		const statusObj = state.dataLoadStatus[oKey];
		// if no parent, treat is
		if (statusObj.children == null) {
			this.dataSubData.dispatchNextPagingUpdate(oKey);
		} else {
			const children = getRootLevelChildren(oKey, state.dataLoadStatus);
			children.forEach(c => {
				this.dataSubData.dispatchNextPagingUpdate(c.key);
			});
		}
	}
}
