import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { CustomPipeModule } from '@trent/custom-pipe/custom-pipe.module';
import { MaterialModule } from '../../shared/share-module/material.module';
import { CkEditorModule } from '../../shared/ck-editor/ck-editor.module';
import { Common2Module } from '../../shared/common2/common2.module';
import { SalesOptionStoreModule } from '@trent/store/sales-option-store';
import { SlidePanelModule } from '../../shared/slide-panel/slide-panel.module';
import { CarouselModule } from '../../shared/carousel/carousel.module';
import { CarouselViewerModule } from '../../shared/carousel-viewer/carousel-viewer.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PtsCommonModule } from '../pts-common/pts-common.module';
import { SubPageModule } from '../../shared/sub-page/sub-page.module';
import { MatTableModule } from '@angular/material/table';
import { SalesOptionsListComponent } from './list/sales-options-list.component';
import { SalesOptionsDetailsComponent } from './details/sales-options-details.component';
import { FormDisplayMode } from '@trent/models/UI';
import { ProductType } from '@trent/models/product';
import { SalesOptionsProductListingComponent } from './sales-options-product-listing/sales-options-product-listing.component';
import { SalesOptionsBulkUploadComponent } from './sales-options-bulk-upload/sales-options-bulk-upload.component';
import { SalesOptionsUploadPictureComponent } from './sales-options-upload-picture/sales-options-upload-picture.component';
import { SalesStatusComponent } from './details/sales-status/sales-status.component';
import { GooglePLacesAutoDirectiveModule } from '../../directive/google-places-auto.module';
import { SearchSalesOptionModule } from '../../shared/pts-search-sales-option/pts-search-sales-option.module';
import { ProductPictureGridModule } from '../../shared/product-picture-grid/product-picture-grid.module';
import { StoreLocationStoreModule } from '@trent/store/store-location-store';
import { MapWrapperModule } from '../../shared/map-wrapper/map-wrapper.module';
import { MapContainerModule } from '../../shared/map-container/map-container.module';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { FileUploadModule } from '../../shared/file-upload/file-upload.module';
import { SocialShareModule } from '../../shared/social-share/social-share.module';
import { SearchRentalModule } from '../../shared/search-rental/search-rental.module';
import { SelectCompanyModule } from '../../shared/select-company/select-company.module';
import { ProductStoreModule } from '@trent/store/product-store';
import { CompanyStoreModule } from '@trent/store/company-store';
import { RentalProductStoreModule } from '@trent/store/rental-product-store';
import { PromoListStoreModule } from '@trent/store/promo-list-store';
import { ReleaseStatusModule } from '../../shared/release-status/release-status.module';
import { SalesOptionService } from '@trent/services/sales.service';
import { StoreLocationService } from '@trent/services/store-location.service';
import { AddOnStoreModule } from '@trent/store/addOn-store';
import { PtsAddOnComponent } from './add-on/pts-add-on.component';
import { AddonDetailComponent } from './add-on/addon-detail/addon-detail.component';
import { MultiGuard } from '@trent/core/multi.guard';
import { AuthGuard } from '@trent/core';
import { AddOnService } from '@trent/services/add-on.service';
import { ProxyVinSelectProductListComponent } from './details/proxy-vin-select-product-list/proxy-vin-select-product-list.component';
import { ProductService } from '@trent/services/product.service';
import { UserStoreModule } from '@trent/store/user-store';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AssetLocService } from '@trent/services/asset-loc.service';
import { SalesWarrantyComponent } from './details/sales-warranty/sales-warranty.component';
import { EditLotModule } from '../../shared/edit-lot/edit-lot/edit-lot.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { minimumSellingPriceStoreModule } from '@trent/store/minimum-selling-price';
import { PriceLogComponent } from './details/price-log/price-log.component';

const routes: Routes = [
  { path: 'list', canActivate: [MultiGuard], component: SalesOptionsListComponent,  runGuardsAndResolvers: 'always' },
  { path: 'truck-details/:id', canActivate: [MultiGuard], component: SalesOptionsDetailsComponent, data: { mode: FormDisplayMode.read, pType: ProductType.truck } },
  { path: 'trailer-details/:id', canActivate: [MultiGuard], component: SalesOptionsDetailsComponent, data: { mode: FormDisplayMode.read, pType: ProductType.trailer } },
  { path: 'truck-details', canActivate: [MultiGuard], component: SalesOptionsDetailsComponent, data: { mode: FormDisplayMode.create, pType: ProductType.truck } },
  { path: 'trailer-details', canActivate: [MultiGuard], component: SalesOptionsDetailsComponent, data: { mode: FormDisplayMode.create, pType: ProductType.trailer } },
  { path: 'bulk-upload', canActivate: [AuthGuard], component: SalesOptionsBulkUploadComponent, data: { mode: FormDisplayMode.create } },
  // Yard Person
  { path: 'truck-list', canActivate: [MultiGuard], component: SalesOptionsProductListingComponent, data: { pType: ProductType.truck, uploadPicPage: true } },
	{ path: 'trailer-list', canActivate: [MultiGuard], component: SalesOptionsProductListingComponent, data: { pType: ProductType.trailer, uploadPicPage: true } },
	{ path: 'upload-picture/:pid', canActivate: [MultiGuard], component: SalesOptionsUploadPictureComponent },
  // AddOn Routes
  { path: 'addons', canActivate: [AuthGuard], component: PtsAddOnComponent },
  { path: 'addons/detail', canActivate: [AuthGuard], component: AddonDetailComponent, data: { mode: FormDisplayMode.create } },
  { path: 'addons/detail/:id', canActivate: [AuthGuard], component: AddonDetailComponent, data: { mode: FormDisplayMode.edit } },
];

@NgModule({
  declarations: [
    SalesOptionsListComponent,
    SalesOptionsDetailsComponent,
    SalesOptionsProductListingComponent,
    SalesOptionsBulkUploadComponent,
    SalesStatusComponent,
    SalesOptionsUploadPictureComponent,
    PtsAddOnComponent,
    AddonDetailComponent,
    ProxyVinSelectProductListComponent,
    SalesWarrantyComponent,
    PriceLogComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MatIconModule,
    CustomPipeModule,
    MaterialModule,
    MatTableModule,
    MapWrapperModule,
    MapContainerModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatCheckboxModule,
    MatGridListModule,
    CkEditorModule,
    FormsModule,
    PtsCommonModule,
    SubPageModule,
    RouterModule.forChild(routes),
    Common2Module,
    CustomPipeModule,
    SalesOptionStoreModule,
    minimumSellingPriceStoreModule,
    SlidePanelModule,
    CarouselModule,
    CarouselViewerModule,
    ReactiveFormsModule,
    GooglePLacesAutoDirectiveModule,
    SearchSalesOptionModule,
    ProductPictureGridModule,
    StoreLocationStoreModule,
    FileUploadModule,
    SocialShareModule,
    SearchRentalModule,
    SelectCompanyModule,
    ProductStoreModule,
    CompanyStoreModule,
    RentalProductStoreModule,
    PromoListStoreModule,
    ReleaseStatusModule,
    AddOnStoreModule,
    UserStoreModule,
    MatTooltipModule,
    EditLotModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    CurrencyPipe,
    SalesOptionService,
    StoreLocationService,
    AddOnService,
    ProductService,
    AssetLocService
  ]
})
export class PtsSalesOptionsModule { }
