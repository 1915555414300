import { AuthState } from '../store/auth-store/auth.state';
import { ValidatorOptions } from 'class-validator';
import { Injectable } from '@angular/core';
import { FirestoreService } from '@trent/services/firestore.service';
import { map, tap } from 'rxjs/operators';
import { instanceToPlain } from 'class-transformer';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@trent/services/base-http.service';
import { Store } from '@ngxs/store';
import { logger } from '@trent/models/log/logger';
import { MinimumSellingPrice } from '@trent/models/minimum-selling-price/minimum-selling-price';
import { Paging } from '@trent/models/observable-util/paging';
import { PagingObesrvable } from '@trent/models/observable-util/paging-obesrvable';
import { Observable } from 'rxjs';
import { MinimumSellingPriceParam, minimumSellingPriceSearchServerQuery } from '@trent/models/minimum-selling-price/minimum-selling-price-param';

// https://angularfirebase.com/lessons/firestore-advanced-usage-angularfire/

@Injectable()
export class MinimumSellingPriceService extends BaseHttpService {

	constructor(store: Store, private db: FirestoreService, private http: HttpClient) {
		super(store);
		this.apiName = 'api';
	}

	public create(s: MinimumSellingPrice, option: ValidatorOptions) {
		// Prepare the post data
		const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

		// Server validation before creating the entry.
		return this.http.post<{ minimumSellingPrice: MinimumSellingPrice }>(this.getApiUrl('/minimum-selling-price/create'),
			{
				payload: instanceToPlain(s),
				option: option
			},
			{ headers: headers })
			.pipe(
				tap(r => logger.log('response: ', r)),
			);
	}

	public update(sid: string | number, s: MinimumSellingPrice, option?: ValidatorOptions) {
		// Prepare the post data
		const headers = this.addBearerToken();
		// Server validation before creating the entry.
		return this.http.post<{ sid: string | number, minimumSellingPrice: MinimumSellingPrice }>(this.getApiUrl('/minimum-selling-price/update'),
			{
				sid: sid,
				payload: instanceToPlain(s),
				option: option
			},
			{ headers: headers })
			.pipe(
				tap(r => logger.log('response: ', r)),
			);
	}

	getNewDocId() {
		return this.db.docId(MinimumSellingPrice.collectionName);
	}


	public getAllminimumSellingPrices() {

		// Get store locations
		const c = this.store.selectSnapshot(AuthState.userClaim);
		logger.log('default store is: ', Object.keys(c.cClaim.cRoles));
		return this.db.colWithIdsInjected$<MinimumSellingPrice>(MinimumSellingPrice.collectionName);
	}

	gedminimumSellingPriceById(id: string | number) {
		return this.db.docWithInjectedId$<MinimumSellingPrice>(
			`${MinimumSellingPrice.collectionName}/${id}`, id);
	}

	/**
	 * get all companies (admin request)
	 */
	public getAllminimumSellingPrices_PagingObservable() {
		const p$: PagingObesrvable<MinimumSellingPrice, MinimumSellingPriceParam> =
			new PagingObesrvable<MinimumSellingPrice, MinimumSellingPriceParam>(this.db,
				(p: Paging, param?: MinimumSellingPriceParam) => this.getAllminimumSellingPrices_batch(p, param));
		return p$;
	}

	/**
	* Get the next batch of the data from server.
	* @param p paging information.
	*/
	private getAllminimumSellingPrices_batch(p: Paging, param: MinimumSellingPriceParam): Observable<{ [key: string]: MinimumSellingPrice }> {
		if (param.orderBy == null) { param.orderBy = 'updatedAt'; }
		if (param.orderDirection == null) { param.orderDirection = 'desc'; }
		return this.db
			.colWithIdsInjectedNew$<MinimumSellingPrice>(`${MinimumSellingPrice.collectionName}`, ref => minimumSellingPriceSearchServerQuery(ref, param, p))
			.pipe(
				tap(arr => {
					if (arr == null || arr.length === 0) {
						logger.log('All data is recevied, Paging is FULL');
						p.full = true;
					} else {
						p.lastDoc = arr[arr.length - 1];
					}
				}),
				map(arr => {
					// logger.log('From Server, before mapping is applied: ', arr);

					const arrayAsObj = arr.reduce((acc, cur) => {
						const id = cur.id;
						const data = cur;
						return { ...acc, [id]: data };
					}, {});

					// logger.log('after converting to array object dic ', arrayAsObj);
					return arrayAsObj;
				})
			);
	}
}