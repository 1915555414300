import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'trent-app-comp-shell',
  template: `
  <!-- TPine Rental Shell control -->
   <trent-shell>
    <router-outlet>
    </router-outlet>
  </trent-shell>
  `,
  styles: [
  ]
})
export class AppCompShellComponent {
  constructor() { }
}
