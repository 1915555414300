import { Exclude, Expose, instanceToInstance, plainToInstance } from 'class-transformer';
import { IsDefined, ValidateIf, ValidatorOptions } from 'class-validator';
import { AddOnBase, AddOnCategory, AddOnValidationGroup } from './add-on-base';
import { TireInspection } from '../inspection/tire-inspection';

export enum TiresType {
	Steer = 1,
	Drive = 2,
	All = 3
}

const tires: AddOnValidationGroup = 'tires';

@Exclude()
export class AddOnTires extends AddOnBase {

	@Expose()
	@ValidateIf(o => o.category === AddOnCategory.Tires, { groups: [tires] })
	@IsDefined({ message: 'Tire Type is required' })
	tiresType: TiresType;

	@Expose()
	@ValidateIf(o => o.category === AddOnCategory.Tires && o.new, { groups: [tires] })
	@IsDefined({ message: 'Tire Make is required' })
	tiresMake: TireInspection;

	@Expose()
	@ValidateIf(o => o.category === AddOnCategory.Tires, { groups: [tires] })
	new: boolean;

	constructor() {
		super();
		this.tiresType = null;
	}

	sanitize() {
		super.sanitize();
	}

	clone() {
		const t = instanceToInstance(this);
		t.sanitize();
		return t;
	}

	validateSync(options: ValidatorOptions) {
		const r = this.validateSyncBase(this);
		return r;
	}

	updatePriceQuote(s: AddOnTires) {
		this.tiresType = s.tiresType;
		this.tiresMake = s.tiresMake;
	}

	public static parse(obj) {
		if (obj == null) { return null; }
		const m = plainToInstance<AddOnTires, any>(AddOnTires, obj);
		m.sanitize();
		return m;
	}
}