import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { AddOnState } from './addOn.state';
import { AddOnService } from '@trent/services/add-on.service';

@NgModule({
	imports: [
		CommonModule,
		NgxsModule.forFeature([AddOnState])
	],
	providers: [
		AddOnService
	]
})
export class AddOnStoreModule { }