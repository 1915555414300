// import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { NgModule } from '@angular/core';

import { AppComponent } from '../app.component';
import { ShellComponent } from '../shared/shell/shell.component';

import { HomeComponent } from '../home/home.component';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

import { faGoogle, faFacebook, faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import { faRoad } from '@fortawesome/free-solid-svg-icons';

import { baseDeclarations, baseExports, baseImports, baseProviders } from '../ws-common/app-module-base-data';
import { AppTprRoutingModule } from './app-tpr-routing.module';
import { PtsSalesOptionsModule } from '../pts/pts-sales-options/pts-sales-options.module';

/**
 * App Module to be used for WebSite: TPine Rental.
 * Only Add content ( Imports / Declaration / Exports etc.) here that is specific to TPine Rental.
 * Common content to be used in all websites must be used in App-base-data.ts file.  
 */

@NgModule({
  declarations: [
    ...baseDeclarations,
    
    /** Components unique to TPine Rental */
    ShellComponent,
    HomeComponent,
  ],
  imports: [
    ...baseImports,
    /** Modules unique to TPine Rental */
    AppTprRoutingModule,
    PtsSalesOptionsModule
    ],
  exports: [
    ...baseExports
  ],
  providers: [
    ...baseProviders
  ],
  bootstrap: [AppComponent]
})
export class AppTprModule {

  // Add fontowesome icons.
  constructor(library: FaIconLibrary) {

    // https://github.com/FortAwesome/angular-fontawesome/blob/master/docs/usage/using-other-styles.md
    library.addIcons(faGoogle, faFacebook, faRoad, faMicrosoft);
  }
}
