<!-- eslint-disable @angular-eslint/template/no-negated-async -->
<div *ngIf="!(isHandset$ | async)" class="list-container-desktop">
    <ng-container *ngIf="m && m.length > 0; else empty">
      <div class="table-area">
        <div class="table_header">
          <div>
            <mat-form-field style="margin-bottom: -1.25em">
              <mat-label>Filter</mat-label>
              <input type="search" autocomplete="off" matInput (keyup)="applyFilter($event.target.value)" #dtopFilterCtrl>
            </mat-form-field>
          </div>
          <div class="pos-fixed">
  
          </div>
        </div>
        <div class="table">
          <ng-container>
            <table mat-table [dataSource]="dataSource">
              <!-- category Column -->
              <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef> Category </th>
                <td mat-cell *matCellDef="let element">
                  {{ getCategoryName(element.category) }}
                </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element">
                  {{element.name}}
                </td>
              </ng-container>
              <!-- Description Column -->
              <ng-container matColumnDef="description">
                <th style="width: 20% !important" mat-header-cell *matHeaderCellDef> Description </th>
                <td style="width: 20% !important" mat-cell *matCellDef="let element" >
                  {{element.description}}
                </td>
              </ng-container>
              <!-- Status Column -->
              <ng-container matColumnDef="status">
                <th style="width: 20% !important" mat-header-cell *matHeaderCellDef> Status </th>
                <td style="width: 20% !important" mat-cell *matCellDef="let element" >
                  <span *ngIf="element.isActive">Active</span>
                  <span *ngIf="!element.isActive">Inactive</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>
                  <button mat-icon-button color="primary" (click)="createAddOn()">
                    <mat-icon>add_circle</mat-icon>
                  </button>
                </th>
                <td mat-cell *matCellDef="let element; let i=index;">
                  <div style="display: block; grid-template-columns: 1fr 1fr 2fr 2fr; gap: 10px" align="start">
                    <button mat-icon-button color="primary" (click)="editRecord(element.id)">
                      <mat-icon aria-label="Edit">edit</mat-icon>
                    </button>
                  </div>
                </td>
  
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;">
              </tr>
            </table>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
  
  <ng-container *ngIf="!!(isHandset$ | async)">
  
    <mat-sidenav-container class="mobile-sideNav-container">
      <mat-sidenav-content>
        <div class="card-list-container" *ngIf="!!dataSource && dataSource?.data?.length > 0; else empty">
          <div class="card-list-filter" *ngIf="showFilter">
            <mat-card appearance="outlined">
              <mat-form-field>
                <mat-label>Filter</mat-label>
                <input type="search" matInput (keyup)="applyFilter($event.target.value)" autocomplete="off">
                <button style="font-size: 25px" type="button" color="primary" mat-icon-button matSuffix
                  (click)="clearFilter();">
                  <mat-icon>search_off</mat-icon>
                </button>
              </mat-form-field>
            </mat-card>
          </div>
          <div>
            <ng-container *ngFor="let d of dataSource.filteredData">           
              <div class="mobile-card-container">
                <mat-card appearance="outlined" class="card-mobile">
                  <div class="card-list">
                    <h6>Category</h6>
                    <div> {{getCategoryName(d.category)}} </div>
                    <div class="divider">
                      <mat-divider></mat-divider>
                    </div>
                    <h6>Name</h6>
                    <div> {{d.name}} </div>
                    <div class="divider">
                      <mat-divider></mat-divider>
                    </div>
                    <h6> Description </h6>
                    <div> {{d.description}} </div>
                    <div class="divider">
                      <mat-divider></mat-divider>
                    </div>
                    <h6> Status </h6>
                    <div>Active</div>
                  </div>
                  <div class="list-action-bottom">
                    <button mat-icon-button type="button" color="primary" (click)="editRecord(d.id)">
                      <mat-icon>edit</mat-icon>
                    </button>
                  </div>
                </mat-card>
              </div>
            </ng-container>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  
  </ng-container>
  
  <div [ngClass]="{'add-fixed-mobile': !!(isHandset$ | async) }"
    *ngIf="(!!(isHandset$ | async)) && !!dataSource">
    <button mat-mini-fab color="primary" type="button" (click)="createAddOn()">
      <mat-icon>add
      </mat-icon>
    </button>
  </div>
  
  <ng-template #empty>
    <mat-card appearance="outlined" >
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon matSuffix color="primary">settings</mat-icon>
        </div>
        <mat-card-title>No AddOns Found</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          You do not have any addOns in your records.
        </p>
      </mat-card-content>
      <mat-card-actions class="empty-list-actions">
        <button mat-mini-fab type="button" color="primary" (click)="createAddOn()">
          <mat-icon>add</mat-icon> 
        </button>
      </mat-card-actions>
    </mat-card>
  
  </ng-template>