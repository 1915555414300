import { BaseModel } from '../base/base-model';
import { IsEnum, IsDefined, ValidatorOptions, IsInt, IsIn, IsPositive, ValidateIf } from 'class-validator';
import { ProductType } from '../product/product-type';
import { IValidationMsg, IValDbStatusResult } from '../error-handling/validation-info';
import { instanceToInstance, Expose, Exclude, Type, Transform } from 'class-transformer';
import { Address } from '../address/address';
import { VendorCompanySummary, CompanyFleet } from '../company/company-fleet';
import { ProductBase, ProductSummary, TruckSummary, TruckType } from '../product';
import { CompanyBase } from '../company';
import { CustomFirebaseMetadata, Picture } from '../inspection/picture';
import { currencies, CurrencyType } from '../finance/currency/currency-type';
import { SalesOptionStatus } from './sales-option-status';
import { CronActions, UpdateDocRef } from '../cron-jobs/cron-jobs-log';
import { SalesOptionSummary } from './sales-option-summary';
import { StoreLocationSummary } from '../store-location/store-location';
import { sanitizeDate, toSentence } from '../utility';
import { FileInfo, IFileCopyData } from '../media/file-info';
import { DbStatus } from '../base/db-status';
import { Task } from '../sys/task';
import { TaskType } from '../sys/task-type';
import { TaskUtl } from '../sys/task-utl';
import { EnumHelper } from '../utility/enum-helper';
import { isNil, isNumber } from 'lodash';
import { SOExcelSummary } from './so-excel-summary';
import { SalesPerson } from '../legacy-contract';
import { differenceInDays } from 'date-fns';
import { Specs } from './sales-spec';
import { SalesWarranty } from './sales-warranty';
import { MinimumSellingPrice } from '../minimum-selling-price/minimum-selling-price';

export interface Specification {
	value: string | number;
	displayOnPage: boolean;
	displayOnCard: boolean;
}
export interface SalesOptionSpecs {
	make?: Specification,
	model?: Specification,
	modelYear?: Specification,
	stockId?: Specification,
	odometer?: Specification,
	unitColor?: Specification,
	truckType?: Specification,
	engineModel?: Specification,
	engineManufacturer?: Specification,
	horsepower?: Specification,
	fuelType?: Specification,
	suspension?: Specification,
	doors?: Specification,
	tires?: Specification,
	ratio?: Specification,
	wheels?: Specification,
	wheelBase?: Specification,
	sleeperType?: Specification,
	sleeperSize?: Specification,
	torque?: Specification,
	gearRatio?: Specification,
}

export type SalesValidationGroup = 'details' | 'price' | 'pictures' | 'specs' | 'minSellingPrice' | 'warranty' | 'additional';
const details: SalesValidationGroup = 'details';
const price: SalesValidationGroup = 'price';
const pictures: SalesValidationGroup = 'pictures';
const specs: SalesValidationGroup = 'specs';
const warranty: SalesValidationGroup = 'warranty';
const additional: SalesValidationGroup = 'additional';

export const ImageTypeShortString: string[] = ['NA', 'LL', 'OG'];//MKN for excel
export enum ImageType {
	notApplicable = 0,// If the images are not received yet
	lookALike = 1, // If the images are borrowed/copied from a similar looking unit for the time being
	original = 2, // If the images are actual ones
}

export enum ImageUploadStatus {
	all = 0, // All Images
	pending = 1, // Original
}

//For Sales options algolia dates
export const SODates = ['holdDate', 'mfgDate', 'itemReceivedDate', 'productSummary.plateExpiryDate', 'productSummary.safetyExpiryDate', 'vendorCompSummary.insExpiryDate', 'createdAt', 'updatedAt'];

export enum SalesStatus {
	available = 1,
	hold = 2,
	funded = 3,
	delivered = 4,
	inactive = 5,
	staging = 6,
}

//MKN- Proxy Vin for LookALike Picture type
export interface IProxyVin {
	vin: string,
	pid: string | number
}

export interface SalesPromo {
	promoId: string;
	index: number;
}

export const conditionalPrice = 200000;

export interface PriceLog {
	price: number;
	updatedAt: Date;
	updatedBy: LogUpdatedBy;
}

export interface LogUpdatedBy {
	uid: string | number;
	displayName: string;
}

@Exclude()
export class SalesProductBase extends BaseModel {

	public static readonly collectionName = 'sales-options';

	//Auto trader id for pts seo search
	@Expose()
	autoTraderId: string;

	thumbNailImg: string;

	@Expose()
	@Type(() => Picture)
	pictures: Picture[];//Update when publish the sales options

	@Expose()
	@IsDefined({ message: 'Retail price is required', groups: [price] })
	@IsInt()
	@IsPositive()
	price: number;

	@Expose()
	@IsDefined({ message: 'Wholesale price is required', groups: [price] })
	@IsInt()
	@IsPositive()
	wholesalePrice: number;

	@Expose()
	showPrice: boolean;

	@Expose()
	@IsDefined({ message: 'Specs are required', groups: [specs] })
	specs: SalesOptionSpecs;

	@Expose()
	@IsEnum(ProductType)
	productType: ProductType;

	productId: string;

	@Expose()
	@IsIn(currencies)
	currency: CurrencyType; // 'CAD' | 'USD';

	@Expose()
	productSummary: ProductSummary;

	@Expose()
	vendorCompSummary: VendorCompanySummary;

	@Expose()
	salesOptionStatus: SalesOptionStatus;

	minimumSellingPrice: number;

	address: Address;

	@Expose()
	@IsDefined({ message: 'Store Location is required', groups: [details] })
	storeLocationSummary: StoreLocationSummary;

	/** This is to differentiate units among multiple statuses based on images uploaded. */
	@Expose()
	@IsDefined({ message: 'Select picture type', groups: [pictures] })
	imageType: ImageType;

	@Expose()
	originalExcelSummary: SOExcelSummary;

	@Expose()
	@IsDefined({ message: 'Sales status is required', groups: [details] })
	status: SalesStatus;

	@Expose()
	@ValidateIf(o => o.status === SalesStatus.hold || (o.status === SalesStatus.delivered), { groups: [details] })
	@IsDefined({ message: 'Sales Person required', groups: [details] })
	salesPerson: SalesPerson;

	@Expose()
	@ValidateIf(o => (o.status === SalesStatus.hold) || (o.status === SalesStatus.delivered), { groups: [details] })
	@IsDefined({ message: 'Branch is required', groups: [details] })
	branch: StoreLocationSummary;

	@Expose()
	@ValidateIf(o => o.status === SalesStatus.hold, { groups: [details] })
	@IsDefined({ message: 'Deposit is required', groups: [details] })
	deposit: boolean;

	@Expose()
	holdDate: Date;

	@Expose()
	@ValidateIf(o => o.status === SalesStatus.hold, { groups: [details] })
	@IsDefined({ message: 'Approved by is required', groups: [details] })
	approvedBy: string;

	@Expose()
	comments: string;

	@Expose()
	proxyVin: IProxyVin;

	@Expose()
	copyToOg?: boolean;

	@Expose()
	promotion: SalesPromo;

	@Expose()
	warranty: SalesWarranty[];

	@Expose()
	additionalNotes: string;

	@Expose()
	showAdditionalNotes: boolean;

	@Expose()
	mfgDate: Date;

	@Expose()
	itemReceivedDate: Date;

	@Expose()
	ownershipStatus: 'YES' | 'NO';

	/**
	 * @author KS
	 * @purpose Get Hold Days
	 */
	get holdDays() {
		if (!this.holdDate) return null;
		const currentDate = new Date();
		return differenceInDays(currentDate, this.holdDate);
	}

	//Calculating Inventory Age
	get inventoryAge() {
		return differenceInDays(new Date(), this.itemReceivedDate);
	}

	constructor() {
		super();
		this.imageType = ImageType.notApplicable;
		this.pictures = [];
		this.status = SalesStatus.staging;
		this.salesPerson = { name: '', uid: '' };
		this.warranty = [];
		this.showAdditionalNotes = false;
	}

	/** create sales option by injection company summary, prod summary and setting properties
	 * @param vComp Vendor Company
	 * @param prod Product
	 */
	createSalesOption(vComp: CompanyBase, prod: ProductBase): SalesProductBase {
		this.vendorCompSummary = (<CompanyFleet>vComp).getVendorCompanySummary();
		this.productSummary = prod.getProductSummary();
		this.salesOptionStatus = SalesOptionStatus.open;
		return this;
	}

	updateSalesOption(s: SalesProductBase) {
		this.vendorCompSummary = s.vendorCompSummary;
		this.productSummary = s.productSummary;
		this.salesOptionStatus = s.salesOptionStatus;
		this.specs = s.specs;
		this.currency = s.currency;
		this.productType = s.productType;
		this.price = s.price;
		this.showPrice = s.showPrice;
		this.wholesalePrice = s.wholesalePrice;
		this.imageType = s.imageType;
		this.status = s.status;
		this.salesPerson = s.salesPerson;
		this.branch = s.branch;
		this.deposit = s.deposit;
		this.approvedBy = s.approvedBy;
		this.comments = s.comments;
		this.promotion = s.promotion;
		this.warranty = s.warranty;
		this.additionalNotes = s.additionalNotes;//mkn
		this.showAdditionalNotes = s.showAdditionalNotes;
	}

	updateSalesStatus(): { sales: SalesProductBase, chgDoc: UpdateDocRef } {
		const updatedSales = this.clone();
		updatedSales.salesOptionStatus = SalesOptionStatus.obsolete;
		const c = this.getCronJobLogForSalesStatus(updatedSales);

		return { sales: updatedSales, chgDoc: c };
	}

	getCronJobLogForSalesStatus(updatedSales: SalesProductBase) {
		let chgDoc: UpdateDocRef;
		const chgMap1 = new Map();

		chgMap1.set(`salesOptionStatus`, {
			before: (<SalesProductBase>this).salesOptionStatus,
			after: (<SalesProductBase>updatedSales).salesOptionStatus,
		});

		chgDoc = {
			did: this.id,
			collection: SalesProductBase.collectionName,
			revCreated: this.revId + 1,
			action: CronActions.setSalesObsoleteStatus,
			change: [chgMap1]
		};
		return chgDoc;
	}

	sanitize() {
		super.sanitize();
		this.holdDate = sanitizeDate(this.holdDate);
		this.itemReceivedDate = sanitizeDate(this.itemReceivedDate);
		this.mfgDate = sanitizeDate(this.mfgDate);
		// if data was received from firebase, date is stored as snapshot.
	}

	validateSync(options: ValidatorOptions): IValidationMsg {
		let s = this.validateSyncBase(this, options);
		// Validate Details
		if (options.groups.length && options.groups?.includes(details)) {
			if (!this.storeLocationSummary.sid) s['details'] = ['Location is required'];
		}
		// Validate Price
		if (options.groups.length && options.groups?.includes(price)) {
			if (this.price < 0) {
				s['price'] = ['Price cannot be zero'];
			}
			if (this.price < this.minimumSellingPrice) {
				s['price'] = ['Price is less than minimum selling price'];
			}
		}
		// Validate Spec
		if (options.groups.length && options.groups?.includes(specs)) {
			const sSpec = this.validateSpecs();
			s = Object.assign(s, sSpec);
		}
		// Validate Additional Details
		if (options.groups.length && options.groups?.includes(additional)) {
			if (this.mfgDate > this.itemReceivedDate) s['additional'] = ['Item received date is less than MFG Date'];
		}
		return s;
	}

	/** 
	 * Analyze product to see if more information is needed 
	 * or suggest the user to submit for approval.
	 */
	getValDbStatus(): IValDbStatusResult {
		const result: IValDbStatusResult = {
			pass: false,
			message: undefined,
			groupResult: {}
		};

		let x: { groupPass: boolean; message: string; };

		// details
		let s = this.validateSyncGroup(details);
		x = { groupPass: null, message: '' };
		x.groupPass = Object.keys(s).length === 0;
		x.message = (x.groupPass) ? 'Sales Information Details' : 'Sales information is required';
		result.groupResult[details] = x;

		// price
		s = this.validateSyncGroup(price);
		x = { groupPass: null, message: '' };
		x.groupPass = Object.keys(s).length === 0;
		x.message = x.groupPass ? 'Manage Pricing' : 'Price is required';
		result.groupResult[price] = x;

		// pictures
		s = this.validateSyncGroup(pictures);
		x = { groupPass: null, message: '' };
		x.groupPass = Object.keys(s).length === 0;
		x.message = x.groupPass ? 'Product pictures' : 'Upload pictures';
		result.groupResult[pictures] = x;

		// specs
		s = this.validateSyncGroup(specs);
		x = { groupPass: null, message: '' };
		x.groupPass = Object.keys(s).length === 0;
		x.message = x.groupPass ? 'Product Specifications' : 'Invalid selection of specs';
		result.groupResult[specs] = x;

		// additional
		s = this.validateSyncGroup(additional);
		x = { groupPass: null, message: '' };
		x.groupPass = Object.keys(s).length === 0;
		x.message = (x.groupPass) ? 'Additional Information' : 'Invalid Data';
		result.groupResult[additional] = x;

		// is it passed all the tests? (True means it failed here)
		result.pass = !Object.keys(result.groupResult).some((k) => !result.groupResult[k].groupPass);

		// passed.
		if (!result.pass) {
			if (this.dbStatus === DbStatus.Initial || this.dbStatus === DbStatus.ReleasedMod) {
				result.message = 'Admin approval is pending!';
			}
		} else {
			result.message = 'More information is required!';
		}

		return result;
	}

	clone() {
		const t = instanceToInstance(this);
		t.sanitize();
		return t;
	}

	validateSyncGroup(group: SalesValidationGroup): IValidationMsg {
		return this.validateSync({ groups: [group] });
	}

	/**
	 * Get Sales Option Summary.
	 */
	public getSalesOptionSummary(): SalesOptionSummary {
		const sSum = {
			sid: this.id,
			sRevid: this.revId,
			address: this.address,
			currency: this.currency,
			price: this.price,
			wholesalePrice: this.wholesalePrice,
			specs: this.specs,
		};
		return sSum;
	}

	/**
	 * @param cid UI helper, check if sales-option can be updated
	 */
	canEdit(cid: string, isAdmin: boolean) {
		return this.vendorCompSummary.cid === cid || !isAdmin ? true : false;
	}

	/*
	 * Get Currency string for Map Label e.g. 4000/month, 1000/week, 100/day
	 */
	getCurrencyString() {
		return this.currency === 'CAD' ? `Rent(C$): ` : this.currency === 'USD' ? `Rent(U$): ` : null;
	}

	expireSalesOption() {
		const uSales = this.clone();
		uSales.salesOptionStatus = SalesOptionStatus.obsolete;
		return uSales;
	}

	/** Set currency for Sales based on vendor company address */
	setCurrency(countryString: 'CA' | 'US'): void {
		switch (countryString) {
			case 'CA':
				this.currency = 'CAD';
				break;
			case 'US':
				this.currency = 'USD';
				break;
			default:
				throw new Error('failed to set currency');
		}
	}

	validateSpecs(): IValidationMsg {
		let r: IValidationMsg = {};
		if (!this.specs) return;
		let countPage = 0;
		let countCard = 0;
		Object.entries(this.specs)
			.map(([key, value]) => ({ key, value }))
			.forEach((item: any) => {
				if (item?.value?.displayOnPage) countPage++;
				if (item?.value?.displayOnCard) countCard++;
			});
		if (countPage === 0) {
			r['specsPage'] = ['Select at least one specification for page'];
		}
		if (!(countCard === 3)) {
			r['specsCard'] = ['You can choose maximum of 3 specs for a card'];
		}
		return r;
	}

	removeUndefined() {
		if (!this.specs) return;
		for (let key in this.specs) {
			if (!this.specs[key]) {
				delete this.specs[key];
			}
		}
	}

	/** Update all attached documents paths from /draft/ to /rel/ */
	updateFilePathsToRelease(pictures, uid: string, oldP: string, newP: string, isReleaseMode: boolean = true): IFileCopyData[] {
		const R: IFileCopyData[] = [];
		let index = 0;
		if (!pictures || (pictures && !pictures.length)) {
			this.pictures = [];
			return R;
		}

		this.pictures = [];
		for (const o of pictures) {
			const pict = new Picture();
			pict.path = o.name;
			pict.name = o.name.replace(/^.*[\\\/]/, '');
			pict.uploadedOn = new Date(o.metadata?.updated);
			if(o?.logoPosition) pict.logoPosition = o.logoPosition;
			let r = FileInfo.replaceFilePathCopyOp(o.name, oldP, newP,(oldP == newP ? true : false));
			if (!!r) {
				pict.path = r.newPath;
				r.customMetadata = o.metadata && o.metadata?.metadata;
				if (!r.customMetadata) {
					r.customMetadata = { uid: uid, vin: (this.productSummary as TruckSummary).vin, order: index + 1, visibility: true };
				}
				r.customMetadata['imageType'] = this.imageType;
				if (this.imageType == ImageType.lookALike && this.proxyVin) {
					r.customMetadata['vin'] = this.proxyVin.vin;
				}
				if (r.customMetadata['order']) r.customMetadata['order'] = Number(r.customMetadata['order']);
				if (r.customMetadata['visibility']) r.customMetadata['visibility'] = JSON.parse(r.customMetadata['visibility']);
				if (!!r.customMetadata['visibility'] || isReleaseMode) {
					if(r.customMetadata['loc'] === 'VIN Sticker'){
						r.customMetadata['visibility'] = r.customMetadata['visibility'];
					}else{
						r.customMetadata['visibility'] = r.customMetadata['visibility'] ? true : false;
					}
					R.push(r);
					pict.customFirebaseMetadata = r.customMetadata as CustomFirebaseMetadata;
					this.pictures.push(pict);
				}

			}
		}
		return R;
	}

	//#region admin task
	setAdminTaskInputs(task: Task, websiteUrl: string) {
		// task.senderCompanyName = this.companyFriendlyName();
		task.taskType = this.getTaskType();
		task.name = `${toSentence(EnumHelper.getName(TaskType, task.taskType))}`;
		task.data = { action: `${task.taskType}`, action_key: `${this.id}` };

		const urlSegment = TaskUtl.getUrlFromTaskType(task.taskType, task.data.action_key).url;

		task.notification = {
			title: `${(this.productSummary as TruckSummary).unitName} ${task.name}`,
			body: 'Click to get details',
			icon: 'https://placeimg.com/250/250/people',
			clickAction: `${websiteUrl}${urlSegment}`
		};
	}


	getTaskType(): TaskType {
		if (isNil(this.dbStatus)) {
			return this.productSummary.productType == 2 ? TaskType.SalesTruckCreated : TaskType.SalesTrailerCreated;
		}
		switch (this.dbStatus) {
			case DbStatus.Initial:
				if (this.revId === 0) {
					return this.productSummary.productType == 2 ? TaskType.SalesTruckCreated : TaskType.SalesTrailerCreated;
				} else {
					return this.productSummary.productType == 2 ? TaskType.SalesTruckUpdated : TaskType.SalesTrailerUpdated;
				}
			case DbStatus.SubmitedForApproval:
				return this.productSummary.productType == 2 ? TaskType.RequestSalesTruckApproval : TaskType.RequestSalesTrailerApproval;
			case DbStatus.ReleasedMod:
				return this.productSummary.productType == 2 ? TaskType.SalesTruckUpdated : TaskType.SalesTrailerUpdated;
			case DbStatus.Released:
				return this.productSummary.productType == 2 ? TaskType.SalesTruckReleased : TaskType.SalesTrailerReleased;

			default:
				throw new Error(`Invalid dbStatus, ${this.dbStatus} is not programmed yet`);

		}
	}

	/**
	 * Generate Sales Options Specs using product object
	 * @param product 
	 */
	generateSpecs(product: ProductBase) {
		if (!product) return;
		let specs = {
			make: undefined,
			model: undefined,
			modelYear: undefined,
			stockId: undefined,
			odometer: undefined,
			unitColor: undefined,
			truckType: undefined,
			engineModel: undefined,
			engineManufacturer: undefined,
			horsepower: undefined,
			fuelType: undefined,
			suspension: undefined,
			doors: undefined,
			tires: undefined,
			ratio: undefined,
			wheels: undefined,
			wheelBase: undefined,
			sleeperType: undefined,
			sleeperSize: undefined,
			torque: undefined,
			gearRatio: undefined,
		} as SalesOptionSpecs;

		for (const key in specs) {
			if (product[key] !== undefined) {
				this.specs[key] = {
					value: product[key],
					displayOnPage: (!!this.specs[key]?.displayOnPage) ? this.specs[key].displayOnPage : true,
					displayOnCard: (!!this.specs[key]?.displayOnCard) ? this.specs[key].displayOnCard : false
				};
			}
		}
	}

	/**Parse price - Remove comma and $ symbol */
	parsePrice(price: string): number {
		if (!price) {
			return undefined;
		}

		if (isNumber(price)) {
			return price;
		}

		return Number(price.replace(/[$,]/g, '').trim());
	}

	generateSalesOptionsFromExcel(item: any, prodEm: ProductBase) {
		this.autoTraderId = item.autoTraderId;
		this.originalExcelSummary = new SOExcelSummary();
		this.originalExcelSummary.createSOOriginalExcelSummary(item);

		this.id = item.id;//bulk modify case
		if (item.marketingLocation) {
			this.storeLocationSummary = {
				sid: item.marketingLocation
			};
		}

		this.specs = new Specs();
		this.generateSpecs(prodEm);
		let counterSpecCard = 0;
		for (let key in this.specs) {
			if (counterSpecCard === 3) {
				break;
			}
			if (this.specs[key]) {
				this.specs[key].displayOnCard = true;
				counterSpecCard++;
			}
		}

		if (item.price) this.price = this.parsePrice(item.price);
		if (item.wholesale) this.wholesalePrice = MinimumSellingPrice.formatPrice(item.wholesale);
		// this.wholesalePrice = undefined;
		this.imageType = ImageType.notApplicable;
		this.status = SalesStatus.staging;
		if (!!item.pictureStatus) {
			switch (item.pictureStatus) {
				case "OG":
					this.imageType = ImageType.original;
					//this.status = SalesStatus.available;
					break;
				case "LL":
					this.imageType = ImageType.lookALike;
					if (item.proxyVin) {
						//this.status = SalesStatus.available;
						this.proxyVin = {
							vin: item.proxyVin,
							pid: ''
						};
					} else {
						this.status = SalesStatus.staging;
					}
					break;
				case "NA":
					this.imageType = ImageType.notApplicable;
					this.status = SalesStatus.staging;
					break;
				default: this.imageType = ImageType.notApplicable; break;
			}
		}
		if (item.status) {
			this.status = item.status;
		}
		if (item.status == SalesStatus.hold) {
			this.approvedBy = item.approvedBy;
			this.holdDate = new Date();
			this.deposit = item.deposit == 'YES' ? true : false;
			this.salesPerson = item.salesPerson;
			this.comments = item.comments;
		}
		this.additionalNotes = item.additionalNotes;
		if(item.itemReceivedDate) this.itemReceivedDate = sanitizeDate(new Date(item.itemReceivedDate));
		if(item.mfgDate) this.mfgDate = sanitizeDate(new Date(item.mfgDate));
		this.ownershipStatus = item.ownershipStatus;
		this.productSummary = prodEm.getProductSummary();
		this.productType = prodEm.productType;

	}

	/**
	 * @author Cm
	 * @param obj1 is the old sales option specs
	 * @param obj2 is the new sales option specs
	 * @returns 
	 */
	updateSpecs(obj1, obj2) {
		for (const key in obj2) {
			if (obj1.hasOwnProperty(key)) {
				//Existing specs updated
				this.specs[key] = { displayOnPage: obj1[key].displayOnPage, displayOnCard: obj1[key].displayOnCard, value: obj2[key].value };
			} else {
				//new specs add in Existing specs 
				this.specs[key] = { displayOnPage: true, displayOnCard: false, value: obj2[key].value };
			}
		}
	}

	/**
	* @author Cm
	* @param d is the Price Quote.
	* @description When Price quote created sales option status make hold and update according to it.
	* @returns 
	*/
	salesOptionHold(u) {
		this.status = SalesStatus.hold;
		this.salesPerson = { uid: u?.uid, name: u?.displayName };
		this.deposit = true;
		// this.holdDays = 1;
		// @@@ Here add the branch hard coded data later it change @@@
		this.approvedBy = 'John doe';
		this.branch = {
			phone: '+1114111898',
			name: 'Abcd',
			sid: 'asassewfdwerrs1245'
		};
	}

	/**
	* @author Cm
	* @description When Price quote rejected sales option status make Available and update according to it.
	* @returns 
	*/
	salesOptionAvailable() {
		this.status = SalesStatus.available;
		delete this.salesPerson;
		delete this.deposit;
		// delete this.holdDays;
		delete this.approvedBy;
		delete this.branch;
	}

	/**
	 * @author KS
	 * @purpose Add Promo Image
	 * @param promoImg 
	 * @param index 
	 * @returns 
	 */
	handlePromoImg(promoImg: Picture, index: number) {
		if (!promoImg || !index) return;
		// Create empty array if pictures not defined
		this.pictures = this.pictures || [];
		// Remove old promo image from pictures
		if(!!this.promotion?.index) this.pictures = this.pictures.filter((p,i) => i !== this.promotion.index);
		// Use splice to insert the item at the specified index
		this.pictures.splice(index - 1, 0, promoImg);
		this.promotion = this.promotion || {} as any;
		this.promotion.index = this.pictures.findIndex(p => p.path.toLowerCase().includes('pts/promotions'));
	}

	/**
	 * @author KS
	 * @purpose Remove Promo Image
	 * @param promoImg 
	 * @param index 
	 * @returns 
	 */
	removePromoImg() {
		// Use splice to insert the item at the specified index
		const index = this.promotion.index;
		this.pictures.splice(index, 1);
		// Set promotion object as null
		this.promotion = null;
	}
}

