import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { BaseForm } from '@trent/models/UI/base-form';
import { toSentence } from '@trent/models/utility';
import { EnumHelper } from '@trent/models/utility/enum-helper';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { EventService } from '@trent/services/event.service';
import { ImageUploadStatus, ImageType, SalesOptionParam, SalesStatus, generateFilterStringForAlgolia } from '@trent/models/sales-option';
import { ProductSearchParam, ProductType } from '@trent/models/product';
import { logger } from '@trent/models/log/logger';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Paging } from '@trent/models/observable-util/paging';
import { StoreLocationParam } from '@trent/models/store-location/store-location-param';
import { StoreLocationState, StoreLocationsRequested } from '@trent/store/store-location-store';
import { StoreLocation } from '@trent/models/store-location/store-location';
import { UserProfile } from '@trent/models/user';
import { ICompanyParam } from '@trent/models/company';
import { CompaniesRequested, CompanyState } from '@trent/store/company-store';
import { AuthState } from '@trent/store/auth-store/auth.state';
import { CompanyStatus } from '@trent/models/company/company-status';
import { isEqual, isNil, omitBy } from 'lodash';
import { map } from 'rxjs';
import algoliasearch from 'algoliasearch/lite';
import { algoliaClientConfig } from '@trent/core/environments/fb-env';
import { environment } from '@trent/core/environments/environment';

export enum UserSearchType {
	SalesOperation = 1,
	SalesPerson,
	YardPerson,
}
@Component({
	selector: 'trent-search-sales-option',
	templateUrl: './pts-search-sales-option.component.html',
	styleUrls: ['./pts-search-sales-option.component.scss']
})

export class SearchSalesOptionComponent extends BaseForm<ProductSearchParam> implements OnInit, OnDestroy {
	userSearchType = UserSearchType;
	imageTypeEnum = EnumHelper.getNamesAndValues(ImageType);
	imageUploadStatusEnum = EnumHelper.getNamesAndValues(ImageUploadStatus);
	statusEnum;
	productTypeEnum = [
		{
			id: ProductType.truck,
			name: 'Truck'
		}, {
			id: ProductType.trailer,
			name: 'Trailer'
		}
	];

	productParam: ProductSearchParam = {};
	priceRange = { from: 0, to: 200000 };
	salesParam: SalesOptionParam = { priceRange: { from: 0, to: 200000 } };
	companyParam: ICompanyParam = { lessorStatus: CompanyStatus.approved }; // isLessee: true
	vendorCompanyList = [];
	storeLocations: StoreLocation[];
	userProfile: UserProfile;
	@Output() paramEmit = new EventEmitter<{ param: ProductSearchParam | SalesOptionParam }>();
	@Output() cancelEmit = new EventEmitter();
	dateRangeStart: Date;
	dateRangeEnd: Date;
	pictureLastUpdatedByUid: string | number;
	minOdoValue: number = 0;
	maxOdoValue: number = 1000000;
	odometerRange = { from: 0, to: 1000000 };
	makesList = [];
	modelList = [];
	modelYearList = [];
	filterProperties = ['productSummary.make'];
	client = algoliasearch(algoliaClientConfig.appId, algoliaClientConfig.searchApiKey);
	index = this.client.initIndex('sales-options');
	assignedStoreLocationId: string;
	websiteId:string;

	constructor(store: Store,
		dialogService: DialogService,
		es: EventService,
		@Inject(MAT_DIALOG_DATA) public data: {
			param;
			searchType: UserSearchType.SalesOperation | UserSearchType.YardPerson;
			isProdList: false;
		}
	) {
		super(store, dialogService, es);
	}

	ngOnInit() {
		this.websiteId = environment.webSite.id;
		console.log(this.websiteId, 'this.websiteId:::');
		let sEnum = EnumHelper.getNamesAndValues(SalesStatus);
		const usrSubs = this.store.select(AuthState.user).subscribe(u => {
			this.userProfile = u;
			if (!!u?.roles?.sales) {
				this.statusEnum = sEnum.filter(s => s.value != SalesStatus.delivered).filter(s => s.value != SalesStatus.inactive);
				this.assignedStoreLocationId = u?.assignedStoreLocations[0]?.storeLocationId;
				this.salesParam.storeLocationId = this.assignedStoreLocationId;
			} else {
				this.statusEnum = sEnum;
			}
		});

		if (!!usrSubs) {
			this.mSubscription.push(usrSubs);
		}
		if ((this.data?.param && this.data.searchType === UserSearchType.SalesOperation) || this.data.isProdList) {
			this.getVendors();
		}
		if (!!this.data.param) {
			this.productParam = { ...this.data.param };
			logger.log('productParam', this.productParam);
		}
		this.getStoreLocations();
		this.productParam.lastUpdatedRange = { from: null, to: null };
		this.getFilterValues();
	}

	/**
	 * @author {PT}
	 * @purpose get vendor company lists
	 */
	getVendors() {
		if (this.userProfile.roles && (this.userProfile.roles.sales)) {
			this.companyParam.authId = `${this.userProfile.id}`;
		}
		this.store.dispatch(new CompaniesRequested({ pData: { size: 1000, offset: 0, full: false }, param: this.companyParam }));
		const vendorSub = this.store.select(CompanyState.selectAllCompanies)
			.pipe(map(clientFilterFn => clientFilterFn({ ...this.companyParam })))
			.subscribe(b => {
				if (b) {
					this.vendorCompanyList = b;
				}
			});
		if (!!vendorSub) {
			this.mSubscription.push(vendorSub);
		}
	}

	/**
	* @author PT
	* @purpose Get Store Locations
	*/
	getStoreLocations() {
		const pageData: Paging = { size: 100, offset: '', full: false };
		const sParam: StoreLocationParam = { isPTS: true };
		this.store.dispatch(new StoreLocationsRequested({ pData: pageData, param: sParam }));
		const locationSub = this.store.select(StoreLocationState.selectAllStoreLocations)
			.pipe(map(clientFilterFn => clientFilterFn({ ...sParam })))
			.subscribe(s => {
				if (s.length > 0) {
					this.storeLocations = s;
				}
			});

		if (!!locationSub) {
			this.mSubscription.push(locationSub);
		}
	}

	toSentence(s: string, n?: number) {
		if (n && n === ImageType.lookALike) {
			return s.charAt(0).toUpperCase() + s.slice(1);;
		}
		return toSentence(s);
	}


	/**
	 * @purpose Send search params conditionally
	 */
	updateSearch() {
		if (this.data.searchType === UserSearchType.YardPerson) { // Search params for Promo List(new) & Yard Person
			if (this.data.isProdList) {
				// Only For Promo list
				this.productParam.physicalLocationId = this.salesParam.storeLocationId;
				this.productParam.imageType = this.salesParam.imageType;
			};
			if (this.productParam.imageUploadStatus === ImageUploadStatus.all) delete this.productParam.imageUploadStatus;
			if (!this.productParam.lastUpdatedRange.from) delete this.productParam.lastUpdatedRange;
			if (!!this.pictureLastUpdatedByUid) this.productParam.pictureLastUpdatedByUid = this.pictureLastUpdatedByUid;
			if (!!this.salesParam.country) this.productParam.country = this.salesParam.country;
			if (!!this.salesParam.odometerRange) this.productParam.odometerRange = this.salesParam.odometerRange;
			this.productParam.physicalLocationId = this.salesParam.storeLocationId;
			this.paramEmit.emit({ param: omitBy(this.productParam, isNil) });
		} else {
			// Search Params for sales option list
			if (isEqual(this.salesParam.priceRange, this.priceRange)) {
				delete this.salesParam.priceRange;
			}
			if (isEqual(this.salesParam.odometerRange, this.odometerRange)) {
				delete this.salesParam.odometerRange;
			}
			if (!!this.productParam.make) this.salesParam.makesArray = [this.productParam.make as any];
			if (!!this.productParam.model) this.salesParam.modelArray = [this.productParam.model as string];
			if (!!this.productParam.modelYear) this.salesParam.modelYear = this.productParam.modelYear;
			this.paramEmit.emit({ param: omitBy(this.salesParam, isNil) });
		}
	}

	cancel() {
		this.cancelEmit.emit();
	}

	ngOnDestroy(): void {
		this.cleanListeners();
		this.data = null;
	}

	/**
	 * @author KS
	 * @purpose Set date range
	 */
	dateRange() {
		this.productParam.lastUpdatedRange.from = this.dateRangeStart.getTime();
		this.productParam.lastUpdatedRange.to = this.dateRangeEnd.getTime();
	}

	selectUser(key: string) {
		if (!key) return;
		this.productParam.pictureLastUpdatedByUid = key;
	}


	onOdoRange() {
		this.salesParam.odometerRange = { from: this.minOdoValue, to: this.maxOdoValue };
	}

	onOdoMinValue(event: Event) {
		this.minOdoValue = +(<HTMLInputElement>event.target).value;
	}

	onOdoMaxValue(event: Event) {
		this.maxOdoValue = +(<HTMLInputElement>event.target).value;
	}

	async getDataCountForProperty(property) {
		let { cityArray, countryArray, ...param } = this.data.param;
		let p = { ...param };
		const filters = generateFilterStringForAlgolia(p);
		try {
			const { facets } = await this.index.search('', {
				facets: [property], // Request facets for all filter properties
				filters
			});
			return facets[property];
		} catch (error) {
			logger.error(`Error counting data for property ${property}: ${error.message}`);
			return null;
		}
	}

	/**
	 * @author KS
	 * @purpose Get filters values
	 */
	async getFilterValues() {
		const dataCounts = {};
		this.makesList = [];
		for (const property of this.filterProperties) {
			const counts = await this.getDataCountForProperty(property);
			if (counts) {
				dataCounts[property] = counts;
			}
		}
		const keys = Object.keys(dataCounts);
		if (keys?.length > 0)
			for (const key of keys) {
				const insideKeys = Object.keys(dataCounts[key]);
				if (key.includes('make')) {
					this.makesList = insideKeys;
				}
			}
	}

	async getModels() {
		const property = 'productSummary.model';
		this.modelList = [];
		let dataCounts = {};
		const counts = await this.getDataCountForProperty(property);
		if (counts) {
			dataCounts[property] = counts;
		}
		const keys = Object.keys(dataCounts);
		if (keys?.length > 0)
			for (const key of keys) {
				const insideKeys = Object.keys(dataCounts[key]);
				// making the obj for Model filter
				if (key.includes('model')) {
					this.modelList = insideKeys;
				}

			}
	}

	/**
	 * @author KS
	 * @purpose Get model years | seperated because of conflicting with `model`
	 */
	async getModelYear() {
		const dataCounts = {};
		const property = 'productSummary.modelYear';
		const counts = await this.getDataCountForProperty(property);
		if (counts) {
			dataCounts[property] = counts;
		}

		const keys = Object.keys(dataCounts);
		if (keys?.length > 0)
			for (const key of keys) {
				const insideKeys = Object.keys(dataCounts[key]);
				if (key.includes('modelYear')) {
					this.modelYearList = insideKeys;
				}
			}
	}

	// Load model on makes selection
	onMakes() {
		if (this.productParam.make) {
			this.data.param.makesArray = [this.productParam.make];
			this.getModels();
		} else {
			this.modelList = [];
		}
	}

	// Load modelYear on makes selection
	onModel() {
		if (this.productParam.model) {
			this.data.param.modelArray = [this.productParam.model];
			this.getModelYear();
		} else {
			this.modelYearList = [];
		}
	}
}