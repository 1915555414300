import { Component, ContentChild, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { Animations } from '../animations';
import { UtilityService } from '@trent/services/utility.service';
import { getUrlFromPath } from '@trent/services/firebase-storage';
import { logger } from '@trent/models/log/logger';
import { Picture } from '@trent/models/inspection/picture';

@Component({
  selector: 'trent-carousel2',
  templateUrl: './carousel2.component.html',
  styleUrls: ['./carousel2.component.scss'],
  animations: [Animations.animeFade]
})

export class Carousel2Component implements OnInit, OnDestroy {

  _value: Picture[] = [];

  @Input()
  get list(): Picture[] {
    return this._value;
  }
  set list(v: Picture[]) {
    if (v !== this._value) {
      this._value = v;
    }
  }

  @Input() defaultImg:string;
  @Input() heightImg;
  @Input() intervalCall:boolean;
  @Input() showThumbnails: boolean;
  @Input() arrowShow: boolean = true;
  @Input() showHeightVW: boolean = true;

  @ContentChild('home', { static: false }) homeTemplateRef: TemplateRef<any>;
  @ViewChild('thumbnailBar', { static: false }) thumbnailBar: ElementRef;

  constructor(private us: UtilityService) { }


  current = 0;
  interval;
  ngOnInit() {
    if (this.us.isPlatformBrowser && this.intervalCall) {
      this.interval = setInterval(() => {
        this.current = ++this.current % this.list.length;
      }, 9000);
    }
  }

  /**
	 * Load picture if static path picture get failed
	 * @param picture
	 */
  async handleImageError(picture : Picture){
    picture.serverUrl = '../../../../assets/pride-truck-sales-logo.webp';
		const r = await getUrlFromPath(picture.path);
		if (!r.success) {
		  logger.log(r.error);
		} else {
		  picture.serverUrl = r.url;
		}
	}

  prevArrow() {
    if (!isNaN(+this.list?.length)) {
      if (this.current > 0) {
        this.current -= 1;
      } else {
        this.current = this.list.length - 1;
      }
    }

    let w = this.thumbnailBar?.nativeElement?.clientWidth;
    if(w < this.list.length * 78){
      this.thumbnailBar?.nativeElement.scrollBy({ left: -78, behavior: 'smooth' });
    }

  }
  nextArrow() {
    if (!isNaN(+this.list?.length)) {
      if (this.current < this.list.length - 1) {
        this.current += 1;
      } else {
        this.current = 0;
      }
    }
    let w = this.thumbnailBar?.nativeElement?.clientWidth;
    if(w < this.list.length * 78){
      this.thumbnailBar?.nativeElement.scrollBy({ left: 78, behavior: 'smooth' });
    }
  }

  ngOnDestroy(): void {
    if (!!this.interval) {
      clearInterval(this.interval);
    }
  }

  selectedImage(i: number) {
    this.current = i;
  }
} 