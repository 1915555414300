import { Exclude, Expose, instanceToInstance, plainToInstance } from 'class-transformer';
import { IsDefined, ValidatorOptions } from 'class-validator';
import { AddOnBase } from './add-on-base';

export enum WarrantyType {
	OEM = 1,
	Pride = 2,
	Others = 3,
}

@Exclude()
export class AddOnWarranty extends AddOnBase {

	@Expose()
	@IsDefined({ message: 'Warranty type is required' })
	warrantyType: WarrantyType;

	constructor() {
		super();
		this.warrantyType = null;
	}

	sanitize() {
		super.sanitize();
	}

	clone() {
		const t = instanceToInstance(this);
		t.sanitize();
		return t;
	}

	validateSync(options: ValidatorOptions) {
		const r = this.validateSyncBase(this);
		return r;
	}

	updatePriceQuote(s: AddOnWarranty) {
		this.warrantyType = s.warrantyType;
	}

	public static parse(obj) {
		if (obj == null) { return null; }
		const m = plainToInstance<AddOnWarranty, any>(AddOnWarranty, obj);
		m.sanitize();
		return m;
	}
}