import { instanceToInstance, Expose, plainToInstance, Type } from 'class-transformer';
import { Length, IsDate, ValidatorOptions, validateSync, IsNumber, ValidateIf, isDefined, IsDefined, ValidateNested } from 'class-validator';
import { IValidationMsg } from '../error-handling/validation-info';
import { logger } from '../log/logger';
import { sanitizeDate, sanitizeDateIPoint } from '../utility/sanitize-helper';
import { toValidationError } from '../utility/validation-helper';
import { ITransactionSummaryBase, TransactionBase, PaymentService } from './transaction-base';
import { isAfter, isDate } from 'date-fns';
import { IPriceSummary, IPymtSchedule } from '../finance/invoice/i-item';
import { TransactionSummary } from './transaction-b';
import { FileInfo } from '../media/file-info';

export interface TransactionSummaryManual extends ITransactionSummaryBase {
  /** comment enter in the user form */
  comment: string;
  /** transaction id to map history summary to transactions array  in order; for Bambora this firebase ID. for manual create string using payment date*/
}

/**
 * MKN - Added Payment Type
 */
export enum PaymentType {
  creditCardPOS =  1, 
  demandDraft = 2, 
  certifiedCheque = 3,
  wire = 4
}

export class TransactionManual extends TransactionBase {


  @Expose()
  @ValidateIf(o => !!o.comment)
  @Length(5, 256)
  comment: string;

  @Expose()
  @IsDate()
  paymentDate: Date;

  maxPaymentDate: Date;

  /** Transaction ID enter by user form */
  @Expose()
  @Length(1, 20)
  transactionId: string;
  /** Transaction ID enter by user form */

  /** ;for Bambora this firebase ID. for manual create string using payment date*/
  @Expose()
  @Length(1, 20)
  order_number: string;



  // @Expose({ toPlainOnly: true })
  // get order_number(): string {
  //   if(isDate(this.paymentDate)){
  //     return `manual-${this.paymentDate.valueOf()}-1`;
  //   }
  // }

  @Expose()
  @IsDefined({ message : "Payment Type is required"})
  paymentType: PaymentType;

  
  @Expose()
  @ValidateNested({ message: 'Upload signed document' })
  @Type(() => FileInfo)
  paymentReceipt: FileInfo;

  @IsNumber({ maxDecimalPlaces: 2 }, { message: 'Enter valid payment amount' })
  amount: number;

  get summary(): TransactionSummaryManual {
    return {

      created: this.created,
      amount: this.amount,
      paymentService: this.paymentService,
      vendorTransId: this.transactionId,
      comment: this.comment,
      vendorOrderNumber: this.order_number


    };
  }

  constructor() {
    super();
    this.paymentService = 'Manual';
    this.paymentReceipt = new FileInfo();
    this.maxPaymentDate = new Date();
  }
  public static parse(obj) {
    try {
      if (obj == null) { return null; }
      if (obj instanceof TransactionManual) { return obj; }
      obj = sanitizeDateIPoint(obj);
      const m = plainToInstance<TransactionManual, any>(TransactionManual, obj);
      m.sanitize();
      return m;
    } catch (error) {
      logger.error('Error happened during parse', error);
      return null;
    }
  }


  sanitize() {

    // super.sanitize();
    super.sanitize();
    this.paymentDate = sanitizeDate(this.paymentDate);
  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  /**
   * 
   * @param options 
   * @param pendingPayment is optional; When calling from fns call with pendingPayment to validated the input; then with to pending payment to match the total
   * @returns 
   */
  validateSync(options?: ValidatorOptions, pendingPayment?: IPriceSummary): IValidationMsg {

    // for nested entry for address, add address group in it.
    const r = this.validateSyncBase(this, options);
    if (isAfter(this.paymentDate, this.maxPaymentDate)) {
      r['paymentDate'] = ['Payment date cannot be in future'];
    }

    if (!!pendingPayment) {
      if (this.amount !== pendingPayment?.totalAmount) {
        r['amount'] = ['Amount does not match payment due'];
      }
    }

    return r;
  }
  
  // managing the release status.
  public validateSyncBase($this: any, options?: ValidatorOptions, forbidUnknownValues?:boolean): IValidationMsg {
    if(!options){
      options = {};
    }
    options.forbidUnknownValues = forbidUnknownValues ? true : false;
    const r = validateSync($this, options);
    const m = toValidationError(r);
    return m;
  }
  
  setTransactionManualFromSummary(tSummary: TransactionSummary) {
    this.id = 'Manually Logged';
    this.approved = 1;
    this.created = tSummary.created;
    this.amount = tSummary.amount;
    this.paymentService = tSummary.paymentService;
    this.transactionId = tSummary.vendorTransId;
    this.comment = tSummary.comment;
    this.order_number =  tSummary.vendorOrderNumber;
  }
}
