<mat-form-field>
	<mat-label>{{header}}</mat-label>
	<input type="text" aria-label="Number" matInput [matAutocomplete]="auto" #entryKey="ngModel"
		(ngModelChange)="_filter(entryKey)" (keyup.enter)="enterBlur()" name="entryKey" [(ngModel)]="selectedKey"
		[disabled]="false">
	<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
		(optionSelected)="keySelectedFn($event.option.value)">
		<mat-option *ngFor="let option of filtered | keyvalue" [value]="option.value.id">
			<mat-list>
				<mat-list-item>
					<div matListItemIcon class="comp-name">{{firstChar(option.value.displayName)}}</div>
					<h4 matListItemTitle>{{option.value.displayName}}</h4>
					<h4 matListItemLine>{{option.value.email}}</h4>
				</mat-list-item>
			</mat-list>
		</mat-option>
	</mat-autocomplete>
</mat-form-field>