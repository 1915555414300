import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { BaseForm } from '@trent/models/UI/base-form';
import { WarrantyType } from '@trent/models/addon/add-on-warranty';
import { fromFailedValidation } from '@trent/models/error-handling';
import { SalesWarranty, SalesWarrantyType } from '@trent/models/sales-option/sales-warranty';
import { EnumHelper } from '@trent/models/utility/enum-helper';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { EventService } from '@trent/services/event.service';
import { startCase } from 'lodash';

@Component({
	selector: 'trent-sales-warranty',
	templateUrl: './sales-warranty.component.html',
	styleUrls: ['./sales-warranty.component.scss']
})
export class SalesWarrantyComponent extends BaseForm<SalesWarranty> implements OnInit, OnDestroy {

	// Retrieve names and values for warranty providers enumeration
	providers = EnumHelper.getNamesAndValues(WarrantyType);
	// Enum containing possible values for warranty types
	providersValues = WarrantyType;

	// Retrieve names and values for sales warranty types enumeration
	warrantyTypes = EnumHelper.getNamesAndValues(SalesWarrantyType);
	// Enum containing possible values for sales warranty types
	warrantyTypesValues = SalesWarrantyType;

	@ViewChild('warrantyForm', { static: false })
	warrantyForm: NgForm;

	// Constructor for SalesWarrantyComponent
	constructor(
		store: Store,
		eventService: EventService,
		dialogService: DialogService,
		public dialogRef: MatDialogRef<SalesWarrantyComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { warranty: SalesWarranty }
	) {
		super(store, dialogService, eventService);
	}

	// Angular lifecycle hook - Component initialization
	ngOnInit(): void {
		// Placeholder statement, no specific action currently
		this.m = new SalesWarranty();
		this.m = SalesWarranty.parse(this.data.warranty);
		this.em = this.m.clone();
	}

	// Angular lifecycle hook - Component destruction
	ngOnDestroy(): void {
		// Clean up any event listeners
		this.cleanListeners();
	}

	// Convert string to title case
	toTitleCase(s: string) {
		return startCase(s);
	}

	// Handle change event of warranty type
	warrantyTypeChange() {
		// If selected warranty type is not "Other," remove warrantyTypeOther property
		if (this.em.warrantyType !== this.warrantyTypesValues.Other) {
			delete this.em.warrantyTypeOther;
		}
	}

	// Close the current dialog
	close(): void {
		this.dialogRef.close();
	}

	save() {
		const tempR = this.em.validateSync();
		if (Object.keys(tempR).length > 0) {
			this.showAlert(fromFailedValidation(tempR).messageInfo);
			return;
		}
		this.dialogRef.close(this.em);
	}

	// Custom function to disable dates before the start date
	endDateFilter = (date: Date | null): boolean => {
		const startDate = this.em.startDate;

		// Allow selection only if there is no start date or the date is equal to or after the start date
		return !startDate || date >= startDate;
	};

	// Handle start date selection to update the end date filter
	handleStartDateChange(event: MatDatepickerInputEvent<Date>): void {
		this.em.startDate = event.value;
	}
}