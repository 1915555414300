import { Component, ElementRef, OnDestroy, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Address } from '@trent/models/address/address';
import { DbRule, DbStatus } from '@trent/models/base';
import { CompanyBase, CompanyFleet } from '@trent/models/company';
import { fromCreateSuccess, fromError, fromFailedValidation, IValDbStatusResult, MessageInfo, readErrorMessage } from '@trent/models/error-handling';
import { logger } from '@trent/models/log/logger';
import { Paging } from '@trent/models/observable-util/paging';
import { pathSalesMedia, ProductBase, ProductType, Truck, TruckSummary, TruckType } from '@trent/models/product';
import { MinimumSellingPrice } from '@trent/models/minimum-selling-price/minimum-selling-price';
import { IPromoListParam, PromoListType } from '@trent/models/promo';
import { VendorPortfolio } from '@trent/models/promo/vendor-portfolio';
import { SalesOptionParam, SalesTrailer } from '@trent/models/sales-option';
import { ImageType, SalesProductBase, SalesStatus, SalesValidationGroup, conditionalPrice } from '@trent/models/sales-option/sales-product-base';
import { Specs } from '@trent/models/sales-option/sales-spec';
import { SalesTruck } from '@trent/models/sales-option/sales-truck';
import { StoreLocation } from '@trent/models/store-location/store-location';
import { StoreLocationParam } from '@trent/models/store-location/store-location-param';
import { FormDisplayMode } from '@trent/models/UI';
import { BaseForm } from '@trent/models/UI/base-form';
import { PaneType } from '@trent/models/UI/menu-data';
import { UserProfile } from '@trent/models/user/user-profile';
import { TimerHelper, getUrlEncodedSlashToPercentTwoF, toSentence } from '@trent/models/utility';
import { CameraBaseService } from '@trent/services/camera/camera-base.service';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { EventService } from '@trent/services/event.service';
import { SalesOptionService } from '@trent/services/sales.service';
import { promiseWraper } from '@trent/models/utility';
import { CompanyRequested, CompanyState } from '@trent/store/company-store';
import { ProductRequested, ProductState, ProductStateReset } from '@trent/store/product-store';
import { SalesOptionByParamRequested, SalesOptionRequested, SalesOptionState, SalesOptionStateReset } from '@trent/store/sales-option-store';
import { StoreLocationsRequested, StoreLocationState } from '@trent/store/store-location-store';
import { ValidationOptions } from 'class-validator/types/decorator/ValidationOptions';
import { ValidatorOptions } from 'class-validator/types/validation/ValidatorOptions';
import { isEmpty, isEqual, startCase } from 'lodash';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { RoleGroupLevelRank } from '@trent/models/role-group/role-group-level';
import { deleteFile, getMetaData, getUrlFromPath, listAllFiles } from '@trent/services/firebase-storage';
import { MenuData } from '@trent/models/UI/menu-data';
import { Picture } from '@trent/models/inspection/picture';
import { EnumHelper } from '@trent/models/utility/enum-helper';
import { currencies } from '@trent/models/finance/currency/currency-type';
import { UserService } from '@trent/services/user.service';
import { SalesWarrantyComponent } from './sales-warranty/sales-warranty.component';
import { SalesWarranty, SalesWarrantyType } from '@trent/models/sales-option/sales-warranty';
import { MatTableDataSource } from '@angular/material/table';
import { WarrantyType } from '@trent/models/addon/add-on-warranty';
import { DatePipe } from '@angular/common';
import { ProductService } from '@trent/services/product.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { RoleLevel } from '@trent/models/user';
import { MinimumSellingPriceParam } from '@trent/models/minimum-selling-price/minimum-selling-price-param';
import { MinimumSellingPricesRequested, MinimumSellingPriceState, MinimumSellingPriceStateReset } from '@trent/store/minimum-selling-price';
import { AppState1 } from '@trent/store/root-store';
import { AppSetting } from '@trent/models/sys/app-setting';

@Component({
	selector: 'trent-sales-options-details',
	templateUrl: './sales-options-details.component.html',
	styleUrls: ['./sales-options-details.component.scss']
})
export class SalesOptionsDetailsComponent extends BaseForm<SalesProductBase> implements OnDestroy, OnInit {

	/** root model of the record. will be pulled only if m is not a root. i.e it is from draft/release.  */
	mRoot: SalesProductBase;
	searchTxt: string;
	sid: string;
	isLeftVisible = true;
	activePane: PaneType = 'left';
	productInfoId: string;
	isHandset$: Observable<boolean>;
	isHandset: boolean;
	salesId: string | number;
	prodType: ProductType;
	vParam: IPromoListParam;
	salesRootId: string | number;
	saveCancelTxt: string;
	pid: string | number;
	product$: Observable<ProductBase>;
	product: ProductBase;
	productRoot: ProductBase;
	vPortfolio: VendorPortfolio;
	vCompany: CompanyBase;
	dBStatusEnum = DbStatus;
	salesStatusEnum = SalesStatus;
	roleLevelEnum = RoleGroupLevelRank;
	ImageTypeEnum = ImageType;
	menuData: MenuData[] = [];
	imgHeight: number = 16;

	path: string;
	baseUrl: string;
	imgContHt: string;
	currSubMenu: SalesValidationGroup;
	@ViewChild('filePicker', { static: false })
	filePicker: ElementRef<HTMLInputElement>;
	@ViewChild('detailsForm', { static: false })
	detailsForm: NgForm;
	@ViewChild('priceForm', { static: false })
	priceForm: NgForm;
	@ViewChild('specsForm', { static: false })
	specsForm: NgForm;
	@ViewChild('picturesForm', { static: false })
	picturesForm: NgForm;
	@ViewChild('additionalForm', { static: false })
	additionalForm: NgForm;
	msp: MinimumSellingPrice;
	storeLocations: StoreLocation[];
	salesOption: SalesProductBase;
	salesOperationsUser: RoleLevel;
	yardPerson: RoleLevel;
	showSelectProductsList: boolean = false;
	vCid: string | number;
	proxyVinProductPictures: Picture[];
	proxyVin: string;
	isCreate: boolean;
	subscriptions: Subscription;
	currencyList = currencies;
	picturePath: string[] = [];
	releasedPicturePath: string;
	proxyPicturePath: string[] = [];
	enableVal = false;
	hasUpdated = false;
	isMoveToOg: boolean = false;
	definedPrice = 0;
	vehicleRetailPrice;
	pic = [];
	selectedLogoPosition = [];
	salesOptionPic = [];
	// Enum containing possible values for sales warranty types
	warrantyTypesValues = SalesWarrantyType;
	// Enum containing possible values for warranty types
	providersValues = WarrantyType;

	warrantyColumns = ['title', 'action'];
	isLoading = false; // Flag to check if service call is in progress
	@ViewChild('infoDialog') infoDialog: TemplateRef<any>;
	infoDialogRef;
	infoData;

	aPictures = [];

	get warrantyDataSource() {
		return new MatTableDataSource(this.em.warranty);
	};

	get emRTruck(): SalesTruck {
		return (this.em as any) as SalesTruck;
	}
	set emRTruck(value: SalesTruck) {
		this.em = value as any;
	}

	get dbStatusColor(): string {
		if (this.m) {
			switch (this.m.status) {
				case SalesStatus.available:
					return '#33691e';
				default:
					return 'var(--warn)';
			}
		}
	}

	get updateBtnShow() {
		if ((this.em.dbStatus == 2 || this.em.dbStatus == 1) && (this.salesOperationsUser?.l == 2 || this.salesOperationsUser?.l == 1)) {
			return false;
		} else if (this.yardPerson?.l == 1) {
			return false;
		} else {
			return true;
		}

	}

	/**
	 * Class Constructor
	 * @param store 
	 * @param activatedRoute 
	 * @param eventService 
	 * @param dialog 
	 * @param dialogService 
	 * @param router 
	 * @param us 
	 * @param ss 
	 * @param camera 
	 */
	constructor(store: Store,
		private activatedRoute: ActivatedRoute,
		eventService: EventService,
		public dialog: MatDialog,
		dialogService: DialogService,
		private router: Router,
		private ss: SalesOptionService,
		private productService: ProductService,
		public camera: CameraBaseService,
		public userService: UserService,
		private salesService: SalesOptionService,
	) {
		super(store, dialogService, eventService);
	}

	ngOnInit(): void {
		this.isHandset$ = this.us.isHandset$;
		this.mSubscription.push(this.us.isHandset$.subscribe(h => {
			this.isHandset = h;
			if (!h) {
				this.es.emit(this.es.loadPageOptions, []);
			}

			this.imgHeight = h ? 57 : 16;

		}));
		this.eventListeners.push(
			this.es.listen<boolean>(this.es.menuBackBtnClickCust, () => this.showLeft()),
			this.es.listen<boolean>(this.es.menuFireEdit, () => this.edit()),
			this.es.listen<boolean>(this.es.menuFireSaveCancel, (save) => save ? this.save() : this.cancel())
		);
		this.bindMenuSaveCancelDisplay = true;
		this.displayMode = this.activatedRoute.snapshot.data.mode;
		let uTester: UserProfile;
		this.user$.subscribe(u => {
			this.salesOperationsUser = u?.roles?.salesOperations;
			this.yardPerson = u?.roles?.yardPerson;
			if (this.yardPerson) {
				this.currSubMenu = 'pictures';
			} else {
				this.currSubMenu = 'details';
			}
			uTester = u;
			if (!!u) setTimeout(() => this.getRetailPriceFee(), 1500);
		});
		const queryParams$ = this.activatedRoute.queryParams;
		this.mSubscription.push(queryParams$.subscribe(p => {
			this.pid = p.pid;
			this.salesId = p.salesId;
			this.vCid = p.vCid;
		}));
		this.prodType = this.activatedRoute.snapshot.data.pType;

		this.initData();
		this.saveCancelTxt = 'Sales Details';
		this.setUpFormValidation();
		if (this.isReadMode) {
			this.setUpMobileButtons();
		}
	}

	ngOnDestroy(): void {
		this.cleanListeners();
		this.store.dispatch(new ProductStateReset());
		this.store.dispatch(new SalesOptionStateReset());
		this.store.dispatch(new MinimumSellingPriceStateReset());
	}

	initData() {
		if (this.subscriptions) this.subscriptions.unsubscribe();
		if (!!this.salesId) {
			this.title = 'Update Sales option';
			this.displayMode = FormDisplayMode.read;
			this.vParam = { type: PromoListType.vendorPortfolio, isMasterList: true };
			this.store.dispatch(new SalesOptionRequested({ id: this.salesId }));
			const s$ = this.store.select(SalesOptionState.selectSalesOptionById).pipe(map(clientFilterFn => clientFilterFn(this.salesId)));
			// this.store.dispatch(new minimumSellingPriceRequested({ id: this.salesId }));
			// const w$ = this.store.select(minimumSellingPriceState.selectminimumSellingPriceById).pipe(map(clientFilterFn => clientFilterFn(this.salesId)));
			const pageDataW: Paging = { size: 100, offset: '', full: false };
			const sParamW: MinimumSellingPriceParam = { salesOptionId: this.salesId };
			this.store.dispatch(new MinimumSellingPricesRequested({ pData: pageDataW, param: sParamW }));
			const w$ = this.store.select(MinimumSellingPriceState.selectAllminimumSellingPrices)
				.pipe(map(clientFilterFn => clientFilterFn({ ...sParam })));


			const pageData: Paging = { size: 500, offset: '', full: false };
			const sParam: StoreLocationParam = { isPTS: true };
			const loc$ = this.store.select(StoreLocationState.selectAllStoreLocations)
				.pipe(map(clientFilterFn => clientFilterFn({ ...sParam })));

			let p$: Observable<ProductBase>;

			const prod$ = this.store.select(SalesOptionState.selectSalesOptionById)
				.pipe(map(filterFn => filterFn(this.salesId)),
					switchMap(d => {
						if (!!d) {
							this.pid = d.productSummary.pid;
							this.store.dispatch(new ProductRequested({ id: this.pid }));
							p$ = this.store.select(ProductState.selectProductById)
								.pipe(map(filterFn => filterFn(this.pid)));
						} else {
							p$ = of();
						}
						return p$;
					}));


			const comb$ = combineLatest([s$, w$, prod$, loc$]);
			this.mSubscription.push(comb$.subscribe(x => {
				if (!!x[0]) {
					this.m = x[0];
					this.mRoot = this.em;
					// this.pid = this.em?.productSummary.pid;
					// this.releasedPicturePath = `product-media/${(this.em.productSummary as TruckSummary).vin}/sales-option`;
					// this.picturePath[0] = `product-media/${(this.em.productSummary as TruckSummary).vin}/draft`;
					// // this.getStoreLocations();
					// this.listAllFilesPic(this.releasedPicturePath);
					// if (this.em?.pictures) this.selectedLogoPosition = this.em?.pictures.map(p => ({ position: p?.logoPosition, path: p.path.replace('sales-option', 'draft') }));

					// if (this.em?.productSummary?.productType === ProductType.truck) {
					// 	this.baseUrl = '/sales-options/truck-details';
					// }
					// if (this.em?.productSummary?.productType === ProductType.trailer) {
					// 	this.baseUrl = '/sales-options/trailer-details';
					// }
				}
				if (x[1].length > 0 && !!this.m) {
					this.em = this.m.clone();
					this.em.minimumSellingPrice = x[1][0].price;
					this.pid = this.em?.productSummary.pid;
					// Assign currency according to vendor company
					this.em.setCurrency(this.em.vendorCompSummary.address.country as 'CA' | 'US');
					this.releasedPicturePath = `product-media/${(this.em.productSummary as TruckSummary).vin}/sales-option`;
					this.picturePath[0] = `product-media/${(this.em.productSummary as TruckSummary).vin}/draft`;
					this.listAllFilesPic(this.releasedPicturePath);

					if (this.em?.pictures) this.selectedLogoPosition = this.em?.pictures.map(p => ({ position: p?.logoPosition, path: p.path.replace('sales-option', 'draft') }));

					if (this.em?.productSummary?.productType === ProductType.truck) {
						this.baseUrl = '/sales-options/truck-details';
					}
					if (this.em?.productSummary?.productType === ProductType.trailer) {
						this.baseUrl = '/sales-options/trailer-details';
					}

				}
				if (!!x[2] && this.em) {
					this.product = x[2];
					this.em.productSummary = this.product.getProductSummary();
					this.aPictures = this.product.pictures;
					this.releasedPicturePath = `product-media/${(this.em.productSummary as TruckSummary).vin}/sales-option`;
					this.picturePath[0] = `product-media/${(this.em.productSummary as TruckSummary).vin}/draft`;
					this.listAllFilesPic(this.releasedPicturePath);
				}
				if (x[3]?.length > 0) {
					this.storeLocations = x[3];

				}
			}));

		} else if (this.pid && this.vCid) {
			const pageData: Paging = { size: 100, offset: '', full: false };
			// Sales Option Param
			const sParam: SalesOptionParam = { pid: this.pid };
			// Get Sales Option
			this.store.dispatch(new SalesOptionByParamRequested({ pData: pageData, param: sParam }));
			const s$ = this.store.select(SalesOptionState.selectSalesOptionByParam).pipe(map(filterFn => filterFn({ ...sParam })));
			this.mSubscription.push(s$.subscribe(x => {
				if (!!x.length) {
					this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
						this.router.navigate([`${this.baseUrl}`], { queryParams: { salesId: `${DbRule.getRootId(x[0].id)}` } });
					});
				} else {
					this.title = 'Create Sales option';
					switch (this.prodType) {
						case ProductType.truck:
							this.m = new SalesTruck();
							break;
						case ProductType.trailer:
							this.m = new SalesTrailer();
							break;
						default:
							break;
					}
					this.em = this.m.clone();
					this.mRoot = this.em;
					this.em.address = new Address();
					if (!this.em.specs) {
						this.em.specs = new Specs();
					}
					this.em.storeLocationSummary = { sid: '' };
					this.initializeState();
				}
			}));
		}
	}

	/**
	 * @author KS
	 * @purpose Initialize State
	 */
	private initializeState() {
		// Get Product
		this.store.dispatch(new ProductRequested({ id: this.pid }));
		this.product$ = this.store.select(ProductState.selectProductById).pipe(map(filterFn => filterFn(`${this.pid}`)));
		// Get Company
		let cid = !!this.vCid ? this.vCid : this.defaultCompany?.cid;
		this.store.dispatch(new CompanyRequested({ id: `${cid}` }));

		const vCompany$ = this.store.select(CompanyState.selectCompanyById).pipe(map(filterFn => filterFn(cid)));
		try {
			this.subscriptions = combineLatest([this.product$, vCompany$]).subscribe((d) => {
				if (!!d[0]) {
					this.product = d[0] as Truck;
					this.picturePath[0] = `product-media/${(this.product as Truck).vin}/draft`;
					this.releasedPicturePath = `product-media/${(this.product as Truck).vin}/sales-option`;
					this.listAllFilesPic(this.releasedPicturePath);
				}
				if (!!d[1] && !isEqual(d[1], this.vCompany)) {
					this.vCompany = d[1] as CompanyFleet;
					this.getStoreLocations();
				}

				if (this.product && this.vCompany) {
					this.em.setCurrency(this.vCompany.address.country as 'CA' | 'US');
					this.em.productType = +this.product.productType;
					this.em.productSummary = this.product.getProductSummary();
					this.em.createSalesOption(this.vCompany, this.product);
					this.em.generateSpecs(this.product);
					this.path = pathSalesMedia(`${this.product.cid}`, `${this.em.productSummary.pid}`);
					this.bindChangeDetect();
				}
				if (this.em?.productSummary?.productType === ProductType.truck) {
					this.baseUrl = '/sales-options/truck-details';
				}
				if (this.em?.productSummary?.productType === ProductType.trailer) {
					this.baseUrl = '/sales-options/trailer-details';
				}

			});
		} catch (error) {
			logger.error('[Sales-Option] Error in combine Latest');
		}
		this.mSubscription.push(this.subscriptions);
	}

	isProductPicturePublishIsRequired() {
		if (this.yardPerson || this.salesOperationsUser?.l === RoleGroupLevelRank.level1 || this.m?.status !== this.salesStatusEnum.available) {
			return false;
		} else {
			return true;
		}
	}

	isProductOrderingIsRequired() {
		if (this.yardPerson || this.salesOperationsUser?.l === RoleGroupLevelRank.level1 || this.m?.status !== this.salesStatusEnum.available) {
			return false;
		} else {
			return true;
		}
	}


	/**
	 * @author KS
	 * @purpose Get Store Locations
	 */
	getStoreLocations() {
		const pageData: Paging = { size: 500, offset: '', full: false };
		const sParam: StoreLocationParam = { isPTS: true };
		this.store.dispatch(new StoreLocationsRequested({ pData: pageData, param: sParam }));
		const storeLocationSubscription = this.store.select(StoreLocationState.selectAllStoreLocations)
			.pipe(map(clientFilterFn => clientFilterFn({ ...sParam })))
			.subscribe(s => {
				if (s.length > 0) {
					this.storeLocations = s;
				}
			});
		this.mSubscription.push(storeLocationSubscription);
	}

	/**
	* @author KS
	* @purpose Save Sales Option
	*/
	async save() {
		// Validate Minimum Selling Price
		if (!this.validateminimumSellingPrice()) return;
		const option: ValidatorOptions = {
			groups: ['details', 'price', 'specs', 'warranty', 'additional']
		};

		let pictures = (await listAllFiles(this.picturePath[0])).items;

		const tempR = this.em.validateSync(option);

		if (this.em.imageType == ImageType.lookALike) {
			tempR['proxyVin'] = ['Proxy vin require for Lookalike picture type'];
		}

		if (this.em.imageType == ImageType.original && !pictures.length && !this.em.copyToOg) {
			tempR['originalPic'] = ['Picture not upload in original'];
		}

		if (this.em.status == SalesStatus.available && !pictures.length && !this.em.copyToOg) {
			tempR['status'] = [`Please upload images for status: Availablea`];
		}

		if (Object.keys(tempR).length > 0) {
			this.showAlert(fromFailedValidation(tempR).messageInfo);
			return;
		}

		this.showLoading(10);
		if (this.isCreateRequest) {
			if ((this.defaultCompanyId || this.vCid) !== this.product.cid && !this.user.isEditor) {
				console.log('cid does not match the product cid', this.defaultCompanyId, this.product.cid);
				alert('cid does not match the product cid');
				return;
			}
		}
		const pspPayload = new MinimumSellingPrice();
		pspPayload.price = this.em.minimumSellingPrice;
		const prom = (this.isCreateRequest) ?
			this.ss.create(this.em, this.product.cid, this.sid, pspPayload).toPromise() :
			this.ss.update(this.em, this.msp, this.em.minimumSellingPrice, this.selectedLogoPosition).toPromise();
		prom.then(r => {
			this.hideLoading();
			this.displayMode = FormDisplayMode.read;
			const msg = new MessageInfo({
				msgCss: 'accent',
				header: this.isCreateRequest ? 'Sales Created' : 'Sales Updated',
				description: this.isCreateRequest ? 'Sales option Created successfully.' : 'Sales option Updated successfully.'
			});
			this.showPopover(msg);
			setTimeout(() => {
				let id = r.id ? r.id : this.salesId;
				if (id) {
					this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
						this.router.navigate([`${this.baseUrl}`], { queryParams: { salesId: `${DbRule.getRootId(id)}` } });
					});
				}
				this.listAllFilesPic(this.releasedPicturePath);
			}, 700);
			this.bindChangeDetect();
		}).catch(err => {
			this.hideLoading();
			this.showAlert(readErrorMessage(err)); ///MKN- show error message
		});
		return false;
	}

	async publish() {
		//Check is Sales Option status is Available
		if (this.em.status !== SalesStatus.available) {
			const msg = new MessageInfo({
				msgCss: 'warn',
				header: 'Invalid sales status',
				description: 'Cannot publish sales option, invalid sales status.'
			});
			this.showAlert(msg);
			return;
		}

		// Validate Minimum Selling Price
		if (!this.validateminimumSellingPrice()) return;

		const option: ValidatorOptions = {
			groups: ['details', 'price', 'pictures', 'specs', 'warranty', 'additional']
		};
		const tempR = this.em.validateSync(option);

		if (this.em.status !== SalesStatus.available) {
			tempR['available'] = ['Sales option is not available for release'];
		}
		if (this.em.imageType == ImageType.notApplicable) {
			tempR['notApplicable'] = ['Picture type(Original/Lookalike) is required'];
		}
		if (this.em.imageType == ImageType.lookALike) {
			tempR['proxyVinPic'] = ['Proxy vin require for lookALike picture type'];
		}
		if (this.em.imageType == ImageType.original && !(await listAllFiles(this.picturePath[0])).items?.length) {
			tempR['originalPic'] = ['Picture not upload in original'];
		}

		if (Object.keys(tempR).length > 0) {
			this.showAlert(fromFailedValidation(tempR).messageInfo);
			return;
		}
		this.showLoading(10);
		const prom = this.ss.publish(this.em, this.em.id, this.selectedLogoPosition).toPromise();
		prom.then(r => {
			this.showPopover(fromCreateSuccess('Sales option published successfully.'));
			this.hideLoading();
			setTimeout(() => {
				let id = r.sid ? r.sid : this.salesId;
				if (id) {
					this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
						this.router.navigate([`${this.baseUrl}`], { queryParams: { salesId: `${DbRule.getRootId(id)}` } });
					});
				}
				this.listAllFilesPic(this.releasedPicturePath);
			}, 700);
			this.bindChangeDetect();
		}).catch(err => {
			this.hideLoading();
			this.showAlert(readErrorMessage(err));
		});
		return false;
	}

	async getPictures() {
		return (await listAllFiles(this.picturePath[0])).items;
	}

	adminApproveRecord() {
		if ((this.em.dbStatus === DbStatus.SubmitedForApproval) && !this.em.draftId) {
			this.publish();
		} else if (!!this.em.draftId) {
			this.salesOperationsApproveRecord();
		}
	}

	async publishImages() {
		const pictures = await this.getPictures();
		if (!pictures || (pictures && pictures.length == 0)) {
			const msg = new MessageInfo({
				msgCss: 'warn',
				header: 'Upload Picture',
				description: 'No pictures'
			});
			this.showAlert(msg);
			return;
		}
		this.showLoading(10);
		const prom = this.ss.publishImages(this.em.id).toPromise();
		prom.then(r => {
			this.showPopover(fromCreateSuccess('Image [Published successfully]'), null, () => { });
			this.hideLoading();
			setTimeout(() => {
				this.pid = DbRule.getRootId(r.data?.productSummary?.pid);
				let id = r.sid ? r.sid : this.salesId;

				if (id) {
					this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
						this.router.navigate([`${this.baseUrl}/${id}`]);
					});
				}
			}, 500);
		}).catch(err => {
			this.hideLoading();
			this.showAlert(readErrorMessage(err));
		});
		return false;
	}

	/**
	 * @author KS
	 * @purpose Get Current Form
	 */
	get currentForm(): NgForm {
		switch (this.currSubMenu) {
			case 'details':
				return this.detailsForm;
			case 'price':
				return this.priceForm;
			case 'specs':
				return this.specsForm;
			case 'pictures':
				return this.picturesForm;
			case 'additional':
				return this.additionalForm;
			case 'warranty':
			default:
				return this.detailsForm;
		}
	}

	/**
	 * Setup Form Validation
	 */
	private setUpFormValidation() {
		const timer = new TimerHelper();
		timer.setInterval(100, 10, () => {
			const form: NgForm = this.currentForm;
			if (form == null) {
				return false;
			}
			this.setFormValidation<SalesProductBase>(SalesProductBase, form, () => {
				const option: ValidationOptions = {
					groups: [this.currSubMenu]
				};
				return { model: this.em, option };
			});
			return true;
		});
	}

	showLeft() {
		this.isLeftVisible = !this.isLeftVisible;
		this.activePane = this.isLeftVisible ? 'left' : 'right';
		this.isGoBackCust = !this.isLeftVisible;
	}

	/**
	 * Show hide panes
	 * @param sMenu 
	 */
	showHide(sMenu: SalesValidationGroup) {
		this.currSubMenu = sMenu;
		this.es.emit(this.es.loadPageOptions, []);
		this.setUpFormValidation();
		if (this.isLeftVisible) {
			this.showLeft();
		}
		if (sMenu == 'pictures') {
			if (this.em.proxyVin) {
				this.proxyVin = `${this.em.proxyVin.vin}`;
				if ((this.em.status === SalesStatus.available)) {
					this.proxyVinProductPictures = this.em.pictures;
				} else {
					this.listAllFiles(`product-media/${this.em.proxyVin.vin}/draft`);
				}
			}
		}
	}

	/**
	 * Change display mode to edit
	 */
	edit() {
		this.displayMode = FormDisplayMode.edit;
		this.setUpMobileButtons();
	}

	/**
	 * Go back to list page
	 */
	cancel() {
		this.displayMode = FormDisplayMode.read;
		const listType = (this.em.productSummary.productType === ProductType.truck) ? 'truck' : 'trailer';
		if (this.vCid) {
			this.router.navigate([`list/${listType}/${this.vCid}`]);
		} else if (!!this.salesId) {
			if (this.user.roles.salesOperations.l === RoleGroupLevelRank.level2) {
				this.router.navigate(['sales-options/list']);
			} else {
				this.router.navigate([`list/${listType}/${this.em.vendorCompSummary.cid}`]);
			}
		}
	}

	/**
	 * Setup mobile buttons
	 */
	setUpMobileButtons() {
		if (this.isLeftVisible) {
			this.showHideEdit(true);
			this.showHideSaveCancel(false);
		} else {
			this.showHideEdit(false);
			this.showHideSaveCancel(true);
		}
	}

	/**
	 * Get Db Status
	 */
	get valDbStatus(): IValDbStatusResult {
		if (this.em) {
			return this.em.getValDbStatus();
		}
	}

	/**
	 * Bing change detect
	 */
	bindChangeDetect() {
		this.es.emit<{ m: SalesProductBase, mRoot: SalesProductBase, valDbStatus: IValDbStatusResult }>(this.es.changeDetect, {
			m: this.m, mRoot: this.mRoot, valDbStatus: this.em.getValDbStatus()
		});
	}

	get showReleaseStatus() {
		if (this.serverReqInProcess) {
			return false;
		} else {
			return true;
		}
	}

	openLatestRecord() {
		this.redirectParam(`${this.mRoot.draftIdUrl}`);
	}

	openDraftRecord() {
		const draftId = this.mRoot.getDraftIdUrl(`${SalesProductBase.collectionName}`);
		this.redirectParam(`${draftId}`);
	}

	redirectParam(id: string) {
		if (this.m.id !== id) {
			const idEncoded = id.includes('/') ? getUrlEncodedSlashToPercentTwoF(`${id}`) : id;
			this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
				this.router.navigateByUrl(`${this.baseUrl}/${idEncoded}`);
			});
		}
	}

	/** User can submit the data record for admin approval. */
	submitForApproval() {

		if (this.m.dbStatus !== DbStatus.Initial && this.m.dbStatus !== DbStatus.ReleasedMod) {
			this.showPopover(fromError('Data record must be in "initial" or "modified"' + 'status before review can be submitted').messageInfo);
			return;
		}
		const option: ValidatorOptions = {
			groups: ['details', 'price', 'pictures', 'specs', 'warranty', 'additional']
		};

		const tempR = this.m.validateSync(option);
		if (!isEmpty(tempR)) {
			logger.error(tempR);
			return;
		}
		this.showLoading(10);
		const prom = this.ss.requestApproval(this.em.id, option).toPromise();
		prom
			.then((r) => {
				this.showPopover(fromCreateSuccess('Sales-options [Submitted for approval]'), null, () => { });
				this.hideLoading();
				// this.initData();
				setTimeout(() => {
					let id = r.sid ? r.sid : DbRule.getRootId(this.salesId);
					logger.log('id', id);
					if (id) {
						this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
							this.router.navigate([`${this.baseUrl}/${id}`]);
						});
						this.initData();
					}
				}, 500);
				this.bindChangeDetect();
			})
			.catch((error) => {
				this.hideLoading();
				this.showAlert(readErrorMessage(error));
				this.em = this.m.clone();
			});
	}

	cancelApprovalReq() {
		if (!DbRule.isDraftBranch(this.em.id) && this.mRoot.revId > 0) {
			logger.error('[Sales-options component] Unknown error, cancelApprove Req');
			return;
		}
		this.showLoading(10);
		logger.log('cancel request for approval');
		const prom = this.ss.cancelApprovalReq(this.em.id).toPromise(); // , this.m);
		prom
			.then((r) => {
				this.showPopover(fromCreateSuccess('Sales Option [Unlocked]'), null, () => { });
				this.hideLoading();
				let id = r.sid ? r.sid : this.salesId;
				if (id) {
					this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
						this.router.navigate([`${this.baseUrl}/${DbRule.getRootId(id)}`]);
					});
				}
				this.bindChangeDetect();
			})
			.catch((error) => {
				this.hideLoading();
				this.showAlert(readErrorMessage(error));
				this.em = this.m.clone();
			});
	}

	salesOperationsApproveRecord() {
		if (!this.valDbStatus.pass) {
			const m = new MessageInfo();
			m.header = 'Validation';
			m.description = 'Validation failed';
			m.msgCss = 'warn';
			m.list = [this.valDbStatus.message];
			this.showAlert(m);
			return;
		}
		const option: ValidatorOptions = {
			groups: ['details', 'price', 'pictures', 'specs', 'warranty', 'additional']
		};

		const tempR = this.m.validateSync(option);
		if (!isEmpty(tempR)) {
			logger.error(tempR);
			return;
		}
		this.showLoading(10);
		if (this.m.dbStatus !== DbStatus.SubmitedForApproval) {
			this.showPopover(fromError('Data record must be in "initial" or "modified"' + 'status before review can be submitted').messageInfo);
			return;
		}
		this.ss.salesOperationsApprove(this.em.id, option).toPromise()
			.then((r) => {
				this.showPopover(fromCreateSuccess('Sales [Submitted for approval]'), null, () => { });
				this.hideLoading();
				setTimeout(() => {
					this.pid = DbRule.getRootId(r.data?.productSummary?.pid);
					if (r?.sid) {
						this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
							this.router.navigate([`${this.baseUrl}/${r.sid}`]);
						});
					}
					this.initializeState();
				}, 800);
			})
			.catch((error) => {
				this.hideLoading();
				this.showAlert(readErrorMessage(error));
				this.em = this.m.clone();
			});
	}
	adminRejectRecord() {
		logger.log('Admin reject record was executed.');
	}

	deleteDraft() {
		if (!DbRule.isDraftBranch(this.em.id)) {
			logger.error('[Sales option form component] Unknown error, cancelApprove Req');
			return;
		}
		this.showLoading(10);
		logger.log('cancel request for approval');
		const prom = this.ss.deleteDraft(this.em.id).toPromise(); // , this.m);
		prom
			.then((r) => {
				this.hideLoading();
				this.showPopover(fromCreateSuccess('Sales [Unlocked]'), null, () => { });
				// update the validation and the db status of the model.
				if (r?.sid !== this.pid) {
					this.redirectParam(r.sid as string);
				}
			})
			.catch((error) => {
				this.hideLoading();
				this.showAlert(readErrorMessage(error));
				this.em = this.m.clone();
			});
	}

	toTitleCase(s: string) {
		return startCase(s);
	}

	onImageTypeChanged() {
		if (this.em.imageType === ImageType.original || this.em.imageType === ImageType.notApplicable) {
			this.em.proxyVin = undefined;
			this.showSelectProductsList = false;
		} else if (this.em.imageType === ImageType.lookALike) {
			this.clearProxyVin();
			this.em.proxyVin = (this.m.clone()).proxyVin;
		}
		this.isMoveToOg = false;
	}

	selectedProduct(product: ProductBase,e) {
		this.showSelectProductsList = false;
		this.proxyVin = `${(product as Truck).vin}`;
		this.proxyVinProductPictures = [];
		this.em.proxyVin = {
			vin: (product as Truck).vin,
			pid: product.id
		};

		if (!product?.pictures || (product.pictures && product.pictures?.length == 0)) {
			this.listAllFiles(`product-media/${(product as Truck).vin}/draft`);
		} else {
			this.proxyVinProductPictures = product.pictures;
		}
	}

	/**
   * Get paths and urls for all pictures in temp
   */
	async listAllFiles(path: string) {
		// list all files
		const fstrorageRef = (await listAllFiles(path)).items;
		logger.log('list all files = ', fstrorageRef);

		if (fstrorageRef && fstrorageRef.length == 0) {
			this.proxyVin = '';
			this.proxyVinProductPictures = [];
			this.em.proxyVin = undefined;
			const msg = new MessageInfo({
				msgCss: 'warn',
				header: 'Images not found',
				description: 'Selected product does not contain any images.'
			});
			this.showAlert(msg);
			return;
		} else {
			const pictures: Picture[] = [];
			await fstrorageRef.forEach(async (e, i) => {
				const p = new Picture();
				p.path = e.fullPath;
				p.name = e.name;
				p.serverUrl = (await getUrlFromPath(p.path)).url;
				pictures.push(p);
				if (pictures.length == fstrorageRef.length) {
					this.proxyVinProductPictures = pictures;
					logger.info('[SalesOptionsDetailsComponent] ListAllFiles, proxyVinProductPictures:', this.proxyVinProductPictures);
				}
			});
		}
	}

	closeSearchProductPage() {
		this.showSelectProductsList = false;
	}

	clearProxyVin() {
		this.proxyVin = '';
		this.proxyVinProductPictures = [];
		this.em.proxyVin = undefined;
	}

	getTitle(t: string) {
		// Check for all caps
		return !(/^[A-Z]+$/.test(t)) ? toSentence(t) : t;
	}

	getValue(key, value) {
		return (key === 'truckType') ? this.getTitle(EnumHelper.getName(TruckType, value)) : value;
	}

	/**
	 * Load picture if static path picture get failed
	 * @param picture
	 */
	async handleImageError(picture: Picture) {
		picture.serverUrl = '../../../../assets/pride-truck-sales-logo.webp';
		const r = await getUrlFromPath(picture.path);
		if (!r.success) {
			logger.log(r.error);
		} else {
			picture.serverUrl = r.url;
		}
	}

	/**
	 * @author KS
	 * @purpose Realtime displayOnPage selection validation
	 * @param key 
	 * @returns 
	 */
	displayOnPageValidation(key) {
		if (!this.em.specs) return;
		const msg = new MessageInfo({
			msgCss: 'warn',
			header: 'Invalid Specs Selection',
			description: '',
		});
		let countPage = 0;
		Object.entries(this.em.specs)
			.map(([key, value]) => ({ key, value }))
			.forEach((item: any) => {
				if (item?.value?.displayOnPage) countPage++;
			});
		if (countPage === 0) {
			msg.description = 'Select at least one specification for page';
			this.showAlert(msg).then(() => {
				setTimeout(() => {
					this.em.specs[key].displayOnPage = true;
				}, 500);
			});
		}
	}

	/**
	 * @author KS
	 * @purpose Realtime displayOnCard selection validation
	 * @param key 
	 * @returns 
	 */
	displayOnCardValidation(key) {
		if (!this.em.specs) return;
		const msg = new MessageInfo({
			msgCss: 'warn',
			header: 'Invalid Specs Selection',
			description: '',
		});
		msg.description = 'You can choose maximum of 3 specs for a card';
		let countCard = 0;
		Object.entries(this.em.specs)
			.map(([key, value]) => ({ key, value }))
			.forEach((item: any) => {
				if (item?.value?.displayOnCard) countCard++;
			});
		if (!this.hasUpdated) {
			if (countCard === 3) {
				this.enableVal = true;
				this.hasUpdated = true;
			}
		}
		if ((countCard > 3)) {
			this.showAlert(msg).then(() => {
				setTimeout(() => {
					this.em.specs[key].displayOnCard = false;
				}, 500);
			});
		}
		if ((countCard < 2) && this.enableVal) {
			this.showAlert(msg).then(() => {
				setTimeout(() => {
					this.em.specs[key].displayOnCard = true;
				}, 500);
			});
		}
	}

	copyToOriginal() {
		this.isMoveToOg = true;
		this.em.copyToOg = true;
		this.em.imageType = ImageType.original;
		this.proxyPicturePath[0] = this.picturePath[0];
		this.proxyPicturePath[1] = `product-media/${this.em?.proxyVin?.vin}/draft`;
	}


	selectPicsOutPut(e) {
		if (this.m.pictures?.length > 0) {
			this.em.pictures = this.pic?.length > 0 ? [...this.pic, ...e] : [...this.m.pictures, ...e];
		} else if (this.product.pictures?.length > 0) {
			this.em.pictures = [...this.product.pictures, ...e];
		} else {
			this.em.pictures = e;
		}
	}
	// Cm - When product picture deleted then sales-option picture also delete
	deletePicture(p: string) {
		let chgPath = p.replace('/draft/', '/sales-option/');
		this.em.pictures.forEach(async e => {
			if (e.path == chgPath) {
				const dP = await promiseWraper(deleteFile(chgPath));
				logger.log('[deletePicture --->]', dP);
			} else {
				this.pic.push(e);
			}
		});
		if (!!this.pic) {
			this.em.pictures = this.pic;
		}
	}

	showHideImages(event) {
		if (!!this.salesId && this.em.status == SalesStatus.available && !!event) {
			this.aPictures = this.aPictures.map(p => {
				if (event.path == p.path) {
					p.customFirebaseMetadata = event.customMetadata;
				}
				return p;
			});
			this.em.pictures = this.aPictures;
		}
	}

	logoPositionOutPut(e) {
		this.selectedLogoPosition = e;
	}

	openInfoDialog(uid: string, date) {
		this.showLoading(10);
		this.userService.getUserDisplayNameByUid(uid).subscribe((data: { displayName: string }) => {
			this.infoData = { name: data.displayName, date };
			this.hideLoading();
			this.infoDialogRef = this.dialog.open(this.infoDialog);
		}, (error) => {
			logger.error('error getting last modified user details ', error);
			this.hideLoading();
		});
	}

	cancelDialog() {
		this.infoDialogRef.close();
	}

	// Updates or adds a sales warranty to the list
	// If 'w' is provided, it updates the warranty at the specified 'index'
	// If 'w' is not provided, it adds a new warranty to the list
	updateWarranty(w?: SalesWarranty, index?: number) {
		// If 'w' is provided, use it; otherwise, create a new SalesWarranty instance
		let warranty = (w) ? w : new SalesWarranty();
		// Open the SalesWarrantyComponent dialog
		const dialogRef = this.dialog.open(SalesWarrantyComponent, {
			position: {
				top: this.isHandset ? '90px' : '',
			},
			data: { warranty: warranty },
			minWidth: this.isHandset ? '70vw' : '500px',
			autoFocus: false,
		});
		// Subscribe to the dialog's afterClosed event
		dialogRef.afterClosed().subscribe(async (result) => {
			// If the result has a provider and 'w' is not provided, add the result to the warranty list
			if (!!result?.provider && !w) {
				this.em.warranty = this.em.warranty || [];
				this.em.warranty.push(result);
			} else if (!!result?.provider && w) {
				this.em.warranty[index] = result;
			}
			if(this.isHandset){
				this.displayMode = FormDisplayMode.edit; // Cm- in mobile after close the dialog back button save and cancel icon not show
				this.isGoBackCust = true;
			}
		});
	}


	// Deletes a warranty from the list
	deleteWarranty(row: SalesWarranty) {
		// Find the index of the specified warranty in the data source
		const index = this.warrantyDataSource.data.indexOf(row);
		// If the warranty is found in the data source, remove it from the array
		if (index !== -1) {
			this.em.warranty.splice(index, 1);
		}
	}

	// Retrieves the name of a warranty provider based on its type
	getWarrantyProvider(t: WarrantyType) {
		// Retrieve names and values for warranty providers enumeration
		const providers = EnumHelper.getNamesAndValues(WarrantyType);
		// Find the provider with the specified type and get its name
		const status = providers.find(x => x.value === t);
		const s = status ? status.name : '';
		// Return the formatted title
		return this.getTitle(s);
	}

	// Retrieves the name of a sales warranty type based on its type
	getWarrantyType(t: SalesWarrantyType) {
		// Retrieve names and values for sales warranty types enumeration
		const warrantyTypes = EnumHelper.getNamesAndValues(SalesWarrantyType);
		// Find the warranty type with the specified type and get its name
		const status = warrantyTypes.find(x => x.value === t);
		const s = status ? status.name : '';
		// Return the formatted title
		return this.getTitle(s);
	}

	retailPriceUpdate(e) {
		// Update minimumSellingPrice price
		this.em.minimumSellingPrice = this.em.wholesalePrice;
		// Both em and vehicle available according to country set the price 
		if (this.vehicleRetailPrice) {
			let country = this.em.currency == 'CAD' ? 'CA' : 'US';
			this.definedPrice = this.vehicleRetailPrice[`${country}`];
		}
		this.em.price = this.definedPrice + this.em.wholesalePrice;
	}

	/**
	 * @author Cm
	 * @description Get retail price fee
	 */
	getRetailPriceFee() {
		this.mSubscription.push(
			this.store.select(AppState1.appSettings).subscribe(as => {
				const appSetting: AppSetting = as;
				if ((appSetting?.finInput as any)?.retailPriceFeeDb) {
					if (this.em?.productSummary.productType == ProductType.truck) {
						this.vehicleRetailPrice = (appSetting?.finInput as any)?.retailPriceFeeDb.truck;
					}
					if (this.em?.productSummary.productType == ProductType.trailer) {
						this.vehicleRetailPrice = (appSetting?.finInput as any)?.retailPriceFeeDb.trailer;
					}
				}
			})
		);
	}

	/**
	 * Update showPrice
	 */
	priceCheck() {
		this.em.showPrice = (this.em.price < conditionalPrice);
	}



	/**
	 * Validate product's whole sale price
	 * @returns 
	 */
	validateminimumSellingPrice() {
		const tempR = {};
		if (!this.em.minimumSellingPrice) tempR['Sales Option'] = ['Wholesale price is required'];
		if (!isEmpty(tempR)) {
			this.showAlert(fromFailedValidation(tempR).messageInfo);
			this.hideLoading();
			return false;
		} else {
			return true;
		}
	}

	// Custom function to disable dates before the start date
	itemReceivedDateFilter = (date: Date | null): boolean => {
		const mfgDate = this.em.mfgDate;

		// Allow selection only if there is no start date or the date is equal to or after the start date
		return !mfgDate || date >= mfgDate;
	};

	// Handle start date selection to update the end date filter
	handleMfgDateChange(event: MatDatepickerInputEvent<Date>): void {
		this.em.mfgDate = event.value;
	}

	/**
	 * Get paths and urls for all pictures in temp
	 */
	async listAllFilesPic(path: string) {
		// list all files
		const fstrorageRef = (await listAllFiles(path)).items;
		if (fstrorageRef?.length > 0) {
			const pictures: Picture[] = [];
			fstrorageRef.forEach(async (e, i) => {
				const p = new Picture();
				p.path = e.fullPath;
				p.name = e.name;
				p.serverUrl = (await getUrlFromPath(p.path)).url;
				const s = await getMetaData(p.path) as any;
				if(!!s){
					p.customFirebaseMetadata = s.customMetadata;
					p.uploadedOn = s.updated;
				}
				pictures.push(p);
			});
			this.salesOptionPic = pictures.sort((a, b) => +b?.customMetadata?.order - +a?.customMetadata?.order).filter(p => p.path.includes('pts/promotions') || p.customFirebaseMetadata?.visibility );
		}
	}



	// Warranty title string for warranty
	getWarrantyTitle(w: SalesWarranty): String {
		if (!w) return;
		const salesWarranty = new SalesWarranty();
		return salesWarranty.getWarrantyTitle(w);
	}

}