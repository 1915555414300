import { FileInfo, IFileMoveData } from '../media/file-info';
import { CompanyBase, CompanyValidationGroup, CompanySummaryBase } from './company-base';
import {
  ValidatorOptions, Matches, Length, IsBoolean, Max, IsNumber, ValidateNested, IsDefined,
  IsDate, ValidateIf, IsInt, MaxLength
} from 'class-validator';
import { CompanyType } from './company-type';
import { isDate, isAfter, addYears, addDays } from 'date-fns';
import { IValidationMsg, IValidate, IValDbStatusResult } from '../error-handling/validation-info';
import { instanceToInstance, plainToInstance, Type, Expose, Exclude } from 'class-transformer';
import { DbStatus } from '../base';
import { sanitizeDateIPoint, sanitizeDate } from '../utility';
import { ContactCard } from '../user/contact-card';
import { Address } from '../address/address';
import { logger } from '../log/logger';
import { CarrierCompanySummary } from './company-carrier';
import { OwnershipType } from './ownership-type';
import { CompanyStatus } from './company-status';


export interface CustomerCompanySummary extends CompanySummaryBase {
  officer: ContactCard[];
}

export interface VendorCompanySummary extends CompanySummaryBase {
  officer: ContactCard[];
  companyType?: CompanyType;
}
/** https://safer.fmcsa.dot.gov/CompanySnapshot.aspx
 * https://saferwebapi.com/documentation/snapshots-usdot
 */
const regex = {
  js: {
    nounCvor: /^([0-9]){3}([\-])([0-9]){3}(\-)([0-9]){3}$/,
    nounNir: /^([A-Z]){1}([\-])([0-9]){6}(\-)([0-9]){1}$/,
    nounUsDot: /^([0-9]){5,7}$/,
    nounMcNo: /^([0-9]){6,7}$/,
  }
};

const legal: CompanyValidationGroup = 'legal';
const voidCheque: CompanyValidationGroup = 'voidCheque';
const accountDetails: CompanyValidationGroup = 'accountDetails';


/** Fleet Providers */
@Exclude()
export class CompanyFleetEquipment extends CompanyBase implements IValidate {

  /** Not to be filled manually will be automatically updated as assets are added / deleted */
  @Expose()
  @IsNumber()
  nTruck = 0;

  /** Not to be filled manually will be automatically updated as assets are added / deleted */
  @Expose()
  @IsNumber()
  nDriver = 0;

  /** Not to be filled manually will be automatically updated as assets are added / deleted */
  @Expose()
  @IsNumber()
  nTrailer = 0;

  /** UI Helper for Min Insurance expiry */
  get insExpiryDateMinDate() {
    return new Date();
  }
  /** UI Helper for Max Insurance expiry */
  get insExpiryDateMaxDate() {
    return addDays(addYears(new Date(), 1), -1);
  }
  get isLesseeStr() {
    if (!!this.isLessee) {
      return 'Approved Lessee';
    } else {
      return 'Not Approved as Lessee';
    }
  }
  get isLessorStr() {
    if (!!this.isLessor) {
      return 'Approved Lessor';
    } else {
      return 'Not Approved as Lessor';
    }
  }
  public get carrierCompanySummary(): CarrierCompanySummary {
    return {
      cid: `${this.id}`,
      name: this.name,
      legalName: this.legalName,
      address: this.address,
      dbStatus: this.dbStatus,
      revId: this.revId,
      phone: !!this.companyContact ? this.companyContact.phone : undefined,
      email: !!this.companyContact ? this.companyContact.email : undefined
    };
  }

  // #endregion
  constructor() {
    super();
    this.voidCheque = new FileInfo();
    this.companyType = CompanyType.EquipmentVendor;
    FileInfo.updateFileSecurity(this, true);
  }

  /** Convert all GeoPoint class instances to IPoint. required before parsing data coming back
 * from firebase. This must be called BEFORE PARSE and not AFTER PARSE
  * @param data Data to be parsed
  * @param type type to be used to decipher latitude/longitude. This is needed because at client the type is : firebase.firestore.GeoPoint
  * and at function it is admin.firestore.GeoPoint they are two different types.
  * https://github.com/Microsoft/TypeScript/wiki/FAQ#why-cant-i-write-typeof-t-new-t-or-instanceof-t-in-my-generic-function
  */
  public static parse(obj) {
    // public static parse<T extends IPoint>(obj, ipointType?: { new(...args: any[]): T }) {
    try {
      if (obj == null) { return null; }
      // obj = sanitizeDateIPoint(obj, ipointType);
      obj = sanitizeDateIPoint(obj);
      if(obj.isLessor === true && obj.lesseeStatus === undefined){
        obj.lessorStatus = CompanyStatus.approved;
      }
      if(obj.isLessee === true && obj.lesseeStatus === undefined){
        obj.lesseeStatus = CompanyStatus.approved;
      }
      const m = plainToInstance<CompanyFleetEquipment, any>(CompanyFleetEquipment, obj);
      m.sanitize();
      if (m.contact == null) { m.contact = new ContactCard(); }
      if (m.aOI == null) { m.aOI = new FileInfo(); }
      if (m.voidCheque == null) { m.voidCheque = new FileInfo(); }
      if (m.officer?.length > 0) {
        m.officer = ContactCard.parseArray((<CompanyFleetEquipment>obj).officer);
      }
     
      if (m.address == null) { m.address = new Address(); }

      return m;
    } catch (error) {
      logger.log('Error happened during parse', error);
      return null;
    }
  }

  public getCustomerCompanySummary(c?: CompanyFleetEquipment): CustomerCompanySummary {
    return {
      cid: `${this.id}`,
      legalName: this.legalName,
      address: this.address,
      officer: this.officer,
      dbStatus: this.dbStatus,
      revId: this.revId,

    };
  }
  public getVendorCompanySummary(): VendorCompanySummary {
    return {
      cid: `${this.id}`,
      legalName: this.legalName,
      address: this.address,
      officer: this.officer,
      dbStatus: this.dbStatus,
      revId: this.revId,
      phone: this.companyContact?.phone,
      email: this.companyContact?.email,
      companyType: this.companyType
    };
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  sanitize() {
    super.sanitize();
  }

  /** Update all attached documents paths from /draft/ to /rel/ */
  updateFilePathsToRelease(): IFileMoveData[] {
    const R: IFileMoveData[] = [];

    let r = FileInfo.updatePathToRelease(this.voidCheque); // this.aOI
    if (!!r) { R.push(r); }

    if (this.ownershipType === OwnershipType.privateCorp) {
      r = FileInfo.updatePathToRelease(this.aOI);
      if (!!r) { R.push(r); }
      for (const o of this.officer) {
        r = FileInfo.updatePathToRelease(o.photoId);
        if (!!r) { R.push(r); }
      }
    }
    return R;
  }

  validateSyncGroup(group: CompanyValidationGroup ): IValidationMsg {
    return this.validateSync({ groups: [group] });
  }

  validateSync(options?: ValidatorOptions): IValidationMsg {

    // for nested entry for address, add address group in it.
    if (!!options && !!options.groups && options.groups.indexOf(legal) > -1) {
      options.groups.push(Address.gName);
      // options.groups.push(ContactCard.gName);
    }

    const r = super.validateSync(options);

    return r;
  }

  /** Analyze company to see if more information is needed or suggest the user
   * to submit for approval.*/
  getValDbStatus(): IValDbStatusResult {
    const result: IValDbStatusResult = {
      pass: false,
      message: undefined,
      groupResult: {}
    };    //  { [group: string]: { result: boolean; message: string; } } = {};
    let x: { groupPass: boolean; message: string; };

    // Legal
    let r = this.validateSyncGroup(legal);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Manage Legal Information' : 'Legal information is required'; //  `${Object.values(r)[0]}`
    result.groupResult[legal] = x;

    // accountDetails
    r = this.validateSyncGroup(accountDetails);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Manage Accounting Information' : 'Accounting information is required';
    result.groupResult[accountDetails] = x;



    // is it passed all the tests? (True means it failed here)
    result.pass = !Object.keys(result.groupResult).some((k) => !result.groupResult[k].groupPass);

    // passed.
    if (!result.pass) {
      if (this.dbStatus === DbStatus.Initial || this.dbStatus === DbStatus.ReleasedMod) {
        result.message = 'Admin approval is pending!';
      }
    } else {
      result.message = 'More information is required!';
    }

    return result;
  }
  getValDbStatusToApprove(): IValDbStatusResult {


    let result: IValDbStatusResult = {
      pass: false,
      message: undefined,
      groupResult: {}
    };
    result = this.getValDbStatus();
    if (!result.pass) {
      return result;
    }
    let x: { groupPass: boolean; message: string; };

    result.pass = !Object.keys(result.groupResult).some((k) => !result.groupResult[k].groupPass);
    // passed.
    if (!result.pass) {

      result.message = 'Upload Credit Package';

    }

    return result;
  }
  validateSetLesseeLessor(options?: ValidatorOptions) {
    const r = super.validateSyncBase(this, options);
    if (this.dbStatus < DbStatus.Released && this.lesseeStatus === CompanyStatus.approved) {
      r['lesseeStatus'] = ['Cannot change status for company which is not in released state'];
    }
    if (this.dbStatus < DbStatus.Released && this.lessorStatus === CompanyStatus.approved) {
      r['lessorStatus'] = ['Cannot change status for company which is not in released state'];
    }
    return r;
  }
}
