import { PicLocId } from './pic-loc-id';
import { FileInfo } from '../media/file-info';
import { Exclude, Expose, instanceToInstance, plainToInstance } from 'class-transformer';
import { Length, validateSync, ValidationError, IsEnum } from 'class-validator';
import { IValidationMsg } from '../error-handling/validation-info';
import { sanitizeDateIPoint } from '../utility/sanitize-helper';
import { toValidationError } from '../utility/validation-helper';
import { environment } from '../../core/environments/environment';

export const pictureTargetSize = 500000;//500 KB

//Maintain storage image metadata
export interface CustomFirebaseMetadata  {
  imageType : string,
  firebaseStorageDownloadTokens : string,
  loc : string,
  visibility : boolean,
  uid? : string,
  vin? : string,
  order : number,
}

export enum LogoPosition{
   northwest = 1,
   northeast = 2,
   southwest = 3,
   southeast = 4,
   noLogo = 5
}

@Exclude()
export class Picture extends FileInfo {
  constructor() {
    super();
  }

  @Expose()
  @IsEnum(PicLocId)
  locId?: PicLocId;

  /** Displayed name to the end user. this is not the name of the file stored at cloud storage. */
  @Expose()
  @Length(0, 96, { message: 'Comments be $constraint1 - $constraint2 chars long' })
  comment?: string;

  _serverUrl : string;

  get serverUrl() {
    return this._serverUrl ? this._serverUrl : `https://firebasestorage.googleapis.com/v0/b/${environment.fire.storageBucket}/o/${encodeURIComponent(
      this.path
    )}?alt=media&token=${this.customFirebaseMetadata?.firebaseStorageDownloadTokens}`;  
  }

  set serverUrl(path : string){
    this._serverUrl = path;
  }

  @Expose()
  isPlaceHolder?: boolean;

  /** UI helper */
  deleteDisabled?: boolean;

  /** Displayed name to the end user. this is not the name of the file stored at cloud storage. */
  @Expose()
  customFirebaseMetadata?: CustomFirebaseMetadata;

  @Expose()
  logoPosition:LogoPosition;

  public static parse(obj) {
    if (obj == null) { return null; }

    if (obj instanceof (Picture)) { return <Picture>obj; }

    const m = plainToInstance<Picture, any>(Picture, sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
  }
  public static parsePictureArray(obj: any) {
    return obj.map(o => Picture.parse(o));
  }
  sanitize() {
  }

  clone() {
    const t = instanceToInstance(this);
    // t.sanitize();
    return t;
  }
  validateSync(): IValidationMsg {
    const r = this.validateSyncBase(this);
    return r;
  }

  /** Helper Method  to convert validation errors to easy display messages messages */
  protected toValidationError(ve: ValidationError[], prefix?: string): IValidationMsg {
    return toValidationError(ve);
  }
  addPlaceHolder(url: string) {
    this.url = url;
    this.isPlaceHolder = true;
  }


}

