import { IsDefined, IsNumber, Length, Min, ValidateIf, ValidatorOptions, validateSync } from 'class-validator';
import { Exclude, instanceToInstance, plainToInstance, Expose, Type, Transform } from 'class-transformer';
import { IValidationMsg } from '../error-handling';
import { sanitizeDateIPoint, toValidationError } from '../utility';
import { BaseModel } from '../base';
import { logger } from '../log/logger';
import { FileInfo } from '../media/file-info';

/**
 * 
 * @author - MKN
 * Interface create/update api call
 * 
 */
export interface IPromotionData {
	data: Promotions;
	promotionId?: string | number;
	options: ValidatorOptions;
}

export enum PromotionType {
	application = 1,
	product = 2,
}

export interface PromotionsSummary {
	promoImgPosition: number,
	url: string,
	id: string | number,
}

/**
 * @author - MKN
 * @purpose - 
 */
@Exclude()
export class Promotions extends BaseModel {

	public static readonly collectionName = 'promotions';

	@Expose()
	@Length(3, 50, { message: 'Promotion Name must be $constraint1 - $constraint2 chars long' })
	name: string;

	@Expose()
	@Length(3, 150, { message: 'Promotion header must be $constraint1 - $constraint2 chars long' })
	header: string;

	@Expose()
	@IsDefined({ message: 'email Template must be defined' })
	@ValidateIf((object, value) => object.promotionType !== PromotionType.product)
	emailTemplate: string;

	@Expose()
	country: 'USA' | 'CA' = 'CA';

	@Expose()
	// @Length(1, 500, { message: 'SMS Template must be $constraint1 - $constraint2 chars long' })
	@IsDefined({ message: 'SMS Template must be defined' })
	@ValidateIf((object, value) => object.promotionType !== PromotionType.product)
	smsTemplate: string;

	@Expose()
	promotionType: PromotionType;

	@Expose()
	@IsDefined({ message: 'Promotional image is required' })
	promoImg: FileInfo;

	@Expose()
	@ValidateIf((object, value) => object.promotionType === PromotionType.product)
	@IsDefined({ message: 'Image position is required' })
	@Min(1, { message: 'Image position must be greater than 0' })
	promoImgPosition: number;

	constructor() {
		super();
		this.promotionType = PromotionType.application;
	}

	public static parse(obj) {
		if (obj instanceof (Promotions)) { return <Promotions>obj; }

		const m = plainToInstance<Promotions, any>(Promotions, sanitizeDateIPoint(obj));
		m.sanitize();
		return m;
	}

	sanitize() {
		// super.sanitize();
	}

	clone() {
		const t = instanceToInstance(this);
		t.sanitize();
		return t;
	}

	validateSync(options?: any): IValidationMsg {

		const m = validateSync(this, options);
		const r = toValidationError(m);
		return r;
	}

	updatePromotionData(iUpdateData: Promotions) {
		this.name = iUpdateData.name;
		this.header = iUpdateData.header;
		this.emailTemplate = iUpdateData.emailTemplate;
		this.smsTemplate = iUpdateData.smsTemplate;
		this.promoImgPosition = iUpdateData.promoImgPosition
		this.promoImg = iUpdateData.promoImg
	}


	getPromotionsSummary(): PromotionsSummary {
		return {
			promoImgPosition: this.promoImgPosition,
			url: this.promoImg?.url,
			id: this.id,
		};
	}

}