import { BaseModel } from '../base/base-model';
import { IsEnum, IsDate, IsBoolean, IsDefined, IsArray, IsNumber, ValidateNested, ValidateIf, ValidatorOptions, IsInt, MaxLength, isArray, Equals, IsIn, } from 'class-validator';
import { ProductType } from '../product/product-type';
import { Term } from './term';
import { sanitizeDate } from '../utility/sanitize-helper';
// tslint:disable-next-line:max-line-length
import { isDate, isAfter, addYears, addDays, startOfDay, isBefore, addMonths, addWeeks, compareAsc, isWithinInterval, startOfTomorrow, differenceInDays, isEqual } from 'date-fns';

import { IValidationMsg, IValDbStatusResult } from '../error-handling/validation-info';
import { IPointGeo } from '../product-search/interfaces';
import { instanceToInstance, Expose, Exclude, Type } from 'class-transformer';
import { Address } from '../address/address';
// import { ProductSummary, BidBase, ProductBase, CompanyBase } from '../index';
import { addNDays } from '../utility/utl-datef';
import { BindingBid } from '../bid/binding-bid';
import { DbStatus } from '../base/db-status';
import { VendorCompanySummary, CompanyFleet } from '../company/company-fleet';
import { RentOptionStatus } from './rent-option-status';
import { RentOptionSummary } from './rent-option-summary';
import { NegoTermsBase } from '../nego-terms-base/nego-terms-base';
import { BidStatus } from '../bid/bid-status';
import { Author } from '../bid/author';
import { RentalTruck } from './rental-truck';
import { UpdateDocRef, CronActions } from '../cron-jobs/cron-jobs-log';
import { DbRule } from '../base/db-rule';
import { ProductSummary, ProductBase } from '../product';
import { CompanyBase } from '../company';
import { BidBase } from '../bid/bid-base';
import { BucketName } from '../server-storage/i-strorage-metadata';
import { MapLabel } from '../map/map-label';
import { Picture } from '../inspection/picture';
import { shortDate } from '../utility/timer-helper';
import { MileageType } from './milage-type';
import { RentalTerm } from './rental-term';
import { RentalPlan } from './rental-plan';
import { logger } from '../log/logger';
import { currencies, CurrencyType } from '../finance/currency/currency-type';
import { isNil } from 'lodash';

// tslint:disable-next-line:max-line-length
export type RentalValidationGroup = 'locationAndDeposit' | 'ratePlan' | 'shortTerm' | 'monthly' | 'yearly' | 'pictures' | 'exclusive' | 'unitAssignment';
const locationAndDeposit: RentalValidationGroup = 'locationAndDeposit';
const ratePlan: RentalValidationGroup = 'ratePlan';
const shortTerm: RentalValidationGroup = 'shortTerm';
const monthly: RentalValidationGroup = 'monthly';
const yearly: RentalValidationGroup = 'yearly';
const pictures: RentalValidationGroup = 'pictures';
const exclusive: RentalValidationGroup = 'exclusive';
const unitAssignment: RentalValidationGroup = 'unitAssignment';



@Exclude()
export class MilageLimit {
  mileageType: MileageType;
  ratePerMile: number;
  milage: number;
  plan: RentalTerm;

}

@Exclude()
export class RentalProductBase extends BaseModel {




  constructor() {
    super();

    this.bindingBids = [];
    this.rentalPlans = RentalPlan.initPlans();

  }
  public static readonly collectionName = 'rent-options';
  public static readonly bucket: BucketName = 'appspot.com';

  thumbNailImg: string;

  // /** Required for back dated units Assigned by vendor without saving rent option*/
  // @Expose()
  // @IsBoolean()
  // isLegacy = false;

  // /** Required for units Assigned by vendor without saving rent option*/
  // @IsBoolean()
  // isAssignmentUnit = false;

  @Expose()
  @IsBoolean()
  isReplacementUnit = false;

  // /** Required for units Assigned by vendor without saving rent option*/
  // @Expose()
  // @ValidateIf(o => o.isAssignmentUnit === true && o.deposit > 0, {groups: [unitAssignment]})
  // @IsDate( {groups: [unitAssignment]})
  // depositDate: Date;

  // /** Required for Assigned by vendor without saving rent option*/
  // @Expose()
  // @ValidateIf(o => o.isAssignmentUnit === true, {groups: [unitAssignment]})
  // @IsDate({groups: [unitAssignment]})
  // firstPaymentDate: Date;

  @Expose()
  @Type(() => Picture)
  @IsDefined({ message: 'Upload Pictures', groups: [pictures] })
  pictures: Picture[];

  @Expose()
  @Type(() => RentalPlan)
  @ValidateNested({ message: 'Define Rental Plans', groups: [ratePlan] })
  rentalPlans: RentalPlan[];

  get shortPlans() {
    return this.rentalPlans.filter(f => f.rentalTerm < RentalTerm.monthly && f.isAvailable && f.isSelected);
  }
  get monthlyPlans() {
    // tslint:disable-next-line:max-line-length
    return this.rentalPlans.filter(f => f.rentalTerm >= RentalTerm.monthly && f.rentalTerm < RentalTerm.oneYear && f.isAvailable && f.isSelected);
  }
  get yearlyPlans() {
    return this.rentalPlans.filter(f => f.rentalTerm >= RentalTerm.oneYear && f.isAvailable && f.isSelected);
  }

  get selectedPlans() {
    return this.rentalPlans.filter(f => f.isAvailable && f.isSelected);
  }


  @Expose()
  @IsEnum(ProductType)
  productType: ProductType;

  productId: string;

  // @Expose()
  // @IsEnum(Term, { message: 'Select Rental Term Option' })
  term: Term;

  @Expose()
  @IsDate({ message: 'Avialable Start date is required', groups: [ratePlan] })
  availStartDate: Date;

  get availStartDateMin() {
    return new Date();
  }
  get availStartDateMax() {
    return (addMonths(new Date(), 24));
  }
  @Expose()
  @IsDate({ message: 'Avialable End date is required', groups: [ratePlan] })
  availEndDate: Date;

  get availEndDateMin() {
    if (!!this.availStartDate) {
      return addDays(this.availStartDate, 1);
    } else {
      return addDays(new Date(), 1);
    }
  }
  get availEndDateMax() {
    return (addMonths(new Date(), 24));
  }
  // @Expose()
  // @IsNumber()
  rate: number;

  @Expose()
  // @Equals('CAD' || 'USD')
  @IsIn(currencies)
  currency: CurrencyType; // 'CAD' | 'USD';

  @Expose()
  @IsNumber(undefined, { message: 'Number is required for Security Deposit', groups: [locationAndDeposit] })
  deposit: number;

  // @Expose()
  rentPerDay: number; // calculated value at the functions

  // @ExpValidateNested({ message: 'Address info is required' })
  @Expose()
  @ValidateNested({ message: 'Address info is required', groups: [locationAndDeposit, Address.gName] })
  @IsDefined({ message: 'Address information is required', groups: [locationAndDeposit, Address.gName] })
  @Type(() => Address)
  startAddress: Address;

  @Expose()
  @ValidateIf(f => !!f.isOneWay, { groups: [locationAndDeposit, Address.gName] })
  @ValidateNested({ message: 'Address info is required', groups: [locationAndDeposit, Address.gName] })
  @IsDefined({ message: 'Address information is required', groups: [locationAndDeposit, Address.gName] })
  @Type(() => Address)
  endAddress: Address;

  @Expose()
  @ValidateIf(f => !!f.isOneWay, { groups: [locationAndDeposit, Address.gName] })
  @IsNumber(undefined, { groups: [locationAndDeposit] })
  dropOffFee: number;

  @Expose()
  @IsDefined({ groups: [locationAndDeposit, Address.gName] })
  startLoc: IPointGeo;

  @ValidateIf(f => !!f.isOneWay, { groups: [locationAndDeposit, Address.gName] })
  @Expose({ groups: [locationAndDeposit, Address.gName] })
  endLoc?: IPointGeo;

  @Expose()
  @IsBoolean({ groups: [locationAndDeposit] })
  isOneWay = false;

  @Expose()
  productSummary: ProductSummary;

  @Expose()
  @IsArray()
  bindingBids: Array<BindingBid>;


  // @IsDefined() // vendorCompSummary not passed client, but added by
  @Expose()
  vendorCompSummary: VendorCompanySummary;

  @Expose()
  // @IsEnum(RentOptionStatus) // Property set at servier does not required client validation
  rentOptionStatus: RentOptionStatus;

  @Expose()
  // @IsDate() // Property set at servier does not required client validation // on create set to availableEndDate.
  obsoleteOn: Date;   // on addition of binding bid sdate from openDateRange

  // @ValidateIf(o => !!o.isMonthlySelected && !!o.isMonthlyAvail)
  // @Expose()
  // @IsNumber(undefined, { message: 'Must be a number' })
  rateMonthly: number;

  // @ValidateIf(o => !!o.isWeeklySelected && !!o.isWeeklyAvail)
  // @Expose()
  // @IsNumber(undefined, { message: 'Must be a number' })
  rateWeekly: number;

  // @ValidateIf(o => !!o.isDailySelected)
  // @Expose()
  // @IsNumber(undefined, { message: 'Must be a number' })
  rateDaily: number;

  /** Monthly rate on 3 month term */
  // @ValidateIf(o => !!o.isMonthlySelected && !!o.isThreeMonthAvail)
  // @Expose()
  // @IsNumber(undefined, { message: 'Must be a number' })
  rateThreeMonthTerm: number;
  /** Monthly rate on 6 month term */
  // @ValidateIf(o => !!o.isMonthlySelected && !!o.isSixMonthAvail)
  // @Expose()
  // @IsNumber(undefined, { message: 'Must be number' })
  rateSixMonthTerm: number;
  /** Monthly rate on 6 month term */
  // @ValidateIf(o => !!o.isMonthlySelected && !!o.isYearlyAvail)
  // @Expose()
  // @IsNumber(undefined, { message: 'Must be a number' })
  rateYearTerm: number;
  /**
   * Rate entered in UI as $/miles e.g. 29c/mile is entered as 0.29
   */
  // @Expose()
  // @ValidateIf(o => o.mileageType !== MileageType.unlimted)
  // @IsNumber(undefined, { message: 'Rate per mile e.g. $0.29/mile ' })
  ratePerMile: number;

  /**
   * Rate entered in UI as $/miles e.g. 29c/mile is entered as 0.29
   */
  // @Expose()
  // @IsEnum(MileageType)
  mileageType = MileageType.minimum;

  /**
 * minimum miles per day
 */
  // @ValidateIf(o => o.mileageType !== MileageType.unlimted && o.isDailyAvail && o.isDailySelected)
  // @Expose()
  // @IsInt()
  mileageDaily: number;
  /**
 * minimum miles per week
 */
  // @ValidateIf(o => o.mileageType !== MileageType.unlimted && o.isWeeklyAvail && o.isWeeklySelected)
  // @Expose()
  // @IsInt()
  milageWeekly: number;
  /**
 * minimum miles per month
 */
  // @ValidateIf(o => o.mileageType !== MileageType.unlimted && o.isMonthlyAvail && o.isMonthlySelected)
  // @Expose()
  // @IsInt()
  milageMonthly: number;

  get applyMonthly() { if (this.isMonthlyAvail && this.isMonthlySelected) { return true; } else { return false; } }
  get applyWeekly() { if (this.isWeeklyAvail && this.isWeeklySelected) { return true; } else { return false; } }
  get applyDaily() { if (this.isDailyAvail && this.isDailySelected) { return true; } else { return false; } }


  // isMonthlyAvail: boolean;
  // isWeeklyAvail: boolean;
  isDailyAvail = true;

  // @ValidateIf(o => isAfter(o.avaialEndDate, addMonths(o.availStartDate, 1)))
  // @Expose()
  // @IsBoolean()
  isMonthlySelected = true;

  // @ValidateIf(o => isAfter(o.avaialEndDate, addWeeks(o.availStartDate, 1)))
  // @Expose()
  // @IsBoolean()
  isWeeklySelected = true;

  // @Expose()
  // @IsBoolean()
  isDailySelected = true;

  // @ValidateIf(o => isAfter(o.avaialEndDate, addMonths(o.availStartDate, 3)))
  // @Expose()
  // @IsBoolean()
  isThreeMonthTermSelected = true;

  // @ValidateIf(o => isAfter(o.avaialEndDate, addMonths(o.availStartDate, 6)))
  // @Expose()
  // @IsBoolean()
  isSixMonthTermSelected = true;

  // @ValidateIf(o => isAfter(o.avaialEndDate, addMonths(o.availStartDate, 6)))
  // @Expose()
  // @IsBoolean()
  isYearTermSelected = true;

  @Expose()
  @IsBoolean()
  isExclusive = false;
  @Expose()

  @ValidateIf(o => !!o.isExclusive, { groups: [exclusive] })
  @Expose()
  @IsArray()
  targetCCids: string[] = [];

  @Expose()
  @IsBoolean()
  isActive = true;

  @ValidateIf(o => !!o.promoContent, { groups: [locationAndDeposit] })
  @Expose()
  @MaxLength(300)
  promoContent: string;

  @Expose()
  shortUrl: string; //PT - the url which we are going to use while sending any product detail with the user

  startD?: Date[];  // available Start Dates based on binding bids,calcuated value not stored in db
  endD?: Date[]; // available End Dates based on binding bids,calcuated value not stored in db

  get openDateRange(): { start: Date, end: Date }[] {
    return this.getOpenRentalDates();
  }

  dist?: number; // distance from the search lat/long used for client search not stored in db

  bidsWaitingForVendor: BidBase[]; // property no saved in dB, prop required for myRental UI
  bidsWaitingForCustomer: BidBase[]; // property no saved in dB, prop required for myRental UI
  bidsAccepted: BidBase[];

  get minTerm() {
    let term: Term;
    if (!!this.rateMonthly && !this.rateWeekly && !this.rateDaily) {
      term = Term.monthly;
    } else if (!!this.rateWeekly && !this.rateDaily) {
      term = Term.weekly;
    } else if (!!this.rateDaily) {
      term = Term.daily;
    }
    return term;
  }
  get rateString(): { monthly: string, weekly: string, daily: string } {
    let mrs: string;
    let wrs: string;
    let drs: string;
    if (!!this.rateMonthly && !!this.isMonthlyAvail) {
      mrs = `$${this.rateMonthly} ${this.currency} per month`;
    }
    if (!!this.rateWeekly && !!this.isWeeklyAvail) {
      wrs = `$${this.rateWeekly} ${this.currency} per week`;
    }
    if (!!this.rateDaily) {
      drs = `$${this.rateDaily} ${this.currency} per day`;
    }
    return { monthly: mrs, weekly: wrs, daily: drs };
  }
  static availableDate(r: RentalProductBase): { sDate: Date[], eDate: Date[] } {
    const sDate: Date[] = [];
    const eDate: Date[] = [];

    let j: number;
    if (r.bindingBids.length === 0 || r.bindingBids === null || r.bindingBids === undefined) {
      sDate.push(r.availStartDate);
      eDate.push(r.availEndDate);
    } else {
      const bindingBids = r.bindingBids;
      const sortedBindingBids = bindingBids.sort((a, b) => a.bindingStartD.valueOf() - b.bindingStartD.valueOf());

      /** if binding bid does not strart from avialable start date */
      if (r.availStartDate.valueOf() !== sortedBindingBids[0].bindingStartD.valueOf()) {
        sDate.push(r.availStartDate);
        eDate.push(addNDays(-1, sortedBindingBids[0].bindingStartD));
        for (j = 1; j < sortedBindingBids.length; j = j + 1) {

          if (sortedBindingBids[j].bindingStartD.valueOf() > addNDays(1, sortedBindingBids[j - 1].bindingEndD).valueOf()) {

            sDate.push(addNDays(1, sortedBindingBids[j - 1].bindingEndD));
            eDate.push(addNDays(-1, sortedBindingBids[j].bindingStartD));
          }
        }
      }
      /** if binding bid strarts from avialable start date */

      if (r.availStartDate.valueOf() === sortedBindingBids[0].bindingStartD.valueOf()) {

        for (j = 1; j < sortedBindingBids.length; j = j + 1) {


          if (sortedBindingBids[j].bindingStartD.valueOf() > addNDays(1, sortedBindingBids[j - 1].bindingEndD).valueOf()) {

            sDate.push(addNDays(1, sortedBindingBids[j - 1].bindingEndD));
            eDate.push(addNDays(-1, sortedBindingBids[j].bindingStartD));
          }
        }
      }
      /** if binding bid does not end at avialable end date */
      if (r.availEndDate.valueOf() !== sortedBindingBids[sortedBindingBids.length - 1].bindingEndD.valueOf()) {
        sDate.push(addNDays(1, sortedBindingBids[sortedBindingBids.length - 1].bindingEndD));
        eDate.push(r.availEndDate);
      }
    }
    // remove availability date segements if end Date is in past
    for (let ix = 0; ix < sDate.length; ix++) {
      if (eDate[ix].valueOf() < new Date().valueOf()) {
        eDate.splice(ix, 1);
        sDate.splice(ix, 1);
      }
      // if start date is in past but end date is in future reset start date to tomorrow start of the day
      if (!!sDate[ix] && sDate[ix].valueOf() < new Date().valueOf() && eDate[ix].valueOf() > new Date().valueOf()) {
        sDate[ix] = startOfDay(addDays(new Date(), 1));
      }
    }
    return { sDate: sDate, eDate: eDate };
  }
  public static getRentPerDay(r: number, t: Term): number {
    let rPerDay = null;
    switch (t) {
      case Term.daily:
        rPerDay = r;
        break;
      case Term.weekly:
        rPerDay = r / 7;
        break;
      case Term.monthly:
        rPerDay = r / 30.44;
        break;
      default:
        break;
    }
    return rPerDay;
  }

  public static getApplicableRentalTerm(startDate: Date, endDate: Date, rentSummary: RentOptionSummary)
    : { applicableTerm: Term, proratedDailyRate: number } {
    let applicableTerm: Term;
    let proratedDailyRate: number;
    // if duration is more than month monthly rate is provided
    if ((compareAsc(endDate, addMonths(startDate, 1)) === 0 || isAfter(endDate, addMonths(startDate, 1))) &&
      !!rentSummary.rateMonthly) {
      applicableTerm = Term.monthly;
      proratedDailyRate = rentSummary.rateMonthly / 30.44;
    }
    // if duration is more than week and less than month and weekly rate is provided
    if ((compareAsc(endDate, addWeeks(startDate, 1)) === 0 || isAfter(endDate, addWeeks(startDate, 1))) &&
      isBefore(endDate, addMonths(startDate, 1)) && !!rentSummary.rateWeekly) {
      applicableTerm = Term.weekly;
      proratedDailyRate = rentSummary.rateWeekly / 7;
    }
    // if duration is more than week (can be even more than month)and weekly rate is provided but monthly rate is not provided
    if ((compareAsc(endDate, addWeeks(startDate, 1)) === 0 || isAfter(endDate, addWeeks(startDate, 1)))
      && !!rentSummary.rateWeekly && !rentSummary.rateMonthly) {
      applicableTerm = Term.weekly;
      proratedDailyRate = rentSummary.rateWeekly / 7;
    }
    // if duration is less than week and daily rate is provided
    if (isBefore(endDate, addWeeks(startDate, 1)) && !!rentSummary.rateDaily) {
      applicableTerm = Term.daily;
      proratedDailyRate = null;
    }
    // if duration is more than week and daily rate is provided but weekly rate is not provided
    if (isAfter(endDate, addWeeks(startDate, 1)) && !!rentSummary.rateDaily && !rentSummary.rateWeekly) {
      applicableTerm = Term.daily;
      proratedDailyRate = null;
    }
    // if duration is more than month and daily rate is provided but monthly rate is not provided
    if (isAfter(endDate, addMonths(startDate, 1)) && !!rentSummary.rateDaily && !rentSummary.rateMonthly && !rentSummary.rateWeekly) {
      applicableTerm = Term.daily;
      proratedDailyRate = null;
    }
    return { applicableTerm: applicableTerm, proratedDailyRate: proratedDailyRate };
  }

  /** Filters obsolete and closed rent opptions before cleaned by cron job
   * @param: RentalProductBase[]
   *
   */
  public static setObsClosedRentals(p: RentalProductBase[]) {
    p.map(e => {
      if (e.openDateRange.length === 0) { e.rentOptionStatus = RentOptionStatus.closed; }
    });
    p.map(e => {
      if (e.openDateRange.length > 0 &&
        isAfter(new Date(), e.openDateRange[e.openDateRange.length - 1].end)) { e.rentOptionStatus = RentOptionStatus.obsolete; }
    });
    return p;
  }
  public updateBindingBidInRentOption(bid: BidBase,
    negoTerms: NegoTermsBase, bidStatus: BidStatus)
    : RentalProductBase {
    let bBids = [];
    if (negoTerms.author === Author.customer) {
      const index = this.bindingBids.findIndex(x => x.bindingBidId === bid.id);
      if (bidStatus === BidStatus.waitingForVendor || bidStatus === BidStatus.rejected) {
        bBids = this.bindingBids.slice();
        bBids.splice(index, 1);
        this.bindingBids = bBids;
      } else if (bidStatus === BidStatus.accepted) {
        this.bindingBids[index].bidStatus = bidStatus;
      }
    } else {
      if (bidStatus === BidStatus.waitingForCustomer || bidStatus === BidStatus.accepted) {
        if (!this.bindingBids && this.bindingBids.length === 0) { this.bindingBids = []; }
        const idx = this.bindingBids.findIndex(f => f.bindingBidId === bid.id);
        const d = {
          bindingBidId: bid.id,
          bindingStartD: negoTerms.startDate,
          bindingEndD: negoTerms.endDate,
          bidStatus: bidStatus,
          revId: bid.revId
        };
        if (idx > -1) {
          this.bindingBids[idx] = d;
        } else {
          this.bindingBids
            .push(d);
        }
        if (isBefore(negoTerms.endDate, this.availEndDate)) {
          this.availStartDate = addDays(negoTerms.startDate, 1);
        }
        this.isActive = false;
        this.rentOptionStatus = RentOptionStatus.closed;
      }
    }
    this.obsoleteOn = this.setObsoleteDate();
    if (!this.obsoleteOn) { this.rentOptionStatus = RentOptionStatus.closed; }
    return this;
  }
  /** creat rent option by injection company summary, prod summary and setting propertes
   * @param vComp Vendor Company
   * @param prod Product
   */
  createRentOption(vComp: CompanyBase, prod: ProductBase): RentalProductBase {
    this.vendorCompSummary = (<CompanyFleet>vComp).getVendorCompanySummary();
    this.productSummary = prod.getProductSummary();
    this.rentOptionStatus = RentOptionStatus.open;
    // this.rentPerDay = RentalProductBase.getRentPerDay(this.rate, this.term);
    this.obsoleteOn = this.setObsoleteDate();
    // if ((<RentalTruck><unknown>this).pictures.length > 0) {
    //   (<RentalTruck><unknown>this).hasPicture = true;
    // } else { (<RentalTruck><unknown>this).hasPicture = false; }
    return this;
  }
  removeBindIngBidOnExpiry(bidId: string | number): { rental: RentalProductBase, chgDoc: UpdateDocRef } {
    const updatedRental = this.clone();
    const index = updatedRental.bindingBids.findIndex(x => x.bindingBidId === bidId);
    if (updatedRental.bindingBids[index].bidStatus === BidStatus.waitingForCustomer) {
      let bBids = [];
      bBids = updatedRental.bindingBids.slice();
      bBids.splice(index, 1);
      updatedRental.bindingBids = bBids;
      updatedRental.obsoleteOn = updatedRental.setObsoleteDate();
      const c = this.getCronJobLog(updatedRental);
      return { rental: updatedRental, chgDoc: c };
    } else {
      return null;
    }
  }
  getCronJobLog(updatedRental: RentalProductBase): UpdateDocRef {

    let chgDoc: UpdateDocRef;
    const chgMap1 = new Map();
    const chgMap2 = new Map();

    chgMap1.set(`bindingBids`, {
      before: (<RentalProductBase>this).bindingBids,
      after: (<RentalProductBase>updatedRental).bindingBids,
    });
    chgMap2.set(`obsoleteOn`, {
      before: this.obsoleteOn,
      after: updatedRental.obsoleteOn,
    });
    chgDoc = {
      did: this.id,
      collection: RentalProductBase.collectionName,
      revCreated: this.revId + 1,
      action: CronActions.removedBindingBid,
      change: [chgMap1, chgMap2]
    };
    return chgDoc;
  }
  updateRentalStatus(): { rental: RentalProductBase, chgDoc: UpdateDocRef } {
    const updatedRental = this.clone();
    updatedRental.rentOptionStatus = RentOptionStatus.obsolete;
    const c = this.getCronJobLogForRentakStatus(updatedRental);

    return { rental: updatedRental, chgDoc: c };
  }
  getCronJobLogForRentakStatus(updatedRental: RentalProductBase) {
    let chgDoc: UpdateDocRef;
    const chgMap1 = new Map();

    chgMap1.set(`rentOptionStatus`, {
      before: (<RentalProductBase>this).rentOptionStatus,
      after: (<RentalProductBase>updatedRental).rentOptionStatus,
    });

    chgDoc = {
      did: this.id,
      collection: RentalProductBase.collectionName,
      revCreated: this.revId + 1,
      action: CronActions.setRentalObsoleteStatus,
      change: [chgMap1]
    };
    return chgDoc;
  }
  get availStartDateIso(): string { return isDate(this.availStartDate) ? this.availStartDate.toISOString() : ''; }
  set availStartDateIso(val) { this.availStartDate = sanitizeDate(val); } // sanitizeIonicDatePicker(val); }

  get availEndDateIso(): string { return isDate(this.availEndDate) ? this.availEndDate.toISOString() : ''; }
  set availEndDateIso(val) { this.availEndDate = sanitizeDate(val); } // sanitizeIonicDatePicker(val); }

  // get obsoleteOnIso(): string { return isDate(this.obsoleteOn) ? this.obsoleteOn.toISOString() : ''; }
  // set obsoleteOnIso(val) { this.obsoleteOn = sanitizeDate(val); } // sanitizeIonicDatePicker(val); }

  sanitize() {
    super.sanitize();
    // if data was recieved from firebase, date is stored as snapshot.
    this.availStartDate = sanitizeDate(this.availStartDate);
    this.availEndDate = sanitizeDate(this.availEndDate);
    this.obsoleteOn = sanitizeDate(this.obsoleteOn);

    if ((typeof this.startAddress) === 'object') {
      this.startAddress = Address.parse(this.startAddress);
    }
    if ((typeof this.endAddress) === 'object') {
      this.endAddress = Address.parse(this.endAddress);
    }

    if (this.bindingBids === undefined || this.bindingBids === null) {
      this.bindingBids = [];
    }
    let i = 0;
    for (i = 0; i < this.bindingBids.length; i = i + 1) {
      this.bindingBids[i].bindingStartD = sanitizeDate(this.bindingBids[i].bindingStartD);
      this.bindingBids[i].bindingEndD = sanitizeDate(this.bindingBids[i].bindingEndD);

    }
  }
  /** Analyze product to see if more information is needed or suggest the user
  * to submit for approval.*/
  getValDbStatus(): IValDbStatusResult {
    const result: IValDbStatusResult = {
      pass: false,
      message: undefined,
      groupResult: {}
    };

    let x: { groupPass: boolean; message: string; };

    // locationAndDeposit
    let r = this.validateSyncGroup(locationAndDeposit);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Security Deposit and Location Details' : 'Rental information is required'; //  `${Object.values(r)[0]}`
    result.groupResult[locationAndDeposit] = x;

    // rate
    r = this.validateSyncGroup(ratePlan);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Setup/Update Rental Rate Plans' : 'Rate Plans information is required';
    result.groupResult[ratePlan] = x;
    // shortTerm
    const xShort = [];

    for (const p of this.shortPlans) {
      const rS = p.validateSync();
      if (Object.keys(rS).length > 0) {
        xShort.push(rS);
      }
    }
    x = { groupPass: null, message: '' };
    x.groupPass = xShort.length === 0;
    x.message = (x.groupPass) ? 'Setup/Update Short Term Plans' : 'Short Term Plans info is required';
    result.groupResult[shortTerm] = x;
    // Monthly Term
    const xMonthly = [];

    for (const p of this.monthlyPlans) {
      const rM = p.validateSync();
      if (Object.keys(rM).length > 0) {
        xMonthly.push(rM);
      }
    }
    x = { groupPass: null, message: '' };
    x.groupPass = xMonthly.length === 0;
    x.message = (x.groupPass) ? 'Setup/Update Monthly Term Plans' : 'Monthly Term Plans info is required';
    result.groupResult[monthly] = x;
    // Yealy Term
    const xYearly = [];

    for (const p of this.yearlyPlans) {
      const rY = p.validateSync();
      if (Object.keys(rY).length > 0) {
        xYearly.push(rY);
      }
    }
    x = { groupPass: null, message: '' };
    x.groupPass = xYearly.length === 0;
    x.message = (x.groupPass) ? 'Setup/Update Yearly Term Plans' : 'Yearly Term Plans info is required';
    result.groupResult[yearly] = x;

    // pictures
    r = this.validateSyncGroup(pictures);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Add/Update Unit Pictures' : 'Pictures are required';
    result.groupResult[pictures] = x;

    // exclusive
    r = this.validateSyncGroup(exclusive);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Setup/Update Exclusive Deal' : 'Exclusive company info required';
    result.groupResult[exclusive] = x;

    // is it passed all the tests? (True means it failed here)
    result.pass = !Object.keys(result.groupResult).some((k) => !result.groupResult[k].groupPass);
    // passed.
    if (Object.keys(r).length === 0) {
      if (this.dbStatus === DbStatus.Initial || this.dbStatus === DbStatus.ReleasedMod) {
        result.message = 'More information is required!';
      }
    } else {
      result.message = 'Admin approval is pending!';
    }
    return result;
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  validateSyncGroup(group: RentalValidationGroup): IValidationMsg {
    return this.validateSync({ groups: [group] });
  }
  validateSync(options: ValidatorOptions): IValidationMsg {


    if (options.groups.length || options.groups?.includes(locationAndDeposit)) {
      options.groups.push(Address.gName);
    }
    // if (!!otherRentals && !!rid) {
    //   otherRentals = otherRentals.filter(f => f.id !== rid);
    // }


    const r = this.validateSyncBase(this, options);
    // if(otherRentals?.length > 0){
    //   r['otherRentals'] = ['In activate open rentals'];

    // }
    // if(bids?.length > 0){
    //   r['openContracts'] = ['Terminate open contracts'];

    // }

    // if (!!otherRentals) {
    //   otherRentals = RentalProductBase.setObsClosedRentals(otherRentals);
    //   openRentals = otherRentals.filter(f => f); // .rentOptionStatus === RentOptionStatus.open
    //   // remove current rental
    //   openRentals = openRentals.filter(f => f.id !== rid);
    // }

    // if (!options || options.groups.includes(pictures)) {
      if (this.pictures?.length === 0) {
        r['pictures'] = ['Picture is required'];
      }
    // }
    /** for update of rental prod rentalId passed to validateSync to remove edited rent option from validation check*/
    // if (!!rid) {
    //   openRentals = openRentals.filter(x => x.id !== DbRule.getRootId(rid));
    // }
    // expiry data must be in the future.
    // check when new rent options is created, skip when rent options is updated
    // createAt used to check form is used when fn validateSync is called without arguments by BaseForm class
    // !otherRentals || !otherRentals.find(f => f.id === rid) is used for server side because createdAt value from client cannot be trusted,
    if (isDate(this.availStartDate)) {
      if (!this.createdAt) {
        if (isAfter(new Date(), this.availStartDate)) {
          r['availStartDate'] = ['availStartDate date must be in future'];
        }
      }
      // else {
      //   if (!otherRentals || !otherRentals.find(f => f.id === rid)) {
      //     if (isAfter(new Date(), this.availStartDate)) {
      //       // 'availStartDate1' used instead of 'availStartDate' because form shows validation error when rent option is updated,
      //       // the code block called by BaseForm with passing otherRentals
      //       r['availStartDate1'] = ['availStartDate date must be in future'];
      //     }
      //   }
      // }
    }
    if (isDate(this.availEndDate)) {
      if (isAfter(new Date(), this.availEndDate)) {
        r['availEndDate'] = ['availEndDate date must be in future'];
      }
    }
    if (this.availEndDate < this.availStartDate) {
      r['availEndDate'] = ['Invalid Contract Dates .....'];
    }
    // let openRentals: RentalProductBase[];
    // if (!!otherRentals) {
    //   openRentals = otherRentals.filter(f => f.id !== rid);
    // }
    // if (!!otherRentals && otherRentals.length > 0 && !!this.availStartDate) {
    //   if (!this.isDateOpenForRent1(openRentals, bids, this.availStartDate)) {
    //     r['availStartDate'] = ['availStartDate date cannot overlap the open rent options'];
    //   }
    // }
    // if (!!otherRentals && otherRentals.length > 0 && !!this.availEndDate) {
    //   if (!this.isDateOpenForRent1(openRentals, bids, this.availEndDate)) {
    //     r['availEndDate'] = ['availEndDate date cannot overlap the open rent options'];
    //   }
    // }
    // if (!!otherRentals && otherRentals.length > 0 && !!this.availStartDate && !!this.availEndDate) {
    //   if (!this.isDateStraddleOpenRental2(openRentals, bids, this.availStartDate, this.availEndDate)) {
    //     r['availEndDate'] = ['availEndDate date cannot Straddle the open rent options'];
    //   }
    // }
    // if (!!this.bindingBids) {
    //   const s = this.checkUpdatedStartDateRemovesBindingBid();
    //   if (!!s) {
    //     r['availStartDate'] = [`${s}`];
    //   }
    //   const e = this.checkUpdatedEndDateRemovesBindingBid();
    //   if (!!e) {
    //     r['availEndDate'] = [`${e}`];
    //   }
    //   if (this.mileageType === MileageType.unlimited &&
    //     (this.ratePerMile || !!this.mileageDaily || !!this.milageWeekly || !!this.milageMonthly)) {
    //     r['mileageType'] = [`milage range set for unlimited milage`];
    //   }

    // }
    if (!!this.availStartDate && !!this.availEndDate) {
      if (!!this.rentalPlans && this.rentalPlans.filter(f => f.isSelected).length === 0) {
        r['rentalPlans'] = [`At least one rental plan needs to be selected`];

      }
    }
    return r;
  }
  // validateSyncLegacy(options: ValidatorOptions, otherRentals?: RentalProductBase[], rid?: string | number, bids?: BidBase[]) {
  //   if (options.groups.length || options.groups?.includes(locationAndDeposit)) {
  //     options.groups.push(Address.gName);
  //   }
  //   if (!!otherRentals && !!rid) {
  //     otherRentals = otherRentals.filter(f => f.id !== rid);
  //   }
  //   const r = this.validateSyncBase(this, options);
  //   if(!!isDate(this.depositDate) && !!isDate(this.firstPaymentDate) && isAfter(this.firstPaymentDate, this.depositDate)) {
  //     r['depositDate'] = ['Deposit Due Date failed'];
  //   }

  //   return r;

  // }
  isRentalOverlap(openRentals: RentalProductBase[], d: Date): boolean {
    const response = [];
    if (!!d) {
      for (let j = 0; j < openRentals.length; j = j + 1) {
        if (
          d.valueOf() >= openRentals[j].availStartDate.valueOf() &&
          d.valueOf() <= openRentals[j].availEndDate.valueOf()
        ) {
          response.push(false);
        } else {
          response.push(true);
        }
      }
      if (response.filter(f => f === false).length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return null;
    }
  }
  /** @deprecated */
  isDateOpenForRent(openRentals: RentalProductBase[], d: Date) {
    for (const r of openRentals) {
      if (r.rentOptionStatus === RentOptionStatus.open) {
        if (isWithinInterval(d, { start: r.availStartDate, end: r.availEndDate })) {
          return false;
        }
      } else {
        const bBids = r.bindingBids;
        for (const b of bBids) {
          if (isWithinInterval(d, { start: b.bindingStartD, end: b.bindingEndD })) {
            return false;
          }
        }
      }

    }
    return true;
  }
  /** @deprecated */
  isDateOpenForRent1(openRentals: RentalProductBase[], bids: BidBase[], d: Date) {
    for (const r of openRentals) {
      if (r.rentOptionStatus === RentOptionStatus.open) {
        if (isWithinInterval(d, { start: r.availStartDate, end: r.availEndDate })) {
          return false;
        }
      } else {
        const bBids = r.bindingBids;
        for (const b of bBids) {
          if (isWithinInterval(d, { start: b.bindingStartD, end: b.bindingEndD })) {
            return false;
          }
        }
      }

    }
    if (isArray(bids)) {
      for (const b of bids) {
        if (b.bidStatus === BidStatus.waitingForVendor || b.bidStatus === BidStatus.accepted) {
          if (isWithinInterval(d, { start: b.bidNegoTerms.startDate, end: b.bidNegoTerms.endDate })) {
            return false;
          }
        }
      }
    }
    return true;
  }
  /** 
   * @depreciated
   * filters such that start and end date fall only in one open rent segment does not straddle two open segments 
   * */
  isDateStraddleOpenRental(openRentals: RentalProductBase[], dFixed: Date, d: Date): boolean {
    if (!!openRentals && !!dFixed && !!d && openRentals.length > 0) {
      openRentals.sort((a, b) => a.availStartDate.valueOf() - b.availStartDate.valueOf());
      const openSegments: { d1: Date, d2: Date }[] = [];
      for (let i = 0; i < openRentals.length - 1; i++) {
        const openStartDate = openRentals[i].availEndDate;
        const openEndDate = openRentals[i + 1].availStartDate;
        openSegments.push({ d1: openStartDate, d2: openEndDate });
      }
      openSegments.unshift({ d1: new Date(), d2: openRentals[0].availStartDate });
      openSegments.push({
        d1: openRentals[openSegments.length - 1].availEndDate,
        d2: addYears(openRentals[openSegments.length - 1].availEndDate, 5)
      });

      const selectedSegment = openSegments.find(f => dFixed.valueOf() >= f.d1.valueOf()
        && dFixed.valueOf() <= f.d2.valueOf());

      if (!!selectedSegment && (d.valueOf() < selectedSegment.d1.valueOf() || d.valueOf() > selectedSegment.d2.valueOf())) {
        return false;
      } else { return true; }
    } else {
      return true;
    }
  }
  /** filters such that start and end date fall only in one open rent segment does not straddle two open segments */
  isDateStraddleOpenRental2(rentals: RentalProductBase[], bids: BidBase[], dFixed: Date, d: Date): boolean {

    if (!!rentals && !!bids && !!dFixed && !!d) {
      rentals.sort((a, b) => a.availStartDate.valueOf() - b.availStartDate.valueOf());


      bids.sort((a, b) => a.bidNegoTerms.startDate.valueOf() - b.bidNegoTerms.startDate.valueOf());

      const blockedDates1: { d1: Date, d2: Date }[] = rentals.map(e => {
        const d1 = e.availStartDate; const d2 = e.availEndDate;
        return { d1, d2 };
      });
      const blockedDates2: { d1: Date, d2: Date }[] = bids.map(e => {
        const d1 = e.bidNegoTerms.startDate; const d2 = e.bidNegoTerms.endDate;
        return { d1, d2 };
      });
      const blockedDates: { d1: Date, d2: Date }[] = blockedDates1.concat(blockedDates2);
      if (blockedDates.length === 0) {
        return true;
      }
      blockedDates.sort((a, b) => a.d1.valueOf() - b.d1.valueOf());
      let openDates: { d1: Date, d2: Date }[] = [];


      for (let i = 0; i < blockedDates.length - 1; i++) {
        const x = blockedDates[i];
        const y = blockedDates[i + 1];
        const _d1 = addDays(x.d2, 1);
        const _d2 = addDays(y.d1, -1);
        if (isAfter(_d2, _d1)) {
          openDates.push({ d1: _d1, d2: _d2 });
        }
      }
      openDates.unshift({ d1: new Date(), d2: addDays(blockedDates[0].d1, -1) });
      openDates.push({
        d1: blockedDates[blockedDates.length - 1].d2,
        d2: addYears(blockedDates[blockedDates.length - 1].d2, 10)
      });
      // all open dates are in future
      openDates = openDates.filter(f => isAfter(f.d2, f.d1));
      const selected: { d1: Date, d2: Date } = openDates.find(f => isWithinInterval(dFixed, { start: startOfDay(f.d1), end: startOfDay(f.d2) }));
      if (!!selected && isWithinInterval(d, { start: selected.d1, end: selected.d2 })) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }

    const openSegments: { d1: Date, d2: Date }[] = [];

    for (let i = 0; i < rentals.length - 1; i++) {
      let openStartDate: Date;
      let openEndDate: Date;
      if (rentals[i].rentOptionStatus === RentOptionStatus.open) {
        openStartDate = rentals[i].availEndDate;
      } else if (rentals[i].bindingBids?.length > 0) {
        openStartDate = rentals[i].bindingBids[rentals[i].bindingBids?.length - 1].bindingEndD;
      }
      if (rentals[i + 1].rentOptionStatus === RentOptionStatus.open) {
        openEndDate = rentals[i + 1].availStartDate;
      } else if (rentals[i + 1].bindingBids?.length > 0) {
        openEndDate = rentals[i + 1].bindingBids[0].bindingStartD;
      }
      openSegments.push({ d1: openStartDate, d2: openEndDate });
    }
    openSegments.unshift({ d1: new Date(), d2: rentals[0].availStartDate });
    openSegments.push({
      d1: rentals[openSegments.length - 1].availEndDate,
      d2: addYears(rentals[openSegments.length - 1].availEndDate, 5)
    });

    const selectedSegment = openSegments.find(f => dFixed.valueOf() >= f.d1.valueOf()
      && dFixed.valueOf() <= f.d2.valueOf());

    if (!!selectedSegment && (d.valueOf() < selectedSegment.d1.valueOf() || d.valueOf() > selectedSegment.d2.valueOf())) {
      return false;
    } else { return true; }

  }
  filterBindingBidsDates(d: Date): boolean {
    const b: boolean[] = [];
    this.bindingBids.forEach(ele => {
      b.push(isWithinInterval(d, { start: ele.bindingStartD, end: ele.bindingEndD }));
      // b.push(isWithinRange(d, ele.bindingStartD, ele.bindingEndD));
    });
    if (b.includes(true)) {
      return false;
    } else {
      return true;
    }
  }
  /**
   * checks if updated rent option dates removes dates in the binding Bid
   */
  checkUpdatedStartDateRemovesBindigBid() {
    for (const b of this.bindingBids) {
      if (isWithinInterval(b.bindingStartD, { start: this.availStartDate, end: this.availEndDate })) {
        return `Open bid ${shortDate(b.bindingStartD)}-${shortDate(b.bindingEndD)}`;
      }
    }
    return null;

  }
  /**
   * checks if updated rent option dates removes dates in the binding Bid
   */
  checkUpdatedEndDateRemovesBindigBid() {
    for (const b of this.bindingBids) {
      if (isWithinInterval(b.bindingEndD, { start: this.availStartDate, end: this.availEndDate })) {
        return `Open bid ${shortDate(b.bindingStartD)}-${shortDate(b.bindingEndD)}`;
      }
    }
    return null;

  }
  /**
   * Get Rent Option Summary.
   */
  public getRentOptionSummary(): RentOptionSummary {
    const rSum = {
      rid: this.id,
      rRevid: this.revId,

      startAddress: this.startAddress,
      endAddress: this.endAddress,
      startLoc: this.startLoc,
      endLoc: this.endLoc,
      isOneWay: this.isOneWay,
      availStartDate: this.availStartDate,
      availEndDate: this.availEndDate,
      currency: this.currency,
      rateMonthly: this.rateMonthly,
      rateWeekly: this.rateWeekly,
      rateDaily: this.rateDaily,
      deposit: this.deposit,
      rentalPlans: this.rentalPlans
      // deprecated
      // term: this.term,
      // rentPerDay: this.rentPerDay,
      // rate: this.rate,
    };
    return rSum;
  }
  /** get rent string for Map Label
   * e.g. 4000/month, 1000/week, 100/day
  */
  getLowestRentString(showStar?: boolean) {
    let r: string; //  = 'Rent(C$): ';
    if (!this.rentalPlans || this.rentalPlans.length === 0) {
      return null;
    }
    const minRent = this.rentalPlans.filter(f => f.isSelected).reduce((acc, val) => {
      return acc.ratePerDay < val.ratePerDay ? acc : val;
    });
    if (!minRent.ratePerMile) {
      minRent.ratePerMile = 0;
    }
    return `$${minRent.ratePerDay}/day + $${minRent?.ratePerMile}/mile ${showStar ? minRent.getStar() : ''}`;

    if (!this.rateMonthly && !this.rateWeekly && !this.rateDaily) {
      return null;
    }
    if (!!this.rateMonthly) {
      r = `$${this.rateMonthly}/month`;
    }
    if (!!this.rateWeekly) {
      if (!!this.rateMonthly) {
        r = `${r}, `;
      }
      r = !!r ? `${r}$${this.rateWeekly}/week` : `$${this.rateWeekly}/week`;
    }
    if (!!this.rateDaily) {
      if (!!this.rateMonthly || !!this.rateWeekly) {
        r = `${r}, `;
      }
      r = !!r ? `${r}$${this.rateDaily}/day` : `$${this.rateDaily}/day`;
    }
    return r;
  }
  getRentString2(showStar?: boolean) {
    let r = '';
    if (!this.rentalPlans || this.rentalPlans.length === 0) {
      return null;
    }
    this.rentalPlans.forEach((p, i) => {
      if (!!p.ratePerDay) {
        r = `${r} $${p.ratePerDay}/day (${p.termString} term)${showStar ? p.getStar() : ''}  | `;
      }

    });

    return r;
  }
  getMilageString() {
    let r = '* Milage Limitation <br>';
    if (!this.rentalPlans || this.rentalPlans.length === 0) {
      return null;
    }
    this.rentalPlans.forEach((p, i) => {
      if (!!p.ratePerDay && (p.mileageType === MileageType.maximum || p.mileageType === MileageType.minimum)) {
        r = `${r} ${p.getMinMaxMileage()} ${p.milage.toLocaleString()}/${p.mileageString2} (${p.termString} term) <br>`;
      }

    });

    return r;
  }
  getCommonMileRateString() {
    if (!this.rentalPlans || this.rentalPlans.findIndex(f => f.ratePerMile) === - 1) {
      return undefined;
    }
    const commonRate = this.rentalPlans.reduce((acc, val) => {
      return acc.ratePerMile === val.ratePerMile ? acc : val;
    });

    if (!!commonRate) {
      return ` + ${commonRate.ratePerMile}&#162/mile`;
    } else {
      return undefined;
    }
  }

  // getMileRateString() {
  //   const rM = this.rentalPlans.filter(f => f.isAvailable && f.isSelected)
  //   if (!rM) {
  //     return undefined;
  //   }

  //   //const rM = this.rentalPlans.map(f => f.ratePerMile).filter(f => !!f);
  //   const b = rM.every(e => e === rM[0]);
  //   if (b) {
  //     return ` $${rM[0].ratePerMile}/mile`;
  //   } else {
  //     let r = '';
  //     rM.forEach((p, i) => {
  //       if (p.ratePerMile) {
  //         if (p.mileageType !== MileageType.unlimited) {
  //           r = `${r}  <br>$${p.ratePerMile}/mile (${p.termString} term)`;
  //         } else {
  //           r = `${r}  <br> Unlimited (${p.termString} term)`;
  //         }
  //       }
  //     });
  //     return r;
  //   }
  // }
  getMileRateString() {
    if (!this.rentalPlans || this.rentalPlans.findIndex(f => f.ratePerMile) === - 1) {
      return undefined;
    }
    const rM = this.rentalPlans.map(f => f.ratePerMile).filter(f => !!f);
    const b = rM.every(e => e === rM[0]);
    if (b) {
      return ` $${this.rentalPlans.find(f => !isNil(f.ratePerMile)).ratePerMile}/mile`;
    } else {
      let r = '';
      this.rentalPlans.forEach((p, i) => {
        if (p.ratePerMile) {
          if (p.mileageType !== MileageType.unlimited) {
            r = `${r}  <br>$${p.ratePerMile}/mile (${p.termString} term)`;
          } else {
            r = `${r}  <br> Unlimited (${p.termString} term)`;
          }
        }
      });
      return r;
    }
  }

  /** get Currency string for Map Label
   * e.g. 4000/month, 1000/week, 100/day
  */
  getCurrencyString() {
    if (this.currency === 'CAD') {
      return `Rent(C$): `;
    } else if (this.currency === 'USD') {
      return `Rent(U$): `;
    } else {
      return null;
    }
  }
  /** get rent string for mileage rate
   * e.g. 10,000/month, 4000/week, 600/day
  */
  getMilageRate() {
    let r: string; //  = 'Rent(C$): ';
    if (!this.mileageType || this.mileageType === MileageType.unlimited) {
      return `Unlimited Milage`;
    }
    if (!!this.ratePerMile) {
      r = `Plus ${this.ratePerMile * 100} cents/mile`;
    }
    return r;
  }
  /** get rent string for mileage limit
   * e.g. 10,000/month, 4000/week, 600/day
  */
  getMilageLimit() {
    let r: string; //  = 'Rent(C$): ';
    if (!this.mileageType || this.mileageType === MileageType.unlimited) {
      return null;
    }
    if (!!this.milageMonthly) {
      r = `${this.milageMonthly.toLocaleString()} miles/month`;
    }
    if (!!this.milageWeekly) {
      r = (r ? r + ' | ' : null) + `${this.milageWeekly.toLocaleString()} miles/week`;
    }
    if (!!this.mileageDaily) {
      r = (r ? r + ' | ' : null) + `${this.mileageDaily.toLocaleString()} miles/day`;
    }
    return r;
  }
  /** get rent string for Milege min/max
   * e.g. min: Minimum Milage; max: Include Milage XXX miles/month 
  */
  getMilageMinMaxStg() {
    let r: string; //  = 'Rent(C$): ';
    if (!this.mileageType || this.mileageType === MileageType.unlimited) {
      return null;
    }
    if (this.mileageType === MileageType.minimum) {
      r = `Minimum Mileage:`;
    }
    if (this.mileageType === MileageType.maximum) {
      r = `Free Mileage: `;
    }

    return r;
  }
  /**
   * returns open sorted rent options by removing binding bids
   * @param bid optional bid will be removed from bindings bids array when calculating open rental dates.
   */
  public getOpenRentalDates(bid?: BidBase): { start: Date, end: Date }[] {
    let r: { start: Date, end: Date }[] = [];
    const lastEndDate = this.bindingBids.map(f => f.bindingEndD).sort((a, b) => a.valueOf() - b.valueOf()).pop();
    if (isAfter(this.availStartDate, lastEndDate)) {
      r.push({ start: this.availStartDate, end: this.availEndDate });
      return r;
    }



    this.bindingBids = !!this.bindingBids ? this.bindingBids : [];
    if (!!bid) {
      const j = this.bindingBids.findIndex(f => f.bindingBidId === bid.id);
    }
    const bb = this.bindingBids.sort((a, b) => a.bindingStartD.valueOf() - b.bindingStartD.valueOf());
    // if there are no binding bids open range is original available start and end dates
    if (!bb || bb.length === 0) {
      r.push({ start: this.availStartDate, end: this.availEndDate });
    } else {
      if (bb[0].bindingStartD.valueOf() !== this.availStartDate.valueOf()) {
        r.push({ start: this.availStartDate, end: addDays(bb[0].bindingStartD, -1) });
      }
      for (let i = 0; i < bb.length - 1; i++) {
        const s = addDays(bb[i].bindingEndD, 1);
        const e = addDays(bb[i + 1].bindingStartD, -1);
        if (isAfter(e, s)) {
          r.push({ start: s, end: e });
        }
      }
      if (bb[bb.length - 1].bindingEndD.valueOf() !== this.availEndDate.valueOf()) {
        r.push({ start: addDays(bb[bb.length - 1].bindingEndD, 1), end: this.availEndDate });
      }
      // remove if end dates are in past
      r = r.filter(f => isAfter(f.end, new Date()));
      // if start date is in past set start to current date
      r.forEach(element => {
        if (isBefore(element.start, new Date())) { element.start = new Date(); }
      });
      // remove the date ranges which don't meet the rental terms selected
      // e.g. if weekly term is selected date ranges less than week will be removed
      r.filter(ele => {
        if (!this.rateWeekly && !this.rateDaily && isBefore(ele.end, addMonths(ele.start, 1))) {
          return false;
        } else {
          return true;
        }
      });
      r.filter(ele => {
        if (!this.rateDaily && isBefore(ele.end, addWeeks(ele.start, 1))) {
          return false;
        } else {
          return true;
        }
      });
      // sort from earliest to latest date
      r = r.sort((a, b) => a.end.valueOf() - b.end.valueOf());
    }
    return r;
  }
  setObsoleteDate(): Date {
    if (!this.bindingBids || this.bindingBids.length === 0) {
      return this.availEndDate;
    } else if (this.getOpenRentalDates().length > 0) {
      return this.getOpenRentalDates()[this.getOpenRentalDates().length - 1].end;
    } else {
      return null;
    }
  }

  get isMonthlyAvail() {
    if (!!this.availStartDate && !!this.availStartDate) {
      const b: boolean[] = [];
      this.openDateRange.forEach(ele => {
        if (isAfter(addDays(ele.end, 1), addMonths(ele.start, 1))) {
          b.push(true);
        }
      });
      if (b.includes(true)) {
        return true;
      } else {
        return false;
      }
    } else {
      return null;
    }
  }
  get isWeeklyAvail() {
    if (!!this.availStartDate && !!this.availStartDate) {
      const b: boolean[] = [];
      this.openDateRange.forEach(ele => {
        if (isAfter(addDays(ele.end, 1), addWeeks(ele.start, 1))) {
          b.push(true);
        }
      });
      if (b.includes(true)) {
        return true;
      } else {
        return false;
      }
    } else {
      return null;
    }

  }
  get isThreeMonthAvail() {
    return this._isMonthAvailHelper(1);

  }
  get isSixMonthAvail() {
    return this._isMonthAvailHelper(6);
  }
  get isYearlyAvail() {
    return this._isMonthAvailHelper(12);
  }
  private _isMonthAvailHelper(months: number): boolean {
    if (!!this.availStartDate) {
      const b: boolean[] = [];
      this.openDateRange.forEach(ele => {
        if (isAfter(addDays(ele.end, 1), addMonths(ele.start, months))) {
          b.push(true);
        }
      });
      if (b.includes(true)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  getMinDate(): Date {
    const tomorrow = startOfTomorrow();
    const availStartD = this.getOpenRentalDates()[0]?.start;

    if (isBefore(availStartD, tomorrow)) {
      return tomorrow;
    } else {
      return availStartD;
    }
  }
  getBindingBidDates(): { startD: Date; endD: Date; }[] {
    const r: { startD: Date; endD: Date; }[] = [];
    for (const b of this.bindingBids) {
      r.push({ startD: b.bindingStartD, endD: b.bindingEndD });
    }
    return r;
  }
  expireRentOption() {
    const uRental = this.clone();
    uRental.rentOptionStatus = RentOptionStatus.obsolete;
    return uRental;
  }
  /**
   *
   * @param cid UI helper, check if rent-option can be updated
   */
  canEdit(cid: string, isAdmin: boolean) {
    if (this.vendorCompSummary.cid === cid) {
      return true;
    }
    if (!isAdmin) {
      return true;
    }
    // if (this.rentOptionStatus !== RentOptionStatus.open && !isAdmin) {
    //   return false;
    // }
    return false;
  }
  /**
   * if @param mileageType unlimited set milage related params undefined
   */
  setMilageUnlimited() {
    if (this.mileageType === MileageType.unlimited) {
      this.ratePerMile = undefined;
      this.mileageDaily = undefined;
      this.milageWeekly = undefined;
      this.milageMonthly = undefined;
    } else {
      throw new Error(`called with incorrect ${this.mileageType} parameter`);

    }

  }
  cleanupSelectedTerms() {
    if (!this.isWeeklyAvail && this.isWeeklySelected !== undefined) {
      this.isWeeklySelected = undefined;
    }
    if (!this.isMonthlyAvail && this.isMonthlySelected !== undefined) {
      this.isMonthlySelected = undefined;
    }
    if (!this.isThreeMonthAvail && this.isThreeMonthTermSelected !== undefined) {
      this.isThreeMonthTermSelected = undefined;
    }
    if (!this.isSixMonthAvail && this.isSixMonthTermSelected !== undefined) {
      this.isSixMonthTermSelected = undefined;
    }
    if (!this.isYearlyAvail && this.isYearTermSelected !== undefined) {
      this.isYearTermSelected = undefined;
    }
  }
  /** set available plans when setting rent option based dated selected [used for publishing rent-option] */
  setRentalPlanAvailability() {
    if (!!this.availStartDate && !!this.availEndDate) {
      const days = differenceInDays(this.availEndDate, this.availStartDate);
      console.log({ days });
      for (const p of this.rentalPlans) {
        if (p.rentalTerm <= days) {
          p.isAvailable = true;
        } else {
          p.isAvailable = false;
          p.isSelected = false;
        }
      }
    }
    logger.info('[rental base] plan availability set ', this.rentalPlans);
  }


  getApplicablePlan(negoTerm: NegoTermsBase): RentalPlan {
    const days = differenceInDays(negoTerm.endDate, negoTerm.startDate);
    const plans = this.rentalPlans.filter(f => f.rentalTerm <= days && f.isAvailable && f.isSelected && f.isStillValid(this.availEndDate));
    const p = plans.reduce((prev, next) => prev.rentalTerm > next.rentalTerm ? prev : next);
    return RentalPlan.parse(p);


  }
  removeDepositForContractChange() {
    const rentOptionU = this.clone();
    rentOptionU.deposit = null;
    return rentOptionU;
  }

  /** set @param isExclusive true if using is replacement required to block access to users outside vendor   */
  setExclusive(cid: (string) = this.vendorCompSummary.cid) {
    if (!!this.isReplacementUnit || this.targetCCids?.length > 0) {
      this.isExclusive = true;
    }
    if ((!this.targetCCids || this.targetCCids.length === 0) && this.isReplacementUnit) {
      this.targetCCids = [cid];
    }
  }
  setRentalToAssign(vCompany: CompanyFleet) {
    this.startAddress = new Address();
    this.endAddress = new Address();
    this.isOneWay = false;
    this.setCurrency(vCompany.address.country as 'CA' | 'US');
    // startAddress: this.startAddress,
    // endAddress: this.endAddress,
    // startLoc: this.startLoc,
    // endLoc: this.endLoc,
    // isOneWay: this.isOneWay,
    // availStartDate: this.availStartDate,
    // availEndDate: this.availEndDate,
    // currency: this.currency,

    // rentalPlans: this.rentalPlans
  }

  /** Set currency for rental based on vendor company address */
  setCurrency(countryString: 'CA' | 'US'): void {
    switch (countryString) {
      case 'CA':
        this.currency = 'CAD';
        break;
      case 'US':
        this.currency = 'USD';
        break;
      default:
        throw new Error('failed to set currency');
    }
  }


}


