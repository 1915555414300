import { ProductType } from '../product/product-type';
import { MapLabel } from '../map/map-label';
import { shortDateTime } from '../utility/timer-helper';
import { IPoint } from '../product-search/interfaces';
import { SalesTruck } from './sales-truck';
import { SalesTrailer } from './sales-trailer';
import { SalesProductBase } from './sales-product-base';
import { LogoPosition } from '../inspection/picture';

export type SalesProduct = SalesTruck | SalesTrailer;
export type Currency = 'CAD' | 'USD';
export type SalesAgreement = 'sales-trailer' | 'sales-truck';

export const parseSalesProduct = (obj: any): SalesProductBase => {
  if (obj == null) { return null; }
  const s = <SalesProductBase>obj;
  switch (s.productType) {
    case ProductType.trailer:
      return SalesTrailer.parse(s);
    case ProductType.truck:
      return SalesTruck.parse(s);
    default:
      throw new Error(`Invalid Product Type in the Sales Product. Product Type: ${s.productType} is invalid or not yet implemented.`);
  }
};

export const parseSalesProductArray = (obj: any[]): SalesProductBase[] => {
  const s = !!obj ? obj.map(o => <SalesProductBase>parseSalesProduct(o)) : null;
  return s;
};

export const salesMapLabels = (sales: SalesProductBase[], cid?: string | number): MapLabel[] => {
  const labels: MapLabel[] = [];
  for (const e of sales as SalesTrailer[]) {
    if (!!e.address?.geoLoc?.geopoint) {
      labels.push({
        iPoint: {
          latitude: Math.round(e.address.geoLoc.geopoint.latitude * 1000000) / 1000000,
          longitude: Math.round(e.address.geoLoc.geopoint.longitude * 1000000) / 1000000
        },
        title: `${e.productSummary.summaryString}(${e.productSummary.modelYear})`,
        productType: e.productType,
        addressFormated: e.address.addressFormated,
        country: e.address.country,
        index: null,
        startTime: null,
        stateProv: e.address.stateProv,
        id: e.id,
        desc: `${getCurrencyString(e.currency)}<br>${getSalesString(e.price, e.wholesalePrice)}<br>`,
        clickLabel: !!cid && cid === e.vendorCompSummary.cid ? [{ btnLabel: 'Details', emitterAction: 'mapClusterAction' }] :
          [{ btnLabel: 'Rent', emitterAction: 'mapLabelActionById' }]
      });
    }
  }
  return labels;
};

/**
 * get rent string for Map Label
 * e.g. 4000/month, 1000/week, 100/day
 */
export const getSalesString = (price: number, wholesalePrice: number) => {
  let s: string;
  if (!price && !wholesalePrice) {
    return null;
  }
  s = !!wholesalePrice ? `$${wholesalePrice}` : !!price ? `$${price}` : '';
  return s;
};

/**
 * get Currency string for Map Label
 * e.g. 4000/month, 1000/week, 100/day
 */
export const getCurrencyString = (currency: Currency) => {
  return currency === 'CAD' ? `Rent(C$): ` : currency === 'USD' ? `Rent(U$): ` : null;
};

export const getSalesAgreement = (pType: ProductType, country: 'CA' | 'US' = 'CA') => {
  switch (pType) {
    case ProductType.trailer:
      return 'sales-trailer';
    case ProductType.truck:
      return `truck-sales-agreement-${country.toLowerCase()}`;
    default:
      throw new Error(`invalid product, sales agreement not defined for ${pType}`);
  }
};

export const assetLocMapLabels = (locData: {
  unitName: string; iPoint: IPoint; timestamp: number;
  pType: ProductType, refId: string | number
}[]): MapLabel[] => {
  const labels: MapLabel[] = [];
  for (const d of locData) {
    labels.push({
      iPoint: d.iPoint, title: d.unitName,
      desc: `Location recorded at <br>${shortDateTime(new Date(d.timestamp))}`,
      productType: d.pType,
      id: d.refId,
      clickLabel: null
    });
  }
  return labels;
};

export const getSalesByProdType = (prodType: ProductType) => {
  switch (prodType) {
    case ProductType.truck:
      return new SalesTruck();
    case ProductType.trailer:
      return new SalesTrailer();
    default:
      break;
  }
};

/**
 * @author Cm
 * @param array1 is the selected logo position
 * @param array2 is the files comes from sales option storage
 * @returns pictures array update logo position according to sales option
 */
export const updateLogoPosition = (array1, array2) =>{
  const resultArray = [];
  
  // Create a object to store objects by path for faster lookup
  const pathObj = {};
  
  // Process the first array
  for (const obj of array1) {
      // Store the object
    pathObj[`${obj.path}`]= obj;
  }
  
  // Process the second array
  for (const obj of array2) {
    // Check if the path exists in the objects
    let key = pathObj[`${obj.path.replace('sales-option', 'draft')}`];
    if (key) {
       // If the path exists, update the position and push to the result array
      obj.logoPosition = key.position;
    }else{
      obj.logoPosition = LogoPosition.northeast;
    }
    resultArray.push(obj);
  }
  return resultArray;
};


/**
 * @author Cm
 * @param array1 is the sales option picture array
 * @param array2 is the files comes from product storage
 * @returns pictures array update metadata according to sales option
 */
export const updatePicturesMetaData = (pictures,files) =>{
  const resultArray = [];
  
  // Create a object to store objects by path for faster lookup
  const pathObj = {};
  
  // Process the first array
  for (const p of pictures) {
      // Store the object
    pathObj[`${p.path}`]= p;
  }
  
  // Process the second array
  for (const f of files) {
    // Check if the path exists in the objects
    let obj = pathObj[`${f.name.replace('draft', 'sales-option')}`] || pathObj[`${f.name}`];
    if (obj) {
       // If the path exists, update the metadata and push to the result array
      f.metadata.metadata  = obj.customFirebaseMetadata;
    }
    resultArray.push(f);
  }
  return resultArray;
};

export const logoPositionValue = (v:LogoPosition)=>{
  switch (v) {
    case 1:
      return 'northwest';
    case 2:
      return 'northeast';
    case 3:
      return 'southwest';
    case 4:
      return 'southeast';
    case 5:
      return 'noLogo';
  }
};
