<div [ngStyle]="getStylesPicBtnDesktop()">
  <!-- <p class="header-title" *ngIf="headerTitle">{{headerTitle}}</p> -->
  <div class="add-btn" *ngIf="!isHandset">
    <button mat-mini-fab color="warn" type="button" (click)="openDialog()"  [disabled]="isEditMode">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <ng-container *ngIf="!!m && m.length>0 && !!showImages">
    <div class="pic-container" [ngClass]="{'d-flex flex-wrap pic-container-desk': !isHandset}">
      <ng-container *ngFor="let p of m; index as j">
        <div [style.height.px]="!showRemoveButton(p) ? containerHeight : containerHeight + 50"
          class="pic-button-container" [ngClass]="{'tile-view h-220px': !isHandset , 'h-262px': showLogoPosition && !isEditMode}">
          <div class="image-button">
            <button mat-icon-button class="del-button" color="primary" (click)="openConfDialog('DeleteFile', j)"
              *ngIf="showRemoveButton(p) || proxyPic(p)" [disabled]="isEditMode">
              <mat-icon>delete</mat-icon>
            </button>
            <button class="up-down-button" mat-icon-button color="primary" (click)="orderFile(j, 'up')"
              *ngIf="showOrderingIcon"  [disabled]="isEditMode">
              <mat-icon>{{upDownIcon(j, 'up')}}</mat-icon>
            </button>
            <button class="up-down-button" mat-icon-button color="primary" (click)="orderFile(j, 'down')"
              *ngIf="showOrderingIcon"  [disabled]="isEditMode">
              <mat-icon>{{upDownIcon(j, 'down')}}</mat-icon>
            </button>
            <ng-container
              *ngIf="showVisibilityIcon && !!p.customMetadata && p.customMetadata?.visibility !== undefined">
              <mat-slide-toggle name="visibility" [(ngModel)]="p.customMetadata.visibility"
                (change)="showHide($event, j)"  [disabled]="isEditMode"></mat-slide-toggle>
            </ng-container>
            <ng-container *ngIf="showSelectedPics && !proxyPic(p)">
              <mat-slide-toggle name="selectedPic" (change)="onSelectedPic($event,p)"  [disabled]="isEditMode"></mat-slide-toggle>
            </ng-container>

          </div>
          <div class="image-container" [style.height.px]="containerHeight" [ngClass]="{'w-220': !isHandset}">
            <div class="img-area" [style.height.px]="imageHeight">
              <div class="img-area__inner">
                <img [src]="p.url" alt="" class="image" *ngIf="!!p.url">
                <img src="../../../assets/pts-watermark-min.png" alt="" class="watermark-image" *ngIf="!!p.url && showLogoPosition  && getMatchPos(p) !== 5" [style.width.%]="getImageDimension(p).width" [ngClass]="{'top-left': getMatchPos(p) === 1, 'top-right': getMatchPos(p) === 2, 'bottom-left': getMatchPos(p) === 3, 'bottom-right': getMatchPos(p) === 4}">
              </div>
            </div>
            <div *ngIf="p.customMetadata?.loc" class="img-text">
              {{p.customMetadata?.loc}}</div>
            <div *ngIf="showLogoPosition && !isEditMode">
              <mat-form-field>
                <mat-label>Select Logo pos.</mat-label>
                <mat-select (selectionChange)="onSelectLogoPosition($event.value,p)" [value]="getMatchPos(p)">
                  <mat-option *ngFor="let item of logoPositionEnum | enumPipe" [value]="+item.key">{{logoPositionName(item.key)}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <!-- <mat-card> -->
  <!-- <div  class="btn-container">
        <ng-container *ngFor="let l of locIds">
          <button mat-stroked-button (click)="userLocation=l; mainFilePicker.click()"><mat-icon [svgIcon]="getIcon(l).svgIcon" [color]="'black'"></mat-icon>{{getIcon(l).name}}</button>
          </ng-container>
  
      </div> -->
  <!-- <ng-container *ngTemplateOutlet="assetMap"></ng-container> -->

  <!-- </mat-card> -->

  <!-- <div class="btn-container" *ngIf="uploadRequired">
    <ng-container *ngFor="let l of locIds">
      <button mat-stroked-button (click)="userLocation=l; mainFilePicker.click()" [title]="getIcon(l).name">
        <mat-icon [matBadgeHidden]="!checkImageUploaded(l)" matBadge={{checkUploadedImageCount(l)}} aria-label="Edit"
          matBadgeColor="warn">
          <img [src]="getIcon(l).svgIcon" alt="" srcset="">
        </mat-icon>

      </button>

    </ng-container>

  </div> -->

</div>
<div class="pictures-container">
  <input #mainFilePicker [id]="currentIndex" class="hide" type="file" (change)="addPictures($event, userLocation)"
    multiple>
</div>

<ng-template #assetMap>
  <mat-card>
    <mat-card-content>
      <div class="top-bottom-row">
        <ng-container *ngFor="let j of [0,1,2]">
          <div>
            <ng-container *ngTemplateOutlet="camera context: {i: 0, j: j}"></ng-container>
          </div>
        </ng-container>
      </div>
      <div class="middle">
        <div class="middle-camera">
          <ng-container *ngFor="let i of [0]">
            <div style="grid-row: 2;">
              <ng-container *ngTemplateOutlet="camera context: {i: i, j: 0}"></ng-container>
            </div>
          </ng-container>
        </div>
        <div class="img-container">
          <ng-container *ngIf="productType === pTypeEnum.truck">
            <img src="assets/sleeper_truck_top.png" height="150px">
          </ng-container>
          <ng-container *ngIf="productType === pTypeEnum.trailer">
            <img src="assets/dry_van_top.png" height="150px">
          </ng-container>
        </div>
        <div class="middle-camera">
          <ng-container *ngFor="let i of [0]">
            <div style="grid-row: 2;">
              <ng-container *ngTemplateOutlet="camera context: {i: i, j: 2}"></ng-container>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="top-bottom-row">
        <ng-container *ngFor="let j of [0]">
          <div style="grid-column: 2;">
            <ng-container *ngTemplateOutlet="camera context: {i: 4, j: j}"></ng-container>
          </div>
        </ng-container>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>
<ng-template #camera let-i='i' , let-j='j'>
  <a class="acamera" href="javascript:void(0)" [attr.data-menu]="modalClientId" (click)="onCameraClick(i, j)">
    <!-- 'filePicker.click()' -->
    <mat-icon [ngClass]="{'warn-color': i===currI && j===currJ}"
      style="overflow:visible; color: var(--warn);">camera_alt
      <span [matBadge]="getPicCount(i, j)" matBadgePosition="before" matBadgeColor="primary"
        style="color: yellow;"></span>
    </mat-icon>
  </a>
  <!-- Input File -->
  <!-- <input #filePicker class="hide" type="file" (change)="addPictures($event, i, j)"> -->
</ng-template>

<ng-template #btnDialog>
  <!-- <h2 matDialogTitle>Hello dialog!</h2> -->
  <div style="padding:16px" [ngClass]="{'w-370p': !isHandset}">
    <div class="btn-container">
      <ng-container *ngFor="let l of locIds">
        <button mat-stroked-button (click)="userLocation=l; mainFilePicker.click();cancelDialog()" [title]="getIcon(l).name" [ngClass]="{'w-88p': isHandset}">
          <mat-icon [matBadgeHidden]="!checkImageUploaded(l)" matBadge={{checkUploadedImageCount(l)}} aria-label="Edit"
            matBadgeColor="warn">
            <img [src]="getIcon(l).svgIcon" alt="" srcset="">
          </mat-icon>
        </button>
      </ng-container>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button (click)="cancelDialog()">Cancel</button>
  </mat-dialog-actions>
</ng-template>

<div class="add-btn-m" *ngIf="isHandset">
  <button mat-mini-fab color="warn" type="button" (click)="openDialog()"  [disabled]="isEditMode">
    <mat-icon>add</mat-icon>
  </button>
</div>
