import { routesBase, setSiteRoutes } from '../ws-common/app-routing-base-data';
import { CustomPreloadingStrategy } from '@trent/core/router-preload';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotfoundComponent } from '../notfound/notfound.component';
import { HomeComponent } from '../home/home.component';



const routeAllTpr: Routes = [
  { path: '', component: HomeComponent },
  ...routesBase,
  /** ADD TPine Rental UNIQUE ROUTES - START. */


  /** ADD Pride Truck sales UNIQUE ROUTES - END. */
  
  /** THIS SHOULD BE LAST as not found */
  { path: '**', component: NotfoundComponent }
];

function getRoutes() {
  setSiteRoutes(routeAllTpr);
  return routeAllTpr;
}


@NgModule({
  imports: [RouterModule.forRoot(getRoutes(), {
    preloadingStrategy: CustomPreloadingStrategy,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    // relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppTprRoutingModule { }
