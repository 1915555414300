import { StateBase } from '../state-base';
import { State, StateContext, Action, Selector } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { noop } from 'rxjs';
import * as entity from '../entity.state';
import { Injectable } from '@angular/core';
import { getObjKey, Paging } from '@trent/models/observable-util/paging';
import { logger } from '@trentm/log/logger';
import { buildDataRequest, getRootLevelChildren, IDataLoadStatus, LoadStatus, updatePaging } from '@trent/models/observable-util/data-status';
import { PagingContainer } from '@trent/models/observable-util/paging-container';
import { getSalesOptionSearchOptOrChildren, parseSalesProduct, SalesOptionParam, salesOptionSearchAlgolia, salesOptionSearchClientFilter, SalesProductBase, SODates } from '@trent/models/sales-option';
import { SalesOptionService } from '@trent/services/sales.service';
import { AlgoliaSearchService } from '@trent/services/algolia-search.service';
import { updateNestedDateValueToFirebaseTimeStamp } from '@trent/models/utility';

// #region State Model
export interface SalesOptionStateModel {

  // New approach with paging
  salesOptionByUserLoaded: boolean;
  salesOptionByUser: { [id: string]: SalesProductBase };  // changed

  /** Which client side queries are complete */
  dataLoadStatus: {
    [key: string]: IDataLoadStatus<SalesOptionParam>;
  };
  allSalesOptionsLoaded: boolean;
  salesOptions: { [id: string]: SalesProductBase };
  salesOptionsFiltered: { [id: string]: SalesProductBase };
  ids: string[];
  totalCount?: number;
  salesHistory: { [id: string]: SalesProductBase };
}

function initSalesOptionState() {
  return {
    salesOptionByUserLoaded: false,
    salesOptionByUser: {},
    allSalesOptionsLoaded: false,
    dataLoadStatus: {},
    salesOptions: {},
    salesOptionsFiltered: {},
    ids: [],
    salesHistory: {}
  };
}

// #endregion

// #region Actions for new data methods with paging

export class SalesOptionUpdated {
  static readonly type = '[SalesOptions] Store Location Updated';
  constructor(public payload: { sid: string | number, salesOption: SalesProductBase }) { }
}
export class SalesOptionRequested {
  static readonly type = '[SalesOption] Request a single sales option';
  constructor(public payload: { id: string | number }) { }
}
export class SalesOptionLoaded {
  static readonly type = '[SalesOption] Load a single sales option entity';
  constructor(public payload: { id: string | number, data: SalesProductBase }) { }
}
export class SalesOptionStateReset {
  static readonly type = '[SalesOption] Reset State';
}
export class SalesOptionRemoveById {
  static readonly type = '[SalesOption] Remove by Id';
  constructor(public payload: { sid: string | number }) { }
}
export class SalesOptionsRequested {
  static readonly type = '[SalesOptions] All Store Locations Requested';
  constructor(public payload: { pData: Paging, param: SalesOptionParam }) { }
}
export class SalesOptionsLoaded {
  static readonly type = '[SalesOption] All Store Locations Loaded';
  constructor(public payload: {
    data: { [id: string]: SalesProductBase }, // Data
    key: string,
  }) { }
}

export class FetchSalesOptionsUsingAlgolia {
  static readonly type = '[SalesOption] Fetch using Algolia';
  constructor(public payload: { pData: Paging, param: SalesOptionParam }) { }
}

export class SalesOptionsNextPageRequested {
  static readonly type = '[SalesOption] All Store Locations requested - Next Page';
  constructor(public payload: { option: SalesOptionParam }) { }
}
export class SalesOptionsUpdated {
  static readonly type = '[SalesOption] Store Locations Updated';
  constructor(public payload: { id: string | number, data: SalesProductBase }) { }
}
export class GetSalesOptionByUserLoaded {
  static readonly type = '[SalesOption] Get Store Locations for user - loaded (paged)';
  constructor(public payload: { data: { [id: string]: SalesProductBase } }) { }
}
export class SalesOptionByParamRequested {
  static readonly type = '[Sales Option] Sales Option by Product Id Requested';
  constructor(public payload: { pData: Paging; param: any }) { }
}
export class SalesOptionByParamLoaded {
  static readonly type = '[Sales Option] Sales Option by Product Id Loaded';
  constructor(public payload: {
    data: { [id: string]: SalesProductBase },
    key: string,
  }) { }
}
export class SetSalesOptionIds {
  static readonly type = '[SalesOption] SetIds';
  constructor(public payload: string[]) { }
}
export class AllSalesHistoryLoaded {
  static readonly type = '[Sales Option] All Sales History Loaded';
  constructor(public payload: {
    data: { [id: string]: SalesProductBase },
    key: string,
  }) { }
}

// #endregion

@State<SalesOptionStateModel>({
  name: 'salesOption',
  defaults: initSalesOptionState()
})
@Injectable()
export class SalesOptionState extends StateBase {

  /** Container that keep all of the subscription related to gettting data. */
  dataSubData: PagingContainer<SalesProductBase, SalesOptionParam> = new PagingContainer();

  constructor(private salesOptionService: SalesOptionService, private algoliaSearchService: AlgoliaSearchService) {
    super();
  }

  // #region Selectors
  @Selector()
  static getSalesOptionIds(state: SalesOptionStateModel) {
    return state.ids;
  }

  @Selector()
  static selectSalesOptionById(state: SalesOptionStateModel) {
    // new approach, use dictionary of company.
    return entity.getByIdFn_new(state.salesOptionByUser, parseSalesProduct);
  }

  @Selector()
  static selectAllSalesOptions(state: SalesOptionStateModel) {
    return (o: SalesOptionParam): SalesProductBase[] => {
      if (state?.salesOptions == null || Object.keys(state.salesOptions).length === 0) {
        return [];
      }

      // remove keys that have revision/draft ids, i.e that contain '~' in the id.
      const keys = Object.keys(state.salesOptions).filter(k =>
        k.indexOf('/') === -1 && state.salesOptions[k] != null);

      // object without the rev/draft.
      const filtered = keys.reduce((obj, k) => {
        obj[k] = state.salesOptions[k];
        return obj;
      }, {});

      let output = Object.values(filtered).map(x => parseSalesProduct(x));
      output = salesOptionSearchClientFilter(output, o);

      return output;
    };
  }


  @Selector()
  static selectAllSalesOptionsUsingAlgolia(state: SalesOptionStateModel) {
    return (o: SalesOptionParam): SalesProductBase[] => {
      if (state?.salesOptions == null || Object.keys(state.salesOptions).length === 0) {
        return [];
      }

      let output = Object.values(state.salesOptions).map(x => parseSalesProduct(x));
      // output = salesOptionSearchClientFilter(output, o);

      return output;
    };
  }

  @Selector()
  static selectTotalCount(state: SalesOptionStateModel) {
    return state.totalCount;;
  }

  @Selector()
  static selectSalesOptionByParam(state: SalesOptionStateModel) {
    // console.log('sales option Select-STORE', state.salesOptions);
    return (o: SalesOptionParam): SalesProductBase[] => {
      if (state.salesOptions == null || Object.keys(state.salesOptions).length === 0) {
        return [];
      }
      // remove keys that have revision/draft ids, i.e that contain '~' in the id.
      const keys = Object.keys(state.salesOptions).filter(k =>
        k.indexOf('/') === -1 && state.salesOptions[k] != null);

      // object without the rev/draft.
      const filtered = keys.reduce((obj, k) => {
        obj[k] = state.salesOptions[k];
        return obj;
      }, {});
      let output: SalesProductBase[];

      output = Object.values(filtered).map(x => <SalesProductBase>parseSalesProduct(x));
      output = salesOptionSearchClientFilter(output, o);

      return output;
    };
  }

  @Selector()
  static selectAllSalesOptionHistory(state: SalesOptionStateModel) {
    return (o: SalesOptionParam): SalesProductBase[] => {

      if (state.salesHistory == null || Object.keys(state.salesHistory).length === 0) {
        return [];
      }
      // remove keys that have revision/draft ids, i.e that contain '~' in the id.
      const keys = Object.keys(state.salesHistory).filter(k =>
        k.includes('/') && state.salesHistory[k] != null);
      // object without the rev/draft.
      const filtered = keys.reduce((obj, k) => {
        obj[k] = state.salesHistory[k];
        return obj;
      }, {}); 

      let output = Object.values(filtered).map(x => parseSalesProduct(x));
      output = salesOptionSearchClientFilter(output, o);

      return output;
    };
  }

  // #endregion

  @Action(SetSalesOptionIds)
  setSalesOptionIds(context: StateContext<SalesOptionStateModel>, action: SetSalesOptionIds) {
    const state = context.getState();
    context.patchState({
      ids: { ...state.ids, ...action.payload }
    });
  }

  @Action(SalesOptionByParamRequested)
  allRentalProductsRequested(context: StateContext<SalesOptionStateModel>, action: SalesOptionByParamRequested) {
    const oKey = getObjKey(action.payload.param);

    // Define function that return the data status object from the state.
    const getDataStatusStateFn = () => context.getState().dataLoadStatus;

    /** custom build the OR children query. */
    const buildOrQueryChildrenFn = (o: SalesOptionParam) => getSalesOptionSearchOptOrChildren(o);
    // if data requested now, is already partially loaded by another query's child previously
    // but the number of the items are not enough (ex. as a child, it loaded only 5, but current
    // request ask for more, then next page of that query should be called instead.)
    const nextPageFn = (param: SalesOptionParam) => {
      context.dispatch(new SalesOptionsNextPageRequested({ option: param }));
    };
    buildDataRequest(
      oKey, action.payload.param, action.payload.pData,
      getDataStatusStateFn,
      buildOrQueryChildrenFn,
      nextPageFn,
      (
        obj: { [key: string]: IDataLoadStatus<SalesOptionParam> },
        set: { key: string, node: IDataLoadStatus<SalesOptionParam> }[]
      ) => {

        if (!!obj) {
          // Patch the state.
          const state = context.getState();
          context.patchState({
            dataLoadStatus: { ...state.dataLoadStatus, ...obj }
          });
        }

        // Process the query.
        set.forEach((val) => {
          // some of the nodes are already loaded. Only process that are loading... status.
          if (val.node.loadStatus !== LoadStatus.Loading) {
            return;
          }
          // if this request is just overwriting a stall or pending request, unsubscribe that observable
          this.dataSubData.unsubscribe(val.key);

          // console.log('New Service sub created: ', val);

          // create the paging observable and call db.
          const r = this.salesOptionService.getAllSalesOptions_PagingObservable();
          const rentalProd$ = r.getData(action.payload.pData, val.node.param)
            .pipe(
              map(rProducts => {
                context.dispatch(new SalesOptionByParamLoaded({
                  data: rProducts,
                  key: val.key
                }));
                return rProducts;
              }));
          const sub = this.subscribe(rentalProd$, () => noop(), SalesOptionByParamRequested.type);
          // save the observable call
          this.dataSubData.addData(val.key, sub, r);
        });
      }
    );
  }

  @Action(SalesOptionByParamLoaded)
  allRentalProductsLoaded(context: StateContext<SalesOptionStateModel>, action: SalesOptionByParamLoaded) {
    // console.log('rent option loaded ', action.payload.data);
    const state = context.getState();
    const subData = this.dataSubData.getData(action.payload.key);
    const updatedLoadStatus = updatePaging(action.payload.key, state.dataLoadStatus, subData);
    context.patchState({
      allSalesOptionsLoaded: true,
      dataLoadStatus: updatedLoadStatus,
      salesOptions: { ...state.salesOptions, ...action.payload.data }
    });
  }

  @Action(GetSalesOptionByUserLoaded)
  getSalesOptionByUserLoaded(context: StateContext<SalesOptionStateModel>, action: GetSalesOptionByUserLoaded) {
    const state = context.getState();
    context.patchState({
      salesOptionByUserLoaded: true,
      salesOptionByUser: { ...state.salesOptionByUser, ...action.payload.data },
    });
  }

  @Action(SalesOptionRequested)
  salesOptionRequested(context: StateContext<SalesOptionStateModel>, action: SalesOptionRequested) {
    const state = context.getState();
    const id = action.payload.id;
    if (state.salesOptionByUser[action.payload.id] == null) {
      // logger.log('comp by id, not found in the store, gettting from server....');
      const s = this.salesOptionService.getSalesOptionById(action.payload.id)
        .pipe(
          map(data => {
            return context.dispatch(new SalesOptionLoaded({ id, data }));
          }
          ));
      this.subscribe(s, (x) => noop(), SalesOptionRequested.type);
    }
  }

  @Action(SalesOptionLoaded)
  salesOptionLoaded(context: StateContext<SalesOptionStateModel>, action: SalesOptionLoaded) {
    const state = context.getState();
    const c = {};
    c[action.payload.data?.id] = action.payload.data;
    context.patchState({
      salesOptionByUser: { ...state.salesOptionByUser, ...c }
    });
  }


  @Action(SalesOptionUpdated)
  salesOptionUpdated(context: StateContext<SalesOptionStateModel>, action: SalesOptionUpdated) {
    const state = context.getState();
    let c = action.payload.salesOption;
    if (typeof (c.toFirebaseObj()) === 'function') {
      c = c.toFirebaseObj();
    }
    context.patchState({
      salesOptionByUser: { ...state.salesOptionByUser, ...{ [action.payload.sid]: c } }
    });
  }


  @Action(SalesOptionStateReset)
  salesOptionStateReset(context: StateContext<SalesOptionStateModel>, action: SalesOptionStateReset) {
    // unsubscribe the data
    this.clearSubscriptions();
    context.setState(initSalesOptionState());
  }

  @Action(SalesOptionRemoveById)
  dataByIdRemoved(context: StateContext<SalesOptionStateModel>, action: SalesOptionRemoveById) {
    const state = context.getState();
    if (Object.keys(state.salesOptionByUser).indexOf(`${action.payload.sid}`) > -1) {
      const currState = context.getState();
      const newData = { ...currState.salesOptionByUser };
      delete newData[action.payload.sid];
      context.patchState({ salesOptionByUser: newData });
      const state1 = context.getState();
      logger.log('[SalesOption-State], item removed by id', action.payload.sid, state1);

    } else { logger.log('[SalesOption-State], item to be removed id is not available in the store'); }
  }
  // #region All Records
  @Action(SalesOptionsRequested)
  dataRequested(context: StateContext<SalesOptionStateModel>, action: SalesOptionsRequested) {

    const oKey = getObjKey(action.payload.param);

    // Define function that return the data status object from the state.
    const getDataStatusStateFn = () => context.getState().dataLoadStatus;

    /** custom build the OR children query. */
    const buildOrQueryChildrenFn = (o: SalesOptionParam) => getSalesOptionSearchOptOrChildren(o);

    // if data requested now, is already partially loaded by another query's child previously
    // but the number of the items are not enough (ex. as a child, it loaded only 5, but current
    // request ask for more, then next page of that query should be called instead.)
    const nextPageFn = (option: SalesOptionParam) => {
      context.dispatch(new SalesOptionsNextPageRequested({ option }));
    };

    buildDataRequest(
      oKey, action.payload.param, action.payload.pData,
      getDataStatusStateFn,
      buildOrQueryChildrenFn,
      nextPageFn,
      (
        obj: { [key: string]: IDataLoadStatus<SalesOptionParam> },
        set: { key: string, node: IDataLoadStatus<SalesOptionParam> }[]
      ) => {

        if (!!obj) {
          // Patch the state.
          const state = context.getState();
          context.patchState({
            dataLoadStatus: { ...state.dataLoadStatus, ...obj }
          });
        }

        // Process the query.
        set.forEach((val) => {
          // some of the nodes are already loaded. Only process that are loading... status.
          if (val.node.loadStatus !== LoadStatus.Loading) {
            return;
          }
          // if this request is just overwriting a stall or pending request, unsubscribe that observable
          this.dataSubData.unsubscribe(val.key);

          // create the paging observable and call db.
          const p = this.salesOptionService.getAllSalesOptions_PagingObservable();
          const prod$ = p.getData(action.payload.pData, val.node.param)
            .pipe(
              map(pickDrops => {
                context.dispatch(new SalesOptionsLoaded({
                  data: pickDrops as any,
                  key: val.key
                }));
                return pickDrops;
              }));
          const sub = this.subscribe(prod$, () => noop(), SalesOptionRequested.type);
          // save the observable call
          this.dataSubData.addData(val.key, sub, p);
        });
      }
    );
  }

  @Action(SalesOptionsLoaded)
  salesOptionsLoaded(context: StateContext<SalesOptionStateModel>, action: SalesOptionsLoaded) {
    const state = context.getState();
    const subData = this.dataSubData.getData(action.payload.key);
    const updatedLoadStatus = updatePaging(action.payload.key, state.dataLoadStatus, subData);
    context.patchState({
      allSalesOptionsLoaded: true,
      dataLoadStatus: updatedLoadStatus,
      salesOptions: { ...state.salesOptions, ...action.payload.data }
    });
  }


  @Action(SalesOptionsNextPageRequested)
  allSalesOptionNextPageRequested(context: StateContext<SalesOptionStateModel>, action: SalesOptionsNextPageRequested) {
    const oKey = getObjKey(action.payload.option);
    const state = context.getState();
    // find the node. can be parent or child
    const statusObj = state.dataLoadStatus[oKey];
    // if no parent, treat is
    if (statusObj.children == null) {
      this.dataSubData.dispatchNextPagingUpdate(oKey);
    } else {
      const children = getRootLevelChildren(oKey, state.dataLoadStatus);
      children.forEach(c => {
        this.dataSubData.dispatchNextPagingUpdate(c.key);
      });
    }
  }

  @Action(FetchSalesOptionsUsingAlgolia)
  fetchRentalProductUsingAlgolia(context: StateContext<SalesOptionStateModel>, action: FetchSalesOptionsUsingAlgolia) {
    const oKey = getObjKey(action.payload.param);
    const state = context.getState();
    const { query, indexName } = salesOptionSearchAlgolia(action.payload.param, action.payload.pData);
    const rAlgolia = this.algoliaSearchService.getDataUsingAlgolia(query, indexName);
    rAlgolia.subscribe(rProducts => {
      const data = {};
      rProducts.hits.forEach(hit => {
        hit.id = hit.objectID;
        hit = updateNestedDateValueToFirebaseTimeStamp(hit, SODates);//modified dates to firestore timestamp
        data[hit.objectID] = hit;
      });
      context.patchState({
        allSalesOptionsLoaded: true,
        salesOptions: data,
        totalCount: rProducts.nbHits
      });
    });
  }

  @Action(AllSalesHistoryLoaded)
  allSalesHistoryLoaded(context: StateContext<SalesOptionStateModel>, action: AllSalesHistoryLoaded) {
    const state = context.getState();
    const subData = this.dataSubData.getData(action.payload.key);
    const updatedLoadStatus = updatePaging(action.payload.key, state.dataLoadStatus, subData);
    context.patchState({
      allSalesOptionsLoaded: true,
      dataLoadStatus: updatedLoadStatus,
      salesHistory: { ...state.salesHistory, ...action.payload.data }
    });
  }
}
