import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { IAuthCoreService } from '@trent/services/auth/iauth-core.service';
import { BaseGuard } from './base.guard';
import { Store } from '@ngxs/store';
import { AuthState } from '@trent/store/auth-store/auth.state';

@Injectable({
	providedIn: 'root'
}) export class MultiGuard extends BaseGuard implements CanActivate {
	constructor(private store: Store, private auth: IAuthCoreService, private router: Router) {
		super();
	}

	canActivate(route: ActivatedRouteSnapshot) {

		let admin = this.auth.isAdmin$.subscribe(role => role);
		let editor = this.store.select(AuthState.isEditor).subscribe(role => role);
		let credit = this.store.select(AuthState.isCredit).subscribe(role => role);

		if (!admin || !editor || !credit) {
			const returnUrl = route['_routerState'].url;
			if (this.allowRedirect(returnUrl)) {
				this.resetCounter(returnUrl);
				if (returnUrl == null || returnUrl === '') {
					return this.router.navigateByUrl('/login');
				} else {
					return this.router.navigate(['/login', {
						returnUrl,
						fromAdminGuard: true
					}]);
				}
			}
		}
	}
}