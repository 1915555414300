import { Exclude, Expose, instanceToInstance, plainToInstance } from "class-transformer";
import { IValidationMsg } from "../error-handling";
import { IsDefined, IsInt, IsPositive, ValidatorOptions } from 'class-validator';
import { sanitizeDateIPoint } from "../utility";
import { BaseModel } from "../base";
import { isNumber } from 'lodash';

@Exclude()
export class MinimumSellingPrice extends BaseModel {
	public static readonly collectionName = 'minimum-selling-price';

	@Expose()
	@IsInt({ message: 'Number is required to mention price' })
	@IsPositive()
	price: number;

	@Expose()
	@IsDefined({ message: 'Product Id is required' })
	productId: string | number;

	@Expose()
	@IsDefined({ message: 'Sales Option Id is required' })
	salesOptionId: string | number;

	constructor() {
		super();
		this.price = 0;
		this.productId = '';
		this.salesOptionId = '';
	}

	validateSync(options: ValidatorOptions): IValidationMsg {
		const r = this.validateSyncBase(this, options);
		if (!this.price) {
			r['price'] = ['Set Minimum Selling Price'];
			if (this.price === 0) {
				r['price'] = ['Price cannot be 0'];
			}
		}
		if (!this.productId) {
			r['productId'] = ['Product Id is required'];
		}
		if (!this.salesOptionId) {
			r['salesOptionId'] = ['Sales Option Id is required'];
		}
		return r;
	}

	/** when read from jason or from plain to class, the date objects may be stored as string. convert them to date. */
	sanitize() {
		// if data was received from firebase, date is stored as snapshot.
		super.sanitize();
	}

	public static parse(obj) {
		if (obj == null) { return null; }
		const m = plainToInstance<MinimumSellingPrice, any>(MinimumSellingPrice, sanitizeDateIPoint(obj)); // ,{ strategy: 'excludeAll' }); exclude all fails.
		m.sanitize();
		return m;
	}


	public updateminimumSellingPrice(s: MinimumSellingPrice) {
		this.price = s.price;
	}

	clone() {
		const t = instanceToInstance(this);
		t.sanitize();
		return t;
	}

	/**
	 * Remove special char($ , space etc) from price
	 * @param price 
	 * @returns 
	 */
	public static formatPrice(price: string): number {
		if (!price) {
			return undefined;
		}
		if (isNumber(price)) {
			return price;
		}
		return Number(price.replace(/[$,]/g, '').trim());
	}
}