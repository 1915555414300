import * as entity from '@trent/store/entity.state';
import { Selector, State, Action, StateContext } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { noop, Subscription } from 'rxjs';
import { Paging, getObjKey } from '../../models/observable-util/paging';
import { PagingContainer } from '../../models/observable-util/paging-container';
import { StateBase } from '../state-base';
import { IDataLoadStatus, buildDataRequest, LoadStatus, updatePaging, getRootLevelChildren } from '../../models/observable-util/data-status';
import { Injectable } from '@angular/core';
import { AddOnParam, addOnSearchClientFilter, getAddOnSearchOptOrChildren } from '@trent/models/addon/add-on-param';
import { AddOnBase } from '@trent/models/addon/add-on-base';
import { AddOnService } from '@trent/services/add-on.service';
import { parseAddOn } from '@trent/models/addon/add-on-helper';

/**
 *  AddOn State Model
 */

export interface AddOnStateModel {
  param?: AddOnParam;
  paging: Paging;
  allAddOnsLoaded: boolean;
  allAddOnsLoadStatus: {
    [key: string]: IDataLoadStatus<AddOnParam>;
  };
  addOns: { [id: string]: AddOnBase };
  addOnHistory: { [id: string]: AddOnBase }; 

}

/**
 * init AddOn State Model
 */
function initAddOnState(): AddOnStateModel {
  return {
    param: null,
    paging: { size: 10, offset: 0, full: false },
    allAddOnsLoaded: false,
    allAddOnsLoadStatus: {},
    addOns: {},
    addOnHistory: {}
  };
}
// #endregion

// AddOn actions


export class AllAddOnsRequested {
  static readonly type = '[AddOns] All AddOns Requested';
  constructor(public payload: { pData: Paging, param: AddOnParam }) { }
}

export class AllAddOnsLoaded {
  static readonly type = '[AddOns] All AddOns Loaded';
  constructor(public payload: {
    data: { [id: string]: AddOnBase },
    key: string,
  }) { }
}

export class AllAddOnsNextPageRequested {
  static readonly type = '[AddOns] All AddOns Requested - Next Page';
  constructor(public payload: { param: AddOnParam }) { }
}

export class AddOnRequested {
  static readonly type = '[AddOns] Request a single AddOn entity';
  constructor(public payload: { id: string | number }) { }
}

export class AddOnLoaded {
  static readonly type = '[AddOns] Load a single AddOn entity';
  constructor(public payload: { data: AddOnBase }) { }
}

export class AllAddOnHistoryLoaded {
  static readonly type = '[AddOns] All AddOn History Loaded';
  constructor(public payload: {
    data: { [id: string]: AddOnBase },
    key: string,
  }) { }
}

export class AddOnStateReset {
  static readonly type = '[AddOns] Reset State';
}

// #end region actions


@State<AddOnStateModel>({
  name: 'addOn',
  defaults: initAddOnState()
})

@Injectable()
export class AddOnState extends StateBase {
  allAddOnReqSub: Subscription;
  allAddOnsSubData: PagingContainer<AddOnBase, AddOnParam> = new PagingContainer();

  constructor(private addOnService: AddOnService) { super(); }

  // #region Static Selectors
  @Selector()
  static selectAllAddOns(state: AddOnStateModel) {
    return (o: AddOnParam): AddOnBase[] => {
      
      if (state.addOns == null || Object.keys(state.addOns).length === 0) {
        return [];
      }
      // remove keys that have revision/draft ids, i.e that contain '~' in the id.
      const keys = Object.keys(state.addOns).filter(k =>
        k.indexOf('/') === -1 && state.addOns[k] != null);
      // object without the rev/draft.
        const filtered = keys.reduce((obj, k) => {
          obj[k] = state.addOns[k];
          return obj;
        }, {});
      let output = Object.values(filtered).map(x => parseAddOn(x));
      output = addOnSearchClientFilter(output, o);
      return output;
    };
  }

  /** Is the Paging Full for this search. */
  @Selector()
  static selectAllAddOnsFull(state: AddOnStateModel) {
    return (o: AddOnParam): boolean => {
      const oKey = getObjKey(o);
      const r = state.allAddOnsLoadStatus && state.allAddOnsLoadStatus[oKey];
      return (!!r) ? r.pData.full : true;
    };
  }

  @Selector()
  static selectAddOnById(state: AddOnStateModel) {
    return entity.getByIdFn_new(state.addOns, parseAddOn);
  }
  
  @Selector()
  static selectAddOnParam(state: AddOnStateModel) {
    return {
      ...state.param
    };
  }

  @Selector()
  static selectAllAddOnHistory(state: AddOnStateModel) {
    return (o: AddOnParam): AddOnBase[] => {

      if (state.addOnHistory == null || Object.keys(state.addOnHistory).length === 0) {
        return [];
      }
      // remove keys that have revision/draft ids, i.e that contain '~' in the id.
      const keys = Object.keys(state.addOnHistory).filter(k =>
        k.includes('/') && state.addOnHistory[k] != null);
      // object without the rev/draft.
        const filtered = keys.reduce((obj, k) => {
          obj[k] = state.addOnHistory[k];
          return obj;
        }, {});

      let output = Object.values(filtered).map(x => parseAddOn(x));
      output = addOnSearchClientFilter(output, o);

      return output;
    };
  }
  // #endregion selectors

  // #region Custom Functions and subscriptions
  public clearSubscriptions() {
    if (!!this.allAddOnReqSub) {
      this.allAddOnReqSub.unsubscribe();
      this.allAddOnReqSub = null;
    }
    this.allAddOnsSubData.unsubscribeAll();
    super.clearSubscriptions();
  }

  @Action(AddOnStateReset)
  addOnStateReset(context: StateContext<AddOnStateModel>, action: AddOnStateReset) {
    // unsubscribe the data
    console.log('RentalProductStateReset action called');
    this.clearSubscriptions();
    context.setState(initAddOnState());
  }

  @Action(AllAddOnsRequested)
  allAddOnsRequested(context: StateContext<AddOnStateModel>, action: AllAddOnsRequested) {
    const oKey = getObjKey(action.payload.param);

    // Define function that return the data status object from the state.
    const getDataStatusStateFn = () => context.getState().allAddOnsLoadStatus;

    /** custom build the OR children query. */
    const buildOrQueryChildrenFn = (o: AddOnParam) => getAddOnSearchOptOrChildren(o);
    // if data requested now, is already partially loaded by another query's child previously
    // but the number of the items are not enough (ex. as a child, it loaded only 5, but current
    // request ask for more, then next page of that query should be called instead.)
    const nextPageFn = (param: AddOnParam) => {
      context.dispatch(new AllAddOnsNextPageRequested({ param }));
    };
    buildDataRequest(
      oKey, action.payload.param, action.payload.pData,
      getDataStatusStateFn,
      buildOrQueryChildrenFn,
      nextPageFn,
      (
        obj: { [key: string]: IDataLoadStatus<AddOnParam> },
        set: { key: string, node: IDataLoadStatus<AddOnParam> }[]
      ) => {
        if (!!obj) {
          // Patch the state.
          const state = context.getState();
          context.patchState({
            allAddOnsLoadStatus: { ...state.allAddOnsLoadStatus, ...obj }
          });
        }
        // Process the query.
        set.forEach((val) => {
          // some of the nodes are already loaded. Only process that are loading... status.
          if (val.node.loadStatus !== LoadStatus.Loading) {
            return;
          }
          // if this request is just overwriting a stall or pending request, unsubscribe that observable
          this.allAddOnsSubData.unsubscribe(val.key);

          console.log('New Service sub created: ', val);
          // create the paging observable and call db.
          const b = this.addOnService.getAllAddOns_PagingObservable();
          const addOn$ = b.getData(action.payload.pData, val.node.param)
            .pipe(
              map(addOns => {
                  context.dispatch(new AllAddOnsLoaded({
                    data: addOns,
                    key: val.key
                  }));
                return addOns;
              }));
          const sub = this.subscribe(addOn$, () => noop(), AllAddOnsRequested.type);
          this.allAddOnsSubData.addData(val.key, sub, b);
        }
        );
      });
  }

  @Action(AllAddOnsLoaded)
  allAddOnsLoaded(context: StateContext<AddOnStateModel>, action: AllAddOnsLoaded) {
    const state = context.getState();
    const subData = this.allAddOnsSubData.getData(action.payload.key);
    const updatedLoadStatus = updatePaging(action.payload.key, state.allAddOnsLoadStatus, subData);
    context.patchState({
      allAddOnsLoaded: true,
      allAddOnsLoadStatus: updatedLoadStatus,
      addOns: { ...state.addOns, ...action.payload.data } 
    });
  }

  @Action(AllAddOnsNextPageRequested)
  allAddOnsNextPageRequested(context: StateContext<AddOnStateModel>, action: AllAddOnsNextPageRequested) {
    const oKey = getObjKey(action.payload.param);
    const state = context.getState();
    // find the node. can be parent or child
    const statusObj = state.allAddOnsLoadStatus[oKey];
    // if no parent, treat is
    if (statusObj.children == null) {
      this.allAddOnsSubData.dispatchNextPagingUpdate(oKey);
    } else {
      const children = getRootLevelChildren(oKey, state.allAddOnsLoadStatus);
      children.forEach(c => {
        this.allAddOnsSubData.dispatchNextPagingUpdate(c.key);
      });
    }
  }
  
  @Action(AddOnRequested)
  addOnRequested(context: StateContext<AddOnStateModel>, action: AddOnRequested) {
    const state = context.getState();
    console.log('requested AddOnBase is not in the store. sending request to server', action.payload.id);
    this.addOnService.getAddOnById(action.payload.id)
      .pipe(
        map(data => {
          // console.log('from server: retal map was called', data);
          return context.dispatch(new AddOnLoaded({ data }));
        }
        )).subscribe(noop);

  }

  @Action(AddOnLoaded)
  addOnLoaded(context: StateContext<AddOnStateModel>, action: AddOnLoaded) {
    const state = context.getState();
    const b = {};
    b[action.payload.data.id] = action.payload.data;

    context.patchState({
      addOns: { ...state.addOns, ...b } // entity.update(state.addOns, action.payload.data)
    });
  }

  @Action(AllAddOnHistoryLoaded)
  allAddOnHistoryLoaded(context: StateContext<AddOnStateModel>, action: AllAddOnHistoryLoaded) {
    const state = context.getState();
    const subData = this.allAddOnsSubData.getData(action.payload.key);
    const updatedLoadStatus = updatePaging(action.payload.key, state.allAddOnsLoadStatus, subData);
    context.patchState({
      allAddOnsLoaded: true,
      allAddOnsLoadStatus: updatedLoadStatus,
      addOnHistory: { ...state.addOnHistory, ...action.payload.data } // entity.addMany(state.addOnHistory, action.payload.addOnHistory)
    });
  }
}