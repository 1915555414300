import { keys } from 'lodash';
import { Injectable } from '@angular/core';
import { BaseHttpService } from '@trent/services/base-http.service';

import { FirestoreService } from '@trent/services/firestore.service';
import { map, tap } from 'rxjs/operators';
import { plainToInstance, instanceToPlain } from 'class-transformer';
import { HttpHeaders, HttpClient } from '@angular/common/http';
// tslint:disable-next-line:max-line-length
import { ProductBase, ProductDocType } from '@trent/models/product/product-base';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { ValidatorOptions } from 'class-validator';
import { Paging } from '@trent/models/observable-util/paging';
import { PagingObesrvable } from '@trent/models/observable-util/paging-obesrvable';
import { ProductSearchParam, Trailer, productSerachServerQuery } from '@trent/models/product';
import { UserProfile } from '@trent/models/user/user-profile';
import { logger } from '../models/log/logger';
import { query, where } from '@angular/fire/firestore';
import { InventoryTransfer } from '@trentm/product/inventory-transfer';
import { StoreLocationSummary } from '@trent/models/store-location/store-location';

@Injectable()
export class ProductService extends BaseHttpService {

  offset = new BehaviorSubject(null);
  infinite: Observable<any[]>;

  public readonly allProducts: PagingObesrvable<ProductBase, ProductSearchParam>;

  constructor(store: Store,
    // auth: AuthService,
    private db: FirestoreService, private http: HttpClient) {
    super(store);
    this.allProducts = new PagingObesrvable<ProductBase, ProductSearchParam>(this.db, this.getAllProducts1_batch);
    this.apiName = 'api';
  }

  public requestApproval(cid: string | number, pid: string | number) {
    const headers = this.addBearerToken();
    return this.http.post<{ pid: string | number; product: ProductBase }>(this.getApiUrl('/product/requestApproval'),
      {
        cid: cid,
        pid: pid
        // company: instanceToPlain(c),
        // option: option
      },
      { headers: headers })
      .pipe(
        tap(r => console.log('response: ', r)),
      );
  }

  /** Send request to approve the record. */
  public admin_approveProduct(cid: string | number, pid: string | number) { // :  CompanyFleet) {
    // Prepare the post data
    const headers = this.addBearerToken();
    return this.http.post<{ pid: string | number, product: ProductBase }>(this.getApiUrl('/product/adminApprove'),
      {
        pid, cid
      },
      { headers: headers })
      .pipe(
        tap(r => console.log('response: ', r)),
      );
  }
  /** cancel Product approval request */
  cancelApprovalReq(cid: string | number, pid: string | number) {
    // Prepare the post data
    const headers = this.addBearerToken();
    return this.http.post<{ pid: string | number, product: ProductBase }>(this.getApiUrl('/product/cancelApprovalReq'),
      {
        pid, cid
      },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(
        tap(r => logger.log('response: ', r)),
      );
  }

  public create(p: ProductBase, cid: string, option?: ValidatorOptions) {
    // Prepare the post data
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
    return this.http.post<{ pid: string | number, product: ProductBase }>(this.getApiUrl('/product/create'),
      // Server validation before creating the entry.
      {
        product: instanceToPlain(p),
        cid,
        option: option
      },
      { headers: headers })
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }

  public update(pid: string | number, p: ProductBase, option?: ValidatorOptions) {
    // Prepare the post data
    const headers = this.addBearerToken();

    // Server validation before creating the entry.
    return this.http.post<{ pid: string | number, product: ProductBase }>(this.getApiUrl('/product/update'),
      {
        product: instanceToPlain(p),
        pid: pid, option: option
      },
      { headers: headers })
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }
  public deleteDraft(cid: string | number, pid: string | number) {
    // Prepare the post data
    const headers = this.addBearerToken();

    // Server validation before creating the entry.
    return this.http.post<{ pid: string | number, product: ProductBase }>(this.getApiUrl('/product/delete-draft'),
      {
        cid: cid,
        pid: pid
      },
      { headers: headers })
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }
  public getSecureUrl(pid: string | number, docType: ProductDocType, cid?: string | number) {
    // Prepare the post data
    const headers = this.addBearerToken();
    return this.http.post<{ url: string }>(this.getApiUrl('/product/getSecureUrl'),
      { pid, docType, cid },
      // tslint:disable-next-line:object-literal-shorthand
      { headers: headers })
      .pipe(map(x => (!!x && !!x.url && x.url.length) ? x.url[0] : ''))
      .toPromise();
  }

  public getProductByUser() {

    const prodRef = this.db.colWithIds$<Trailer>('product');

    return prodRef.pipe(
      map(x => {
        const R: Trailer[] = [];
        // Inject the ids in to the model.
        for (const a of x) {
          const c = plainToInstance<Trailer, Trailer>(Trailer, a.data);
          (<any>c).id = a.id;
          R.push(c);
        }
        return R;
      })
    );
  }

  public getAllProducts(u: UserProfile) {
    u = UserProfile.parse(u);
    const cKeys = keys(u.cRolesAll);
    console.log('64 : product.service -> Getting All Products');
    console.log('company Keys are: ', cKeys);
    console.log('user: ', u);
    console.warn('HG: Work on getting Products here');


    return this.db.colWithIdsInjected$<ProductBase>(ProductBase.collectionName, ref =>
      query(ref, where('cid', '==', cKeys[cKeys.length - 1])));


    // return this.db.colWithIdsInjected$<CompanyFleet>(CompanyFleet.collectionName, ref =>
    //   ref.where(`authUsers.${uid}.dataFlag`, '==', true)
    //   // ref.where(`id`, '==', '1XTSoCZMLhnipi2Llgsx')
    // );

  }

  public getProductById(id: string | number) {
    return this.db.docWithInjectedId$<ProductBase>(
      `${ProductBase.collectionName}/${id}`, id);
    // `product/${id}`);
  }

  public getProductsByCompId(cid: string | number) {
    return this.db.colWithIdsInjected$<ProductBase>('product',
      ref => query(ref, where(`cid`, '==', `${cid}`)));

  }

  public getAllProducts1_PagingObservable() {
    const p: PagingObesrvable<ProductBase, ProductSearchParam> =
      new PagingObesrvable<ProductBase, ProductSearchParam>(this.db, this.getAllProducts1_batch);
    return p;
  }

  public getAllProducts1(pData: Paging, o: ProductSearchParam) {
    return this.allProducts.getData(pData, o);
  }

  private getAllProducts1_batch(p: Paging, o: ProductSearchParam): Observable<{ [key: string]: ProductBase }> {
    console.log('Product Server called with', p.offset, p.size);
    return this.db
      .colWithIdsInjectedNew$<ProductBase>(ProductBase.collectionName,
        ref => productSerachServerQuery(ref, o, p))
      .pipe(
        tap(arr => {
          if (arr == null || arr.length === 0) {
            console.log('All data is recevied, Paging is FULL');
            p.full = true;
          } else {
            p.lastDoc = arr[arr.length - 1];
          }
        }),
        map(arr => {
          // console.log('Got here 137', arr);
          return arr.reduce((acc, cur) => {
            const id = cur.id;
            const data = cur;
            return { ...acc, [id]: data };
          }, {});
        })
      );
  }

  /**
   * 
   * @author MKN 
   * activate/Deactivate product 
   * @param pid 
   * @param status 
   * @returns 
   */

  public activeDeactivateProduct(pid: string | number, status: boolean) {
    const headers = this.addBearerToken();
    return this.http.post<{ pid: string | number; product: ProductBase }>(this.getApiUrl('/product/activateDeactivateProduct'),
      {
        status,
        pid
      },
      { headers: headers })
      .pipe(
        tap(r => console.log('response: ', r)),
      );
  }

  /**
   * Update product image type to original
   * @param pid 
   * @returns 
   */
  public updatePictureTypeStatus(pid: string | number) {
    // Prepare the post data
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
    return this.http.post<{ pid: string | number }>(this.getApiUrl('/product/update-picture-type-status'),
      // Server validation before creating the entry.
      {
        pid: pid
      },
      { headers: headers })
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }

  public transfer(cid: string | number, pid: string | number, i: InventoryTransfer, option?: ValidatorOptions) {
    // Prepare the post data
    const headers = this.addBearerToken();

    // Server validation before creating the entry.
    return this.http.post<{ pid: string | number, product: ProductBase }>(this.getApiUrl('/product/transfer'),
      {
        pid: pid,
        cid: cid,
        transfer: instanceToPlain(i),
        option: option
      },
      { headers: headers })
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }

  public updateLot(pid: string | number, lot: string, option?: ValidatorOptions) {
    // Prepare the post data
    const headers = this.addBearerToken();

    // Server validation before creating the entry.
    return this.http.post<{ pid: string | number, product: ProductBase }>(this.getApiUrl('/product/update-lot'),
      {
        pid: pid,
        lot: lot,
        option: option
      },
      { headers: headers })
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }

  /**
   * @author KS
   * @purpose Remove salesOptionId from products
   * @returns 
   */
  public bulkModifySales() {
    // Prepare the post data
    const headers = this.addBearerToken();

    // Server validation before creating the entry.
    return this.http.post<{ pid: string | number, product: ProductBase }>(this.getApiUrl('/product/bulk-modify-sales'), {},
      { headers: headers })
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }

   /**
   * @author KS
   * @purpose Bulk modify gps provider
   * @returns 
   */
   public bulkModifyGPS() {
    // Prepare the post data
    const headers = this.addBearerToken();

    // Server validation before creating the entry.
    return this.http.post<{ pid: string | number, product: ProductBase }>(this.getApiUrl('/product/bulk-modify-gps'), {},
      { headers: headers })
      .pipe(
        tap(r => console.log('response: ', r))
      );
  }


  // *** promo app functions ***
  // createPromo(p: PromoProdClientPayload) {
  //   // Prepare the post data
  //   const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
  //   return this.http.post<{ pid: string | number, product: PromoProduct }>(this.getApiUrl('/product/promoCreate'),
  //     // Server validation before creating the entry.
  //     {
  //       product: instanceToPlain(p),
  //     },
  //     { headers: headers })
  //     .pipe(
  //       tap(r => console.log('response: ', r))
  //     );
  // }
  // updatePromo(pid: string | number, p: PromoProdClientPayload) {
  //   // Prepare the post data
  //   const headers = this.addBearerToken();
  //   // Server validation before creating the entry.
  //   return this.http.post<{ pid: string | number, product: PromoProduct }>(this.getApiUrl('/product/promoUpdate'),
  //     {
  //       product: instanceToPlain(p),
  //       pid: pid,
  //     },
  //     { headers: headers })
  //     .pipe(
  //       tap(r => console.log('response: ', r))
  //     );
  // }
}
