import { UtilityService } from '@trent/services/utility.service';
import { cloneDeep } from 'lodash';
// import { UserSetting } from './../../models/setting/user-setting';
import { IUserClaim } from './../../models/user/user-claim';
import { instanceToPlain } from 'class-transformer';
import { State, Action, StateContext, Selector, NgxsOnInit, Store } from '@ngxs/store';
import * as r from '../../models/user/role';
import { UserProfile } from '../../models/user/user-profile';
import { StateBase } from '../state-base';

// import { productOptionInit } from '@trent/models/product/product-search-option';
// import { rentOptionParamInit, myRentalParamInit } from '../../models/rental/rent-option-param';
// import { bidParamInit } from '../../models/bid/bid-param';
import { DeepCopy } from '../../models/utility/deep-copy';
// import { tripParamInit } from '../../models/trip/trip-param';
import { FirestoreService } from '../../services/firestore.service';
import { LocalStorage } from '../../services/local-storage/local-storage.service';
import { SiteData } from '../../models/site-data';
import { Subscription } from 'rxjs';
import { UserSetting } from '../../models/setting/user-setting';
import { tripParamInit } from '../../models/trip/trip-param';
import { Injectable } from '@angular/core';
import { UserRef } from '@trent/models/user/user-ref';
import { IAgreementStatus, initAgreementStatus } from '@trent/models/user-agreement';
// import { pickDropParamInit } from '../../models/pickup-dropoff/pick-drop-param';
import { logger } from '@trentm/log/logger';
import { productOptionInit } from '@trent/models/product';
import { rentOptionParamInit } from '@trent/models/rental/rent-option-param';
import { User } from '@angular/fire/auth';


export interface AuthStateModel {
  loadedFromDb: boolean;
  loggedIn: boolean;
  isNewUser: boolean;
  authToken: string;
  user: UserProfile;
  userRef: UserRef;
  refId: string;
  // userAuth: ua.IUserAuth;
  userClaim: IUserClaim; // { [key: string]: any };

  /** Current Agreement Status. Note, this will only be loaded if the net Rev in the claim (cClaim.ag)
   * is smaller then what is specified in the AppSettings. user agreements are up to date. */
  agreementStatus?: IAgreementStatus;
  agreementStatusRequested: boolean;

  /** is a call to db required to check the agreement status. (Track by claim data) */
  agreementDbCallReqd: boolean;
}

export class Login {
  static readonly type = '[Auth] login';
  constructor(public payload: { user: User }) { }
}
export class Logout {
  static readonly type = '[Auth] logout';
}

export class SetToken {
  static readonly type = '[Auth] Set Token';
  constructor(public payload: { token: string }) { }
}

export class SetClaim {
  static readonly type = '[Auth] Set Claim';
  constructor(public payload: IUserClaim) { }
}

export class SetSettings {
  static readonly type = '[Auth] Set Settings';
  constructor(public payload: { setting: UserSetting, updateLocalDb?: boolean }) { }
}

export class ProfileFromDbLoaded {
  static readonly type = '[Auth] Profile is loaded from Db';
  constructor(public payload: UserProfile) { }
}

export class ProfileFromDbRequested {
  static readonly type = '[Auth] Profile is requested to be loaded from Db';
  constructor(public payload: { uid: string | number }) { }
}

export class SetUserRef {
  static readonly type = '[Auth] Set User-Ref';
  constructor(public payload: { userRef: UserRef }) { }
}
export class SetRefId {
  static readonly type = '[Auth] Set Ref-Id';
  constructor(public payload: { refId: string }) { }
}

export class SetNewUser {
  static readonly type = '[Auth] Set New user';
  constructor(public payload: { isNewUser: boolean }) { }
}


// export class OutdatedAgreementRequested {
//   static readonly type = '[Auth] Outdated Agreement - Requested';
//   constructor(public payload: {
//     user$: Observable<firebase.User>,
//     appSetting$: Observable<AppSetting>,
//     forceReload?: boolean
//   }) { }
// }

export class AgreementLoaded {
  static readonly type = '[Auth] Outdated Agreements Loaded.';
  constructor(public payload: { data: IAgreementStatus }) { }
}


export class SetAgreementDbCallRequired {
  static readonly type = '[Auth] SetAgreementDbCallRequired. Db need to be called?.';
  constructor(public payload: { data: boolean }) { }
}

export class UserAgreementRequested {
  static readonly type = '[Auth] User Agreement - Requested';
  constructor(public payload: {
    uid: string
  }) { }
}

// export class FcmMessageReceived {
//   static readonly type = '[Auth] Fcm Message Received from server';
//   constructor(public payload: { uid: string }) { }
// }

// export class SetUserAccess {
//   static readonly type = '[Auth] Set User Access params';
//   constructor(public payload: { ua: ua.IUserAuth }) { }
// }

const initAuthState = (): AuthStateModel => {
  return {
    loadedFromDb: false,
    loggedIn: false,
    isNewUser: false,
    authToken: null,
    user: null,
    userRef: 'none',
    refId: null,
    // userAuth: ua.UserAuth.init(),
    userClaim: null,
    agreementStatusRequested: false,
    agreementDbCallReqd: false
  };
};
@State<AuthStateModel>({
  name: 'auth',
  defaults: initAuthState()
})
@Injectable()
export class AuthState extends StateBase implements NgxsOnInit {
  subs: Subscription[] = [];
  private agrrementByUidSub: Subscription;

  constructor(private db: FirestoreService, private store: Store,
    public localStorage: LocalStorage, public us: UtilityService) {
    super();
    logger.log('[Auth-State] constructor called!');
    this.subs.push(this.store.select(AuthState.user).subscribe(u => SiteData.setUser(u)));
    this.subs.push(this.store.select(AuthState.userClaim).subscribe(c => SiteData.setClaim(c)));
  }

  // #region Selectors

  @Selector()
  static isLoggedIn(state: AuthStateModel) {
    return state.loggedIn;
  }

  @Selector()
  static isLoggedOut(state: AuthStateModel) {
    return state.user != null ? false : state.loggedIn;
  }

  @Selector()
  static isAdmin(state: AuthStateModel) {
    return r.isAdminP(state.user);
  }
  @Selector()
  static isEditor(state: AuthStateModel) {
    return r.isEditorP(state.user);
  }
  @Selector()
  static isCredit(state: AuthStateModel) {
    return r.isCreditP(state.user);
  }
  @Selector()
  static isSales(state: AuthStateModel) {
    return r.isSalesP(state.user);
  }
  @Selector()
  static isService(state: AuthStateModel) {
    return r.isServiceP(state.user);
  }
  @Selector()
  static isPurchasing(state: AuthStateModel) {
    return r.isPurchasingP(state.user);
  }
  @Selector()
  static isBilling(state: AuthStateModel) {
    return r.isBillingP(state.user);
  }
  @Selector()
  static displayName(state: AuthStateModel) {
    return (state.user != null) ?
      state.user.displayName : 'Guest';
  }
  @Selector()
  static isMarketing(state: AuthStateModel) {
    return r.isMarketingP(state.user);
  }
  @Selector()
  static isSalesOperations(state: AuthStateModel) {
    return r.isSalesOperationsP(state.user);
  }
  @Selector()
  static isBranchMgr(state: AuthStateModel) {
    return r.isBranchMgrP(state.user);
  }
  @Selector()
  static isRegionMgr(state: AuthStateModel) {
    return r.isRegionMgrP(state.user);
  }
  @Selector()
  static isCountryMgr(state: AuthStateModel) {
    return r.isCountryMgrP(state.user);
  }
  @Selector()
  static isEnterpriseMgr(state: AuthStateModel) {
    return r.isEnterpriseMgrP(state.user);
  }
  @Selector()
  static isYardPerson(state: AuthStateModel) {
    return r.isYardPersonP(state.user);
  }
  @Selector()
  static isPriceApprover(state: AuthStateModel) {
    return r.isPriceApproverP(state.user);
  }
  @Selector()
  static user(state: AuthStateModel) {
    return state.user;
  }

  @Selector()
  static defaultCompany(state: AuthStateModel) {
    const u = state.user;
    if (!!u && !!u.setting && !!u.setting.defaultCompany) {
      return u.setting.defaultCompany;
    }
    return null;
  }

  @Selector()
  static userClaim(state: AuthStateModel) {
    return state.userClaim;
  }

  @Selector()
  static userSignInProvider(state: AuthStateModel) {
    return state.userClaim && state.userClaim.firebase.sign_in_provider;
  }

  @Selector()
  static userSettings(state: AuthStateModel) {
    // return state && state.user && state.user.setting;
    const u = state.user;
    if (!!u && !!u.setting) {
      return u.setting;
    }
    return null;
  }

  @Selector()
  static userProductSearchOption(state: AuthStateModel) {
    const u = state.user;
    if (!!u && !!u.setting && !!u.setting.prodOption) {
      return u.setting.prodOption;
    }
    return productOptionInit();
  }
  @Selector()
  static userRentOptionsSearchOption(state: AuthStateModel) {
    const u = state.user;
    if (!!u && !!u.setting && !!u.setting.rentOptionParam) {
      return u.setting.rentOptionParam;
    }
    return rentOptionParamInit();
  }
  // @Selector()
  // static userMyRentalSearchOption(state: AuthStateModel) {
  //   const u = state.user;
  //   if (!!u && !!u.setting && !!u.setting.myRentalParam) {
  //     return u.setting.myRentalParam;
  //   }
  //   return myRentalParamInit();
  // }
  @Selector()
  static userTripSearchOption(state: AuthStateModel) {
    const u = state.user;
    if (!!u && !!u.setting && !!u.setting.tripParam) {
      return u.setting.tripParam;
    }
    return tripParamInit();
  }
  // @Selector()
  // static userBidSearchOption(state: AuthStateModel) {
  //   const u = state.user;
  //   if (!!u && !!u.setting && !!u.setting.bidParam) {
  //     return u.setting.bidParam;
  //   }
  //   return bidParamInit();
  // }
  // @Selector()
  // static userPickDropOption(state: AuthStateModel) {
  //   const u = state.user;
  //   if (!!u && !!u.setting && !!u.setting.pickDropParam) {
  //     return u.setting.pickDropParam;
  //   }
  //   return pickDropParamInit();
  // }

  @Selector()
  static selectToken(state: AuthStateModel) {
    return state.authToken;
  }

  @Selector()
  static updateProfileRequest(state: AuthStateModel) {
    return state;
  }

  @Selector()
  static userRef(state: AuthStateModel) {
    return state.userRef;
  }

  @Selector()
  static isNewUser(state: AuthStateModel) {
    return state.isNewUser;
  }

  @Selector()
  static outDatedAgreement(state: AuthStateModel) {
    return (!!state.agreementStatus) ? cloneDeep(state.agreementStatus) : null;
  }


  // #endregion
  /** Try Auto-Login from cache upon initiation. */
  async ngxsOnInit(ctx: StateContext<AuthStateModel>) {
    // // logger.log('ngxs on init called');
    // const u = await this.localStorage.get('userData');
    // if (u) {
    //   const userData = <UserData>JSON.parse(u);
    //   // logger.log('login action was called from init$ is: ', userData);
    //   return ctx.dispatch(new Login1(userData));
    // } else {
    //   //  logger.log('logout was called from init$s:');
    //   return ctx.dispatch(new Logout1());
    // }
  }

  /** @deprecated No longer used, Login is done by claim update. */
  @Action(Login)
  async Login(context: StateContext<AuthStateModel>, action: Login) {
    if (action.payload.user) {
      let u = context.getState().user;
      u = await UserProfile.getUserProfileFromFbUser(action.payload.user, u, this.localStorage);
      u = instanceToPlain(u) as UserProfile;
      context.patchState({
        loggedIn: true,
        user: u
      });
    } else {
      context.dispatch(new Logout());
    }
  }

  @Action(Logout)
  Logout(context: StateContext<AuthStateModel>) {
    // initiate
    // preserve userRef and refId param, even after logout.
    const ctxCurr = context.getState();
    const ctxInit = initAuthState();
    ctxInit.refId = ctxCurr.refId;
    ctxInit.userRef = ctxCurr.userRef;

    this.clearSubscriptions();
    context.setState(ctxInit);
  }

  @Action(SetToken)
  SetToken(ctx: StateContext<AuthStateModel>, action: SetToken) {
    ctx.patchState({ authToken: action.payload.token });
  }

  // @Action(SetUserAccess)
  // setUserAccess(ctx: StateContext<AuthStateModel>, action: SetUserAccess) {
  //   ctx.patchState({ userAuth: action.payload.ua });
  // }

  @Action(SetClaim)
  async setClaim(ctx: StateContext<AuthStateModel>, action: SetClaim) {
    const state = ctx.getState();
    // logger.log('Auth State OLD Claim: ', state.userClaim && state.userClaim.cClaim);
    // logger.log('Auth State NEW Claim: ', action.payload && action.payload.cClaim);

    // Update user, only if changed.
    const currUser = state.user;
    const temp = await UserProfile.getUserProfileFromFbClaim(action.payload, currUser, this.localStorage);
    const newUser = instanceToPlain(temp) as UserProfile;
    // logger.log('Auth Store: Old User: ', currUser);
    // logger.log('Auth Store: New User: ', newUser);

    if (!DeepCopy.areEqual(currUser, newUser)) {
      // logger.log('[Auth-State] user was set in state: ', newUser);
      ctx.patchState({ user: newUser });
    } else {
      // logger.log('User STORE: user update skipped, duplicate call detected.');
    }

    // update claim only if changed.
    if (!DeepCopy.areEqual(state.userClaim, action.payload)) {
      ctx.patchState({ userClaim: action.payload });
    } else {
      // logger.log('User STORE: claim update skipped, duplicate call detected.');
    }

    // update login status.
    if (!state.loggedIn && action.payload != null) {
      ctx.patchState({ loggedIn: true });
    }
    if (state.loggedIn && action.payload == null) {
      ctx.patchState({ loggedIn: false });
    }
  }

  @Action(ProfileFromDbLoaded)
  async profileFromDbLoaded(ctx: StateContext<AuthStateModel>, action: ProfileFromDbLoaded) {
    let u = ctx.getState().user;
    u = await UserProfile.getUserProfileFromDbObj(action.payload, this.localStorage);
    u = <UserProfile>instanceToPlain(u);
    u.loadedFromDb = true;
    ctx.patchState({
      loadedFromDb: true,
      user: u
    });
  }

  @Action(ProfileFromDbRequested)
  async profileFromDbRequested(ctx: StateContext<AuthStateModel>, action: ProfileFromDbRequested) {
    const state = ctx.getState();
    if (!state.loadedFromDb) {
      // const sub = this.userService.getUserById(action.payload.uid);
      const sub = this.db.docWithInjectedId$<UserProfile>(`${UserProfile.collectionName}/${action.payload.uid}`);
      this.subscribe(sub, u => {
        if (u != null) {
          this.store.dispatch(new ProfileFromDbLoaded(u));
        }
      }, ProfileFromDbRequested.type);
    }
  }

  @Action(SetSettings)
  async setSettings(ctx: StateContext<AuthStateModel>, action: SetSettings) {
    const state = ctx.getState();
    let u = state.user;
    if (u != null) {
      u = UserProfile.parse(u);
      u.setting = action.payload.setting;
      if (!!action.payload.updateLocalDb) {
        u.saveSettingsLocal(u.id, this.localStorage);
      }
      ctx.patchState({ user: <UserProfile>instanceToPlain(u) });
    }
  }

  @Action(SetUserRef)
  async setUserRef(ctx: StateContext<AuthStateModel>, action: SetUserRef) {
    logger.log('[Auth-State] Setting UserRef: ' + action.payload.userRef);
    ctx.patchState({ userRef: action.payload.userRef });
  }

  @Action(SetRefId)
  async setRefId(ctx: StateContext<AuthStateModel>, action: SetRefId) {
    ctx.patchState({ refId: action.payload.refId });
  }


  @Action(SetNewUser)
  async setNewUser(ctx: StateContext<AuthStateModel>, action: SetNewUser) {
    ctx.patchState({ isNewUser: action.payload.isNewUser });
  }

  // @Action(OutdatedAgreementRequested)
  // outdatedAgreementRequested(context: StateContext<AuthStateModel>, action: OutdatedAgreementRequested) {
  //   if (!context.getState().agreementStatusRequested || action.payload?.forceReload) {
  //     context.patchState({ agreementStatusRequested: true });
  //     if (!!this.outdatedAgreementSub) {
  //       this.outdatedAgreementSub.unsubscribe();
  //     }

  //     const userSetting$ = combineLatest([action.payload.user$, action.payload.appSetting$]);
  //     const a$ = userSetting$
  //       .pipe(
  //         switchMap(([user, setting]) => !!user && setting ?
  //           this.us.getUserAgreementStatusByQuery(user.uid, Object.values(setting.agreementRev))
  //           : of<IAgreementStatus[]>()),
  //         retry(3));

  //     // This subscription is app lifelong as dispatcher is only called once from the appcomponent.
  //     this.outdatedAgreementSub = combineLatest([action.payload.user$, action.payload.appSetting$, a$])
  //       .subscribe(([user, setting, agreements]) => {
  //         const oAgreeements = (!!agreements && agreements.length > 0) ? agreements[0] : null;
  //         context.dispatch(new AgreementLoaded({ data: oAgreeements }));
  //       });
  //   }
  // }


  @Action(AgreementLoaded)
  outdatedAgreementLoaded(context: StateContext<AuthStateModel>, action: AgreementLoaded) {
    context.patchState({
      agreementStatus: action.payload.data
    });
  }


  @Action(SetAgreementDbCallRequired)
  setAgreementDbCallRequired(context: StateContext<AuthStateModel>, action: SetAgreementDbCallRequired) {
    context.patchState({
      agreementDbCallReqd: action.payload.data
    });
  }


  @Action(UserAgreementRequested)
  userAgreementRequested(context: StateContext<AuthStateModel>, action: UserAgreementRequested) {
    const state = context.getState();
    if (state.agreementStatus == null || state.agreementStatus.uid !== action.payload.uid) {
      if (!!this.agrrementByUidSub) {
        this.agrrementByUidSub.unsubscribe();
      }
      this.agrrementByUidSub = this.us.getUserAgreementStatusById(action.payload.uid)
        .subscribe(_a => {
          const a: IAgreementStatus = !!_a && _a.length > 0 ? _a[0] :
            initAgreementStatus(action.payload.uid);
          context.dispatch(new AgreementLoaded({ data: a }));
        });
    }
  }
}
