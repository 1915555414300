import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { MinimumSellingPriceService } from '@trent/services/minimum-selling-price.service';
import { MinimumSellingPriceState } from './minimum-selling-price.state';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([MinimumSellingPriceState])
  ],
  providers: [
    MinimumSellingPriceService
  ]
})
export class minimumSellingPriceStoreModule { }