export enum LegalType {
  legalTrailer = 'trailerRentalAgreement',
  legalTruck = 'truckRentalAgreement',
  legalDriver = 'driverRentalAgreement',
  tripDriverAgreement = 'tripDriverAgreement',
  truckRentalAgreement = 'truckRentalAgreement',
  trailerRentalAgreement = 'trailerRentalAgreement',
  creditCheckAgreement  = 'creditCheckAgreement'  // agreement which vendor will sign before requesting credit report for lessee(customer)
}

/**
 * @author - MKN
 * @purpose - AgreementType - Contract/personalGuaranty type
 */
export enum AgreementType {

  truckRentalContractCA = 0,
  truckRentalContractUS = 1,
  personalGuarantyCA = 2,
  personalGuarantyUS = 3,
  trailerRentalContractCA = 4,
  trailerRentalContractUS = 5,
  trailerPersonalGuarantyCA = 6,
  trailerPersonalGuarantyUS = 7,
}

// tripDriverAgreement = 'trip-driver-agreement',
  // truckRentalAgreement = 'truck-rental-agreement',
  // trailerRentalAgreement = 'trailer-rental-agreement',
  // creditCheckAgreement  = 'credit-check-agreement'