<div class="g-container">
	<mat-card *ngIf="productSummary && em" class="card1">
		<mat-card-header>
			<div mat-card-avatar>
				<mat-icon matSuffix color="primary">{{productSummary?.prodIcon}}</mat-icon>
			</div>
			<mat-card-title>{{productSummary.title}}
				(#{{productSummary.unitName}})
			</mat-card-title>
			<mat-card-subtitle>{{(productSummary.make)}} {{productSummary.modelYear}}
			</mat-card-subtitle>
			<mat-icon (click)="share()" style="cursor: pointer;">share</mat-icon>
		</mat-card-header>
	</mat-card>
	<div *ngIf="productSummary && em" class="card2">
			<!-- Image Type -->
			<!-- <mat-form-field floatLabel="always">
				<mat-label>Picture Type</mat-label>
				<input matInput name="imageType" #imageType="ngModel" [(ngModel)]="em.imageType" style="display: none">
				<div style="display: grid; align-self: bottom">
					<mat-radio-group aria-label="Select an option" name="imageType" #imageType="ngModel" [disabled]="true"
						[(ngModel)]="em.imageType">
						<mat-radio-button color="accent" [value]="ImageTypeEnum.original" style="padding-right: 10px">
							Original
						</mat-radio-button>
						<mat-radio-button color="accent" [value]="ImageTypeEnum.lookALike" style="padding-right: 10px">
							Lookalike
						</mat-radio-button>
						<mat-radio-button color="accent" [value]="ImageTypeEnum.notApplicable" style="padding-right: 10px">
							Not Applicable
						</mat-radio-button>
					</mat-radio-group>
				</div>
			</mat-form-field> -->
			<div *ngIf="!isHandset" style="height:67px"></div>
			<trent-product-picture-grid [path]="picturePath" [product]="em" [showVisibilityIcon]="false"
				[showOrderingIcon]="false" [uploadRequired]="isHandset" (deleteParent)="deletePicture($event)"></trent-product-picture-grid>
	</div>
</div>