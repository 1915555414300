<ng-container *ngIf="mapView || isHandset">
	<trent-slide-panel [activePane]="activePane" (activePanChg)="activePane = $event" [heightRequire]="false">
		<div leftPane class="left">
			<ng-container>
				<mat-sidenav-container class="mobile-sideNav-container">
					<mat-sidenav #editNav mode="over" position="end">
						<ng-container *ngIf="mobileMenu==='editMenu'">
							<trent-edit-dialog [data]="details" (closeEditNav)="close()"></trent-edit-dialog>
						</ng-container>
					</mat-sidenav>
					<mat-sidenav-content>
						<div class="card-list-container"
							*ngIf="!!dataSource && dataSource?.data?.length > 0; else empty">
							<div class="card-list-filter" *ngIf="showFilter">
								<mat-card appearance="outlined">
									<mat-form-field>
										<mat-label>Filter</mat-label>
										<input type="text" autocomplete="off" style="display:none">
										<!-- Auto complete off in browser in load the page-->
										<input type="search" matInput (keyup)="applyFilter($event.target.value)"
											autocomplete="off">
										<button style="font-size: 25px" type="button" color="primary" mat-icon-button
											matSuffix (click)="clearFilter();">
											<mat-icon>search_off</mat-icon>
										</button>
									</mat-form-field>
								</mat-card>
							</div>
							<div [ngClass]="{'add-padding': showFilter}">
								<ng-container *ngTemplateOutlet="vehCards context: { canEdit: true}"></ng-container>
							</div>

							<mat-paginator class="pagination" aria-label="Select page" [pageSize]="pagination.size"
								*ngIf="!mapView" [length]="pagination.count" [pageIndex]="pagination.offset"
								(page)="pageEvents($event)"></mat-paginator>
						</div>
						<div *ngIf="dataSource?.data?.length === 0" class="no-data-wrapper">
							<mat-card class="no-data-card">
								<p class="no-data-text">No Products found.</p>
							</mat-card>
						</div>
					</mat-sidenav-content>
				</mat-sidenav-container>
			</ng-container>
		</div>
		<div rightPane class="h-100">
			<ng-container *ngIf="mapLabels">
				<trent-map-wrapper [mLabels]="mapLabels"></trent-map-wrapper>
			</ng-container>
		</div>
	</trent-slide-panel>
</ng-container>

<div *ngIf="!isHandset && !mapView" class="list-container-desktop">
	<ng-container>
		<div class="table-area">
			<div class="table_header">
				<div>
					<mat-form-field style="margin-bottom: -1.25em">
						<mat-label>Filter by Vin, Make, Model, Unit no.</mat-label>
						<input type="text" autocomplete="off" style="display:none">
						<!-- Auto complete off in browser in load the page-->
						<input type="text" autocomplete="off" [(ngModel)]="filterText" matInput
							(keyup)="applyFilter($event.target.value)" #dtopFilterCtrl>
						<mat-icon matSuffix *ngIf="dtopFilterCtrl.value"
							(click)="resetDatasourceFilters();">close</mat-icon>
					</mat-form-field>
				</div>
				<div class="pos-fixed"> </div>
			</div>
			<div class="table" *ngIf="(dataSource?.data?.length > 0) && (displayedColumns.length > 0)">
				<ng-container>
					<table mat-table [dataSource]="dataSource">
						<!-- Picture Column -->
						<ng-container matColumnDef="pic" *ngIf="isSalesOperation && !salesUploadPic">
							<th mat-header-cell *matHeaderCellDef> PIC </th>
							<td mat-cell *matCellDef="let element">
								<mat-icon [matBadgeHidden]="!element?.pictures" matBadge={{element?.pictures?.length}}
									matBadgeColor="warn">image</mat-icon>
							</td>
						</ng-container>

						<!-- Name Column -->
						<ng-container matColumnDef="vin">
							<th mat-header-cell *matHeaderCellDef> VIN </th>
							<td mat-cell *matCellDef="let element">
								<div class="icon-wrapper">
									{{element?.vin}}
									<mat-icon *ngIf="!!element?.internalComment && !isYardPerson"
										[matTooltip]="element?.internalComment">info</mat-icon>
								</div>
							</td>
						</ng-container>

						<!-- Unit No Column -->
						<ng-container matColumnDef="unit">
							<th mat-header-cell *matHeaderCellDef> Unit Number </th>
							<td mat-cell *matCellDef="let element">
								{{element?.unitName}}
							</td>
						</ng-container>

						<!-- Model Column -->
						<ng-container matColumnDef="model">
							<th mat-header-cell *matHeaderCellDef> Model </th>
							<td mat-cell *matCellDef="let element">
								{{element?.model}}
							</td>
						</ng-container>

						<!-- Make Column -->
						<ng-container matColumnDef="make">
							<th mat-header-cell *matHeaderCellDef> Make </th>
							<td mat-cell *matCellDef="let element">
								{{element?.make}} {{element?.modelYear}}
							</td>
						</ng-container>

						<!-- Location Column -->
						<ng-container matColumnDef="location">
							<th mat-header-cell *matHeaderCellDef>Inventory Location </th>
							<td mat-cell *matCellDef="let element">
								{{element.physicalLocationSummary?.name}}</td>
						</ng-container>

						<!-- Lot Column -->
						<ng-container matColumnDef="lot">
							<th mat-header-cell *matHeaderCellDef style="width: 10% !important">Lot </th>
							<td mat-cell *matCellDef="let element" style="width: 10% !important">
								<div class="edit-lot">
									<span>{{element?.unitLot || 'NA'}}</span>
									<button *ngIf="isYardPerson || salesUploadPic" [disabled]="!element?.isActive"
										mat-icon-button color="" (click)="editLot(element)">
										<mat-icon aria-label="Edit">edit</mat-icon>
									</button>
								</div>

							</td>
						</ng-container>

						<!-- Odometer Column -->
						<ng-container matColumnDef="odometer">
							<th mat-header-cell *matHeaderCellDef> Odometer </th>
							<td mat-cell *matCellDef="let element">
								{{element?.odometer}} {{element?.odometerUnits}}
							</td>
						</ng-container>

						<!-- Last Updated By Column -->
						<ng-container matColumnDef="lastUpdatedBy" *ngIf="isSalesOperation && !salesUploadPic">
							<th mat-header-cell *matHeaderCellDef> Last Updated By</th>
							<td mat-cell *matCellDef="let element">
								{{element?.pictureLastUpdateUserDetails?.displayName}}
							</td>
						</ng-container>

						<!-- Actions -->
						<ng-container matColumnDef="actions">
							<th mat-header-cell *matHeaderCellDef>
								<div class="d-flex align-items-center">
									Actions
									<button mat-icon-button color="primary" (click)="addUnit()"
										*ngIf="!isYardPerson && !salesUploadPic">
										<mat-icon>add_circle</mat-icon>
									</button>
								</div>
							</th>
							<td mat-cell *matCellDef="let element; let i=index;">
								<div style="gap: 5px" class="d-flex d-a-center">
									<!-- Edit Pictures -->
									<button *ngIf="(!isSalesOperation && isYardPerson) || salesUploadPic"
										[disabled]="!element?.isActive" mat-icon-button color="primary"
										(click)="edit(element.id, 1)">
										<mat-icon aria-label="Edit" [matBadgeHidden]="!element.pictures"
											matBadge={{element.pictures?.length}}
											matBadgeColor="warn">camera_enhance</mat-icon>
									</button>
									<button mat-icon-button color="primary" (click)="showOnMap(element)"
										[disabled]="gpsIconDisable(element)">
										<mat-icon aria-label="Edit">gps_fixed</mat-icon>
									</button>

									<!-- Edit Unit -->
									<button *ngIf="!isYardPerson && !salesUploadPic" [disabled]="!element?.isActive"
										mat-icon-button color="primary" (click)="edit(element.id, 2)">
										<mat-icon aria-label="Edit">edit</mat-icon>
									</button>
									<!-- Setup Rental -->
									<button mat-button color="primary" (click)="rentMe(element.id)"
										*ngIf=" !(!element?.isActive || isCreditUser || isSalesOperation) && !!element.isRentable && vCid === element.cid && !salesUploadPic">Setup
										Rental
									</button>
									<!-- Assign Unit -->
									<button
										*ngIf="!(isSalesOperation || isYardPerson) && !!element.isRentable && !salesUploadPic"
										mat-button color="primary" [disabled]="!element?.isActive"
										(click)="assign(element.id)">Assign
									</button>
									<!-- Open Rentals -->
									<button mat-button color="primary" (click)="openRentals(element.id)"
										*ngIf="!(isSalesOperation || !element?.isActive ) && !!element.isRentable && !isYardPerson && !salesUploadPic">Open
										Rentals
									</button>
									<!-- Active/Deactivate -->
									<button mat-button color="primary" *ngIf="!isCreditUser && vCid && !salesUploadPic"
										(click)="activeDeactivateProduct(element.id, element.isActive, i)">
										{{ element.isActive ? 'Inactive' : 'Active' }}
									</button>
									<!-- Create/Update Sales Options -->
									<button *ngIf="isSalesOperation && !salesUploadPic" [disabled]="!element.isActive"
										mat-button type="button" color="primary"
										(click)="setupSales(element.id, element?.salesOptionId)">
										Setup Sales
									</button>
									<div class="transfer-button"
										*ngIf="isSalesOperation && !salesUploadPic && element.isActive">
										<!-- Transfer -->
										<span [matTooltip]="getToolTipInfo(element.id)" matTooltipPosition="above">
											<button mat-button
												[color]="!!getToolTipInfo(element.id)?'primary':'warning'"
												(click)="transfer(element.id)">
												{{ !!getToolTipInfo(element.id) ? 'In Transit' : 'Transfer'}}
											</button>
										</span>
									</div>
								</div>
							</td>
						</ng-container>
						<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
						<tr mat-row [ngClass]="{'make-gold': row.isActive === 'true', 'disabled-row': !row.isActive }"
							*matRowDef="let row; columns: displayedColumns;">
						</tr>
					</table>
				</ng-container>
			</div>
			<mat-paginator class="pagination" aria-label="Select page" [pageSize]="pagination.size"
				[length]="pagination.count" [pageIndex]="pagination.offset" (page)="pageEvents($event)"></mat-paginator>
		</div>
	</ng-container>
</div>
<div *ngIf="!isHandset && (dataSource?.data?.length === 0)" class="no-data-wrapper">
	<mat-card>
		<mat-card-content class="no-data-card">
			<p class="no-data-text">No products found</p>
			<button mat-icon-button color="primary" (click)="addUnit()" *ngIf="!isYardPerson && !salesUploadPic">
				<mat-icon>add_circle</mat-icon>
			</button>
		</mat-card-content>
	</mat-card>
</div>


<ng-template #vehCards let-canEdit="canEdit">
	<ng-container *ngIf="!mapView">
		<ng-container *ngFor="let d of dataSource.filteredData">
			<ng-container *ngTemplateOutlet="cancelClusterFilter"></ng-container>
			<ng-container *ngTemplateOutlet="vCard context:{ d:d }"></ng-container>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="mapView && selectedProduct">
		<ng-container *ngIf="!isHandset">
			<button type="button" color="primary" mat-flat-button matSuffix (click)="backToList()">
				<mat-icon>arrow_back</mat-icon>Back
			</button>
		</ng-container>
		<ng-container *ngTemplateOutlet="vCard context:{ d:selectedProduct }"></ng-container>
	</ng-container>
</ng-template>

<ng-template #vCard let-d="d">
	<mat-card appearance="outlined" class="card-div">
		<div class="card-list">
			<ng-container *ngIf="!!d.vin">
				<h6> Vin </h6>
				<div class="icon-wrapper">
					{{d?.vin}}
					<mat-icon *ngIf="!!d?.internalComment && !isYardPerson"
						[matTooltip]="d?.internalComment">info</mat-icon>
				</div>
				<div class="divider">
					<mat-divider></mat-divider>
				</div>
			</ng-container>
			<ng-container *ngIf="!isSalesOperation">
				<h6>Unit Number </h6>
				<div> {{d?.unitName}} </div>
				<div class="divider">
					<mat-divider></mat-divider>
				</div>
			</ng-container>
			<h6>Make</h6>
			<div> {{d?.make}} {{d?.modelYear}}</div>
			<div class="divider">
				<mat-divider></mat-divider>
			</div>

			<h6>Odometer</h6>
			<div>{{d?.odometer}} {{d?.odometerUnits}}</div>
			<div class="divider">
				<mat-divider></mat-divider>
			</div>
			<ng-container *ngIf="isSalesOperation && !salesUploadPic">
				<h6>Last Updated By</h6>
				<div>{{d?.pictureLastUpdateUserDetails?.displayName}}</div>
				<div class="divider">
					<mat-divider></mat-divider>
				</div>
			</ng-container>
			<ng-container>
				<h6>Inventory Location</h6>
				<div> {{d?.physicalLocationSummary?.name}} </div>
				<div class="divider">
					<mat-divider></mat-divider>
				</div>
			</ng-container>
			<ng-container>
				<h6>Lot</h6>
				<div class="icon-wrapper">
					{{d?.unitLot || 'NA'}}
					<button *ngIf="isYardPerson || salesUploadPic" [disabled]="!d?.isActive" mat-icon-button color=""
						(click)="editLot(d)">
						<mat-icon aria-label="Edit">edit</mat-icon>
					</button>
				</div>
				<div class="divider">
					<mat-divider></mat-divider>
				</div>
			</ng-container>
		</div>
		<div class="list-action-bottom">
			<!-- Picture Count -->
			<mat-icon class="pic-count" *ngIf="isSalesOperation && !salesUploadPic" [matBadgeHidden]="!d.pictures"
				matBadge={{d?.pictures?.length}} matBadgeColor="warn">image</mat-icon>
			<!-- Edit Pictures -->
			<button *ngIf="(!isSalesOperation && isYardPerson) || salesUploadPic" [disabled]="!d.isActive"
				mat-icon-button color="primary" (click)="edit(d.id, 1)">
				<mat-icon aria-label="Edit" [matBadgeHidden]="!d?.pictures" matBadge={{d?.pictures?.length}}
					matBadgeColor="warn">camera_enhance</mat-icon>
			</button>
			<!-- Setup Rental -->
			<button mat-button color="primary" (click)="rentMe(d.id)"
				*ngIf=" !(!d.isActive || isCreditUser || isSalesOperation) && !!d.isRentable && vCid === d.cid &&  !salesUploadPic">Setup
				Rental
			</button>
			<!-- Assign Unit -->
			<button *ngIf="!(isSalesOperation || isYardPerson) && !!d.isRentable && !salesUploadPic" mat-button
				color="primary" [disabled]="!d.isActive" (click)="assign(d.id)">Assign
			</button>
			<!-- Open Rentals -->
			<button mat-button color="primary" (click)="openRentals(d.key)"
				*ngIf="!(isSalesOperation || !d.isActive ) && !!d.isRentable && !isYardPerson && !salesUploadPic">Open
				Rentals
			</button>
			<!-- Active/Deactivate -->
			<button mat-button color="primary" *ngIf="!isCreditUser && vCid"
				(click)="activeDeactivateProduct(d.id, d.isActive)">{{ d.isActive ? 'Inactive' : 'Active' }}
			</button>

		</div>
		<div class="list-action-bottom">

			<div class="ml-auto d-flex d-a-center">
				<!-- Create/Update Sales Options -->
				<button *ngIf="isSalesOperation && !salesUploadPic" [disabled]="!d.isActive" mat-button type="button"
					color="primary" (click)="setupSales(d.id, d?.salesOptionId)">
					Setup Sales
				</button>

				<!-- Transfer -->
				<span [matTooltip]="getToolTipInfo(d?.transfers)" matTooltipPosition="above"
					*ngIf="isSalesOperation && !salesUploadPic && d.isActive">
					<button mat-button color="primary" (click)="transfer(d.id)">
						Transfer
					</button>
				</span>
				<!-- GPS -->
				<button mat-icon-button color="primary" (click)="showOnMap(d)" [disabled]="gpsIconDisable(d)">
					<mat-icon aria-label="Edit">gps_fixed</mat-icon>
				</button>
				<!-- Edit Unit -->
				<button *ngIf="!isYardPerson && !salesUploadPic" [disabled]="!d?.isActive" mat-icon-button
					color="primary" (click)="edit(d.id, 2)">
					<mat-icon aria-label="Edit">edit</mat-icon>
				</button>
			</div>
		</div>
	</mat-card>
</ng-template>

<div class="add-fixed-mobile" *ngIf="!!isHandset && !isYardPerson && !mapView">
	<button mat-mini-fab color="primary" type="button" (click)="addUnit()">
		<mat-icon>add
		</mat-icon>
	</button>
</div>