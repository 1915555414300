import { UtilityService } from '@trent/services/utility.service';
import { cleanListeners } from '@trent/models/utility';
import { getMenuData } from './menu-data';
import { EventService, IEventListener } from '@trent/services/event.service';
import { Component, OnDestroy, OnInit, ViewChild, NgZone } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';
import { ScrollDispatcher, CdkScrollable } from '@angular/cdk/scrolling';
import { MenuData } from '@trent/models/UI/menu-data';
import { Router } from '@angular/router';
import { IAuthCoreService } from '@trent/services/auth/iauth-core.service';
import { Store } from '@ngxs/store';
import { RouterState } from '@trent/store/root-store/router.state';
import { CmsTogglePageEdit } from '@trent/store/cms-store';
import { AuthState } from '@trent/store/auth-store/auth.state';
import { ICompanyRoleProfile } from '@trent/models/user/role-mapping';
import { IDefaultCompanyData } from '@trent/models/user/company-user-profile';
import { Platform } from '@ionic/angular';
import { logger } from '@trent/models/log/logger';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { PageHtmlTemplate } from '@trent/models/cms/page-html';
import { Location } from '@angular/common';
import { CompanyRequested, CompanyState } from '@trent/store/company-store';
import { CompanyStatus } from '@trent/models/company/company-status';
@Component({
  selector: 'trent-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, OnDestroy {
  // pageTemplate: PageHtmlTemplate;
  pQueryParam; // = {...this.rb.queryParams}; 
  htmlId: string;
  nTasks = 0;
  uid: string;
  dComp: IDefaultCompanyData;
  cRoleProfile: ICompanyRoleProfile;
  isMultipleComp: boolean;
  subTitle: string;
  customH: string;
  isFixViewPort: boolean;
  gps: { show: boolean; status: boolean; };
  hideNavBar:boolean = true;

  get url() {
    return this.router.url.toLocaleLowerCase();
  }
  get isRoot() { return this.router.url.split('?')[0] === '/'; }

  get showBg() { return this.isRoot; }

  get showLanding() { return this.router.url.split('?')[0] === '/landing-page'; }
  // settingComponent: any;
  // @ViewChild('settingDrawer', { static: false })
  // settingDrawer: MatDrawer;

  constructor(
    public as: IAuthCoreService,
    // public afAuth: AngularFireAuth,
    private breakpointObserver: BreakpointObserver,
    private scrollDispatcher: ScrollDispatcher,
    private ngZone: NgZone,
    private es: EventService,
    private router: Router,
    private store: Store,
    private us: UtilityService,
    private platform: Platform,
    private matIconRegistry: MatIconRegistry, 
    private domSanitizer: DomSanitizer,
    location: Location,
  ) {
    this.isHandset$ = this.us.isHandset$;
    this.layoutChanges = this.us.layoutChanged$;
    // setTimeout(() => {
    //   console.log('URL IS: ', this.router.url);
    //   console.log('URL Is ROOT: ', this.isRoot);
    // }, 3000);

    this.matIconRegistry.addSvgIcon('truckIcon', this.domSanitizer.bypassSecurityTrustResourceUrl(this.us.getFullUrl('assets/truck-semi.svg')));
    this.matIconRegistry.addSvgIcon('trailerIcon', this.domSanitizer.bypassSecurityTrustResourceUrl(this.us.getFullUrl('assets/dry_van.svg')));
    router.events.subscribe((val) => {
      if (location.path() != '') {
        this.hideNavBar = location.path().includes('/get-info-page/') ? false : true;
      }
    });

  }
  isAuth = false;
  isAdmin = false;
  isSales = false;
  isCredit = false;
  isLeasingSales = false; // PT - added for leasing sales

  displayName = '';
  isEditor: boolean;

  title: string; // = '??-sub title';
  isLeftVisible = true;
  menuFilt: MenuData[] = [];
  subMenuFilt: MenuData[] = [];
  menu: MenuData[] = getMenuData();

  public eventListners: IEventListener[] = [];

  subMenuData: MenuData[];

  sub: Subscription[] = [];

  private readonly SHRINK_TOP_SCROLL_POSITION = 50;
  shrinkToolbar = false;

  @ViewChild('drawer', { static: false })
  drawer: MatSidenav;

  isHandset$: Observable<boolean>;

  layoutChanges: Observable<BreakpointState>;

  isLandscape = false;

  isHandset = false;
  /** effective show/hide of back button. */
  showBackBtn = false;
  /** custom handeling of back button by a given page actions. */
  private showBackBtnCustom = false;
  /** back button flag from the router state of store. */
  private showBackBtnRouter = false;

  // backBtnClickFn: (param) => Promise<boolean>;
  // backBtnClickCheckFn: (param) => Promise<boolean>;


  public showSaveCancel = false;
  public showEdit = false;
  public showNextCancel = false;
  public showSearch = false;
  public showNotification = false;



  ngOnInit(): void {
    // if (this.us.isPlatformServer) {
    //   this.initMenu();
    //   return;
    // }

    this.dComp = null;
    

    // temp using auth.
    this.sub.push(
      this.as.user$.subscribe((x: any) => {
        if (!!x) {
          this.isAuth = true;
          this.displayName = x.displayName;
          this.dComp = null;
          this.isMultipleComp = false;
          this.getdefaultCompany();
        } else {
          this.isAuth = false;
          this.displayName = '';
        }
        this.initMenu();
      })
    );

    this.sub.push(
      this.as.isAdmin$.subscribe(x => {
        this.isAdmin = x;
        this.initMenu();
      })
    );
    this.sub.push(
      this.as.isEditor$.subscribe(x => {
        this.isEditor = x;
        this.initMenu();
      })
    );
      /// Here checking person is sales/ credit person
    this.sub.push(
      this.store.select(AuthState.user).subscribe(u => {
        this.isSales = u?.roles?.sales ? true : false;
        this.isCredit = u?.roles?.credit ? true : false;
        this.isLeasingSales = u?.roles?.leasingSales ? true : false; //PT- setting the boolean value according to the role
        this.initMenu();
      })
    );


    // router state managed back button
    this.sub.push(
      this.store.select(RouterState.canGoBack)
        .subscribe(flag => {
          this.showBackBtnRouter = flag;
          this.updateShowHideBackbutton();
        })
    );

    // back button dispaly request from page
    this.eventListners.push(
      this.es.listen<boolean>(this.es.showHidePageBackBtn, (flag) => {
        this.showBackBtnCustom = flag;
        logger.log(`[Shell] Goback-cust: ${flag}`);
        this.updateShowHideBackbutton();
      })
    );

    // listen to the task counts
    this.eventListners.push(
      this.es.listen<number>(this.es.loadTaskNumbers, (n) => {
        if (n > this.nTasks && !this.showNotification) {
          this.showNotification = true;
          setTimeout(() => {
            this.showNotification = false;
          }, 3000);
        }
        this.nTasks = n; logger.log('nTasks', this.nTasks);

      })
    );

    // Listens to show Save/Cancel buttons on the menu.
    this.eventListners.push(
      this.es.listen<boolean>(this.es.menuShowSaveCancel, (show) => this.showSaveCancel = show)
    );
    // Listens to show Edit buttons on the menu.
    this.eventListners.push(
      this.es.listen<boolean>(this.es.menuShowEdit, (show) => this.showEdit = show)
    );
    // Listens to show Next/Cancel buttons on the menu.
    this.eventListners.push(
      this.es.listen<boolean>(this.es.menuShowNextCancel, (show) => this.showNextCancel = show)
    );
    // Listens to show search buttons on the menu.
    this.eventListners.push(
      this.es.listen<boolean>(this.es.menuShowSearch, (show) => this.showSearch = show)
    );
    // Listens to show search buttons on the menu.
    this.eventListners.push(
      this.es.listen<{ show: boolean, status: boolean }>(this.es.menuShowGPSOn, (gps) => this.gps = gps)
    );

    /** Show the HTML id for cms to edit. */
    this.eventListners.push(
      this.es.listen<PageHtmlTemplate>(this.es.menuShowHtmlEdit, (t) => {
        this.htmlId = t.pid;
        this.pQueryParam = {...this.rb.queryParams, ...t};
        // delete (this.pQueryParam as any)['id'];
      })
    );



    // Update the menu.
    this.initMenu();
    this.eventListners.push(this.es.listen<MenuData[]>(this.es.loadPageOptions,
      data => (this.subMenuData = data)));
    this.sub.push(this.isHandset$.subscribe(x => { this.isHandset = x; }));
    this.sub.push(this.layoutChanges.subscribe(x => {
      // logger.log('[Shell] Orientation Changed: ' + JSON.stringify(x));
      // logger.log('[Shell] Mobile Landscape: ' + x.breakpoints[Breakpoints.HandsetLandscape]);
      // logger.log('[Shell] Tablet Landscape: ' + x.breakpoints[Breakpoints.TabletLandscape]);
      this.isLandscape = x.breakpoints[Breakpoints.HandsetLandscape] || x.breakpoints[Breakpoints.TabletLandscape];
    }));

    // show hide back button
    // this.eventListners.push(
    //   this.es.listen<BackBtnData>(this.es.showHideBackBtn, data => {
    //     if (data.show) {
    //       this.showBackBtn = true;
    //     }
    //     this.backBtnClickFn = data.click;
    //   })
    // );

    // set title
    this.eventListners.push(this.es.listen<string>(this.es.app_setTitle, (title) => this.title = title));
    this.eventListners.push(this.es.listen<string>(this.es.app_setSubTitle, (subTitle) => this.subTitle = subTitle));

    // Obserable to control the shrinking of the top header bar.
    this.scrollDispatcher
      .scrolled()
      .pipe(map((event: CdkScrollable) => event.getElementRef().nativeElement.scrollTop))
      .subscribe(scrollTop => this.ngZone.run(() => (this.shrinkToolbar = scrollTop > this.SHRINK_TOP_SCROLL_POSITION ? true : false)));
    this.getWindowSize();
    this.getFixViewPort();
  }


  ngOnDestroy(): void {
    cleanListeners(this.eventListners, this.sub);
  }

  private updateShowHideBackbutton() {
    this.showBackBtn = this.showBackBtnCustom || this.showBackBtnRouter;
  }

  handleClose() {
    // only close in the handset mode.
    if (this.isHandset) {
      this.drawer.close();
    }
  }

  async handleBackBtn() {
    if (this.showBackBtnCustom) {
      this.es.emit<any>(this.es.menuBackBtnClickCust, null);
    } else {
      // Go back to the history.
      // @param router to be used for changing the url from history.
      // Note: In past, it was used to be optional and router in the router State was used.
      // that was throwing a warning. So now, routing is handled right in the base class and 
      // state is just used to update/keep the history.
      const url = this.store.selectSnapshot(RouterState.navigateBackUrl); //  =>
      if (!!url) {
        this.router.navigateByUrl(url);
      }
    }
  }

  save() { this.es.emit<boolean>(this.es.menuFireSaveCancel, true); }
  cancel() { this.es.emit<boolean>(this.es.menuFireSaveCancel, false); }
  edit() { this.es.emit<boolean>(this.es.menuFireEdit, true); }
  next() { this.es.emit<boolean>(this.es.menuFireNextCancel, true); }
  search() { this.es.emit<boolean>(this.es.menuFireSearch, true); }
  async logout() {
    this.handleClose();
    await this.as.logout();
    this.router.navigate(['/']);
  }

  public getFilterArray(m: MenuData[]) {
    let o: MenuData[] = [];
    if (!this.isAuth) {
      o = m.filter(a => a.auth === 'anonymous');
    } else {
      if (this.isAdmin) {
        o = m.filter(a => a.auth !== 'sales').filter(a =>a.auth !== 'credit');  
      } else if (this.isEditor) {
        o = m.filter(a => (a.auth === 'anonymous' || a.auth === 'auth' || a.auth === 'editor' || a.auth === 'account' || a.auth === 'order' || a.auth === 'addCompany'));
      } else if (this.isCredit && this.isSales) {
        o = m.filter(a => a.auth === 'anonymous' || a.auth === 'credit' || a.auth === 'sales' || a.auth === 'account');
      } else if (this.isSales) {
        o = m.filter(a => a.auth === 'anonymous' || a.auth === 'sales' || a.auth === 'account' || a.auth === 'addCompany' || a.auth === 'auth');
      } else if (this.isCredit) {
        o = m.filter(a => a.auth === 'anonymous' || a.auth === 'credit' || a.auth === 'account');
      }else if(this.isLeasingSales){ //PT - to filter the menu, if leasing sales is there
        o = m.filter(a => a.auth === 'anonymous' || a.auth === 'leasingSales');
      }else {
        // authenticated.
        o = m.filter(a => (a.auth === 'anonymous' || a.auth === 'auth' || a.auth === 'account' || a.auth === 'order' || a.auth === 'addCompany' ));  // && this.router.url !== a.url
      }
    }

      //PT - to add the cid in trailer and truck. So, we can overcome the page refresh issue
      o?.forEach(obj => {
        if((obj.title === 'Trailers' || obj.title === 'Trucks') &&  !!this.dComp && obj?.url?.split('/')?.length < 5){
          // if(this.dComp.cid !== obj?.url?.split('/')[4]){
            obj.url = `${obj.url}/${this.dComp.cid}`;
          // }
          
          if(obj?.url?.split('/')?.length > 3 ){
            const urlParts = obj.url.split('/').slice(0, 4);
            urlParts[3] = this.dComp.cid;
            obj.url = urlParts.join('/');
          }
        }
      });

    return o;
  }

  initMenu() {
    this.menuFilt = this.getFilterArray(this.menu);
    this.store.select(AuthState.user).subscribe(u => {
      if(!u) return;
      // if(!!u?.setting?.defaultCompany?.cid) this.dComp = u.setting?.defaultCompany;
      if(!u.roles?.credit) this.menuFilt = this.menuFilt.filter(m => m.title !== 'Credit');
      if(!!u.roles?.credit || !!u.roles?.sales) this.menuFilt = this.menuFilt.filter(m =>  m.title !== 'Vendor Portal').filter(m =>  m.title !== 'Customer Portal');
      if(!u.roles?.sales) this.menuFilt = this.menuFilt.filter(m => m.title !== 'Sales');
      if(!!u.roles?.leasingSales) this.menuFilt = this.menuFilt.filter(m => m.title !== 'Vendor Portal').filter(m =>  m.title !== 'Customer Portal'); //PT - filtering this as we don't want to display vendor and customer portal options for leasing sales
      if(!u.roles?.leasingSales) this.menuFilt = this.menuFilt.filter(m => m.title !== 'Leasing Sales Portal');
    });
  }

  buldSubMenu(m: MenuData[]) {
    this.subMenuFilt = this.getFilterArray(m);
    setTimeout(() => {
      this.isLeftVisible = false;
    }, 100);
  }

  hideMenu() {
    this.isLeftVisible = !this.isLeftVisible;
  }

  navigateBack() {
    this.isLeftVisible = !this.isLeftVisible;
  }

  /** Used in template binding */
  getLinkObj(x) {
    return { x };
  }

  togglePageEdit() {
    this.store.dispatch(new CmsTogglePageEdit());
    logger.log('toggle edit mode was called!');
    this.handleClose();
    return false;
  }

  /** Get an object to be passed to router query param, rb => record back button navigation. */
  get rb() { return { queryParams: { rb: true } }; }

  /** Alert showing managment. */
  getBadgeColor() {
    const isUrlTask = this.router.url.includes('/task') ? true : false;
    return this.showNotification ? 'warn' : !isUrlTask ? 'accent' : null;
  }
  getdefaultCompany() {
    // this.store.dispatch(new UserByIdRequested({ id: this.uid }));
    this.sub.push(this.store.select(AuthState.user)
      .subscribe(u => {
        if (u) {
          logger.log('[shell] user', u);
          this.dComp = u.setting.defaultCompany;
          if (!!this.dComp) {
            this.checkUserStatus();
          }
          if (!!u.cRolesAll && Object.keys(u.cRolesAll).length > 1) {
            this.isMultipleComp = true;
          }
        }
      }));
  }

  /**
   * @author - PT
   * purpose - to check if the user is vendor or customer. So, we can show/hide vendor/customer portal accordingly
   */
  checkUserStatus() {
    this.store.dispatch(new CompanyRequested({ id: `${this.dComp.cid}` }));
    // Also dispatch root as required.
    const comp$ = this.store.select(CompanyState.selectCompanyById)
      .pipe(map(filterFn => filterFn(this.dComp.cid)));
    this.sub.push(comp$.subscribe(_data => {
      if (!!_data) {
        if (_data.lessorStatus > CompanyStatus.notApproved) {
          this.menuFilt = this.menuFilt.filter(m => m.title !== 'Customer Portal'); //Will hide Customer Portal from Vendor
        } else if (_data.lesseeStatus > CompanyStatus.notApproved) {
          this.menuFilt = this.menuFilt.filter(m => m.title !== 'Vendor Portal');  //Will hide Vendor Portal from Customer
        }
      }

    }));
  }
  toAccount() {
    this.router.navigate(['account']);
  }
  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  getWindowSize() {
    if (this.us.isPlatformBrowser) {

      // We listen to the resize event
      // window.addEventListener('resize', () => {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      const vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      // });
    }
  }
  getFixViewPort() {
    logger.log(`[Shell] this.platform.is('cordova'): ${this.platform.is('cordova')}`);
    logger.log(`[Shell] this.platform.is('android'): ${this.platform.is('android')}`);
    if (this.platform.is('cordova') || this.platform.is('android')) {
      this.isFixViewPort = true;
      logger.log(`[Shell] isFixViewPort: ${this.isFixViewPort}`);
    } else {
      this.isFixViewPort = false;
      logger.log(`[Shell] isFixViewPort: ${this.isFixViewPort}`);
    }

  }

}
