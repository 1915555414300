import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BasePage } from '@trent/models/UI/base.page';
import { DbStatus } from '@trent/models/base';
import { SalesOptionParam, SalesProductBase, SalesStatus, parseSalesProductArray, salesOptionParamInit } from '@trent/models/sales-option';
import { isEqual, sortBy, startCase } from 'lodash';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { EventService } from '@trent/services/event.service';
import { UtilityService } from '@trent/services/utility.service';
import { FetchSalesOptionsUsingAlgolia, SalesOptionState, SalesOptionStateReset } from '@trent/store/sales-option-store';
import { Observable, debounceTime, map } from 'rxjs';
import { toSentence } from '@trent/models/utility';
import { EnumHelper } from '@trent/models/utility/enum-helper';
import { Paging } from '@trent/models/observable-util/paging';
import { FormControl } from '@angular/forms';
import { logger } from '@trent/models/log/logger';
import { SalesOptionsSearchDialogComponent } from '../../../shared/pts-search-sales-option/sales-options-search-dialog/sales-options-search-dialog.component';
import { MenuData, PaneType } from '@trent/models/UI/menu-data';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { CSVHeader } from '../../../admin/sale-option-list/sale-option-header-list';
import { Role, RoleEnum } from '@trent/models/user';
import { makeCSVHeader } from '@trent/models/promo';
import { FetchProductsUsingAlgolia, ProductState } from '@trent/store/product-store';
import { MapLabel } from '@trent/models/map/map-label';
import { getMapLabels } from '@trent/models/store-location/store-location-helper';
import { GpsService } from '@trent/services/gps.service';
import { InventoryTransfer, LocationType, TransferStatus } from '@trent/models/product/inventory-transfer';
import { GpsType } from '@trent/models/product';
import { SalesOptionService } from '@trent/services/sales.service';
import { AssetLocService } from '@trent/services/asset-loc.service';
import { UserSearchType } from '../../../shared/pts-search-sales-option/pts-search-sales-option.component';
import { PriceLogComponent } from '../details/price-log/price-log.component';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@trent/core/environments/environment';

@Component({
	selector: 'trent-pts-sales-options-list',
	templateUrl: './sales-options-list.component.html',
	styleUrls: ['./sales-options-list.component.scss']
})
export class SalesOptionsListComponent extends BasePage<SalesProductBase[]> implements OnInit, OnDestroy {

	isHandset$: Observable<boolean>;
	isHandset: boolean;
	showFilter: boolean;
	displayedColumns: string[] = ['vin', 'make', 'model', 'storeLocation', 'odometer', 'wholesalePrice', 'retailPrice', 'age', 'status', 'actions'];
	dataSource: MatTableDataSource<{}>;
	sParam: SalesOptionParam;
	initialParams: SalesOptionParam = salesOptionParamInit();
	pagination: Paging = { size: 10, offset: 0, full: false, count: 0 };
	searchText = new FormControl('');
	menuData: MenuData[] = [];
	csvGridData = [];
	marketingPartner = [
		{ id: 'truck_paper', name: 'Truck Paper' },
		{ id: 'auto_trader', name: 'Auto trader' }
	];
	salesList = [];
	salesStatus = EnumHelper.getNamesAndValues(SalesStatus);
	salesStatusEnum = SalesStatus;
	products = [];
	mapView = false;
	activePane: PaneType = 'left';
	isLeftVisible = true;
	mapLabels: MapLabel[];
	userRole: Role;
	roleEnum = RoleEnum;
	gpsPointerArray = [];
	gpsAssetArray = [];
	selectedProduct;
	assignedStoreLocationId: string;
	dialogRef: MatDialogRef<any>;
	websiteId: string;

	constructor(
		store: Store,
		ds: DialogService,
		es: EventService,
		private router: Router,
		public us: UtilityService,
		public dialog: MatDialog,
		private currencyPipe: CurrencyPipe,
		public gpsService: GpsService,
		private als: AssetLocService,
		private datePipe: DatePipe,
		private aroute: ActivatedRoute,
		private salesService: SalesOptionService,
	) {
		super(store, ds, es);
		this.title = 'Sales Options';
	}

	ngOnDestroy(): void {
		this.cleanListeners();
		this.store.dispatch(new SalesOptionStateReset());
	}

	ngOnInit() {
		this.websiteId = environment.webSite.id;
		this.isHandset$ = this.us.isHandset$;
		this.sParam = { ...this.initialParams };
		this.mSubscription.push(this.us.isHandset$.subscribe(r => this.isHandset = r));
		this.mSubscription.push(this.user$.subscribe(u => {
			if (!!u) {
				this.userRole = u?.roles;
				this.assignedStoreLocationId = u?.assignedStoreLocations[0]?.storeLocationId;
				if (!!this.assignedStoreLocationId) this.sParam.sid = this.assignedStoreLocationId;
				this.initialize();
			}
		}));
		this.subMData();
		this.getProducts();
		this.searchText.valueChanges
			.pipe(debounceTime(1000))
			.subscribe((res) => {
				this.sParam = { ...this.sParam, searchText: res, };
				this.pagination = { size: 10, offset: 0, full: false, count: 0 };
				this.loadDataUsingAlgolia();
			});
	}

	initialize() {
		this.loadDataUsingAlgolia();
		this.gpsService.getAllVehicleFromGps(GpsType.pointer).then(p => {
			this.gpsPointerArray = p.data;
		}).catch(e => logger.log(e));
	}

	/**
	 * @author - Cm
	 * @purpose - gps provider and vin fetch realtime location show on map
	 */
	showOnMap(d) {
		this.mapLabels = [];
		this.selectedProduct = null;
		// Cm - gps from pointer 
		if (d?.productSummary.gpsProvider == GpsType.pointer) {
			this.gpsPointerArray.forEach((g) => {
				if (g.licensePlate == d?.productSummary.vin) {
					this.selectedProduct = d;
					this.mapLabels = getMapLabels([g]);
				}
			});
		}
		if (this.mapLabels && this.selectedProduct) {
			this.es.emit<MapLabel[]>('showMapLabels', this.mapLabels);
			this.mapView = true;
			if (this.isHandset) {
				this.subMData();
				this.showLeft();
			}
		}
	}

	gpsIconDisable(d) {
		return (this.websiteId == 'pts' && d?.productSummary.gpsProvider === GpsType.pointer) ? false : true;
	}

	getAssetLocation() {
		const d: { unitNames: string[], cid: string } = {
			unitNames: [],
			cid: ''
		};
		this.als.getLoc(d).toPromise()
			.then(r => {
				this.gpsAssetArray = r;
			})
			.catch(err => {
				logger.error('[Sales-option-list] gps location error', err);
			});
	}

	backToList() {
		this.mapView = false;
		this.selectedProduct = null;
		this.getProducts();
	}

	showLeft() {
		this.isLeftVisible = !this.isLeftVisible;
		this.activePane = this.isLeftVisible ? 'left' : 'right';
		this.isGoBackCust = !this.isLeftVisible;
	}

	/**
	 * @author - PT
	 * @pupose - to show the buttons on the header
	 */
	subMData() {
		this.menuData = [];
		if (this.isHandset && this.mapView) {
			this.menuData.push({
				type: 'button',
				title: !this.mapView ? 'Map view' : 'List view',
				icon: !this.mapView ? 'map' : 'list',
				color: !this.mapView ? 'accent' : 'primary',
				click: () => {
					this.mapView = !this.mapView;
					this.subMData();
					this.showLeft();
				}
			});
		}
		this.menuData.push(
			{
				type: 'button',
				title: 'Search',
				icon: 'search',
				color: 'primary',
				click: () => {
					this.showSearch();
				}
			},
		);
		this.es.emit(this.es.loadPageOptions, this.menuData);
	}

	/**
	 * @author - PT
	 * @purpose - 
	 */
	showSearch() {
		const orgProdParams = this.sParam;
		const dialogRef = this.dialog.open(SalesOptionsSearchDialogComponent, {
			position: {
				// top: '90px',
			},
			data: {
				param: { ...this.sParam },
				searchType: UserSearchType.SalesOperation,
			},
			minWidth: this.isHandset ? '80vw' : '40%',
			autoFocus: false,

		});
		dialogRef.afterClosed().subscribe(async (result) => {
			if (result === undefined || Object.keys(result.param)?.length === 0) {
				this.addRemoveCancelFilter(false);
				this.sParam = { ...this.initialParams };
			} else {
				this.sParam = { ...this.sParam, ...result.param };
				this.addRemoveCancelFilter(true);
			}
			this.pagination.offset = 0;
			this.loadDataUsingAlgolia();
			if (isEqual(orgProdParams, this.sParam) || !Object.keys(result?.param).length) {
				this.router.navigate([], {
					relativeTo: this.aroute,
					queryParams: null,
				});
			} else {
				this.router.navigate([], {
					relativeTo: this.aroute,
					queryParams: this.sParam,
					queryParamsHandling: 'merge',
				});
			}
			setTimeout(() => {
				this.es.emit(this.es.loadPageOptions, this.menuData);
			}, 2000);
		});
	}

	/**
	* @author - PT
	* @purpose - to show the menu on the header when search is hit
	*/
	addRemoveCancelFilter(b: boolean) {
		if (!!b) {
			if (!this.menuData.map((f) => f.title).includes('Cancel Search')) {
				this.menuData.push({
					type: 'button',
					title: 'Cancel Search',
					icon: 'search_off',
					color: 'primary',
					click: () => {
						this.clearSearch();
					},
				});
			}
			this.menuData = this.menuData.filter((f) => f.title !== 'Search');
		} else {
			this.menuData = this.menuData.filter((f) => f.title !== 'Cancel Search');
		}
		this.es.emit(this.es.loadPageOptions, this.menuData);
	}

	/**
	 * @author - PT
	 * @purpose - to clear all the params and call the sales options api and addRemoveCancelFilter method
	 */
	clearSearch() {
		this.sParam = { ...this.initialParams };
		this.addRemoveCancelFilter(false);
		this.subMData();
		this.loadDataUsingAlgolia();
	}

	toCurrency(n: number, currency: string) {
		if (!!n) {
			return this.currencyPipe.transform(n, currency, currency + ' ', '1.0');
		}
	}

	/**
	 * setup Mobile Buttons
	 * @returns 
	 */
	setUpMobileButtons() {
		if (!this.isHandset) {
			return;
		}
		this.showHideSearch(true);
	}

	/**
	 * Search data from table
	 * @param filterValue 
	 */
	applyFilter(filterValue: string) {
		this.sParam = { searchText: filterValue };
		this.pagination.currentPage = 0;
		this.loadDataUsingAlgolia();
	}

	/**
	 * Clear search data
	 * @param filterValue 
	 */
	clearFilter() {
		this.showFilter = false;
		this.dataSource.filter = null;
	}

	/**
	 * Go to setup sales
	 * @param id Edit record
	 */
	setupSales(id: string | number) {
		this.router.navigate(['sales-options/truck-details'], { queryParams: { salesId: `${id}` } });
	}

	/**
	 * Convert text to title format
	 * @param s 
	 * @returns 
	 */
	toTitleCase(s: string) {
		return startCase(s);
	}

	/**
	 * Display sales option release status
	 */
	getReleaseStatus(category: number) {
		return toSentence(EnumHelper.getName(DbStatus, category));
	}

	loadDataUsingAlgolia() {
		this.sParam = { ...this.sParam };
		this.store.dispatch(new FetchSalesOptionsUsingAlgolia({ pData: this.pagination, param: { ...this.sParam } }));
		this.mSubscription.push(this.store.select(SalesOptionState.selectTotalCount).subscribe(totalCount => this.pagination.count = totalCount));
		this.store.select(SalesOptionState.selectTotalCount);
		this.mSubscription.push(this.store.select(SalesOptionState.selectAllSalesOptionsUsingAlgolia).pipe(map(filterFn => filterFn({}))).subscribe(s => {
			this.m = s as SalesProductBase[];
			this.dataSource = new MatTableDataSource(s);
			// this.getAssetLocation();
		}));
	}

	/**
	* run the particular method according to the page event
	* @param event  - page event
	*/
	pageEvents(event: any) {
		this.pagination.offset = event.pageIndex;
		this.pagination.size = event.pageSize;
		this.loadDataUsingAlgolia();
	}

	/**
	 * 
	 * @param d status
	 * @returns 
	 */
	status(d: SalesStatus) {
		switch (d) {
			case SalesStatus.delivered:
				return 'Delivered';
			case SalesStatus.inactive:
				return 'Inactive';
			case SalesStatus.available:
				return 'Available';
			case SalesStatus.funded:
				return 'Funded';
			case SalesStatus.hold:
				return 'Hold';
			case SalesStatus.staging:
				return 'Staging';
			default:
				break;
		}
	}

	makeCSVData(data: any, header_type: string): string {
		const replacer = ((key, value) => {
			return value === null ? '' : value;
		}); // specify how you want to handle null values here  
		const mappingArray = CSVHeader.map(item => item[header_type]);
		let csv = data.map(row => CSVHeader.map((fieldName) => {
			let value = fieldName.mapping.length > 0 ? row[fieldName.mapping[0]] : '';
			return JSON.stringify(value, replacer);
		}).join(','));
		csv.unshift(mappingArray.join(','));
		let csvArray = csv.join('\r\n');
		return csvArray;
	}

	downloadFile(header_type: string) {
		if (!this.salesList.length) return;
		let csvData = this.makeCSVData(this.csvGridData, header_type);
		let blob = new Blob(['\ufeff' + csvData], {
			type: 'text/csv;charset=utf-8;'
		});
		let dwldLink = document.createElement('a');
		const url = URL.createObjectURL(blob);
		dwldLink.setAttribute('href', url);
		dwldLink.setAttribute('download', header_type + '.csv');
		dwldLink.style.visibility = 'hidden';
		document.body.appendChild(dwldLink);
		dwldLink.click();
		document.body.removeChild(dwldLink);
	}

	/**
	 * @author KS
	 * @purpose Generate and values to sales option list for CSV
	 * @param salesOptions SalesProductBase[]
	 * @returns 
	 */
	generateList() {
		this.mSubscription.push(
			this.salesService.exportData(this.sParam, this.pagination.count).subscribe(data => {
				if (!data) return;
				let jsonData = parseSalesProductArray(Object.values(data));
				if (!data) return;
				let _data = jsonData.map((s: any) => {
					this.products.forEach(p => {
						if ((s.productSummary.vin === p.vin) && p?.netSuiteStockNumber) {
							s.netSuiteStockNumber = p.netSuiteStockNumber;
						}
					});
					// Assign picture url
					if (s?.pictures?.length > 0) {
						s.pictures = s.pictures.map((pic) => ({
							...pic, url: pic.serverUrl,
						}));
					}
					return s;
				});
				this.salesList = _data;
				logger.log('data', this.salesList);
				if (this.salesList.length) this.csvGridData = makeCSVHeader(this.salesList);
			})
		);
	}

	/**
	 * @author KS
	 * @purpose Get the Sales Status title from enum
	 * @param t 
	 * @returns 
	 */
	getStatus(t: SalesStatus) {
		const status = this.salesStatus.find(x => x.value === t);
		return this.getTitle(status?.name);
	}

	getTitle(t: string) {
		// Check for all caps
		return !(/^[A-Z]+$/.test(t)) ? toSentence(t) : t;
	}

	/**
	 * @author KS
	 * @purpose Get Products
	 */
	getProducts() {
		const pData = { size: 1000, offset: 0, full: false, count: 0 };
		this.store.dispatch(new FetchProductsUsingAlgolia({ pData: pData, param: {} }));
		this.mSubscription.push(this.store.select(ProductState.selectAllProductsUsingAlgolia).pipe(map(filterFn => filterFn({})))
			.subscribe((p: any) => {
				if (!!p) {
					this.products = p;
				}
			}));
	}

	/**
	 * @author KS
	 * @purpose Redirect to transfer page
	 * @param key 
	 * @returns 
	 */
	transfer(key: string) {
		if (!key) return;
		const product = this.products.find(x => x.id === key);
		return (!product?.transfers?.length)
			? this.router.navigate([`trailers/transfer-details/add/${key}`], this.rb)
			: this.router.navigate([`trailers/transfer/${key}`], this.rb);
	}

	/**
	 * @author KS
	 * @purpose Get Info for tool tip
	 * @param key 
	 * @returns 
	 */
	getToolTipInfo(key: string) {
		if (!key && !this.products.length) return;
		const product = this.products.find(x => (x.id === key) && x?.transfers?.length);
		const transfer = product?.transfers.find((x: InventoryTransfer) => x.status === TransferStatus.inTransit);
		if (!transfer) return null;
		const destination = (transfer.destinationLocationType === LocationType.inventory)
			? transfer.destinationInventory.address.city
			: transfer.destinationAddress.city;
		return `Status: In Transit, Destination: ${destination} | ETA: ${this.datePipe.transform(transfer.eta, 'MMM d, y')}`;
	}

	/**
	 * @author KS
	 * @purpose Get valid role
	 */
	get isRoleValid() {
		return (!this.user.roles?.salesOperations) ? false : true;
	}

	changeInvAge(v) {
		if (isNaN(v)) {
			return '';
		} else {
			return `${v} ${(v > 0 || v < 0) ? 'days' : 'day'}`;
		}
	}

	showPriceLog(sales: SalesProductBase) {
		const sP: SalesOptionParam = {};
		this.salesService.getSaleOptionRev(`${sales.id}`)
			.then(h => {
				const logs = this.getHistoryString(h);
				const dialogRef = this.dialog.open(PriceLogComponent, {
					// Set the position of the dialog
					position: {
						top: this.isHandset ? '90px' : '',
					},
					// Pass data (product element) to the dialog
					data: { data: logs },
					// Set the minWidth and autoFocus properties of the dialog
					minWidth: this.isHandset ? '70vw' : '400px',
					autoFocus: false,
				});

			})
			.catch(error => {
				logger.error('history error', error);
			});

	}
	getHistoryString(h: SalesProductBase[]) {
		const uniqueItems = {};
	
		h.forEach(d => {
			if (!!d.wholesalePrice) {
				const key = `${d.wholesalePrice}_${this.datePipe.transform(d.updatedAt, 'dd-MM-yyyy')}`;
	
				// Check if the key already exists, if not, add it to the uniqueItems object
				if (!uniqueItems[key]) {
					uniqueItems[key] = {
						price: d.wholesalePrice,
						date: this.datePipe.transform(d.updatedAt, 'medium')
					};
				}
			}
		});
	
		// Convert the uniqueItems object back to an array
		const logs:{price:string, date:any}[] = Object.values(uniqueItems);
		// Convert date strings to Date objects
		logs.forEach(i => {
			i.date = new Date(i.date);
		});
		
		// Sort the array by date descending order
		logs.sort((a, b) => b.date - a.date);
		return logs;
	}
	
}