import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { BasePage } from '@trent/models/UI/base.page';
import { LogoPosition, Picture, pictureTargetSize } from '@trent/models/inspection/picture';
import { logger } from '@trent/models/log/logger';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { EventService } from '@trent/services/event.service';
import { FullMetadata, Storage } from '@angular/fire/storage';
import { compressImage } from '@trent/models/utility/compare-pics-utl';
import { BucketName } from '@trent/models/server-storage/i-strorage-metadata';
import { MessageInfo, fromCreateSuccess, fromCreateWarning, readErrorMessage } from '@trent/models/error-handling';
import { FileUploadMult } from '../../../models/file-upload/file-upload-mult';
import { deleteFile, getMetadataFS, getUrlFromPath, listAllFiles, updateCustomMetadata } from '@trent/services/firebase-storage';
import { Product, ProductType } from '@trent/models/product';
import { DialogData, ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { EnumHelper } from '@trent/models/utility/enum-helper';
import { PicLocId2 } from '../../../../../../libs/models/inspection/pic-loc-id';
import { UtilityService } from '@trent/services/utility.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { promiseWraper } from '@trent/models/utility';
import { ProductService } from '@trent/services/product.service';
import { toSentence } from '@trent/models/utility';

type DialogType = 'DeleteFile' | 'DeleteAllFiles';

@Component({
  selector: 'trent-product-picture-grid',
  templateUrl: './product-picture-grid.component.html',
  styleUrls: ['./product-picture-grid.component.scss']
})
export class ProductPictureGridComponent extends BasePage<Picture[]> implements OnInit, OnDestroy {
  // path (string), customMetaData (object)


  @Input()
  isEditMode: boolean;

  @Input()
  path: string[] = ['temp'];

  @Output() deleteParent = new EventEmitter<any>();

  @Input()
  product: Product;

  //Access Ordering Icon
  @Input()
  showOrderingIcon: boolean = false;

  //Access Show/Hide Visibility Icon
  @Input()
  showVisibilityIcon: boolean = false;

  //Access  Upload image option
  @Input()
  uploadRequired: boolean = true;

  //Header title
  @Input()
  headerTitle: string;

  //Access Show/Hide Selected pic Icon
  @Input()
  showSelectedPics: boolean = false;

  //Access Show/Hide Logo position
  @Input()
  showLogoPosition: boolean = false;


  @Output() showHideImages = new EventEmitter<any>();
  @Output() selectPicsOutPut = new EventEmitter<any>();

  productType = ProductType.truck;
  pTypeEnum = ProductType;

  imageHeight: number;
  containerHeight: number;


  customMetaData: object;

  currentIndex: number;
  percentComp: number;
  metadata: FullMetadata;
  bucketName: BucketName;
  upLoadFiles: any[];
  showUploadWarning: any;
  filePaths: string[];
  fstrorageRef: import('@angular/fire/storage').StorageReference[];

  newImage: object;
  @ViewChild('btnDialog') btnDialog: TemplateRef<any>;
  dialogRef;
  uploadedPictures: Picture[] = [];
  locIds = EnumHelper.getNames(PicLocId2);
  userLocation: PicLocId2 = PicLocId2.front;
  showImages = true;
  isHandset: boolean;
  selectPics = [];

  logoPositionEnum = LogoPosition;
  @Input()
  selectedLogoPosition = [];
  @Output() logoPositionOutPut = new EventEmitter<any>();

  logName(location: PicLocId2) {
    console.log(`clicked: ${location}`);
  }
  constructor(store: Store, private storage: Storage, public dialog: MatDialog,
    public us: UtilityService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private ps: ProductService,
    dialogService: DialogService, es: EventService,
  ) {
    super(store, dialogService, es);
  }

  ngOnDestroy(): void {
    logger.info('nothing to destroy');
  }

  ngOnInit(): void {
    this.m = []; // used for all listed pictures
    this.em = []; // used for newly uploaded pictures
    this.listAllFiles();
    this.matIconRegistry.addSvgIcon('truckIcon', this.domSanitizer.bypassSecurityTrustResourceUrl(this.us.getFullUrl('assets/truck-semi.svg')));
    this.mSubscription.push(this.us.isHandset$.subscribe(h => this.isHandset = h));
  }


  getStylesPicBtnDesktop() {
    return {
      display: 'grid',
      height: '57vh',
      position: 'relative'
    };
  }

  setLocation(location: PicLocId2) {
    this.userLocation = location;
  }

  addPictures(event: Event, location: PicLocId2) {
    this.showLoading();//MKN - add loader
    this.selectFilesInWeb(event, location);

  }
  selectFilesInWeb(event: Event, location: PicLocId2) {
    const files: FileList = (event.target as any).files;
    if (!files?.length) {//Solved loader issue reported by saharashdeep
      this.hideLoading();
      return;
    }
    logger.log('file open event', event);
    const cMData = this.setMetaData(location, this.m.length);
    for (let index = 0; index < files.length; index++) {
      logger.log(files[index].type);
      if (!files[index] || files[index].type.split('/')[0] !== 'image') {
        logger.log('invalid file type', files[index].type);
        this.hideLoading();
        return;
      }
      const reader = new FileReader();
      const file = files[index];
      const pic = new Picture();
      pic.uploadedOn = new Date();

      reader.onload = (e: any) => {

        logger.log('File client uploaded Name: ', file.name);
        // TODO: update from base64 to blob.
        // see: https://dev.to/daviddalbusco/take-photo-and-access-the-picture-library-in-your-pwa-without-plugins-25jl

        // resize image

        const img = new Image();
        img.src = e.target.result;

        img.onload = async (q) => {
          console.log('findOut', q);
          pic.localUrl = e.target.result;  // pic.url = e.target.result;
          const fileName = `${file.name.replace(/\.[^/.]+$/, '')}.webp`;

          pic.name = `${new Date().getTime()}_${fileName}`;
          pic.path = this.path[0];

          //Check aspect ratio(landscape orientation)
          if (location !== 'VIN Sticker') {
            const aspectRatio = img.width / img.height;
            if (!(aspectRatio > 1)) {
              const msg = new MessageInfo({
                msgCss: 'warn',
                header: 'Orientation error',
                description: 'Please upload landscape orientation image.'
              });
              this.showAlert(msg);
              this.hideLoading();
              return;
            }
          }

          // this.newImage = `${new Date().getTime()}_${file.name}`; // new line for uploaded image

          this.compressImage(q.target, file.size, 1, fileName)
            .then(async (r) => {
              logger.log('resized image', r);
              pic.file = r.compressedImageFile; // add blob to file in pic model, not saved db, can passed back to carousel component to upload

              await this.uploadPvt2(pic, cMData);
              // await this.downloadFile(pic);
            })
            .catch((error) => {
              logger.log(`re sizing error ${error}`);
              this.hideLoading();
            });

          this.newImage = this.em[0]; // added NEW
        };
      };
      reader.readAsDataURL(files[index]);
    }
    // logger.info('[ProductPictureGridComponent] uploaded files(s):', files, 'under <-', location,  '->');
    // logger.info('[ProductPictureGridComponent] new file name:', this.newImage);
  }

  async uploadPvt2(pic: Picture, customMetaData: Object) {

    // const files1 = this.em.map(f => {
    //   return { file: f.file, picture: f };
    // });
    const files1 = [{ file: pic.file, picture: pic }];
    const files = files1.filter(g => !!g.file);
    logger.log('files', files);
    this.percentComp = 10; // manual entry to show the start
    const fileUpload = new FileUploadMult(this.storage);
    if (!!this.path) {
      fileUpload.uploadFiles(this.path, files, [{ customMetadata: customMetaData }], this.bucketName);
    } else {
      fileUpload.uploadFiles('/temp', files, [{ customMetadata: this.customMetaData }], this.bucketName);
    }
    fileUpload.percentage$.subscribe(async r => {
      this.percentComp = r;
      logger.log('percentage', r);
      if (this.percentComp === 100) {
        this.upLoadFiles = [];
        await this.downloadFile(files[0].picture);
      }
    });
    return fileUpload.uploadResult$.toPromise();
  }

  /**
   * Check location type image already uploaded or not
   * @param l 
   * @returns 
   */
  checkImageUploaded(l: PicLocId2) {
    return this.m.findIndex(picture => (picture?.customMetadata && picture?.customMetadata['loc']) == l) > -1;
  }

  /**
  * Check image count
  * @param l 
  * @returns 
  */
  checkUploadedImageCount(l: PicLocId2) {
    const foundUploadedImages = this.m.filter(picture => (picture?.customMetadata && picture?.customMetadata['loc']) == l);
    if (foundUploadedImages?.length > 0) {
      return foundUploadedImages?.length;
    }

    return 0;
  }

  /**
   * Get paths and urls for all pictures in temp
   */
  async listAllFiles() {
    // list all files
    for (let pt of this.path) {
      this.fstrorageRef = (await listAllFiles(pt)).items;

      if (this.fstrorageRef?.length == 0 && this.path[1] == pt) {
        const msg = new MessageInfo({
          msgCss: 'warn',
          header: 'Images not found',
          description: 'Selected proxy vin product does not contain any images reference.'
        });
        this.showAlert(msg);
        return;
      }

      this.filePaths = this.fstrorageRef.map(d => d.fullPath);
      this.fstrorageRef.forEach(async (e, i) => {
        const p = new Picture();
        p.path = e.fullPath;
        p.name = e.name;
        p.url = (await getUrlFromPath(p.path)).url;
        this.metadata = (await getMetadataFS(p.path))?.metadata;
        p.uploadedOn = new Date(this.metadata.updated);
        p.customMetadata = this.metadata?.customMetadata;
        // console.log(p.customMetadata['visibility'])
        if (!!p.customMetadata) {
          p.customMetadata['uid'] = this.metadata?.customMetadata?.uid;
          if (p.customMetadata['visibility'] === undefined) {
            p.customMetadata['visibility'] = false;
          } else {
            p.customMetadata['visibility'] = JSON.parse(p.customMetadata['visibility']);
          }
          if (p.customMetadata['order'] === undefined) {
            p.customMetadata['order'] = i;
          }
          // prevent m from repopulating each upload        
          if (this.m.findIndex(f => f.name === p.name) === -1) {
            this.m.push(p);
            logger.info('PUSHED');
            this.m.sort((a, b) => {
              return +a.customMetadata['order'] - +b.customMetadata['order'];
            });
          } else {
            logger.info('***NO PUSH INTO P');
          }
        }

        // this.m.push(p)

        // this.m = this.em;

      });
    }
    logger.info('[ProductPictureGridComponent] ListAllFiles, m:', this.m);




  }

  /**
   * Load uploaded pictures
   */
  loadPictures(ids: number) {
    const sortedM = Array.from(this.m);

    sortedM.sort((a, b) => new Date(b['customMetadata']['updated']).getTime() - new Date(a['customMetadata']['updated']).getTime());
    const uploadedPictureNames: string[] = []; // array of the names of the uploaded pictures

    for (let picture of this.em) {
      uploadedPictureNames.push(picture.name);
    }
    this.uploadedPictures = this.m.filter(obj => uploadedPictureNames.includes(obj.name));
    logger.info('[ProductPictureComponent] uploadedPictures objects:', this.uploadedPictures);
    /**
     * Option 1: create, or obtain, ids from the uploaded pictures and call those same
     * ids from the cloud storage
     * Option 2: get the uploaded pictures before the get sent to firebase and show them
     * right away
     */

  }

  setMetaData(location: PicLocId2, index: number): Object {
    return { uid: this.user.id, vin: this.product.vin, loc: location, order: index + 1, visibility: location !== 'VIN Sticker' ? true : false };
  }

  trackByFn(index: number, item: Picture): string {
    return item.name; // Replace "id" with a unique property of your item (e.g., a unique database key)
  }
  showRemoveButton(p: Picture) {
    return this.showSelectedPics ? false : true;
  }
  openConfDialog(dialogType: DialogType, index?: number) {
    let dialogData: DialogData;
    switch (dialogType) {
      case 'DeleteFile':
        dialogData = {
          title: 'Delete File',
          subtitle: 'File will be permanently deleted',
          trueButton: 'Delete',
          falseButton: 'Cancel',
          imgUrl: this.m[index].url
        };
        break;
      case 'DeleteAllFiles':
        dialogData = {
          title: `Delete All (${this.m.length} files)`,
          subtitle: 'Files will be permanently deleted',
          trueButton: 'Delete',
          falseButton: 'Cancel',
        };
        break;

      default:
        break;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      // width: '250px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(result => {
      logger.log('The dialog was closed');
      if (dialogType === 'DeleteFile' && !!result) {
        this.deletePicture(index);
      }
    });
  }
  async downloadFile(p: Picture) {
    // this.showImages = false;
    p.url = (await getUrlFromPath(p.path)).url;
    const fullMetaData = (await getMetadataFS(p.path)).metadata;
    if (fullMetaData) {
      p.customMetadata = fullMetaData.customMetadata;
      if (!!p.customMetadata) {
        p.customMetadata['visibility'] = JSON.parse(p.customMetadata['visibility'])
        p.customMetadata['order'] = JSON.parse(p.customMetadata['order']);
      }
      p.name = fullMetaData.name;

      if (this.m.findIndex(f => f.url === p.url) === -1) {
        this.m.push(p);
      }

      // if(this.product.imageType != ImageType.original){
      //   this.updatePictureStatusFlag();
      // }else{
      //   this.showAfterFileUploadingMessage();
      // }
      this.updatePictureStatusFlag();
    }

    // setTimeout(() => {
    //   this.showImages = true;
    // }, 500);
  }

  /**
   * Show file uploading message
   */
  showAfterFileUploadingMessage() {
    this.hideLoading();
    const msg = (this.showUploadWarning) ? fromCreateWarning('file') : fromCreateSuccess('file');
    this.showPopover(msg, 500);
  }

  /**
   * Update product imageType to original
   */
  updatePictureStatusFlag() {
    const prom = this.ps.updatePictureTypeStatus(this.product.id).toPromise();
    prom.then((r) => {
      this.showAfterFileUploadingMessage();
    })
      .catch(err => {
        this.hideLoading();
        this.showAlert(readErrorMessage(err));
      });
  }


  async deletePicture(index: number) {
    this.showLoading();//MKN - add loader

    const path = this.m[index].path;
    const dP = await promiseWraper(deleteFile(path));
    logger.log('[deletePicture --->]', dP);
    if (!dP.success) {
      return;
    }
    this.deleteParent.emit(path);
    this.m.splice(index, 1);
    this.m.forEach(async (e, i) => {
      if (+e.customMetadata['order'] > index + 1 && this.m[i].path !== path) {
        const c = this.m[i].customMetadata;
        c['order'] = +c['order'] - 1;
        this.m = this.m.filter(f => f.path !== path);
        const uM = await promiseWraper(updateCustomMetadata(e.path, { customMetadata: c }));
        if (!!uM.success) {
          e.customMetadata = uM.data.updatedMetadata['customMetadata'];

        }
      }
    });

    // const indexToDelete = orderToDelete - 1;

    // this.m.splice(indexToDelete, 1);
    // for (let i = 0; i < this.m.length; i++) {
    //   this.m[i].customMetadata['order'] = i + 1;
    // }
    // logger.info('DELETED DELETED DELETED')
    // this.listAllFiles()
    this.updatePictureStatusFlag();

  }


  upDownIcon(index: number, direction: string) {

    if (this.m.length === 0 || this.m.length === 1) {
      return null;
    }

    if (direction === 'up') {
      if (index === 0) {
        return null;
      } else {
        return 'arrow_upwards';
      }
    } else {
      if (index === this.m.length - 1) {
        return null;
      } else {
        return 'arrow_downward';
      }
    }

  }
  async orderFile(index: number, direction: string) {
    const first: number = 0;
    const last: number = this.m.length;
    if (direction === 'down') {
      logger.info(this.m[index].customMetadata['order'], 'down into ->', parseInt(this.m[index + 1].customMetadata['order']));
      // this.m[index].customMetadata['order'] = parseInt(this.m[index].customMetadata['order'])+1;
      this.m[index]['customMetadata'] = await this.updateMetaDataOrderChange(index, 'down');
      this.m[index + 1]['customMetadata'] = await this.updateMetaDataOrderChange(index + 1, 'up');

      // this.m[index+1].customMetadata['order'] = parseInt(this.m[index+1].customMetadata['order'])-1;

    } else {
      logger.info(this.m[index].customMetadata['order'], 'up into ->', parseInt(this.m[index - 1].customMetadata['order']));
      // this.m[index].customMetadata['order'] = parseInt(this.m[index].customMetadata['order'])-1;
      this.m[index]['customMetadata'] = await this.updateMetaDataOrderChange(index, 'up');
      this.m[index - 1]['customMetadata'] = await this.updateMetaDataOrderChange(index - 1, 'down');

      // this.m[index-1].customMetadata['order'] = parseInt(this.m[index-1].customMetadata['order'])+1;
    }
    this.m.sort((a, b) => a.customMetadata['order'] - b.customMetadata['order']);
    // this.listAllFiles()

    // logger.info('Index of picture:', index, 'going in dir:', direction)
    // logger.info('True order index:', this.m[index].customMetadata['order'])
  }

  async updateMetaDataOrderChange(index: number, direction: 'up' | 'down') {
    let c = this.m[index].customMetadata;
    c['order'] = direction === 'down' ? parseInt(c['order']) + 1 : parseInt(c['order']) - 1;
    const x = await updateCustomMetadata(this.m[index].path, { customMetadata: c });
    return x.updatedMetadata['customMetadata'];
  }

  async showHide(e: MatSlideToggleChange, index: number) {
    const c = this.m[index].customMetadata;
    c['visibility'] = e.checked;
    // await updateCustomMetadata(this.m[index].path, {customMetadata: c});
    // if(!!this.showHideImages)
    this.showHideImages.emit({ path: this.m[index].path, customMetadata: c, name: this.m[index].name });
    logger.info(this.m[index].name, ', visibility set to', c['visibility']);
  }

  async move(e: MatSlideToggleChange, index: number) {
    const c = this.m[index].customMetadata;
    c['visibility'] = e.checked;
    await updateCustomMetadata(this.m[index].path, { customMetadata: c });
  }
  getIcon(l: PicLocId2) {

    switch (l) {
      case PicLocId2.front:
        return { svgIcon: '../../../assets/PTS Icons/front.svg', name: 'Front', icon: undefined };
      case PicLocId2.frontDriver:
        return { svgIcon: '../../../assets/PTS Icons/frontDriver.svg', name: 'Front Driver', icon: undefined };
      case PicLocId2.frontPassenger:
        return { svgIcon: '../../../assets/PTS Icons/frontPassenger.svg', name: 'Front Passenger', icon: undefined };
      case PicLocId2.driverSide:
        return { svgIcon: '../../../assets/PTS Icons/driverSide.svg', name: 'Driver Side', icon: undefined };
      case PicLocId2.passengerSide:
        return { svgIcon: '../../../assets/PTS Icons/passengerSide.svg', name: 'Passenger Side', icon: undefined };
      case PicLocId2.rear:
        return { svgIcon: '../../../assets/PTS Icons/rear.svg', name: 'Rear', icon: undefined };
      case PicLocId2.underTheHood:
        return { svgIcon: '../../../assets/PTS Icons/underTheHood.svg', name: 'Under The Hood', icon: undefined };
      case PicLocId2.dashBoard:
        return { svgIcon: '../../../assets/PTS Icons/dashboard.svg', name: 'DashBoard', icon: undefined };
      case PicLocId2.bunk:
        return { svgIcon: '../../../assets/PTS Icons/bunk.svg', name: 'Bunk', icon: undefined };
      default:
        return { svgIcon: '../../../assets/PTS Icons/vin.svg', name: 'Vin', icon: undefined };
    }
  }

  // Cm- Add for custom dialog open
  openDialog() {
    this.dialogRef = this.dialog.open(this.btnDialog, {
      position: { bottom: '0px' }
    });
  }


  cancelDialog() {
    this.dialogRef.close();
  }

  onSelectedPic(e: MatSlideToggleChange, p) {
    if (e.checked) {
      this.selectPics.push(p);
    } else {
      if (this.selectPics?.length > 0) {
        this.selectPics = this.selectPics.filter(f => f.name !== p.name);
      }
    }
    this.selectPicsOutPut.emit(this.selectPics);
  }
  // Cm - When product and proxy pic having 
  // 1 - Hide delete button for product
  // 2 - Show the select pic button for proxy pic
  proxyPic(p) {
    if (this.path?.length > 1) {
      return p.path.includes(this.product.vin);
    }
  }

  toSentence(str: string) {
    return toSentence(str);
  }
  /**
   * @author Cm
   */

  onSelectLogoPosition(position, pic) {

    // Check if the item with the same id already exists in the array
    const existingItem = this.selectedLogoPosition.find(item => item.path === pic.path);

    if (existingItem) {
      // If the item exists, update it
      existingItem.position = position;
    } else {
      // If the item doesn't exist, push the new item
      this.selectedLogoPosition.push({ position, path: pic.path });
    }
    this.logoPositionOutPut.emit(this.selectedLogoPosition);
    this.getMatchPos(pic);
  }

  /**
 * @author Cm
 * @description Function to get the dimensions (width and height) of an image
 * @returns width,height
 */
  getImageDimension(p) {
    const img = new Image();
    // Set the source of the image to the specified URL
    img.src = p.url;

    // Calculate the width and height as a percentage of a predefined size
    let width = 186 / img.naturalWidth * 100;
    let height = 74 / img.naturalHeight * 100;
    return { width, height };
  }

  /**
   * @author Cm
   * @description Function to get the logo position based on a provided path
   * @returns position
   */
  getMatchPos(p) {
    // Check if the provided object and selectedLogoPosition array exist
    if (p && !!this.selectedLogoPosition?.length) {
      // Find the first match in the selectedLogoPosition array based on the path
      let matched = this.selectedLogoPosition.filter(s => p?.path == s?.path)[0];

      // Return the matched position if it exists
      return matched?.position ?? LogoPosition.northeast;
    }
    return LogoPosition.northeast;
  }


  logoPositionName(v) {
    switch (v) {
      case 1:
        return 'Top Left';
      case 2:
        return 'Top Right';
      case 3:
        return 'Bottom Left';
      case 4:
        return 'Bottom Right';
      case 5:
        return 'No Logo';
    }
  }

  compressImage(originalImage: any, originalSize: number, quality: number, name: string): Promise<{
    compressedImageFile: File, compressedImageSrc: string, sizeString: string
  }> {
    // showing the compressed image
    // const originalImage = document.querySelector('#originalImage') as any;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    let compressedImageBlob: Blob;
    let compressedImageFile: File;
    let compressedImageSrc: string;
    let sizeString: string;

    // Set the target dimensions for the resized image
    const targetWidth = 1024; // You can adjust this based on your requirement
    const targetHeight = 768; // You can adjust this based on your requirement

    const originalWidth = originalImage.width;
    const originalHeight = originalImage.height;

    // Calculate the resizing factor to maintain the aspect ratio
    const widthFactor = targetWidth / originalWidth;
    const heightFactor = targetHeight / originalHeight;
    const resizingFactor = Math.min(widthFactor, heightFactor);

    const canvasWidth = originalWidth * resizingFactor;
    const canvasHeight = originalHeight * resizingFactor;

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    context.drawImage(
      originalImage as any,
      0,
      0,
      originalWidth,
      originalHeight,
      0,
      0,
      canvasWidth,
      canvasHeight
    );

    // reducing the quality of the image
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (blob) {
            compressedImageBlob = blob;
            compressedImageFile = new File([blob], name, {
              type: blob.type
            });
            compressedImageSrc = URL.createObjectURL(compressedImageBlob);
            sizeString = this.bytesToSize(blob.size);
            resolve({ compressedImageFile, compressedImageSrc, sizeString });
          } else {
            reject('no blob exists');
          }
        },
        'image/jpeg',
        quality
      );
    });
  }

  bytesToSize(bytes: number) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    if (bytes === 0) {
      return '0 Byte';
    }
    // eslint-disable-next-line radix
    const i = parseInt(`${(Math.floor(Math.log(bytes) / Math.log(1024)))}`);
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  }


}
