<div class="primary">
  <ng-container *ngIf="!!em">
    <div mat-dialog-title>
      <span class="closebtn" (click)="cancel()">&times;</span>
      User Settings
    </div>
    <div class="p-16">
      <div class="loading-msg-contianer">
        <mat-progress-bar color="primary" mode="indeterminate" value="50" *ngIf="showLoadingBar"></mat-progress-bar>
        <div class="msg-container"
          [ngClass]="{'update-success': showMsg && showMsg.success, 'update-failure': showMsg && !showMsg.success, 'display-none': !showMsg}">
          <mat-label clas="msg-label" *ngIf="showMsg">{{showMsg.msg}}</mat-label>
          <span class="closebtn" (click)="showMsg=null">&times;</span>
        </div>
      </div>
      <form #uSettingFrom="ngForm">

        <mat-label>Communication Preferences</mat-label>
        <!-- Email  -->
        <div class="comm-toggle-container">

          <mat-label><span matPrefix>
              <mat-icon inline color="primary">email</mat-icon>
            </span>Email</mat-label>
          <div class="toggle">
            <mat-slide-toggle class="toggle" color='primary' [(ngModel)]="em.email" name="email" #fcm="ngModel"
              [disabled]="!isEditMode">
            </mat-slide-toggle>
          </div>
        </div>
        <mat-divider></mat-divider>

        <!-- FCM  -->
        <div class="full-width comm-toggle-container">
          <mat-label><span matPrefix>
              <mat-icon inline color="primary">message</mat-icon>
            </span>App Notifications</mat-label>
          <div class="toggle">
            <mat-slide-toggle class="toggle" color='primary' [(ngModel)]="em.fcm" name="fcm" #fcm="ngModel"
              [disabled]="!isEditMode">
            </mat-slide-toggle>
          </div>
        </div>
        <mat-divider></mat-divider>
        <!-- SMS  -->
        <div class="full-width comm-toggle-container">
          <mat-label><span matPrefix>
              <mat-icon inline color="primary">textsms</mat-icon>
            </span>SMS/Text</mat-label>
          <div class="toggle">
            <mat-slide-toggle class="toggle" color='primary' [(ngModel)]="em.sms" name="sms" #fcm="ngModel"
              [disabled]="!isEditMode">
            </mat-slide-toggle>
          </div>
        </div>
        <mat-divider></mat-divider>

      </form>

    </div>
    <div mat-dialog-actions>
      <button mat-flat-button color="primary" (click)="save()" type="button" class="md-18 right"
        *ngIf="isEditMode">
        <mat-icon>done</mat-icon> OK
      </button>
      <button mat-flat-button color="primary" (click)="edit()" type="button" class="md-18 right"
        *ngIf="!isEditMode">
        <mat-icon>edit</mat-icon> Update
      </button>
      <button mat-stroked-button (click)="cancel()" type="button" class="md-18 right">
        <mat-icon>close</mat-icon> Cancel
      </button>

    </div>
  </ng-container>
</div>