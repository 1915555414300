import { Paging } from '../observable-util/paging';
import { UserProfile } from '../user/user-profile';
import { logger } from '../log/logger';
import { CollectionReference, limit, orderBy, query, Query, QueryConstraint, startAfter, where } from 'firebase/firestore';
import { Promotions, PromotionType } from './promotions';

/**
 * Role Group Store method and interface
 */
export interface IPromotionParam {
  orderDirection?: 'desc' | 'asc';
  orderBy?: string;
  name?: string;
  listType?: PromotionType;
}

export const promotionParamInit = (): IPromotionParam => {
  return {
    orderBy: 'updatedAt',
    orderDirection: 'desc',
  };
};

/** Server filtering of firebase query building of query  */
export const promotionSearchServerQuery = (ref: CollectionReference, param: IPromotionParam, p: Paging) => {
  let q: Query = <any>ref;
  const cons: QueryConstraint[] = [];
  if (!!param.name) {
    cons.push(where('shortName', '==', param.name.toLowerCase()));
  }
  if (!!param.listType) {
    cons.push(where('promotionType', '==', param.listType));
  }
  cons.push(where('updatedAt', '<=', new Date()));
  cons.push(orderBy('updatedAt', 'desc'));

  if (!!p.lastDoc) {
    logger.log('Start After Query: ', p.lastDoc[param.orderBy]);
    cons.push(startAfter(p.lastDoc[param.orderBy]));
  }
  cons.push(limit(p.size));
  q = query(ref, ...cons);
  return q;
};

/** Client side filtering of the data. */
export const promotionSearchClientFilter = (p: Promotions[], o: IPromotionParam) => {

  if (!!o.name) {
    p = p.filter(val => val.name === o.name.toLowerCase());
  }
  if (!!o.listType) {
    p = p.filter(val => val.promotionType === o.listType);
  }
  return p;
};


export const getIPromotionOptionOrChildren = (o: IPromotionParam): { [key: string]: IPromotionParam } => {
  return undefined;
};
