import { CompanyCarrier } from './company-carrier';
import { CompanyBase } from './company-base';
import { CompanyType } from './company-type';
import { CompanyFleet } from './company-fleet';
import { CompanyDriver } from './company-driver';
import { toPascalCase } from '../utility/helper';
import { CompanyFleetEquipment } from './company-fleet-equipment';

export const parseCompanyArray = (obj: any[]): CompanyFleet[] => {
  return obj.map(o => <CompanyFleet>parseCompany(o));
};

export const parseCompany = (obj: any): CompanyFleet | CompanyDriver | CompanyCarrier | CompanyFleetEquipment => {
  if (obj == null) { return null; }
  const c = obj as CompanyBase; // cast it to base, remember it is still a javascript obj at run time.

  // to be removed when data is cleaned. Current does not have property companyType
  if (!c.companyType) { c.companyType = CompanyType.Fleet; }

  switch (c.companyType) {
    case CompanyType.Fleet:
      return CompanyFleet.parse(c);

    case CompanyType.DriverProvider:
      return CompanyDriver.parse(c);

    case CompanyType.Carrier:
      return CompanyFleet.parse(c); //  CompanyCarrier.parse(c);
    case CompanyType.EquipmentVendor:
      return CompanyFleetEquipment.parse(c); 
  
    default:
      throw new Error(`Invalid Company type was provided. Type: ${c.companyType} is invalid.`);
  }
};
/**
 * provide UI friendly name for company based on legal and business name
 * @param name Company business name
 * @param legalName Company leagal name
 */
export const companyFriendlyName = (name: string, legalName: string): string => {
  if (!name) {
    return legalName;
  }
  if (legalName.toLowerCase() === name.toLowerCase()) {
    return name;
  }
  return `${toPascalCase(name)} (${legalName})`;
};
export const getCompanyDetailUrl = (cType: CompanyType) => {
  switch (cType) {
    // case CompanyType.Carrier:
    //   return 'carrier-detail';
    // case CompanyType.DriverProvider:
    //   return 'driver-company-detail';
    case CompanyType.Fleet:
      return 'lessee';
    default:
      throw Error(`[company-helper] incorrect company type '${cType}'. Not supported!!`);
  }
};
/** reset the state if files are not uploaded */
export const resetDoc = (em: CompanyBase , m: CompanyBase) => {
  switch (em.companyType) {
    case CompanyType.Fleet:
      em.aOI = m.aOI;
      (em as CompanyFleet).insCert = (m as CompanyFleet).insCert;
      (em as CompanyFleet).carrierPackage = (m as CompanyFleet).carrierPackage;
      (em as CompanyFleet).companyCreditPackage = (m as CompanyFleet).companyCreditPackage;
      (em as CompanyFleet).bankStatements = (m as CompanyFleet).bankStatements;
      em.voidCheque = m.voidCheque;
      if(!!em.officer[0] && !!m.officer[0]){
        em.officer[0].photoId = m?.officer[0]?.photoId;
      }
      if(!!em.officer[1] && !!m.officer[1]){
        em.officer[1].photoId = m?.officer[1]?.photoId;
      }
      break;
    case CompanyType.EquipmentVendor:
      em.aOI = m.aOI;
      em.voidCheque = m.voidCheque;
      if(!!em.officer[0] && !!m.officer[0]){
        em.officer[0].photoId = m?.officer[0]?.photoId;
      }
      if(!!em.officer[1] && !!m.officer[1]){
        em.officer[1].photoId = m?.officer[1]?.photoId;
      }
      break;
    
    default:
      throw new Error(`Company Type ${em.companyType} is not implemented`);
  }
};