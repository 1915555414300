import { Exclude, Expose, instanceToInstance, plainToInstance } from 'class-transformer';
import { AddOnBase, AddOnCategory, AddOnValidationGroup } from './add-on-base';
import { IsDefined, ValidateIf } from 'class-validator';

const safetyCert: AddOnValidationGroup = 'safety-cert';

@Exclude()
export class AddOnSafetyCertificate extends AddOnBase {

	constructor() {
		super();
	}

	sanitize() {
		super.sanitize();
	}

	clone() {
		const t = instanceToInstance(this);
		t.sanitize();
		return t;
	}

	updatePriceQuote(s: AddOnSafetyCertificate) {
	}

	public static parse(obj) {
		if (obj == null) { return null; }
		const m = plainToInstance<AddOnSafetyCertificate, any>(AddOnSafetyCertificate, obj);
		m.sanitize();
		return m;
	}
}