export const CSVHeader = [
    {
        truck_paper: 'UniqueDealerID',
        auto_trader: 'UniqueDealerID',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'UniqueIdentifier',
        auto_trader: 'UniqueIdentifier',
        defaultValue: '',
        mapping: [],

    },
    {
        truck_paper: 'Category',
        auto_trader: 'Category',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'Manufacturer',
        auto_trader: 'Manufacturer',
        defaultValue: '',
        mapping: ['make'],
    },
    {
        truck_paper: 'Model',
        auto_trader: 'Model',
        defaultValue: '',
        mapping: ['model','model'],

    },
    {
        truck_paper: 'Year',
        auto_trader: 'Year',
        defaultValue: '',
        mapping: ['modelYear','modelYear'],
    },
    {
        truck_paper: 'Condition',
        auto_trader: 'Condition',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'VINSerialNumber',
        auto_trader: 'VINSerialNumber',
        defaultValue: '',
        mapping: ['vin','vin'],
    },
    {
        truck_paper: 'StockNumber',
        auto_trader: 'StockNumber',
        defaultValue: '',
        mapping: ['netSuiteStockNumber'],
    },
    {
        truck_paper: 'Description',
        auto_trader: 'Description',
        defaultValue: '',
        mapping: ['summaryString','summaryString'],
    },
    {
        truck_paper: 'Currency',
        auto_trader: 'Currency',
        defaultValue: '',
        mapping: ['currency','currency'],
    },
    {
        truck_paper: 'FitsToCategory',
        auto_trader: 'FitsToCategory',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'FitsToManufacturer',
        auto_trader: 'FitsToManufacturer',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'FitsToModel',
        auto_trader: 'FitsToModel',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'RetailContactName',
        auto_trader: 'RetailContactName',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'RetailContactPhoneNumber',
        auto_trader: 'RetailContactPhoneNumber',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'RentalContactName',
        auto_trader: 'RentalContactName',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'RentalContactPhoneNumber',
        auto_trader: 'RentalContactPhoneNumber',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'WholesaleContactName',
        auto_trader: 'WholesaleContactName',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'WholesaleContactCRMID',
        auto_trader: 'WholesaleContactCRMID',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'WholesaleContactPhoneNumber',
        auto_trader: 'WholesaleContactPhoneNumber',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'LocationName',
        auto_trader: 'LocationName',
        defaultValue: '',
        mapping: ['addressFormated','addressFormated'],
    },
    {
        truck_paper: 'LocationStreetAddress',
        auto_trader: 'LocationStreetAddress',
        defaultValue: '',
        mapping: ['streetName','streetName'],
    },
    {
        truck_paper: 'LocationCity',
        auto_trader: 'LocationCity',
        defaultValue: '',
        mapping: ['city','city'],
    },
    {
        truck_paper: 'LocationStateProvince',
        auto_trader: 'LocationStateProvince',
        defaultValue: '',
        mapping: ['stateProv','stateProv'],
    },
    {
        truck_paper: 'LocationCountry',
        auto_trader: 'LocationCountry',
        defaultValue: '',
        mapping: ['country','country'],
    },
    {
        truck_paper: 'LocationPostalCode',
        auto_trader: 'LocationPostalCode',
        defaultValue: '',
        mapping: ['postalZipCode'],
    },
    {
        truck_paper: 'LocationPhone',
        auto_trader: 'LocationPhone',
        defaultValue: '',
        mapping: ['phone'],
    },
    {
        truck_paper: 'LocationAlternatePhone',
        auto_trader: 'LocationAlternatePhone',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'LocationCallStatsPhone',
        auto_trader: 'LocationCallStatsPhone',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'LocationFax',
        auto_trader: 'LocationFax',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'Horsepower',
        auto_trader: 'Horsepower',
        defaultValue: '',
        mapping: ['horsepower'],
    },
    {
        truck_paper: 'EngineManufacturer',
        auto_trader: 'EngineManufacturer',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'EngineType',
        auto_trader: 'EngineType',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'EngineSize',
        auto_trader: 'EngineSize',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'EngineBrake',
        auto_trader: 'EngineBrake',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'OverDrive',
        auto_trader: 'OverDrive',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'Drive',
        auto_trader: 'Drive',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'TransmissionSpeed',
        auto_trader: 'TransmissionSpeed',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'SteeringWheelSide',
        auto_trader: 'SteeringWheelSide',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'Suspension',
        auto_trader: 'Suspension',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'RearAxles',
        auto_trader: 'RearAxles',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'FrontAxleLbs',
        auto_trader: 'FrontAxleLbs',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'RearAxleLbs',
        auto_trader: 'RearAxleLbs',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'AxleType',
        auto_trader: 'AxleType',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'FlipAxle',
        auto_trader: 'FlipAxle',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'FuelType',
        auto_trader: 'FuelType',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'Height',
        auto_trader: 'Height',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'Length',
        auto_trader: 'Length',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'Width',
        auto_trader: 'Width',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'InternalHeight',
        auto_trader: 'InternalHeight',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'Composition',
        auto_trader: 'Composition',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'Frame',
        auto_trader: 'Frame',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'Insulated',
        auto_trader: 'Insulated',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'Weight',
        auto_trader: 'Weight',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'CabType',
        auto_trader: 'CabType',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'DumpLocation',
        auto_trader: 'DumpLocation',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'HalfRound',
        auto_trader: 'HalfRound',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'Doors',
        auto_trader: 'Doors',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'SideDoors',
        auto_trader: 'SideDoors',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'BottomLoad',
        auto_trader: 'BottomLoad',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'NumberOfCompartments',
        auto_trader: 'NumberOfCompartments',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'FloorType',
        auto_trader: 'FloorType',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'Flatbed',
        auto_trader: 'Flatbed',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'LiftEndGate',
        auto_trader: 'LiftEndGate',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'HydraulicTail',
        auto_trader: 'HydraulicTail',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'NeckType',
        auto_trader: 'NeckType',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'Extendable',
        auto_trader: 'Extendable',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'Stinger',
        auto_trader: 'Stinger',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'Ratio',
        auto_trader: 'Ratio',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'Tires',
        auto_trader: 'Tires',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'WheelBase',
        auto_trader: 'WheelBase',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'Wheels',
        auto_trader: 'Wheels',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'SleeperType',
        auto_trader: 'SleeperType',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'SleeperSize',
        auto_trader: 'SleeperSize',
        defaultValue: '',
        mapping: ['sleeperSize'],
    },
    {
        truck_paper: 'ReeferManufacturer',
        auto_trader: 'ReeferManufacturer',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'Capacity',
        auto_trader: 'Capacity',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'TankType',
        auto_trader: 'TankType',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'Gallons',
        auto_trader: 'Gallons',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'PneumaticSize',
        auto_trader: 'PneumaticSize',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'VaporRecovery',
        auto_trader: 'VaporRecovery',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'RowSpacing',
        auto_trader: 'RowSpacing',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'BladeSpacing',
        auto_trader: 'BladeSpacing',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'NumberOfPassengers',
        auto_trader: 'NumberOfPassengers',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'NumberOfCars',
        auto_trader: 'NumberOfCars',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'NumberOfHoppers',
        auto_trader: 'NumberOfHoppers',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'NumberOfRows',
        auto_trader: 'NumberOfRows',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'NumberOfBottoms',
        auto_trader: 'NumberOfBottoms',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'Odometer - Reading',
        auto_trader: 'Odometer - Reading',
        defaultValue: '',
        mapping: ['odometer'],
    },
    {
        truck_paper: 'Odometer - Units',
        auto_trader: 'Odometer - Units',
        defaultValue: '',
        mapping: ['odometerUnits'],
    },
    {
        truck_paper: 'Hours',
        auto_trader: 'Hours',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'SeparatorHours',
        auto_trader: 'SeparatorHours',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'ReeferHours',
        auto_trader: 'ReeferHours',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'DealerGroupComments',
        auto_trader: 'DealerGroupComments',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'Certified',
        auto_trader: 'Certified',
        defaultValue: '',
        mapping: [''],
    },
    {
        truck_paper: 'ForSale',
        auto_trader: 'ForSale',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'ForSaleListPrice',
        auto_trader: 'ForSaleListPrice',
        defaultValue: '',
        mapping: ['price'],
    },
    {
        truck_paper: 'ForSaleWholesalePrice',
        auto_trader: 'ForSaleWholesalePrice',
        defaultValue: '',
        mapping: ['wholeSalePrice'],
    },
    {
        truck_paper: 'ForLease',
        auto_trader: 'ForLease',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'ForLeaseDownPayment',
        auto_trader: 'ForLeaseDownPayment',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'ForLeaseMonthlyPrice',
        auto_trader: 'ForLeaseMonthlyPrice',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'ForLeasePaymentFrequency',
        auto_trader: 'ForLeasePaymentFrequency',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'ForLeaseRateMaximum',
        auto_trader: 'ForLeaseRateMaximum',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'ForLeaseRateMinimum',
        auto_trader: 'ForLeaseRateMinimum',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'ForLeaseTermMonths',
        auto_trader: 'ForLeaseTermMonths',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'ForRent',
        auto_trader: 'ForRent',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'ForRentDailyRateMinimum',
        auto_trader: 'ForRentDailyRateMinimum',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'ForRentDailyRateMaximum',
        auto_trader: 'ForRentDailyRateMaximum',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'ForRentWeeklyRateMinimum',
        auto_trader: 'ForRentWeeklyRateMinimum',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'ForRentWeeklyRateMaximum',
        auto_trader: 'ForRentWeeklyRateMaximum',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'ForRentMonthlyRateMinimum',
        auto_trader: 'ForRentMonthlyRateMinimum',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'ForRentMonthlyRateMaximum',
        auto_trader: 'ForRentMonthlyRateMaximum',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'ForRentHourlyRateMinimum',
        auto_trader: 'ForRentHourlyRateMinimum',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'ForRentHourlyRateMaximum',
        auto_trader: 'ForRentHourlyRateMaximum',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'ForRentMileageRateMinimum',
        auto_trader: 'ForRentMileageRateMinimum',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'ForRentMileageRateMaximum',
        auto_trader: 'ForRentMileageRateMaximum',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'ForRentAcreRateMinimum',
        auto_trader: 'ForRentAcreRateMinimum',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'ForRentAcreRateMaximum',
        auto_trader: 'ForRentAcreRateMaximum',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'AcquisitionDate',
        auto_trader: 'AcquisitionDate',
        defaultValue: '',
        mapping: [],
    },
    {
        truck_paper: 'Pictures - URL',
        auto_trader: 'Pictures - URL',
        defaultValue: '',
        mapping: ['0-url','0-url'],
    }, 
    {
        truck_paper: 'Pictures - URL1',
        auto_trader: 'Pictures - URL1',
        defaultValue: '',
        mapping: ['1-url','1-url'],
    },
    {
        truck_paper: 'Pictures - URL2',
        auto_trader: 'Pictures - URL2',
        defaultValue: '',
        mapping: ['2-url','2-url'],
    },
    {
        truck_paper: 'Pictures - URL3',
        auto_trader: 'Pictures - URL3',
        defaultValue: '',
        mapping: ['3-url','3-url'],
    },
    {
        truck_paper: 'Pictures - URL4',
        auto_trader: 'Pictures - URL4',
        defaultValue: '',
        mapping: ['4-url','4-url'],
    },{
        truck_paper: 'Pictures - URL5',
        auto_trader: 'Pictures - URL5',
        defaultValue: '',
        mapping: ['5-url','5-url'],
    },{
        truck_paper: 'Pictures - URL6',
        auto_trader: 'Pictures - URL6',
        defaultValue: '',
        mapping: ['6-url','6-url'],
    },{
        truck_paper: 'Pictures - URL7',
        auto_trader: 'Pictures - URL7',
        defaultValue: '',
        mapping: ['7-url','7-url'],
    },{
        truck_paper: 'Pictures - URL8',
        auto_trader: 'Pictures - URL8',
        defaultValue: '',
        mapping: ['8-url','8-url'],
    },{
        truck_paper: 'Pictures - URL9',
        auto_trader: 'Pictures - URL9',
        defaultValue: '',
        mapping: ['9-url','9-url'],
    }
];
