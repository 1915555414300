import { ProductType } from '../product/product-type';
import { instanceToInstance, plainToInstance, Exclude, Expose, Type } from 'class-transformer';
import { ValidatorOptions } from 'class-validator';
import { TrailerSummary } from '../product/trailer';
import { sanitizeDateIPoint } from '../utility';
import { MapLabel } from '../map/map-label';
import { Picture } from '../inspection/picture';
import { IValidationMsg } from '../error-handling/validation-info';
import { SalesProductBase } from './sales-product-base';

@Exclude()
export class SalesTrailer extends SalesProductBase {

  constructor() {
    super();
    this.productType = ProductType.trailer;
   // this.pictures = [];

  }

  @Expose()
  productSummary: TrailerSummary;

  @Expose()
	get onlineStockNumber() {
		const slName = this.storeLocationSummary?.name;
		const vin = (this.productSummary as TrailerSummary)?.vin
		if(!!slName && !!vin){
			return `${slName.substring((slName.length) - 6, (slName.length))}-${vin?.substring((vin.length) - 6, (vin.length))}`;
		}else{
			return undefined;
		}
	}

  // @Expose()
  // @Type(() => Picture)
  // pictures: Picture[];

  get defaultImage() {
    if (this.productSummary.isReefer) {
      // to be extended for nAxle
      return './assets/dry_van_400_250.png';
    } else {
      return './assets/dry_van_400_250.png';
    }
  }

  public static parse(obj) {
		if (obj == null) { return null; }
		const m = plainToInstance<SalesTrailer, any>(SalesTrailer, sanitizeDateIPoint(obj));
		m.sanitize();
		return m;
	}

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  sanitize() {
    super.sanitize();
  }

  validateSync(options?: ValidatorOptions, ): IValidationMsg {
    const r = super.validateSync(options);
    return r;
  }

  getMapLabel(): MapLabel {
    return {
      title: 'T',
      id: this.id,
      productType: this.productSummary.productType
    };
  }

  /**
   * Get Product Summary.
   */
  public getTrailerSummary() {
    return {
      productSummary: this.productSummary,
    };
  }

  get summary() {
    return `${this.productSummary.trailerLength}' ${this.productSummary.nAxle}-Axle ` +
      `${(this.productSummary.isReefer) ? 'Refer Trailer' : 'Dry Van Trailer'}`;
  }

  createTitle() {
    let title: string;
    switch (this.productSummary.isReefer) {
      case false:
        title = (this.productSummary.make.split(' '))[0] + ' ' + this.productSummary.modelYear + ' ' +
          this.productSummary.nAxle + ' axle ' + 'dry-van';
        break;
      case true:
        title = (this.productSummary.make.split(' '))[0] + ' ' + this.productSummary.modelYear + ' ' +
          this.productSummary.nAxle + ' axle ' + 'reefer';
        break;
      default:
        title = null;
        break;
    }
    return title;
  }
}
