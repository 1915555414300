import { Exclude, Expose, instanceToInstance, plainToInstance } from 'class-transformer';
import { IsDefined, IsEnum, Length, Min, ValidateIf, ValidatorOptions } from 'class-validator';
import { BaseModel } from '../base';
import { IValDbStatusResult, IValidationMsg } from '../error-handling';
import { sanitizeDateIPoint } from '../utility';


export enum AddOnCategory {
	Misc = 0,
	Warranty = 1,
	Tires = 2,
	SafetyCertification = 3,
}
export type AddOnValidationGroup = 'general' | 'misc' | 'safety-cert' | 'warranty' | 'tires';
const general: AddOnValidationGroup = 'general';

@Exclude()
export class AddOnBase extends BaseModel {

	public static readonly collectionName = 'addon';

	@Expose()
	@IsEnum(AddOnCategory)
	@IsDefined({ message: 'Category should be selected', groups: [general] })
	category: AddOnCategory;

	@Expose()
	@ValidateIf(o => o.category === AddOnCategory.Misc, { groups: [general] })
	@Length(2, 150, { message: 'Name needs to be $constraint1-$constraint2 chars', groups: [general] })
	@IsDefined({ message: 'Enter name, e.g. Slotted brake', groups: [general] })
	name: string;

	@Expose()
	@ValidateIf(o => (o.category === AddOnCategory.Misc) || (o.category === AddOnCategory.Warranty) || (o.category === AddOnCategory.SafetyCertification), { groups: [general] })
	@Length(2, 250, { message: 'Description needs to be $constraint1-$constraint2 chars', groups: [general] })
	@IsDefined({ message: 'Description should be provided', groups: [general] })
	description: string;

	@Expose()
	isActive: boolean;

	@Expose()
	@ValidateIf(o => (o.category === AddOnCategory.Misc) || (o.category === AddOnCategory.Tires) || (o.category === AddOnCategory.SafetyCertification), { groups: [general] })
	@IsDefined({ message: 'Price is required', groups: [general] })
	@Min(1, { message: 'Price is cannot be zero', groups: [general] })
	price: number;

	constructor() {
		super();
		this.category = null;
		this.isActive = true;
		this.price = 0;
	}

	sanitize() {
		super.sanitize();
	}

	clone() {
		const t = instanceToInstance(this);
		t.sanitize();
		return t;
	}

	validateSync(options: ValidatorOptions) {
		const r = this.validateSyncBase(this);
		return r;
	}

	public static parse(obj) {
		if (obj == null) { return null; }
		const m = plainToInstance<AddOnBase, any>(AddOnBase, sanitizeDateIPoint(obj));
		m.sanitize();
		return m;
	}

	/**
   * Validate group wise form on UI
   * @param group 
   * @returns 
   */
	validateSyncGroup(group: AddOnValidationGroup): IValidationMsg {
		return this.validateSync({ groups: [group] });
	}

	/**
	 * manage left panel validation on UI
	 * @returns 
	 */
	getValDbStatus(): IValDbStatusResult {
		const result: IValDbStatusResult = {
			pass: false,
			message: undefined,
			groupResult: {}
		};

		let x: { groupPass: boolean; message: string; };

		// general
		let r = this.validateSyncGroup(general);
		x = { groupPass: null, message: '' };
		x.groupPass = Object.keys(r).length === 0;
		x.message = (x.groupPass) ? 'General Information Details' : 'General information is required';
		result.groupResult[general] = x;
		return result;
	}

	updateAddOn(s: AddOnBase) {
		this.category = s.category;
		this.name = s.name;
		this.description = s.description;
		this.isActive = s.isActive;
	}
}