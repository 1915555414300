import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { BasePage } from '@trent/models/UI/base.page';
import { readErrorMessage } from '@trent/models/error-handling';
import { logger } from '@trent/models/log/logger';
import { PriceLog, SalesProductBase } from '@trent/models/sales-option';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { EventService } from '@trent/services/event.service';
import { SalesOptionService } from '@trent/services/sales.service';
import { UtilityService } from '@trent/services/utility.service';
import { Observable } from 'rxjs';

@Component({
	selector: 'trent-price-log',
	templateUrl: './price-log.component.html',
	styleUrls: ['./price-log.component.scss']
})
export class PriceLogComponent extends BasePage<any> implements OnInit {

	logs = [];
	isLoading = false;
	isHandset$: Observable<boolean>;
	isHandset: boolean;

	constructor(
		private datePipe: DatePipe,
		public dialogRef: MatDialogRef<PriceLogComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		store: Store,
		eventService: EventService,
		dialogService: DialogService,
		public us: UtilityService,
	) {
		super(store, dialogService, eventService);
	}

	ngOnInit(): void {
		this.isHandset$ = this.us.isHandset$;
		this.mSubscription.push(this.us.isHandset$.subscribe(r => this.isHandset = r));
		this.logs = this.data.data;
	}

	// Method to close the dialog
	close(): void {
		this.dialogRef.close();
	}
}
