import { IUserClaim, IUserAuthClaim } from '@trent/models/user/user-claim';
import { UserProfile } from '../user/user-profile';
import { logger } from '../log/logger';
/** Keep it in sync with Role Names. IMPORTANT */

/**
 * @author - MKN 
 * Role group Level Enum - for managing different level of access
 */
export enum RoleGroupLevelEnum {

  /** Level 1 of the company */
  level1 = 'l1',

  /** Level 2 of the company */
  level2 = 'l2',

  /** Level 3 of the company */
  level3 = 'l3',

  /** Level 4 of the company */
  level4 = 'l4'
}

/**
 * @author - MKN 
 * Role group Level Rank - for managing different level of access level
 */
export enum RoleGroupLevelRank {

  /** Level 1 :  */
  level1 = 1,

  /** Level 2 :  */
  level2 = 2,

  /** Level 3 :  */
  level3 = 3,

  /** Level 4 :  */
  level4 = 4,

  unAuthorized = 0
}


/**
 * @author - MKN 
 * Role group Level interface - for assigning to company level and in user profile.
 */
export interface RoleGroupLevel {

  /** Level 1 :  */
  l1?: boolean;

  /** Level 2 :  */
  l2?: boolean;

  /** Level 3 :  */
  l3?: boolean;

  /** Level 4 :  */
  l4?: boolean;
}

/**
 * @author - MKN 
 * get Company Role Group Level
 */

export const getCompRGLevel = (rs: RoleGroupLevel | string): RoleGroupLevelRank => {
  if (rs == null) { return RoleGroupLevelRank.unAuthorized; }
  let r: RoleGroupLevel;
  if (typeof (rs) === 'string') {
    r = {};
    r[rs] = true;
  } else {
    r = <RoleGroupLevel>rs;
  }

  if (!!r.l1) { return RoleGroupLevelRank.level1; }
  if (!!r.l2) { return RoleGroupLevelRank.level2; }
  if (!!r.l3) { return RoleGroupLevelRank.level3; }
  if (!!r.l4) { return RoleGroupLevelRank.level4; }
  return RoleGroupLevelRank.unAuthorized;
};


/** For given Role group return highest role in company
 * @param roles: RoleGroupLevel
 */
export const getHighestRGLevel = (roleGroupLevel: RoleGroupLevel): RoleGroupLevel => {
 
  let aLevel = getCompRGLevel(roleGroupLevel);
  switch (aLevel) {
    case RoleGroupLevelRank.level1:
      return { l1: true };
    case RoleGroupLevelRank.level2:
      return { l2: true };
    case RoleGroupLevelRank.level3:
      return { l3: true };
    case RoleGroupLevelRank.level4:
      return { l4: true };

    default:
      return { l1: false, l2: false, l3: false, l4: false};
  }
};

/**
 * @author - MKN 
 * get Role Group Level string data
 */
export const getRGLevelString = (roleGroupLevel: RoleGroupLevel): string => {
  const k = Object.keys(roleGroupLevel)[0];
  switch (k) {
    case 'l1':
      return 'Level 1';
    case 'l2':
      return 'Level 2';
    case 'l3':
      return 'Level 3';
    case 'l4':
      return 'Level 4';

    default:
      throw new Error('Role not defined');
  }
};