import { Component, OnInit, OnDestroy, HostListener, AfterViewInit, Input, ViewChild, ElementRef, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { Router, ActivatedRoute } from '@angular/router';
import {
  AllRentalProductsRequested,
  RentalProductState,
  AllRentalProductNextPageRequested,
  RentalProductRequested,
} from '@trent/store/rental-product-store/rental-product.state';
import { Observable, Subscription, combineLatest, forkJoin } from 'rxjs';
import { RentOptionParam, rentOptionParamInit } from '@trent/models/rental/rent-option-param';
import { map } from 'rxjs/operators';
import { TimerHelper, toFirstWord } from '@trent/models/utility';
import { SiteMedia } from '@trent/models/media/site-media';
import { RentalProductBase } from '@trent/models/rental/rental-product-base';
import { BasePage } from '@trent/models/UI/base.page';
import { Paging } from '@trent/models/observable-util/paging';
import { ProductType } from '@trent/models/product';
import { UtilityService } from '@trent/services/utility.service';
import { EventService, IEventListener } from '@trent/services/event.service';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { AuthState } from '@trent/store/auth-store/auth.state';
import { DbStatus } from '@trent/models/base/db-status';
import { RentOptionStatus } from '@trent/models/rental/rent-option-status';
import { getMapLabelsForRentals, parseRentalProductArray } from '@trent/models/rental/rental-helper';
import { DbRule } from '@trent/models/base/db-rule';
import { logger } from '@trent/models/log/logger';
import { webSiteUrl } from '@trent/core/environments/fb-env';
import { MessageInfo, readErrorMessage } from '@trent/models/error-handling';
import { SocialShareComponent, ShareOn } from '../../shared/social-share/social-share.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MenuData, PaneType } from '@trent/models/UI/menu-data';
import { MapLabel, MapLabelClick } from '@trent/models/map/map-label';
import { MatDialog } from '@angular/material/dialog';
import { RentOptionSearchComponent } from '../search/rent-option-search.component';
import { PromoListsRequested, PromoListState } from '@trent/store/promo-list-store/promo-list.state';
import { IPromoListParam } from '@trent/models/promo/promo-list-param';
import { PromoListType } from '@trent/models/promo/promo-list-type';
import { getRentalAggListTypeId, getMapLabelsAggList } from '@trent/models/promo/promo-helper';
import { RentalTrailerAggregate, RentalTrailerItem } from '@trent/models/promo/rental-trailer-aggregate';
import { pickBy, isEqual, isEmpty, isNil, omitBy, omit } from 'lodash';
import { findDistance } from '@trent/models/utility/distf';
import { fromQueryStr, removeHttp, toQueryStr } from '@trent/models/utility/query-helper';
import { EnumHelper } from '@trent/models/utility/enum-helper';
import { RentalVehicalListItem } from '@trent/models/promo/rental-vehical-list-item';
import { addDays } from 'date-fns';
import { CmsState } from '@trent/store/cms-store';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UrlShortenService } from '@trent/services/shorten-url/shorten-url.service';
import { interval as observableInterval } from 'rxjs';
import { takeWhile, scan, tap } from 'rxjs/operators';
import { PageHtml, PageHtmlTemplate, PageHtmlType } from '@trent/models/cms/page-html';
import { PageMetaTag } from '@trent/models/cms/page-meta-tag';
import { SeoService } from '@trent/services/seo.service';
import { TprRentOptionListPageHtml } from '@trent/models/cms/tpr-rent-option-list-page-html';
import { IAuthCoreService } from '@trent/services/auth/iauth-core.service';
@Component({
  selector: 'trent-rent-option-list',
  templateUrl: './rent-option-list.component.html',
  styleUrls: ['./rent-option-list.component.scss'],
})
export class RentOptionListComponent extends BasePage<RentalProductBase[]> implements OnInit, OnDestroy, AfterViewInit {
  activePane: PaneType = 'left';
  isLeftVisible = true;

  // @ViewChild('picDetail', { static: false })
  // photoViewer: PhotoViewerComponent;

  pData: Paging;
  // @ViewChild('rentOptionParam', { static: true })
  // rentOptionParam: RentOptionParamComponent;
  param: RentOptionParam = {};

  pTypeEnum = ProductType;
  prodType: ProductType;
  rentalProducts$: Observable<RentalProductBase[]>;
  thumbNailImgTruck = 'legoTruck.jpg';
  thumbNailImgTailer = 'legoTrailer.jpg';
  thumbNailImgDriver = 'legoMan.jpg';
  endDate: any;
  startDate: any;
  lat: number;
  long: number;
  points$: Observable<any[]>;
  rentOptionsSub: Subscription;
  showFilter = true;
  searchTxt: string;
  availableDates: Date[];
  sDate: Date[];
  eDate: Date[];
  s: Date[];
  e: Date[];
  radius: number;
  /** any used instead of RentalProductBase to allow typing for sDate and eDate which are not in the rent-option model */
  rOptionsWithDates: any[]; // any used intentionally
  myRental: any;
  city: string;
  rentalSub: Subscription;
  mediaPictures: SiteMedia;
  rid: string | number;
  notLatestTitle: string;
  isHandset$: Observable<boolean>;
  isHandset: boolean;
  rentOptionParam: RentOptionParam;
  mapLabels: MapLabel[];
  aggregateList: RentalTrailerAggregate;
  showNoData: boolean;
  menuData: MenuData[] = [];
  pid: string | number;
  firstCard = 0;
  lastCard = 8;
  paramInit: RentOptionParam;
  isEditMode: boolean;
  isDesktopMapClicked: boolean = true;
  seo: SeoService = inject(SeoService);
  page: TprRentOptionListPageHtml = new TprRentOptionListPageHtml();
  pageTemplate: PageHtmlTemplate;
  private subs: Subscription[] = [];
  /**
   * @author - MKN
   * Hide Marketing ads index card when user perform any search
   */
  isMarketingAdsRequired: boolean = false;
  horizontalIncrement = 0;
  loggedIn: boolean = false;

  @Input()
  horizontalCarousel = false;

  @Input()
  isChild = false;

  constructor(
    store: Store,
    private router: Router,
    private auth: IAuthCoreService,
    private activatedRoute: ActivatedRoute,
    eventService: EventService,
    dialogService: DialogService,
    public dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private urlShortenService: UrlShortenService,
    private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, public es: EventService
  ) {
    super(store, dialogService, eventService);
    
      this.matIconRegistry.addSvgIcon('truckIcon', this.domSanitizer.bypassSecurityTrustResourceUrl(this.us.getFullUrl('assets/truck-semi.svg')));
      this.matIconRegistry.addSvgIcon('trailerIcon', this.domSanitizer.bypassSecurityTrustResourceUrl(this.us.getFullUrl('assets/dry_van.svg')));
    
  }

  ngAfterViewInit(): void {
    if (!this.horizontalCarousel) {
      setTimeout(() => {
        switch (this.prodType) {
          case ProductType.truck:
            this.title = 'Rental Trucks';
            break;
          case ProductType.trailer:
            this.title = 'Rental Trailers';
            break;

          default:
            break;
        }
      }, 1000);
    }
  }

  ngOnInit() {
    if (!this.isChild) {
      this.loadPageHtml();
    }
    this.subs.push(this.auth.isLoggedin$.subscribe(loggedIn => {
      this.loggedIn = loggedIn;
    }));
    this.isHandset$ = this.us.isHandset$;
    this.mSubscription.push(this.us.isHandset$.subscribe((h) => (this.isHandset = h)));
    this.mSubscription.push(this.store.select(CmsState.selectPageEditMode).subscribe(m => {
      this.isEditMode = m;
    }));
    // rent-option by ID can be used to share the link over sms, whatsapp, email e.g. /rent-option/rent-option-list/rid

    this.rid = this.activatedRoute.snapshot.paramMap.get('rid');
    const q: RentOptionParam = { ...{}, ...fromQueryStr(this.activatedRoute.snapshot.queryParams['q'] as any) };
    this.param = { ...q };
    if (!this.rid && this.param.rid) {//MKN - added condition to solved bug PGDT - 78(Share unit) 
      this.rid = this.param.rid;
    }

    // this.pid = this.activatedRoute.snapshot.paramMap.get('pid');
    // this.param.vCid = this.activatedRoute.snapshot.paramMap.get('vCid');
    this.prodType = this.activatedRoute.snapshot.data.pType as ProductType;
    if (!!this.horizontalCarousel) {
      this.prodType = ProductType.truck;
    }
    this.param.type = this.prodType;
    this.param.isExclusive = false;
    this.param.isActive = true;
    this.param.orderBy = !this.param.center ? 'availEndDate' : undefined;
    this.param.availEndDate = addDays(new Date(), 2);
    this.param.rIds = [];
    this.pData = { size: 30, offset: 0, full: false };
    this.mSubscription.push(this.user$.subscribe(u => {
      if (!!this.pid) {
        const sub = this.user$.subscribe((u) => {
          if (!!u && (this.defaultCompany || !!u.roles['editor'])) {
            this.param.pid = this.pid;
            this.param.isActive = true;
            if (this.defaultCompany) {
              this.param.vCid = this.defaultCompany?.cid;
            }
          }
        });
      }
    }));
    this.isMarketingAdsRequired = !isEmpty(q) ? false : true;
    /**
     * @author PT 
     * Solved bug PGDT-70
     */
    this.paramInit = { type: this.prodType, isExclusive: false, isActive: true, orderBy: 'availEndDate', availEndDate: addDays(new Date(), 2) };
    this.getStoreData();
    // if (!!prodType) { this.param = {}; this.param.type = prodType; }

    console.log('[rent-option-list] quaryParams', q);
    this.eventListeners.push(
      this.es.listen<boolean>(this.es.menuBackBtnClickCust, () => {
        if(this.isHandset){
          this.isDesktopMapClicked = true;
        }
        this.showLeft();
      }),
      this.es.listen<{ id: string; clickLabel: MapLabelClick }>(this.es.mapLabelActionById, (e) => this.mapLabelAction(e)),
      this.es.listen<MapLabel[]>(this.es.mapClusterAction, (e) => {
        this.mapClusterAction(e);
        if(this.isHandset){
          this.isDesktopMapClicked = true;
        }
      }
        ),
      this.es.listen<MapLabel[]>(this.es.clearFilter, () => {
        if (this.menuData.map((f) => f.title).includes('Cancel Search')) {
          this.clearSearch();
        }
      })
    );

    this.setupMobileTopMenuEvents();
    this.subMData();
    if (!isEmpty(q)) {
      this.addRemoveCancelFilter(true);
    }
    // if (Object.keys(this.param).filter((f) => f !== 'type').length > 0) {
    //   this.addRemoveCancelFilter(true);
    // }

    // if (!q) {
    //   this.getStoreData();
    // } else {
    //   this.getStoreDataByAggList(q);
    // }
    // const sub = combineLatest([this.user$, this.store.select(AuthState.userRentOptionsSearchOption)])
    //   .subscribe(([u, param]) => {
    //     if (param != null) {   // u != null && u.id != null &&
    //       this.param = rentOptionParamInit();
    //       this.param.center = { latitude: 43.6376466, longitude: -79.7566135 };
    //       this.param.radius = 10; // reset search radius
    //       // this.param.incRadius = 0;
    //       this.param.dbStatus = DbStatus.Released;
    //       this.param.cids = [];
    //       // this.param.isClientGeoFilterReq = true;
    //       this.param.rentOptionStatus = RentOptionStatus.open;
    //       // if (!!this.rid) { this.param = {}; this.param.cids = []; this.param.id = this.rid; }
    //       console.log('defaultCompany', this.defaultCompany);
    //       console.log(' List Page, RentOptions Search called with Parameters:', param);
    //       // unsubscribe the previous subscription
    //       if (!!this.rentalSub) { this.rentalSub.unsubscribe(); }

    //       // reset paging data
    //       this.pData = { size: 10, offset: 0, full: false };
    //       this.getStoreData();

    //     }
    //   });
    // this.mSubscription.push(sub);
  }

  dynamicMetaTags<T extends PageHtml>(p: T): PageMetaTag[] {
    return [
      { content: 'text/html; charset=utf-8', 'http-equiv': 'Content-Type' },
      { keyword: 'Monako Pride Truck, PgeNm, pride group, toronto' }
    ];
  }

  loadPageHtml<T extends PageHtml>() {
    // Setup the ID and page Html type.
    this.pageTemplate = {
      pid: 'rental-marketing-card',
      pageType: PageHtmlType.tprRentOptionList //  PageHtmlType.pageHtml
    };
    // Fire the page html edit event so that edit html can be utilized by admin on the top menu.
    this.es.emit<PageHtmlTemplate>(this.es.menuShowHtmlEdit, { ...this.pageTemplate });

    // Download the page html
    const s = this.seo.loadPageHtml(this.pageTemplate.pid, (p: T) => this.dynamicMetaTags(p), TprRentOptionListPageHtml)
      .subscribe(p => {
        this.page = p;
        if (!!this.page) {
          this.es.emit(this.es.app_setTitle, this.page.title);
        }
      });
    this.subs.push(s);
  }

  getStoreData() {
    this.m = [];
    
    this.store.dispatch(new AllRentalProductsRequested({ pData: this.pData, param: this.param }));

    const aListPData = { size: 1, offset: 0, full: false };
    const aType = getRentalAggListTypeId(this.param.type).listType;
    const aListParam: IPromoListParam = { type: aType };


    this.store.dispatch(new PromoListsRequested({ pData: aListPData, param: aListParam }));
    const rentals$ = this.store.select(RentalProductState.selectRentalAllProducts).pipe(map((clientFilterFn) => clientFilterFn(this.param)));
    const aggList$ = this.store.select(PromoListState.selectAllData).pipe(map((clientFilterFn) => clientFilterFn(aListParam)));
    this.mSubscription.push(combineLatest([rentals$, aggList$]).subscribe(([r, a]) => {
      // if (r == null) {
      //   this.m = [];
      // }
      if (!!r && r.length > 0 || (!!this.m && r.length > this.m.length)) {
        if (this.isUpdateReqd(r, parseRentalProductArray(this.m))) {
            this.m = <RentalProductBase[]>r;


          // if (this.m.length > 0) {
          //   // this.mapLabels = rentalMapLabels(this.m, this.defaultCompany?.cid);
          // }
          for (let i = 0; i < this.m.length; i++) {
            const p1 = this.m[i];
            p1.thumbNailImg = 'legoTruck.jpg';
          }
          logger.log(this.m);
          //MKN - Remove && this.m.filter((f) => f.id === this.rid).length === 0 condition to solve bug PGDT-78(Share unit)
          //i.e Sharing specific units to other users/non-users doesn't show the full hyperlink
          if (!!this.rid) {
            this.getByRid();
          }
          if (!!this.rid && !!DbRule.isFrozenReleaseBranch(this.rid)) {
            this.notLatestTitle = 'Record is not latest';
          }
        }
        this.mapLabels = getMapLabelsForRentals(this.m);

      }
      if (!!a && a.length > 0) {
        this.aggregateList = a[0] as RentalTrailerAggregate;
        // this.mapLabels = getMapLabelsAggList(this.aggregateList.list, this.prodType);
      }
      if (!r && r.length == 0 && !this.isMarketingAdsRequired) {
        if(!this.param.pid)//MKN for Bug PGDT 113
          this.getAlternativeUnitsStoreData();
      }
    }));
  }

  /**
   * @author - MKN
   * Fetch alternative unit when no data found for given search criteria
   */
  getAlternativeUnitsStoreData() {

    const m = new MessageInfo();
    m.msgCss = 'info';
    m.description = "No result found as per your search criteria but don't worry we will show our alternative products.";
    this.showPopover(m, 100);

    this.m = [];
    this.param = { ...this.paramInit };
    logger.log(this.param, "----------this.param");
    this.store.dispatch(new AllRentalProductsRequested({ pData: this.pData, param: this.param }));
    const rentals$ = this.store.select(RentalProductState.selectRentalAllProducts).pipe(map((clientFilterFn) => clientFilterFn(this.param)));
    this.mSubscription.push(rentals$.subscribe((r) => {
      // if (r == null) {
      //   this.m = [];
      // }
      if (!!r && r.length > 0 || (!!this.m && r.length > this.m.length)) {
        if (this.isUpdateReqd(r, parseRentalProductArray(this.m))) {
          this.m = <RentalProductBase[]>r;
          // if (this.m.length > 0) {
          //   // this.mapLabels = rentalMapLabels(this.m, this.defaultCompany?.cid);
          // }
          for (let i = 0; i < this.m.length; i++) {
            const p1 = this.m[i];
            p1.thumbNailImg = 'legoTruck.jpg';
          }
          logger.log(this.m);
          //MKN - Remove && this.m.filter((f) => f.id === this.rid).length === 0 condition to solve bug PGDT-78(Share unit)
          //i.e Sharing specific units to other users/non-users doesn't show the full hyperlink
          if (!!this.rid) {
            this.getByRid();
          }
          if (!!this.rid && !!DbRule.isFrozenReleaseBranch(this.rid)) {
            this.notLatestTitle = 'Record is not latest';
          }
        }
      }

    }));
  }

  shortTitle(str: string) {
    if (!!str) {
      return toFirstWord(str);
    }
    return '?';
  }
  getByRid() {
    if (!!this.m && this.m.length > 0 && this.m.filter((f) => f.id === this.rid).length > 0) {
      this.m = this.m.filter((f) => f.id === this.rid);
    } else {
      this.store.dispatch(new RentalProductRequested({ id: `${this.rid}` }));
    }
  }
  nextPage() {

    this.store.dispatch(new AllRentalProductNextPageRequested({ param: this.param }));


    // if (this.param.radius <= 99) {
    //   this.param.incRadius = this.param.incRadius + 25;
    //   this.store.dispatch(new AllRentalProductsRequested({ pData: this.pData, param: this.param }));
    // } else {
    //   this.store.dispatch(new AllRentalProductNextPageRequested({ param: this.param }));
    // }
  }
  toBid(id: string) {
    this.router.navigate([`bid/create/${id}`], this.rb);

      // if (!this.loggedIn) {
      //   this.router.navigate([`rent-option/product-details/${id}`], this.rb);
      // } else {
      //   this.router.navigate([`bid/create/${id}`], this.rb);
      // }
  }
  toVendorBids(id: string) {
    const q = { rb: true, rid: id };
    this.router.navigate(['bid/list-vendor'], { queryParams: q });
  }
  toBindingBid() {
    // this.router.navigate([`/bid-list-vendor/byBidId/${rentOptionStatus.bindingBidId}`]);
  }
  toggleFilter() {
    this.showFilter = true;
  }
  hideFilter() {
    this.showFilter = false;
  }
  edit(id: string | number) {
    this.router.navigate([`rent-option/detail/${id}`], this.rb);
  }
  ngOnDestroy(): void {
    this.cleanListeners();
    if (!!this.rentOptionsSub) {
      this.rentOptionsSub.unsubscribe();
    }
  }
  // #endregion
  toMap() {
    this.router.navigate([''], this.rb);
  }

  /** author - PT
     * purpose - to share the url with others
     * @param data - rental option object
     */
  share(data: RentalProductBase) {
    if (data.shortUrl) { //if short url is in the data object then we have to use the short url which is already created and stored in rental option
      this.shareProduct(data, data.shortUrl);
    } else { //if no short url then we have to create the short url
      const q = toQueryStr({ rid: data.id }, rentOptionParamInit());
      let url: string;
      // creating url
      switch (this.prodType) {
        case ProductType.truck:
          url = `${webSiteUrl.production}/rent-option/truck/list/?q=${q}`;
          break;
        case ProductType.trailer:
          url = `${webSiteUrl.production}/rent-option/trailer/list/?q=${q}`;
          break;

        default:
          throw new Error('Product type is not defined');
          break;
      }

      this.showLoading();

      // calling shorten url api
      this.urlShortenService.createShortenUrl(url, data.id)
        .subscribe(shortUrl => {
          this.hideLoading();
          if (shortUrl) {
            this.shareProduct(data, shortUrl);
          }
        },
          error => {
            logger.log('update error! hide loading called');
            logger.error(error);
            this.hideLoading();
            this.showAlert(readErrorMessage(error));
          });
    }
  }


  /**
  *  author - PT
   * @param rentOption
   * @param shortUrl 
   */
  shareProduct(rentOption: RentalProductBase, shortUrl: string) {
    const desc = `Details for rental ${EnumHelper.getName(ProductType, rentOption.productType)}`;
    logger.log(rentOption);
    if (!!(<any>navigator) && (<any>navigator).share) {
      (<any>navigator)

        .share({
          title: 'Rental',
          text: desc,
          url: shortUrl,
        })
        .then(() => {
          logger.log('Thanks for sharing!');
          const msg = new MessageInfo({
            msgCss: 'primary',
            header: desc,
            description: shortUrl,
          });
          this._bottomSheet.open(SocialShareComponent, {
            data: {
              title: 'Rental',
              text: desc,
              url: shortUrl,
              shareOn: [ShareOn.email, ShareOn.whatsapp],
            },
          });
        });
    }
  }

  showLeft() {
    logger.log(`[trailer] Before - GoBack ${this.isGoBackCust}, Left-Visible: ${this.isLeftVisible}`);
    this.param = {...this.paramInit};
    this.getStoreData();
    this.isLeftVisible = !this.isLeftVisible;
    this.activePane = this.isLeftVisible ? 'left' : 'right';
    this.isGoBackCust = !this.isLeftVisible;
    logger.log(`[trailer] After - GoBack ${this.isGoBackCust}, Left-Visible: ${this.isLeftVisible}`);
    // this.subMData();
  }
  openMap(id?: string) {
    if (this.isLeftVisible) {
      this.es.emit<string | number>('showOnMapById', id);
    }
    if(this.isHandset){
      this.isDesktopMapClicked = false;
    }
    this.showLeft();
    this.subMData();
  }

  scrollToTop() {
    if (this.isHandset) {
      const t = new TimerHelper();
      t.setInterval(100, 10, () => {
        if (!this.isLeftVisible) {
          this.us.scrollToTop();
          return true;
        }
        return false;
      });
    }
  }

  showOnMapById(id: string | number) {
    if (this.isLeftVisible) {
      this.es.emit<string | number>('showOnMapById', id);
      this.openMap(`${id}`);
    } else {
      this.es.emit<string | number>('showOnMapById', id);
    }
  }
  mapLabelAction(e: { id: string; clickLabel: MapLabelClick }): void {
    if (e.clickLabel === 'Rent') {
      logger.info('[rent-option-list], to bid action');
      this.toBid(e.id);
    } else {
      this.edit(e.id);
    }
  }
  mapClusterAction(e: MapLabel[]): void {
    console.log(
      'e.map(r => r.iPoint)',
      e.map((r) => r.iPoint)
    );
    this.firstCard = 0;
    this.lastCard = 8;
    const filtered = pickBy(this.aggregateList.list, (val, key) => e.map((ele) => ele.id).includes(key));
  
      this.param = {...this.paramInit, rIds: Object.keys(filtered)};
      this.getStoreData();

      if (this.isHandset) {
        // this.showLeft();
        this.isLeftVisible = false;
        this.activePane = 'left';
        this.isGoBackCust = !this.isLeftVisible;
      }

    this.addRemoveCancelFilter(true);
  }
  showSearch() {
    logger.log('search fired');
    // this.param.type = this.prodType;
    const dialogRef = this.dialog.open(RentOptionSearchComponent, {
      position: {
        top: '90px',
      },
      data: {
        // settingType: UserSettingType.tripParam,
        cid: this.defaultCompany?.cid,
        param: this.param,
      },
      minWidth: this.isHandset ? '90vw' : '500px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      logger.log('Dialog result:', result);
      if (!result) {
        this.param = {};
        this.addRemoveCancelFilter(false);
      } else {
        this.param = result;
        if (this.param.center) {
          this.param.orderBy = undefined;
        } else {
          this.param.orderBy = 'availEndDate';
        }
        this.addRemoveCancelFilter(true);
        this.isMarketingAdsRequired = false;
      }
      this.getStoreData();
      // this.getStoreDataBySearch();
    });
  }
  setUpMobileButtons() {
    if (!!this.isHandset) {
      this.showHideSearch(true);
    }
  }
  setupMobileTopMenuEvents() {
    if (!this.horizontalCarousel) {
      this.setUpMobileButtons();
      this.eventListeners.push(
        this.es.listen<boolean>(this.es.menuFireSearch, (search) => {
          if (search) {
            this.showSearch();
          }
        })
      );
    }
  }
  subMData() {
    if (!this.horizontalCarousel) {

      this.menuData = [];
      this.menuData.push(
        {
          type: 'button',
          title: 'Map View',
          icon: !this.isLeftVisible ? 'done' : null,
          color: !this.isLeftVisible ? 'accent' : 'primary',
          click: () => {
            this.firstCard = 0;
            this.lastCard = 8;
            this.openMap();
          },
        },
        {
          type: 'button',
          title: 'List View',
          icon: !!this.isLeftVisible ? 'done' : null,
          color: !!this.isLeftVisible ? 'accent' : 'primary',
          click: () => {
            this.firstCard = 0;
            this.lastCard = 8;
            this.showLeft();
            this.subMData();
          },
        },
        {
          type: 'button',
          title: 'Search',
          icon: 'search',
          color: 'primary',
          click: () => {
            this.showSearch();
          },
        }
      );

      // if (type === TransactionType.credit) {
      //   d.find(f => f.title === 'Vendor Orders').icon = null;
      //   d.find(f => f.title === 'Customer Orders').icon = 'done';
      //   d.find(f => f.title === 'Vendor Orders').color = null;
      //   d.find(f => f.title === 'Customer Orders').color = 'warn';
      // } else {
      //   d.find(f => f.title === 'Vendor Orders').icon = 'done';
      //   d.find(f => f.title === 'Customer Orders').icon = null;
      //   d.find(f => f.title === 'Vendor Orders').color = 'warn';
      //   d.find(f => f.title === 'Customer Orders').color = null;
      // }

      this.es.emit(this.es.loadPageOptions, this.menuData);
    }
  }
  getStoreDataByAggList() {
    const aListPData = { size: 1, offset: 0, full: false };
    const aType = getRentalAggListTypeId(this.prodType).listType;
    const aListParam: IPromoListParam = { type: aType };

    this.store.dispatch(new PromoListsRequested({ pData: aListPData, param: aListParam }));
    const aggList$ = this.store.select(PromoListState.selectAllData).pipe(map((clientFilterFn) => clientFilterFn(aListParam)));
    this.mSubscription.push(
      aggList$.subscribe((a) => {
        if (!!a && a.length > 0) {
          this.aggregateList = (a[0] as RentalTrailerAggregate).removeExlusive(this.defaultCompany?.cid);
          this.aggregateList = (a[0] as RentalTrailerAggregate).removeNotAvail();
          this.mapLabels = getMapLabelsAggList(this.aggregateList.list, this.prodType);
          this.getStoreDataBySearch();
        }
      })
    );
  }
  getStoreDataBySearch(radius = 100) {
    const rids: string[] = [];
    const d = this.aggregateList.filterAggList(this.param);
    const filtered = d.filtered;
    const alternate = d.alternate;
    this.es.emit<MapLabel[]>('showMapLabels', this.mapLabels);
    this.showNoData = isEmpty(filtered);
    if (!isEmpty(alternate)) {
      this.mapLabels = getMapLabelsAggList(alternate, this.prodType);
      this.getFilterdList(alternate, { start: 0, end: 3 });
    } else {
      this.mapLabels = getMapLabelsAggList(filtered, this.prodType);
      this.getFilterdList(filtered, { start: 0, end: undefined });
    }
  }
  getFilterdList(filtered: { [key: string]: RentalVehicalListItem }, page: { start: number; end: number } = { start: 0, end: 9 }) {
    // console.log('[rent-option-list] filtered', { filtered });
    // const rArray$: Observable<RentalProductBase>[] = [];
    this.m = [];
    // for (const id of Object.keys(filtered).slice(page.start, page.end)) {
    for (const id of Object.keys(filtered)) {
      this.store.dispatch(new RentalProductRequested({ id }));
      const rentalProd$ = this.store.select(RentalProductState.selectProductById).pipe(map((filterFn) => filterFn(id)));
      // rArray$.push(rentalProd$);

      this.mSubscription.push(
        rentalProd$.subscribe((d) => {
          if (!!d && this.m.findIndex((f) => f.id === d.id && f.revId === d.revId) === -1) {
            if (d.getOpenRentalDates().length > 0) {
              this.m.push(d);
            }
          }
        })
      );
    }
    // const rentalList$: Observable<RentalProductBase[]> = forkJoin(rArray$);
    // this.mSubscription.push(rentalList$.subscribe(r => {
    //   if (r?.length > 0 && !isEqual(this.m, r)) {
    //   this.m = r;
    //   }
    // }));
  }
  addRemoveCancelFilter(b: boolean) {
    if (!!b) {
      if (!this.menuData.map((f) => f.title).includes('Cancel Search')) {
        this.menuData.push({
          type: 'button',
          title: 'Cancel Search',
          icon: 'search_off',
          color: 'primary',
          click: () => {
            this.clearSearch();
          },
        });
      }
    } else {
      this.menuData = this.menuData.filter((f) => f.title !== 'Cancel Search');
    }
    this.es.emit(this.es.loadPageOptions, this.menuData);
  }
  get showCancelSearch() {
    if (!this.isHandset) {
      return false;
    }
    if (!this.isLeftVisible) {
      return false;
    }
    if (!this.menuData.map((f) => f.title).includes('Cancel Search')) {
      return false;
    }
    return true;
  }
  clearSearch() {
    this.param = { ...this.paramInit };
    this.getStoreData();
    this.addRemoveCancelFilter(false);
    this.isMarketingAdsRequired = true;
  }

  toReplacement(id: string | number) {
    this.router.navigate([`bid/create-contract/${id}`], this.rb);

  }
  loadNext() {
    this.nextPage();
    if (this.lastCard < this.m.length) {
      this.firstCard = this.firstCard + 8;
      this.lastCard = this.lastCard + 8;
    }
  }
  loadPrevious() {
    if (this.firstCard > 0) {
      this.firstCard = this.firstCard - 8;
      this.lastCard = this.lastCard - 8;
    }
    if (this.firstCard < 0) {
      this.firstCard = 0;
      this.lastCard = 8;

    }
  }

  get showPrevious() {
    return this.firstCard > 0;
  }
  get showNext() {
    return true;
    return this.lastCard < this.m.length;
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    return;
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 1) {
      //this.firstCard = this.firstCard - 5;
      this.loadNext();
      // this.lastCard = this.lastCard + 9;
      // this.firstCard = this.lastCard - 9;
    } else if (event.target.scrollTop <= 0) {
      this.loadPrevious();

      //   console.log('triggere top');
      //  this.firstCard = 0;
      //   this.lastCard = 9;
    }
  }
  get containerHeight() {
    if (this.us.isPlatformBrowser && this.m?.length > 0) {
      let mainToolBar: number;
      let cmdBar = 40;

      if (!this.isHandset) {
        mainToolBar = 80;
        cmdBar = 40;
      } else {
        mainToolBar = 60;
        cmdBar = 0;

      }
      const statusBarHeight = 35;
      const hAdjust = mainToolBar + cmdBar + statusBarHeight;
      const height = `calc(100vh - ${hAdjust}px)`;
      return height;
      // document.documentElement.style.setProperty('--hAdjust', `${hAdjust}px`);

    }
  }
  get searchOn() {
    return this.menuData.map((f) => f.title).includes('Cancel Search');
  }

  get cardsOnHorizontalPage() {
    if (this.isHandset) {
      return 1;
    } else {
      return 3;
    }
  }

  get horizontalStart() {
    return 0 + this.cardsOnHorizontalPage * this.horizontalIncrement;
  }
  get horizontalEnd() {
    if (this.cardsOnHorizontalPage + this.cardsOnHorizontalPage * this.horizontalIncrement > this.m.length) {
      return this.m.length;
    } else {
      return this.cardsOnHorizontalPage + this.cardsOnHorizontalPage * this.horizontalIncrement;
    }
  }
  get horizontalIndex() {
    return 1 + this.horizontalIncrement;
  }
  get horizontalPages() {
    if (this.isHandset) {
      return this.m.length;
    } else {
      return Math.ceil(this.m.length / 3);
    }
  }
  addHorizontalIncrement() {
    if (this.horizontalIndex <= this.horizontalPages - 1) {
      this.horizontalIncrement = this.horizontalIncrement + 1;
    }
  }
  subHorizontalIncrement() {
    if (this.horizontalIndex - 1 > 0) {
      this.horizontalIncrement = this.horizontalIncrement - 1;
    }
  }
  get hPages() {
    const p = [];
    if (this.m.length > 0) {
      for (let index = 0; index < this.horizontalPages; index++) {
        p.push(index);
      }
      return p;
    }
  }
  get isDesktopMapView() {

    if(!this.isHandset && this.menuData.find(f => f.title === 'Map View').icon === 'done') {
      return true;
    }
    return false;
  }
}