import { ProductType } from './product-type';
import { ProductBase } from './product-base';
import { Paging, IKeyVal, getDirection, OrderByDirection, getObjKey } from '../observable-util/paging';
import { DeepCopy } from '../utility/deep-copy';
import { logger } from '../log/logger';
import { CollectionReference, limit, orderBy, query, Query, QueryConstraint, startAfter, where } from 'firebase/firestore';
import { buildFilterString, Filter, Operator } from '../utility/filter-builder';
import { IAlgoliaQuery } from '@trent/services/algolia-search.service';
import { ImageUploadStatus as ImageUploadStatus, ImageType } from '../sales-option';
import { I } from '@angular/cdk/keycodes';

const desc: OrderByDirection = 'desc';
/**
 * Obtion settings for searching the product list on the product list page.
 */
export interface ProductSearchParam {

  /** Type of products to be searched */
  type?: ProductType;

  /** Parent Companies to be searched */
  cids?: string[];

  orderBy?: string;

  vin?: string;

  searchText?: string;

  //Search keys added for Yard person - PT
  imageType?: number;
  imageTypes?: number[];
  imageUploadStatus?: number;
  physicalLocationId?: number | string;
  make?: string;
  modelYear?: number;
  model?: number | string;
  country?: string;
  isActive?: boolean;
  vinLastSix?: string | number;
	odometerRange?: { from: number, to: number };
  pictureLastUpdatedByUid?: string | number;
  lastUpdatedRange?: { from: any, to: any };
}

export const prodSearchOrderBy: IKeyVal[] = [
  { key: 'Name', value: 'unitName' },
  { key: 'Update Date', value: `updatedAt desc` },
  { key: 'Creat Date', value: 'createdAt desc' },
  { key: 'Model year', value: 'modelYear desc' },
];

export const productOptionInit = (): ProductSearchParam => {
  return {
    type: <any>'*',
    cids: [],
    orderBy: 'createdAt desc'
  };
};

/** Get the OR condition options. if there are more then one company id's are provided
 *  each cid makes up a unique OR condition query. */
export const getProdSearchOptOrChildren = (o: ProductSearchParam): { [key: string]: ProductSearchParam } => {
  if (!!o.cids && o.cids.length > 1) {
    const r: { [key: string]: ProductSearchParam } = {};
    o.cids.forEach((cid) => {
      const c = DeepCopy.copy(o);
      c.cids = [cid];
      r[getObjKey(c)] = c;
    });
    return r;
  }
  return undefined;
};

/** Server filtering of firebase query building of query  */
export const productSerachServerQuery = (ref: CollectionReference, o: ProductSearchParam, p: Paging) => {
  const cons: QueryConstraint[] = [];
  if (!!o.type && o.type !== <any>'*') {
    cons.push(where('productType', '==', +o.type));
  }
  if (o.cids.length > 0) {
    cons.push(where('cid', '==', o.cids[0]));
  }


  if (!!o.physicalLocationId) {
    cons.push(where('physicalLocationSummary.sid', '==', o.physicalLocationId));
  }

  if (!!o.imageType) {
    cons.push(where('imageType', '==', o.imageType));
  }
  if (!!o.imageTypes && o.imageTypes.length > 0) {
    cons.push(where('imageType', 'in', o.imageTypes));
  }
  const { col, dir } = getDirection(prodSearchOrderBy, (!!o.orderBy) ? o.orderBy : 'createdAt desc');
  // const orderByCol = (!!o.orderBy) ? o.orderBy : 'unitName';

  cons.push(orderBy(col, dir));
  if (!!p.lastDoc) {
    logger.log('last doc: ', p.lastDoc[col]);
    cons.push(startAfter(p.lastDoc[col]));
  }

  cons.push(limit(p.size));
  return query(ref, ...cons);
};

/** Client Filtering of the data */
export const productSerachClientFilter = (p: ProductBase[], o: ProductSearchParam) => {

  // filter by type.
  if (o.type != null && <any>o.type !== '*') {
    p = p.filter((val, idx) => val.productType === +o.type);
  }

  // filter by cid
  if (o.cids.length > 0) {
    p = p.filter((val, idx) => o.cids.indexOf(`${val.cid}`) > -1);
  }

  if (!!o.physicalLocationId) {
    p = p.filter((val, idx) => val.physicalLocationSummary?.sid == o.physicalLocationId);
  }

  if (o.imageType != undefined) {
    p = p.filter((val, idx) => val.imageType === +o.imageType);
  }
  // Filter by image type array
  if (o.imageTypes) {
    p = p.filter((val) => o.imageTypes.indexOf(val.imageType) > -1);
  }

  // finally sorting.
  p = p.sort((a, b) => {
    let aVal = a[o.orderBy];
    let bVal = b[o.orderBy];
    if (aVal == null) {
      aVal = '';
      if (bVal != null) { return -1; }
    }

    if (bVal == null) {
      bVal = '';
      if (aVal != null) { return 1; }
    }

    if (typeof aVal === 'number' || Object.prototype.toString.call(aVal) === '[object Date]') {
      // logger.log(`${aVal} : ${bVal} : ${aVal < bVal}`);
      return aVal - bVal;
    }
    // if (typeof aVal === 'string') {
    // logger.log(`${aVal.toLocaleLowerCase()} : ${bVal.toLocaleLowerCase()} : ${aVal.toLowerCase() < bVal.toLowerCase()}`);
    return aVal.toLowerCase() < bVal.toLowerCase() ? -1 : 1;
    // }
  });

  return p;
};

/** Search product using algolia search API  */
export const ProductSearchAlgolia = (param: ProductSearchParam, p: Paging): { query: IAlgoliaQuery, indexName: string } => {
  const cons: Filter[] = [];
  const query: IAlgoliaQuery = {
    query: '',
    restrictSearchableAttributes: [],
    hitsPerPage: p.size as number,
    page: p.offset as number
  };
  let indexName = 'product';

  if (!!param.type) {
    cons.push({ attributeName: 'productType', operator: Operator.EQUAL, value: param.type });
  }

  if (!!param.cids && param.cids.length > 0) {
    cons.push({ attributeName: 'cid', operator: Operator.COLON, value: param.cids });
  }

  if (!!param.physicalLocationId) {
    cons.push({ attributeName: 'physicalLocationSummary.sid', operator: Operator.COLON, value: param.physicalLocationId });
  }
  if (+param.imageType === ImageType.notApplicable) {
    cons.push({ attributeName: 'picturesLength', operator: Operator.EQUAL, value: 0 });
  }
  if (param.imageType != undefined && (+param.imageType !== ImageType.notApplicable)) {
    cons.push({ attributeName: 'imageType', operator: Operator.EQUAL, value: param.imageType });
  }
  if (!!param.imageTypes && param.imageTypes.length > 0) {
    cons.push({ attributeName: 'imageType', operator: Operator.EQUAL, value: param.imageTypes });
  }
  if (!!param.imageUploadStatus && param.imageUploadStatus === ImageUploadStatus.pending) {
    cons.push(
      { attributeName: 'picturesLength', operator: Operator.EQUAL, value: 0 },
      { attributeName: 'imageType', operator: Operator.NOT_EQUAL, value: ImageType.notApplicable },
    );
  }
  if (param.isActive != undefined) {
    cons.push({ attributeName: 'isActive', operator: Operator.COLON, value: param.isActive });
  }

  if (!!param.searchText) {
    query.query += param.searchText;
  }

  if (!!param.make) {
    query.query += param.make;
    query.restrictSearchableAttributes.push('make');
  }

  if (!!param.model) {
    cons.push({ attributeName: 'model', operator: Operator.COLON, value: param.model });
  }

  if (!!param.modelYear) {
    cons.push({ attributeName: 'modelYear', operator: Operator.EQUAL, value: param.modelYear });
  }

  if (!!param.country) {
    cons.push({ attributeName: 'physicalLocationSummary.address.country', operator: Operator.COLON, value: param.country });
  }

  if (!!param.vinLastSix) {
    cons.push({ attributeName: 'vinLastSix', operator: Operator.COLON, value: param.vinLastSix });
  }

  if (!!param.vin) {
    query.query += param.vin;
    query.restrictSearchableAttributes.push('vin');
    query.restrictSearchableAttributes.push('vinLastSix');
  }

  if (!!param.odometerRange) {
		cons.push({ attributeName: 'odometer', operator: Operator.GREATER_THAN_EQ, value: param.odometerRange.from });
		cons.push({ attributeName: 'odometer', operator: Operator.LESS_THAN_EQ, value: param.odometerRange.to });
	}

  if (!!param.pictureLastUpdatedByUid) {
    cons.push({ attributeName: 'pictureLastUpdateUserDetails.uid', operator: Operator.COLON, value: param.pictureLastUpdatedByUid });
  }


  if (!!param.lastUpdatedRange) {
    cons.push({ attributeName: 'pictureLastUpdatedAt', operator: Operator.GREATER_THAN_EQ, value: param.lastUpdatedRange.from });
    cons.push({ attributeName: 'pictureLastUpdatedAt', operator: Operator.LESS_THAN_EQ, value: param.lastUpdatedRange.to });
  }

  query.filters = buildFilterString(cons);
  return { query, indexName };
};