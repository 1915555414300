<div class="list-container-desktop">
	<ng-container>
		<div class="table-area">
			<div class="table_header">
				<div class="search-input-close">
					<mat-form-field style="margin-bottom: -1.25em">
						<mat-label>Filter</mat-label>
						<input type="search" placeholder="Search by unit Name, VIN, make" autocomplete="off" matInput [(ngModel)]="searchField" (ngModelChange)="this.searchFieldUpdate.next($event)">
					</mat-form-field>
					<button mat-icon-button color="primary" (click)="close()">
						<mat-icon aria-label="Edit" matBadgeColor="warn">cancel</mat-icon>
					</button>
				</div>
			</div>
			<div class="table" *ngIf="dataSource?.data?.length > 0">
				<ng-container>
					<table mat-table [dataSource]="dataSource">
		
						<!-- Name Column -->
						<ng-container matColumnDef="description">
							<th mat-header-cell *matHeaderCellDef> Description </th>
							<td mat-cell *matCellDef="let element" class="cursor-pointer"  (click)="selectProduct(element)">
							{{element.unitName}} - {{element.vin}} <br>
							{{element.make}}<span *ngIf="element?.model">,&nbsp;{{element.model}}</span>
							<br>
							<span *ngIf="element?.modelYear">Year - {{element.modelYear}}</span>
							<span *ngIf="element?.unitColor">,&nbsp;Color - {{element.unitColor}}</span>
							</td>
						</ng-container>
						<ng-container matColumnDef="pic">
							<th mat-header-cell *matHeaderCellDef> Pic </th>
							<td mat-cell *matCellDef="let element; let i=index;" class="cursor-pointer"  (click)="selectProduct(element)">
								<ng-container *ngIf="!!element?.pictures">
									<ng-container  *ngFor="let p of element?.pictures | slice:0:1">
										<img [src]="p?.serverUrl" (error)="handleImageError(p)"   alt="" srcset="" width="40px">
									</ng-container>
								</ng-container>
								<ng-container *ngIf="!element?.pictures">
									<img src="../../../../../assets/pride-truck-sales-logo.webp"   alt="" srcset="" width="40px">
								</ng-container>
							</td>
						</ng-container>
						<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
						<tr mat-row	*matRowDef="let row; columns: displayedColumns;">
						</tr>
					</table>
				</ng-container>
			</div>  
		</div>
	</ng-container>
  
	<ng-container *ngIf="dataSource?.data?.length === 0" >
		<p>No Products found.</p>
	</ng-container>
  </div>
  