import { Pipe, PipeTransform } from '@angular/core';

const toPascalSentence = (text: string) => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};


/**
 *  <select>
     <option *ngFor="let item of countries | enumPipe" [value]="item.key">{{item.value}}</option>
    </select>
 */
@Pipe({ name: 'enumPipe' })
export class EnumPipe implements PipeTransform {
  transform(value, args?: string[]): any {
    const keys = [];
    for (const enumMember in value) {
      if (!isNaN(parseInt(enumMember, 10))) {
        // console.log('is number: ', parseInt(enumMember, 10));
        keys.push({
          // key: enumMember,
          // value: (isNaN(+value[enumMember])) ? value[enumMember] : +value[enumMember]

          key: parseInt(enumMember, 10),
          value: toPascalSentence(value[enumMember])
        });
      }
    }
    return keys;
  }
}

@Pipe({ name: 'exponentialStrength' })
export class ExponentialStrengthPipe implements PipeTransform {
  transform(value: number, exponent: string): number {
    const exp = parseFloat(exponent);
    return Math.pow(value, isNaN(exp) ? 1 : exp);
  }
}


