import { Paging } from "../observable-util/paging";
import { MinimumSellingPrice } from "./minimum-selling-price";
import { CollectionReference, Query, query, QueryConstraint, where, orderBy, startAfter, limit } from 'firebase/firestore';

export interface MinimumSellingPriceParam {
	orderDirection?: 'desc' | 'asc';
	orderBy?: string;
	pid?: string | number; // used only for client filter
	salesOptionId?: string | number; // used only for client filter
	dbStatus?: number;
}

/** Server filtering of firebase query building of query  */
export const minimumSellingPriceSearchServerQuery = (ref: CollectionReference, param: MinimumSellingPriceParam, p: Paging) => {
	let q: Query = <any>ref;
	const cons: QueryConstraint[] = [];
	cons.push(where('updatedAt', '<=', new Date()));
	cons.push(orderBy('updatedAt', 'desc'));
	if(param.pid) cons.push(where('productId', '==', `${param.pid}`));
	if(param.salesOptionId) cons.push(where('salesOptionId', '==', `${param.salesOptionId}`));

	if (!!p.lastDoc) {
	  cons.push(startAfter(p.lastDoc[param.orderBy]));
	}
	cons.push(limit(p.size));
	q = query(ref, ...cons);
	return q;
  };

/** Client Filtering of the data */
export const minimumSellingPriceSearchClientFilter = (p: MinimumSellingPrice[], o: MinimumSellingPriceParam): MinimumSellingPrice[] => {

	// only used as client filter
	if (o.pid) {
		p = p.filter((val, idx) => val.productId === o.pid);
	}
	if (o.salesOptionId) {
		p = p.filter((val, idx) => val.salesOptionId === o.salesOptionId);
	}
	return p as MinimumSellingPrice[];
};

export const getMinimumSellingPriceOptionOrChildren = (o: MinimumSellingPriceParam): { [key: string]: MinimumSellingPriceParam } => {

	return undefined;
  };