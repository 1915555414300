import { InspectionData, InspectionResponse, InspectionValidationGroup, trailerTires } from './inspection-data';
import { Exclude, Expose, Type } from 'class-transformer';
import { instanceToInstance, plainToInstance } from 'class-transformer';
import { Inspection } from './inspection';
import { InspectionStatus } from './inspection-status';
import { IValDbStatusResult, IValidationMsg } from '../error-handling';
import { Trailer } from '../product/trailer';
import { sanitizeDateIPoint } from '../utility';
import { TireInspection } from './tire-inspection';
import { ValidateNested, IsDefined, IsEnum } from 'class-validator';
import { ProductType } from '../product/product-type';
import { IInspectionPersistentData, ITrailerTireData } from './i-inpection-presistent-data';


@Exclude()
export class InspectionDataTrailer extends InspectionData {

  constructor() {
    super();
    this.pictures = [];
    this.location = { geopoint: { latitude: null, longitude: null }, geohash: null };
    this.location.geopoint = { latitude: null, longitude: null };
    this.isIncidentalCharges = true;
  }

  @Expose()
  vehType = 'trailer';

  @Expose()
  @IsEnum(ProductType)
  productType = ProductType.trailer;

  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [trailerTires] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [trailerTires] })
  axleOneOuterDriver: TireInspection = new TireInspection();

  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [trailerTires] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [trailerTires] })
  axleOneInnerDriver: TireInspection = new TireInspection();

  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [trailerTires] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [trailerTires] })
  axleTwoOuterDriver: TireInspection = new TireInspection();

  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [trailerTires] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [trailerTires] })
  axleTwoInnerDriver: TireInspection = new TireInspection();

  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [trailerTires] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [trailerTires] })
  axleThreeOuterDriver: TireInspection = new TireInspection();

  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [trailerTires] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [trailerTires] })
  axleThreeInnerDriver: TireInspection = new TireInspection();

  // @Expose()
  // @ValidateNested({ message: 'Add Tire Details', groups: [trailerTires] })
  // @Type(() => TireInspection)
  // @IsDefined({ message: 'Add Tire Details', groups: [trailerTires] })
  // axleFourOuterDriver: TireInspection = new TireInspection();

  // @Expose()
  // @ValidateNested({ message: 'Add Tire Details', groups: [trailerTires] })
  // @Type(() => TireInspection)
  // @IsDefined({ message: 'Add Tire Details', groups: [trailerTires] })
  // axleFourInnerDriver: TireInspection = new TireInspection();

  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [trailerTires] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [trailerTires] })
  axleOneOuterPassenger: TireInspection = new TireInspection();

  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [trailerTires] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [trailerTires] })
  axleOneInnerPassenger: TireInspection = new TireInspection();

  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [trailerTires] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [trailerTires] })
  axleTwoOuterPassenger: TireInspection = new TireInspection();

  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [trailerTires] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [trailerTires] })
  axleTwoInnerPassenger: TireInspection = new TireInspection();

  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [trailerTires] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [trailerTires] })
  axleThreeOuterPassenger: TireInspection = new TireInspection();

  @Expose()
  @ValidateNested({ message: 'Add Tire Details', groups: [trailerTires] })
  @Type(() => TireInspection)
  @IsDefined({ message: 'Add Tire Details', groups: [trailerTires] })
  axleThreeInnerPassenger: TireInspection = new TireInspection();

  // @Expose()
  // @ValidateNested({ message: 'Add Tire Details', groups: [trailerTires] })
  // @Type(() => TireInspection)
  // @IsDefined({ message: 'Add Tire Details', groups: [trailerTires] })
  // axleFourOuterPassenger: TireInspection = new TireInspection();

  // @Expose()
  // @ValidateNested({ message: 'Add Tire Details', groups: [trailerTires] })
  // @Type(() => TireInspection)
  // @IsDefined({ message: 'Add Tire Details', groups: [trailerTires] })
  // axleFourInnerPassenger: TireInspection = new TireInspection();

  public static parse(obj) {
    if (obj == null) { return null; }

    if (obj instanceof (InspectionDataTrailer)) { return <InspectionDataTrailer>obj; }

    const m = plainToInstance<InspectionDataTrailer, any>(InspectionDataTrailer, sanitizeDateIPoint(obj));
    m.sanitize();
    m?.tires?.forEach(element => {
      element.sanitize();
    });
    return m;
  }

  sanitize() {
    // super.sanitize();
  }

  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  validateSyncGroup(group: InspectionValidationGroup, pickDrop: Inspection, action: InspectionStatus): IValidationMsg {
    return this.validateSync({ groups: [group], pickDrop, action });
  }

  validateSync(options?: any, pickDrop?: Inspection, action?: InspectionStatus): IValidationMsg {
    const r = super.validateSync(options, pickDrop, action);
    return r;
  }

  getValDbStatus(inspection: Inspection, action: InspectionStatus): IValDbStatusResult {
    let x: { groupPass: boolean; message: string; };

    const result = super.getValDbStatus(inspection, action);

    // tires
    let r = this.validateSyncGroup(trailerTires, inspection, action);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Tire Inspection' : 'Tire inspection is required';
    result.groupResult[trailerTires] = x;
    
    // is it passed all the tests? (True means it failed here)
    result.pass = !Object.keys(result.groupResult).some((k) => !result.groupResult[k].groupPass);
    // passed.
    return result;
  }

  setTruckTireData(product: Trailer) {
    this.axleOneOuterDriver = (product?.inspectionPersistentData?.tireData as ITrailerTireData)?.axleOneOuterDriver;
    this.axleOneInnerDriver = (product?.inspectionPersistentData?.tireData as ITrailerTireData)?.axleOneInnerDriver;
    this.axleTwoOuterDriver = (product?.inspectionPersistentData?.tireData as ITrailerTireData)?.axleTwoOuterDriver;
    this.axleTwoInnerDriver = (product?.inspectionPersistentData?.tireData as ITrailerTireData)?.axleTwoInnerDriver;
    this.axleThreeOuterDriver = (product?.inspectionPersistentData?.tireData as ITrailerTireData)?.axleThreeOuterDriver;
    this.axleThreeInnerDriver = (product?.inspectionPersistentData?.tireData as ITrailerTireData)?.axleThreeInnerDriver;
    // this.axleFourOuterDriver = (product?.inspectionPersistentData?.tireData as ITrailerTireData)?.axleFourOuterDriver;
    // this.axleFourInnerDriver = (product?.inspectionPersistentData?.tireData as ITrailerTireData)?.axleFourInnerDriver;
    this.axleOneOuterPassenger = (product?.inspectionPersistentData?.tireData as ITrailerTireData)?.axleOneOuterPassenger;
    this.axleOneInnerPassenger = (product?.inspectionPersistentData?.tireData as ITrailerTireData)?.axleOneInnerPassenger;
    this.axleTwoOuterPassenger = (product?.inspectionPersistentData?.tireData as ITrailerTireData)?.axleTwoOuterPassenger;
    this.axleTwoInnerPassenger = (product?.inspectionPersistentData?.tireData as ITrailerTireData)?.axleTwoInnerPassenger;
    this.axleThreeOuterPassenger = (product?.inspectionPersistentData?.tireData as ITrailerTireData)?.axleThreeOuterPassenger;
    this.axleThreeInnerPassenger = (product?.inspectionPersistentData?.tireData as ITrailerTireData)?.axleThreeInnerPassenger;
    // this.axleFourOuterPassenger = (product?.inspectionPersistentData?.tireData as ITrailerTireData)?.axleFourOuterPassenger;
    // this.axleFourInnerPassenger = (product?.inspectionPersistentData?.tireData as ITrailerTireData)?.axleFourInnerPassenger;
  }

    /** reset properties which are expected to change during return */
    resetPropsForReturn() {
      this.isVinVerified = false;
      this.isPlateNoVerified = false;
      this.pictures = [];
      this.tires.forEach(f => {
        f.showVerify = true;
        f.isMakeVerified = false;
        f.isDateOfManufacturingVerified = false;
      });
      this.exteriorInspectionComments = undefined;
      
  
    }

  /** reset properties if customer dropped with return inspection  */
  resetPropsForReturnWhenReturnExists() {
    this.isVinVerified = false;
    this.isPlateNoVerified = false;
    this.tires.forEach(f => {
      f.showVerify = true;
      f.isMakeVerified = false;
      f.isDateOfManufacturingVerified = false;
    });

  }

  getPersistentData(): IInspectionPersistentData {
    const d: IInspectionPersistentData = {};
    d.tireData = {
      axleOneOuterDriver: this.axleOneOuterDriver,
      axleOneInnerDriver: this.axleOneInnerDriver,
      axleTwoOuterDriver: this.axleTwoOuterDriver,
      axleTwoInnerDriver: this.axleTwoInnerDriver,
      axleThreeOuterDriver: this.axleThreeOuterDriver,
      axleThreeInnerDriver: this.axleThreeInnerDriver,
      // axleFourOuterDriver: this.axleFourOuterDriver,
      // axleFourInnerDriver: this.axleFourInnerDriver,
      axleOneOuterPassenger: this.axleOneOuterPassenger,
      axleOneInnerPassenger: this.axleOneInnerPassenger,
      axleTwoOuterPassenger: this.axleTwoOuterPassenger,
      axleTwoInnerPassenger: this.axleTwoInnerPassenger,
      axleThreeOuterPassenger: this.axleThreeOuterPassenger,
      axleThreeInnerPassenger: this.axleThreeInnerPassenger,
      // axleFourOuterPassenger: this.axleFourOuterPassenger,
      // axleFourInnerPassenger: this.axleFourInnerPassenger
    };
    return d;
  }

  setPropertiesToReview() {
    super.setPropertiesToReview();
    this.showVerify = true;
    this.tires.forEach(f => f.showVerify = true);
  }

  /**
   * @author - PT
   * @purpose - to get all the tires which are in model
   */
  get tires() {
    const t = [this.axleOneOuterDriver, this.axleOneInnerDriver, this.axleTwoOuterDriver, this.axleTwoInnerDriver,
    this.axleThreeOuterDriver, this.axleThreeInnerDriver, 
    // this.axleFourOuterDriver, this.axleFourInnerDriver,
    this.axleOneOuterPassenger, this.axleOneInnerPassenger, this.axleTwoOuterPassenger, this.axleTwoInnerPassenger,
    this.axleThreeOuterPassenger, this.axleThreeInnerPassenger, 
    // this.axleFourOuterPassenger, this.axleFourInnerPassenger
    ];
    if (t.findIndex(f => typeof f === 'undefined') > -1) {
      return [];
    }
    return t;
  }

  /**
   * @author - PT
   * @purpose - to get drive side tires only
   */
  get tiresDSide() {
    const t = [
      this.axleOneOuterDriver, this.axleOneInnerDriver,
      this.axleTwoOuterDriver, this.axleTwoInnerDriver,
      this.axleThreeInnerDriver, this.axleThreeOuterDriver,
      // this.axleFourOuterDriver, this.axleFourInnerDriver
    ];
    if (t.findIndex(f => typeof f === 'undefined') > -1) {
      return [];
    }
    return t;
  }

  /**
   * @author - PT
   * @purpose - to get Passenger side tires only
   */
  get tiresPSide() {
    const t = [
      this.axleOneOuterPassenger, this.axleOneInnerPassenger,
      this.axleTwoOuterPassenger, this.axleTwoInnerPassenger,
      this.axleThreeInnerPassenger, this.axleThreeOuterPassenger,
      // this.axleFourOuterPassenger, this.axleFourInnerPassenger
    ];
    if (t.findIndex(f => typeof f === 'undefined') > -1) {
      return [];
    }
    return t;
  }

  /**
   * @author - PT
   * @purpose - check and uncheck all the drives depending upon the condition
   */
  get checkAllDrive() {
    if (this.tires.findIndex(f => !f.isMakeVerified || !f.isDateOfManufacturingVerified) > -1) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * @author - PT
   * @purpose - check and uncheck only dive tires depending upon the condition
   */
  get checkAllDriveD() {
    if (this.tiresDSide.findIndex(f => !f.isMakeVerified || !f.isDateOfManufacturingVerified) > -1) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * @author - PT
   * @purpose - check and uncheck only passengerTires depending upon the condition
   */
  get checkAllDriveP() {
    if (this.tiresPSide.findIndex(f => !f.isMakeVerified || !f.isDateOfManufacturingVerified) > -1) {
      return false;
    } else {
      return true;
    }
  }

  /**
     * Setup Inspection response based on action and reference inspection
     */
  setupInspectionResponseTrailer(action: InspectionStatus, inspection: Inspection) {
    if (inspection.productSummary.productType !== ProductType.trailer) {
      throw new Error('incorrect fn called for product is not trailer');
    }
    let ref: InspectionData;
    switch (action) {
      case InspectionStatus.vendorDropped:
        this.inspectionResponse = InspectionResponse.initialize;
        break;
      case InspectionStatus.customerPicked:
        ref = inspection.vendorDrop;
        if (!ref) {
          throw new Error('incorrect fn called for product is not truck');
        } else {
          if (this.hasTrailerInspectionChanged(ref)) {
            this.inspectionResponse = InspectionResponse.update;
          } else {
            this.inspectionResponse = InspectionResponse.accept;
          }
        }
        break;
      case InspectionStatus.customerDropped:
        this.inspectionResponse = InspectionResponse.initialize;
        break;
      case InspectionStatus.vendorPicked:
        ref = inspection.customerDrop;
        if (!ref) {
          this.inspectionResponse = InspectionResponse.accept;
        } else {
          if (this.hasTrailerInspectionChanged(ref)) {
            this.inspectionResponse = InspectionResponse.update;
          } else {
            this.inspectionResponse = InspectionResponse.accept;
          }
        }
        break;

      default:
        throw new Error('error calling setupInspectionResponseTruck');
    }
  }

  hasTrailerInspectionChanged(ref: InspectionData) {
    if (this.hasExtCommentsChanged(ref) || this.hasExtPicChanged(ref)) {
      return true;
    } else {
      return false;
    }
  }
}
