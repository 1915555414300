import { Address } from "../address/address";
import { Expose, instanceToInstance, plainToInstance } from 'class-transformer';
import { IsDefined, ValidateIf } from 'class-validator';
import { ValidatorOptions } from "class-validator/types/validation/ValidatorOptions";
import { IValidationMsg } from "../error-handling";
import { sanitizeDateIPoint } from "../utility";
import { BaseModel } from "../base";
import { StoreLocationSummary } from "../store-location/store-location";
import { distanceBetween } from 'geofire-common';
import { ProductSummary } from "./product-helper";

const transfer = 'transfer';

export enum TransferStatus {
	initiated = 0,
	inTransit = 1,
	hold = 2,
	delivered = 3,
}

export enum LocationType {
	gps = 0,
	inventory = 1,
	address = 2
}

export interface LogUpdatedBy {
	uid: string | number;
	displayName: string;
}

export interface TransferLog {
	status: TransferStatus;
	updatedAt: Date;
	updatedBy: LogUpdatedBy;
}

export class InventoryTransfer extends BaseModel {

	sourceLocationType: LocationType;
	destinationLocationType: LocationType;

	@Expose()
	@ValidateIf(o => o.sourceLocationType === LocationType.gps, { groups: [transfer] })
	@IsDefined({ message: 'GPS is required', groups: [transfer] })
	sourceLocationGPS: Address;

	@Expose()
	@ValidateIf(o => o.sourceLocationType === LocationType.inventory, { groups: [transfer] })
	@IsDefined({ message: 'Inventory location is required', groups: [transfer] })
	sourceLocationInventory: StoreLocationSummary;

	@Expose()
	@ValidateIf(o => o.sourceLocationType === LocationType.address, { groups: [transfer] })
	@IsDefined({ message: 'Address is required', groups: [transfer] })
	sourceLocationAddress: Address;

	@Expose()
	@ValidateIf(o => o.destinationLocationType === LocationType.inventory, { groups: [transfer] })
	@IsDefined({ message: 'Inventory location is required', groups: [transfer] })
	destinationInventory: StoreLocationSummary;

	@Expose()
	@ValidateIf(o => o.destinationLocationType === LocationType.address, { groups: [transfer] })
	@IsDefined({ message: 'Address is required', groups: [transfer] })
	destinationAddress: Address;

	@Expose()
	@IsDefined({ message: 'Date is required', groups: [transfer] })
	eta: Date;

	@Expose()
	@IsDefined({ message: 'Status is required', groups: [transfer] })
	status: TransferStatus;

	@Expose()
	transferId: string;

	@Expose()
	logs: TransferLog[];

	// @Expose()
	// productSummary: ProductSummary;

	constructor() {
		super();
		this.sourceLocationType = LocationType.gps;
		this.sourceLocationGPS = new Address();
		this.sourceLocationInventory = {} as StoreLocationSummary;
		this.sourceLocationAddress = new Address();
		this.destinationLocationType = LocationType.inventory;
		this.destinationInventory = {} as StoreLocationSummary;
		this.destinationAddress = new Address();
		this.status = TransferStatus.initiated;
		this.logs = [];
	}

	sanitize() {
		super.sanitize();
	}

	clone() {
		const t = instanceToInstance(this);
		t.sanitize();
		return t;
	}

	validateSync(options: ValidatorOptions): IValidationMsg {
		let s = this.validateSyncBase(this, options);
		
		if (this.status === TransferStatus.delivered && this.sourceLocationType === LocationType.gps) {
			const distance = this.getDistance();
			if (distance > 10 && !s['transfer']) {
				s['transfer'] = ['Destination location does not match source location'];
			}
		}
	
		const addError = (key: string, message: string) => {
			if (!s[key]) {
				s[key] = [];
			}
			s[key].push(message);
		};
	
		if (this.sourceLocationType === LocationType.inventory) {
			const inv = Object.keys(this.sourceLocationInventory);
			if (!inv.length) {
				addError('sourceInventory', 'Source inventory is required');
			}
		}
	
		if (this.sourceLocationType === LocationType.address && !this.sourceLocationAddress.addressFormated) {
			addError('sourceAddress', 'Source address is required');
		}
	
		if (this.destinationLocationType === LocationType.inventory) {
			const inv = Object.keys(this.destinationInventory);
			if (!inv.length) {
				addError('destinationInventory', 'Destination inventory is required');
			}
		}
	
		if (this.destinationLocationType === LocationType.address && !this.destinationAddress.addressFormated) {
			addError('destinationAddress', 'Destination address is required');
		}
	
		return s;
	}

	public static parse(obj) {
		if (obj == null) { return null; }
		const m = plainToInstance<InventoryTransfer, any>(InventoryTransfer, sanitizeDateIPoint(obj));
		m.sanitize();
		return m;
	}

	public static parseArray = (obj: any[]): InventoryTransfer[] => {
		const r = !!obj ? obj.map(o => InventoryTransfer.parse(o)) : null;
		return r;
	};

	/**
	 * @author KS
	 * @purpose Generates transferId
	 * @param vinLastSix 
	 * @param length 
	 * @returns 
	 */
	public generateTransferId(vinLastSix: string | number, length: number): string {
		return `${vinLastSix}-${length}`;
	}

	getDistance() {
		let source: Address;
		let destination: Address;
		switch (this.sourceLocationType) {
			case LocationType.gps:
				source = this.sourceLocationGPS;
				break;
			case LocationType.inventory:
				source = this.sourceLocationInventory.address;
				break;
			case LocationType.address:
				source = this.sourceLocationAddress;
				break;
			default:
				break;
		}

		switch (this.destinationLocationType) {
			case LocationType.inventory:
				destination = this.destinationInventory.address;
				break;
			case LocationType.address:
				destination = this.destinationAddress;
				break;
			default:
				break;
		}

		try {
			return distanceBetween([source.lat, source.long], [destination.lat, destination.long]);
		} catch (error) {
			console.log(error)
			return null;
		}
	}

}