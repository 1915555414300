import { MenuData } from '@trent/models/UI/menu-data';

export const getMenuData = () => {
  const menu: MenuData[] = [
    // {
    //   title: 'Track Delivery', url: '/track-delivery/list', auth: 'anonymous', micon: 'where_to_vote',
    //   iClass: 'fas fa-dollar-sign color-green-dark', description: 'Track Delivery'
    // },
    {
      title: 'Rent Trailers', url: '/rent-option/trailer/list', rb: true, auth: 'anonymous', cIcon: 'trailerIcon',
      iClass: 'fa fa-cog color-yellow-dark', description: 'Rent Trailers'
    },
    {
      title: 'Rent Trucks', url: '/rent-option/truck/list', rb: true, auth: 'anonymous', cIcon: 'truckIcon',
      iClass: 'fa fa-cog color-yellow-dark', description: 'Rent Trailers'
    },
    {
      title: 'Messages', url: '/task', rb: true, auth: 'auth', micon: 'inbox',
      iClass: 'fa fa-cog color-yellow-dark', description: 'Manage your tasks'
    },
    {
      title: 'Vendor Portal', auth: 'auth', micon: 'home', iClass: 'fa fa-cog color-yellow-dark',
      description: 'Manage your rentals',
      subMenu: [
        {
          title: 'Trailers', url: '/list/trailer', auth: 'auth', cIcon: 'trailerIcon',
          iClass: 'fa fa-cog color-yellow-dark', description: 'Manage Trailers'
        },
        {
          title: 'Trucks', url: '/list/truck', auth: 'auth', cIcon: 'truckIcon',
          iClass: 'fa fa-cog color-yellow-dark', description: 'Manage Trailers'
        },
        {
          title: 'Contracts', url: '/bid/list-vendor', auth: 'auth', micon: 'monetization_on',
          iClass: 'fas fa-dollar-sign color-green-dark', description: 'Manage Bids'
        },
        {
          title: 'Spare Parts', url: '/spare-part/list', auth: 'auth', micon: 'settings',
          iClass: 'fas fa-dollar-sign color-green-dark', description: 'Manage Spare Parts'
        },

      ]
    },
    {
      title: 'Customer Portal', auth: 'auth', micon: 'home', iClass: 'fa fa-cog color-yellow-dark',
      description: 'Manage your rentals',
      subMenu: [
        {
          title: 'Contracts', url: '/bid/list-customer', auth: 'auth', micon: 'monetization_on',
          iClass: 'fas fa-dollar-sign color-green-dark', description: 'Manage Bids'
        },
      ]
    },
    {
      title: 'Sales Portal', auth: 'sales', micon: 'home', iClass: 'fa fa-cog color-yellow-dark',
      description: 'Manage your rentals',
      subMenu: [
        {
          title: 'Contracts', url: '/bid/list-sales-person', auth: 'sales', micon: 'monetization_on',
          iClass: 'fas fa-dollar-sign color-green-dark', description: 'Manage Bids'
        },
      ]
    },
    {
      title: 'Credit Portal', auth: 'credit', micon: 'home',
      iClass: 'fa fa-home color-green-dark', description: 'Credit Role',
      subMenu: [
        {
          title: 'Contracts', url: '/bid/list-credit', auth: 'credit', micon: 'money',
          iClass: 'fa fa-home color-green-dark', description: 'Credit Actions'
        },
        {
          title: 'Manage Customers', url: 'company/admin-list-customer', auth: 'credit', micon: 'business',
          iClass: 'fa fa-home color-green-dark', description: 'Admin Actions'
        },
        {
          title: 'Contract Management', url: '/contract-management/list', auth: 'credit', micon: 'description',
          iClass: 'fa fa-home color-green-dark', description: 'Credit Actions'
        },
        {
          title: 'Maintenance List', url: '/maintenance/list', auth: 'credit', micon: 'description',
          iClass: 'fa fa-home color-green-dark', description: 'Credit Actions'
        },
      ]
    },
    {
      title: 'Leasing Sales Portal', auth: 'leasingSales', micon: 'home', iClass: 'fa fa-cog color-yellow-dark',
      description: 'Manage your contracts',
      subMenu: [
        {
          title: 'Contract Management', url: '/contract-management/leasing-sales', auth: 'leasingSales', micon: 'description',
          iClass: 'fas fa-dollar-sign color-green-dark', description: 'Leasing Sales Action'
        }
      ]
    },
    // {
    //   title: 'Carrier Portal', auth: 'auth', micon: 'home', iClass: 'fa fa-cog color-yellow-dark',
    //   description: 'Manage your trips',
    //   subMenu: [
    //     {
    //       title: 'Trips', url: '/trip/carrier', auth: 'auth', micon: 'swap_calls',
    //       iClass: 'fas fa-dollar-sign color-green-dark', description: 'Search Trips'
    //     },
    //     {
    //       title: 'Track Deliveries', url: '/track-delivery/carrier-list', auth: 'anonymous', micon: 'where_to_vote',
    //       iClass: 'fas fa-dollar-sign color-green-dark', description: 'Track Deliveries'
    //     },
    //     {
    //       title: 'Trucks', url: '/list/truck', auth: 'auth', micon: 'local_shipping',
    //       iClass: 'fa fa-cog color-yellow-dark', description: 'Manage Trucks'
    //     },
    //     {
    //       title: 'Trailers', url: '/list/trailer', auth: 'auth', micon: 'view_stream',
    //       iClass: 'fa fa-cog color-yellow-dark', description: 'Manage Trailers'
    //     },
    //     {
    //       title: 'Drivers', url: '/list/driver', auth: 'auth', micon: 'person',
    //       iClass: 'fa fa-cog color-yellow-dark', description: 'Manage Drivers'
    //     },
    //     {
    //       title: 'Notifiers', url: '/list/trip-tracker', auth: 'auth', micon: 'email',
    //       iClass: 'fa fa-cog color-yellow-dark', description: 'Manage Drivers'
    //     },
    //   ]
    // },
    // {
    //   title: 'Driver Portal', auth: 'auth', micon: 'home', iClass: 'fa fa-cog color-yellow-dark',
    //   description: 'Manage your trips',
    //   subMenu: [
    //     {
    //       title: 'Current Trip', url: '/trip/driver-current', auth: 'auth', faicon: 'road',
    //       iClass: 'fas fa-dollar-sign color-green-dark', description: 'Current Trip'
    //     },
    //     {
    //       title: 'Trip History', url: '/trip/driver', auth: 'auth', micon: 'swap_calls',
    //       iClass: 'fas fa-dollar-sign color-green-dark', description: 'Trip History'
    //     },
    //   ]
    // },
    // {
    //   title: 'Shipper Portal', auth: 'auth', micon: 'home', iClass: 'fa fa-cog color-yellow-dark',
    //   description: 'Manage your trips',
    //   subMenu: [
    //     // {
    //     //   title: 'Track', url: '/track-delivery/notifier-list', auth: 'anonymous', micon: 'where_to_vote',
    //     //   iClass: 'fas fa-dollar-sign color-green-dark', description: 'Track Delivery'
    //     // },
    //     {
    //       title: 'Track Deliveries', url: '/track-delivery/notifier-list', auth: 'anonymous', micon: 'where_to_vote',
    //       iClass: 'fas fa-dollar-sign color-green-dark', description: 'Search Delivery'
    //     },
    //   ]
    // },
    {
      title: 'Super Admin', auth: 'admin', micon: 'home',
      iClass: 'fa fa-home color-green-dark', description: 'Manage Admin Actions',
      subMenu: [

        // {
        //   title: 'Manage Mockup Data', click: 'togglePageEdit()', auth: 'auth', micon: 'home',
        //   iClass: 'fa fa-home color-green-dark', description: 'Admin Actions'
        // },
        {
          title: 'Add CMS Page', url: 'cms/add', auth: 'admin', micon: 'add',
          iClass: 'fa fa-home color-green-dark', description: 'Admin Actions'
        },

        {
          title: 'Manage Mockup Data', url: 'admin/mockup', auth: 'auth', micon: 'home',
          iClass: 'fa fa-home color-green-dark', description: 'Admin Actions'
        },
        {
          title: 'Manage Users', url: 'admin/users', auth: 'auth', micon: 'person',
          iClass: 'fa fa-user-check color-green-dark', description: 'Admin Actions'
        },
        {
          title: 'Manage Cron Jobs', url: 'admin/cron-jobs', auth: 'admin', micon: 'home',
          iClass: 'fa fa-home color-green-dark', description: 'Admin Actions'
        },
        {
          title: 'Manage Contract PDF ', url: 'pdf-reader/truck-rental-agreement-ca', auth: 'auth', micon: 'edit',
          iClass: 'fa fa-home color-green-dark', description: 'Admin Actions'
        },
        // {
        //   title: 'Author Messaging', url: '/task/messagingList', rb: true, auth: 'auth', micon: 'inbox',
        //   iClass: 'fa fa-cog color-yellow-dark', description: 'Messaging section'
        // },
        {
          title: 'Manage Role Groups', url: 'admin/role-group', auth: 'admin', micon: 'group',
          iClass: 'fa fa-users color-green-dark', description: 'Admin Actions'
        },
        // {
        //   title: 'Mock Trip Location', url: 'admin/mockup/trip-loc', auth: 'admin', micon: 'home',
        //   iClass: 'fa fa-home color-green-dark', description: 'Admin Actions'
        // },
        // {
        //   title: 'Admin Dashboard', url: 'admin-dashboard', auth: 'admin', micon: 'dashboard',
        //   iClass: 'fa fa-home color-green-dark', description: 'Admin Actions'
        // },
        // {
        //   title: 'Manage Companies', url: 'company/admin-list', auth: 'admin', micon: 'business',
        //   iClass: 'fa fa-home color-green-dark', description: 'Admin Actions'
        // }
      ]
    },
    {
      title: 'Admin', auth: 'editor', micon: 'home',
      iClass: 'fa fa-home color-green-dark', description: 'Manage Admin Actions',
      subMenu: [

        // {
        //   title: 'Manage Mockup Data', click: 'togglePageEdit()', auth: 'auth', micon: 'home',
        //   iClass: 'fa fa-home color-green-dark', description: 'Admin Actions'
        // },
        // {
        //   title: 'Add CMS Page', url: 'cms/add', auth: 'admin', micon: 'home',
        //   iClass: 'fa fa-home color-green-dark', description: 'Admin Actions'
        // },

        // {
        //   title: 'Manage Mockup Data', url: 'admin/mockup', auth: 'auth', micon: 'home',
        //   iClass: 'fa fa-home color-green-dark', description: 'Admin Actions'
        // },
        // {
        //   title: 'Manage Users', url: 'admin/users', auth: 'auth', micon: 'home',
        //   iClass: 'fa fa-home color-green-dark', description: 'Admin Actions'
        // },
        // {
        //   title: 'Manage Cron Jobs', url: 'admin/cron-jobs', auth: 'admin', micon: 'home',
        //   iClass: 'fa fa-home color-green-dark', description: 'Admin Actions'
        // },
        // {
        //   title: 'Mock Trip Location', url: 'admin/mockup/trip-loc', auth: 'admin', micon: 'home',
        //   iClass: 'fa fa-home color-green-dark', description: 'Admin Actions'
        // },
        {
          title: 'Contracts', url: '/bid/list-admin', auth: 'editor', micon: 'money',
          iClass: 'fa fa-home color-green-dark', description: 'Admin Actions'
        },
        // {
        //   title: 'Admin Dashboard', url: 'admin-dashboard', auth: 'editor', micon: 'dashboard',
        //   iClass: 'fa fa-home color-green-dark', description: 'Admin Actions'
        // },
        {
          title: 'Manage Vendors', url: 'company/admin-list-vendor', auth: 'editor', micon: 'business',
          iClass: 'fa fa-home color-green-dark', description: 'Admin Actions'
        },
        {
          title: 'Manage Customers', url: 'company/admin-list-customer', auth: 'editor', micon: 'business',
          iClass: 'fa fa-home color-green-dark', description: 'Admin Actions'
        },
        {
          title: 'Manage Portfolio', url: '/list/master-portfolio/', auth: 'editor', micon: 'settings',
          iClass: 'fa fa-home color-green-dark', description: 'Admin Actions'
        },
        {
          title: 'Contract Management', url: '/contract-management/list', auth: 'editor', micon: 'description',
          iClass: 'fa fa-home color-green-dark', description: 'Admin Actions'
        },
        {
          title: 'Maintenance List', url: '/maintenance/list', auth: 'editor', micon: 'description',
          iClass: 'fa fa-home color-green-dark', description: 'Admin Actions'
        },
        {
          title: 'Export Products', url: 'admin/sale-option-list', auth: 'editor', micon: 'table',
          iClass: 'fa fa-home color-green-dark', description: 'Admin Actions'
        },
        {
          title: 'Manage Subsidiaries', url: 'admin/manage-subsidiaries', auth: 'editor', micon: 'business',
          iClass: 'fa fa-home color-green-dark', description: 'Admin Actions'
        },
        // {
        //   title: 'Author Messaging', url: '/task/messagingList', rb: true, auth: 'auth', micon: 'inbox',
        //   iClass: 'fa fa-cog color-yellow-dark', description: 'Messaging section'
        // },
        {
          title: 'Store Locations', url: 'admin/store-location/', auth: 'auth', micon: 'business', iClass: 'fa fa-cog color-yellow-dark',
          description: 'Manage Store Locations'
        },
        {
          title: 'Sync List', url: 'admin/sync-list/', auth: 'auth', micon: 'list_alt', iClass: 'fa fa-cog color-yellow-dark',
          description: 'Sync Lists'
        },
        {
          title: 'Promotions', url: 'admin/promotions/', auth: 'auth', micon: 'list_alt', iClass: 'fa fa-cog color-yellow-dark',
          description: 'Promotions'
        }
        // {
        //   title: 'Sales', url: '/sales-option/card', auth: 'editor', micon: 'description',
        //   iClass: 'fa fa-home color-green-dark', description: 'Admin Actions',
        // },
        // {
        //   title: 'Add CMS Page', url: 'pdf-reader/truck-rental-agreement-ca', auth: 'auth', micon: 'add',
        //   iClass: 'fa fa-home color-green-dark', description: 'Admin Actions'
        // },
        // {
        //   title: 'Author Messaging', url: '/task/messagingList', rb: true, auth: 'auth', micon: 'inbox',
        //   iClass: 'fa fa-cog color-yellow-dark', description: 'Messaging section'
        // },
      ]
    },
    {
      title: 'Account', auth: 'account', micon: 'home',
      iClass: 'fa fa-home color-green-dark', description: 'Manage Your Account Settings',
      subMenu: [
        {
          title: 'Account Settings', url: '/account', auth: 'account', micon: 'person', iClass: 'fa fa-cog color-yellow-dark',
          description: 'Your Account Settings'
        },
        {
          title: 'My Orders', url: '/order', auth: 'order', micon: 'receipt', iClass: 'fa fa-cog color-yellow-dark',
          description: 'You Order Records'
        },
        {
          title: 'My Companies', url: '/company', auth: 'account', micon: 'business', iClass: 'fa fa-cog color-yellow-dark',
          description: 'You Company Records'
        },
        {
          title: 'Add Company', url: '/company/lessee', auth: 'addCompany', micon: 'add', iClass: 'fa fa-cog color-yellow-dark',
          description: 'Add Fleet Account'
        },
        // {
        //   title: 'Add Equipment vendor', url: '/company/lessee-equipment', auth: 'editor', micon: 'add', iClass: 'fa fa-cog color-yellow-dark',
        //   description: 'Add Fleet Account'
        // },

        // {
        //   title: 'Add Driver Company', url: '/company/driver-company-detail', auth: 'auth', micon: 'home',
        //   iClass: 'fa fa-cog color-yellow-dark', description: 'Add Fleet Account'
        // },
        // {
        //   title: 'My Orders', url: '/order', auth: 'auth', micon: 'home',
        //   iClass: 'fa fa-cog color-yellow-dark', description: 'Manage my orders'
        // }
      ]

    },
    {
      title: 'Contact us', url: '/contact', rb: true, auth: 'anonymous', micon: 'contact_mail',
      iClass: 'fa fa-cog color-yellow-dark', description: 'Contact Us'
    }
  ];

  return menu;
};

