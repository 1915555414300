import { IsInt, IsBoolean, Max, Min, ValidatorOptions, ValidateIf } from 'class-validator';
import { ProductType } from './product-type';
import { plainToInstance, instanceToInstance, Exclude, Expose } from 'class-transformer';
import { GpsType, Vehicle } from './vehicle';
import { sanitizeDate, sanitizeDateIPoint } from '../utility';
import { ProductSummaryBase } from './product-base';
import { FileInfo, IFileMoveData } from '../media/file-info';
import { IValDbStatusResult, IValidationMsg } from '../error-handling/validation-info';
import { DbStatus } from '../base/db-status';
import { isDate } from 'lodash';
import { isAfter, lastDayOfMonth } from 'date-fns';
import { addAYear } from '../utility/utl-datef';
import { VehicleListItem } from '../promo/promo-vehicle';
import { IInspectionPersistentData } from '../inspection/i-inpection-presistent-data';

export type TrailerValidationGroup = 'unitDetails' | 'specificationDetails' | 'physicalLocationDetails' | 'documents' | 'plating' | 'misc' | 'pics';
const unitDetails: TrailerValidationGroup = 'unitDetails';
const specificationDetails: TrailerValidationGroup = 'specificationDetails';//MKN
const physicalLocationDetails: TrailerValidationGroup = 'physicalLocationDetails';//MKN
const documents: TrailerValidationGroup = 'documents';
const plating: TrailerValidationGroup = 'plating';
const misc: TrailerValidationGroup = 'misc';
const pics: TrailerValidationGroup = 'pics';

export type TrailerMake = 'FreightLiner' | 'Volvo' | 'Peterbilt' | 'Kenworth' | 'International' | 'Mack';

export const TrailerMakes = ['Great Dane', 'Hyundai', 'Manac', 'Stoughton', 'Utility', 'Vanguard', 'Wabash', 'Other'] as const;
export type TrailerMakeType = typeof TrailerMakes[number];

export interface TrailerSummary extends ProductSummaryBase {
  unitName: string;
  make: string;
  modelYear: number;
  trailerLength: number;
  nAxle: number;
  isReefer: boolean;
  vin: string;
  plateNo: string;
  safetyExpiryDate: Date;
  summaryString: string;
  title: string;
  prodIcon: string;
  trailerType: TrailerType;
  isUsed:boolean;
  gpsProvider?: GpsType;
  gpsSrNo?: string;
}
export interface ContractTrailerSummary {
  unitName: string;
  make: string;
  modelYear: number;
  trailerLength: number;
  nAxle: number;
  isReefer: boolean;
  vin: string;
  plateNo: string;
  safetyExpiryDate: Date;
}
export enum TrailerType {
  isReefer = 1,
  dryVan = 2,
  flatBed = 3,
}

/** For Trailer  */
@Exclude()
export class Trailer extends Vehicle {
  constructor() {
    super();
    this.productType = ProductType.trailer;
  }

  @Expose()
  get vinLastSix() {
    const l = this.vin ? this.vin.length : 0;
    return l > 6 ? this.vin?.substring(l - 6, l) : undefined;
  }

  @Expose()
  get picturesLength() {
    return (this.pictures) ? this.pictures?.length : 0;
  }

  @Expose()
  @IsInt({ groups: [unitDetails] })
  @Max(53)
  @Min(24)
  trailerLength: number;

  @Expose()
  @IsInt({ groups: [unitDetails] })
  @Max(8)
  @Min(2)
  nAxle: number;

  @Expose()
  @IsBoolean({ groups: [unitDetails] })
  isReefer = false;

  /** inspectionPersistentData added when inspection is completed for a vehicle.*/
  @Expose()
  inspectionPersistentData: IInspectionPersistentData;

  @Expose()
  @ValidateIf(o => !o.unplated && !o.asIs && !!o.isReefer, { groups: [plating]})
  @IsInt( { groups: [plating]})
  pmHours: number;

  //KS - Trailer Type
  @Expose()
  @ValidateIf(o => !!o.vin, { groups: [specificationDetails]})
  trailerType: TrailerType;
  
  /** UI Helper for isReefer Property */
  get reeferDryVan() {
    if (this.isReefer) {
      return 'Reefer';
    } else if (this.isReefer === false) {
      return 'Dry-Van';
    } else {
      return null;
    }
  }

  get summary() {
    return `${this.trailerLength}' ${this.nAxle}-Axle ` +
      `${(this.isReefer) ? 'Reefer' : 'Dry-Van'} Trailer`;
  }

  get defaultImage() {
    if (this.isReefer) {
      // to be extended for nAxle
      return './assets/black-trailer-icon.svg';
    } else {
      return './assets/black-trailer-icon.png';
    }
  }

  /** Convert all GeoPoint class instances to IPoint. required before parsing data coming back
 * from firebase. This must be called BEFORE PARSE and not AFTER PARSE
  * @param data Data to be parsed
  * @param type type to be used to decipher latitude/longitude. This is needed because at client the type is : firebase.firestore.GeoPoint
  * and at function it is admin.firestore.GeoPoint they are two different types.
  * https://github.com/Microsoft/TypeScript/wiki/FAQ#why-cant-i-write-typeof-t-new-t-or-instanceof-t-in-my-generic-function
  */
  public static parse(obj) {
    // public static parse<T extends IPoint>(obj, ipointType?: { new(...args: any[]): T }) {
    try {
      if (obj == null) { return null; }
      obj = sanitizeDateIPoint(obj);
      // obj = sanitizeDateIPoint(obj, ipointType);
      const m = plainToInstance<Trailer, any>(Trailer, sanitizeDateIPoint(obj));
      m.sanitize();
      if (m.asIs === undefined) { m.asIs = false; }
      if (m.unplated === undefined) { m.unplated = false; }
      if (m.safety == null && !m.asIs) { m.safety = new FileInfo(); }
      if (m.vehicleReg == null) { m.vehicleReg = new FileInfo(); }
      return m;
    } catch (error) {
      console.log('Error happened during parse', error);
      return null;
    }
  }



  // public static getTrailerSummary(product: Trailer): TrailerSummary {
  //   return {
  //     unitName: product.unitName,
  //     make: product.make,
  //     modelYear: product.modelYear,
  //     trailerLength: product.trailerLength,
  //     nAxle: product.nAxle,
  //     isReefer: product.isReefer,
  //     cid: product.cid,
  //     pRevid: product.revId
  //   };
  // }
  public getContractSummary(product: Trailer): ContractTrailerSummary {
    return {
      unitName: product.unitName,
      make: product.make,
      modelYear: product.modelYear,
      trailerLength: product.trailerLength,
      nAxle: product.nAxle,
      isReefer: product.isReefer,
      vin: product.vin,
      plateNo: product.plateNo,
      safetyExpiryDate: product.safetyExpiryDate,
    };
  }

  public getProductSummary(): TrailerSummary {
    return {
      unitName: this.unitName,
      make: this.make,
      modelYear: this.modelYear,
      trailerLength: this.trailerLength,
      nAxle: this.nAxle,
      isReefer: this.isReefer,
      isUsed:this.isUsed,
      vin: this.vin,
      plateNo: this.plateNo,
      safetyExpiryDate: this.safetyExpiryDate,
      summaryString: this.summary,
      title: `${(this.isReefer) ? 'Reefer' : 'Dry-Van'} Trailer`,
      prodIcon: `${(this.isReefer) ? 'ac_unit' : 'view_stream'}`,
      trailerType: this.trailerType,
      gpsProvider: this.gpsProvider,
      gpsSrNo: this.gpsSrNo,
      ...super.getProductSummary()
    };
  }

  // public static setTrailerSummary(prodSummary: TrailerSummary): TrailerSummary {
  //   return {
  //     unitName: prodSummary.unitName,
  //     make: prodSummary.make,
  //     modelYear: prodSummary.modelYear,
  //     trailerLength: prodSummary.trailerLength,
  //     nAxle: prodSummary.nAxle,
  //     isReefer: prodSummary.isReefer
  //   };
  // }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  /** Update all attached documents paths from /draft/ to /rel/ */
  updateFilePathsToRelease(): IFileMoveData[] {
    const R: IFileMoveData[] = [];
    let r = FileInfo.updatePathToRelease(this.safety);
    if (!!r) { R.push(r); }

    r = FileInfo.updatePathToRelease(this.vehicleReg);
    if (!!r) { R.push(r); }

    return R;
  }
  validateSync(options?: ValidatorOptions): IValidationMsg {
    // const r = this.validateSyncBase(this, options);
    const r = this.validateSyncBase(this, options);


    // if (isDate(this.safetyExpiryDate)) {
    //   if (isAfter(new Date(), this.safetyExpiryDate)) {
    //     r['safetyExpiryDate'] = ['Safety expiry date must be in future'];
    //   }
    //   // safetyExpiryDate data must within a year, end of the month.
    //   if (isAfter(lastDayOfMonth(this.safetyExpiryDate), lastDayOfMonth(addAYear(1)))) {
    //     r['safetyExpiryDate'] = ['Safety expiry date must be within a year'];
    //   }
    // }

    return r;

  }
  sanitize() {
    super.sanitize();
    this.safetyExpiryDate = sanitizeDate(this.safetyExpiryDate);

  }
  getValDbStatus(): IValDbStatusResult {
    const result: IValDbStatusResult = {
      pass: false,
      message: undefined,
      groupResult: {}
    };
    let x: { groupPass: boolean; message: string; };
    // unitDetails
    let r = this.validateSyncGroup(unitDetails);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Manage unit details' : 'Unit information is required';
    result.groupResult[unitDetails] = x;

    // specificationDetails
    r = this.validateSyncGroup(specificationDetails);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Manage specification details' : 'Specification information is required';
    result.groupResult[specificationDetails] = x;

    // physicalLocationDetails
    r = this.validateSyncGroup(physicalLocationDetails);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Manage physical location details' : 'Physical location is required';
    result.groupResult[physicalLocationDetails] = x;
    
    // Documents

    r = this.validateSyncGroup(documents);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Manage required documents' : 'Documents information is required';
    result.groupResult[documents] = x;

    // Plating
    r = this.validateSyncGroup(plating);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Manage vehicle plating Details' : 'Vehicle plating information is required';
    result.groupResult[plating] = x;

    // misc
    r = this.validateSyncGroup(misc);
    x = { groupPass: null, message: '' };
    x.groupPass = Object.keys(r).length === 0;
    x.message = (x.groupPass) ? 'Manage other vehicle Details' : 'Vehicle other information is required';
    result.groupResult[misc] = x;

    // is it passed all the tests? (True means it failed here)
    result.pass = !Object.keys(result.groupResult).some((k) => !result.groupResult[k].groupPass);
    // passed.
    if (!result.pass) {
      if (this.dbStatus === DbStatus.Initial || this.dbStatus === DbStatus.ReleasedMod) {
        result.message = 'Admin approval is pending!';
      }
    } else {
      result.message = 'More information is required!';
    }
    return result;
  }

  addPlaceHolder(item: { [key: string]: VehicleListItem; }, cid: string) {
    this.cid = cid;
    this.unitName = Object.keys(item)[0];
    this.gpsSrNo = item[this.unitName].iotId;
  }
  get isRentable(): boolean {
    if (this.asIs) {
      return false;
    } else if (this.dbStatus === DbStatus.Released && isAfter(this.safetyExpiryDate, new Date())) {
      return true;
    } else {
      return false;
    }
  }
}
