<div>
    <div mat-dialog-title style="background: var(--primary);">
        <span class="closebtn" (click)="close()">&times;</span>
        Warranty
    </div>
    <mat-dialog-content>
        <form #warrantyForm="ngForm">

            <!-- Mat Form Field for selecting the warranty provider -->
            <mat-form-field class="full-width">
                <mat-label>Provider</mat-label>
                <mat-select name="provider" #provider="ngModel" [(ngModel)]="em.provider">
                    <mat-option *ngFor="let provider of providers" [value]="provider.value">
                        {{ toTitleCase(provider.name) }}
                    </mat-option>
                </mat-select>
                <mat-hint *ngIf="!showError(provider)" align="end">Required</mat-hint>
                <mat-error *ngIf="showError(provider)">{{ provider?.errors?.msg }} </mat-error>
            </mat-form-field>
            <!-- Mat Form Field for specifying a custom providers type (shown only when 'Other' is selected) -->
            <mat-form-field class="full-width" *ngIf="em.provider === providersValues.Others">
                <input matInput type="text" name="providersOther" #providersOther="ngModel"
                    [(ngModel)]="em.providersOther" autocomplete="off" placeholder="Please specify providers">
                <mat-hint *ngIf="!showError(providersOther)" align="end">Required</mat-hint>
                <mat-error *ngIf="showError(providersOther)">{{ providersOther?.errors?.msg }} </mat-error>
            </mat-form-field>

            <div class="d-flex flex-wrap gap-5">
                <!-- Mat Form Field for selecting the start date -->
                <mat-form-field>
                    <mat-label>Start Date</mat-label>
                    <input matInput [matDatepicker]="picker" name="startDate" #startDate="ngModel"
                        placeholder="Start Date" [(ngModel)]="em.startDate" (dateInput)="handleStartDateChange($event)">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-hint *ngIf="!showError(startDate)" align="end">Required</mat-hint>
                    <mat-error *ngIf="showError(startDate)">{{ startDate?.errors?.msg }} </mat-error>
                </mat-form-field>

                <!-- Mat Form Field for months -->
                <mat-form-field>
                    <input matInput type="number" name="months" #months="ngModel" [(ngModel)]="em.months">
                    <mat-label>Months</mat-label>
                    <mat-hint *ngIf="!showError(months)" align="end">Required</mat-hint>
                    <mat-error *ngIf="showError(months)">{{ months?.errors?.msg }}</mat-error>
                </mat-form-field>
            </div>

            <!-- Mileage container with a Mat Form Field for entering mileage and a radio group for selecting the unit -->
            <div class="mileage-container">
                <mat-form-field>
                    <mat-label>
                        Mileage
                        <ng-container *ngIf="em.mileage">({{em?.mileage | number :'1.0-0'}})</ng-container>
                    </mat-label>
                    <input matInput type="number" name="mileage" #mileage="ngModel" [(ngModel)]="em.mileage">
                    <mat-hint *ngIf="!showError(mileage)" align="end">Required</mat-hint>
                    <mat-error *ngIf="showError(mileage)">{{ mileage?.errors?.msg }}</mat-error>
                </mat-form-field>

                <!-- Radio group for selecting the unit (km/mi) -->
                <div class="unit-radio-group">
                    <mat-radio-group name="unit" [(ngModel)]="em.unit">
                        <mat-radio-button value="km">km</mat-radio-button>
                        <mat-radio-button value="mi" class="ml10">mi</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <!-- Mat Form Field for selecting the warranty type -->
            <mat-form-field class="full-width">
                <mat-label>Warranty Type</mat-label>
                <mat-select name="warrantyType" #warrantyType="ngModel" [(ngModel)]="em.warrantyType"
                    (selectionChange)="warrantyTypeChange()">
                    <mat-option *ngFor="let t of warrantyTypes" [value]="t.value">
                        {{ toTitleCase(t.name) }}
                    </mat-option>
                </mat-select>
                <mat-hint *ngIf="!showError(warrantyType)" align="end">Required</mat-hint>
                <mat-error *ngIf="showError(warrantyType)">{{ warrantyType?.errors?.msg }} </mat-error>
            </mat-form-field>

            <!-- Mat Form Field for specifying a custom warranty type (shown only when 'Other' is selected) -->
            <mat-form-field class="full-width" *ngIf="em.warrantyType === warrantyTypesValues.Other">
                <input matInput type="text" name="warrantyTypeOther" #warrantyTypeOther="ngModel"
                    [(ngModel)]="em.warrantyTypeOther" autocomplete="off" placeholder="Please specify warranty type">
                <mat-hint *ngIf="!showError(warrantyTypeOther)" align="end">Required</mat-hint>
                <mat-error *ngIf="showError(warrantyTypeOther)">{{ warrantyTypeOther?.errors?.msg }} </mat-error>
            </mat-form-field>

            <!-- Mat Form Field for entering comments -->
            <mat-form-field class="full-width">
                <mat-label>Comments</mat-label>
                <input matInput type="text" name="comments" #comments="ngModel" [(ngModel)]="em.comments"
                    autocomplete="off">
                <mat-hint align="end">Optional</mat-hint>
            </mat-form-field>

            <!-- Show Warranty -->
            <div class="">
                <span>Show Warranty</span>
                <mat-checkbox name="showWarranty" [(ngModel)]="em.showWarranty"></mat-checkbox>
            </div>

        </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button (click)="close()">Cancel</button>
        <button mat-raised-button color="primary" cdkFocusInitial (click)="save()">Save</button>
    </mat-dialog-actions>
</div>