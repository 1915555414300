import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngxs/store';
import { logger } from '@trent/models/log/logger';
import { ProductBase, ProductSearchAlgolia, ProductSearchParam, Truck } from '@trent/models/product';
import { ImageType, SalesProductBase } from '@trent/models/sales-option';
import { BaseForm } from '@trent/models/UI/base-form';
import { toSentence } from '@trent/models/utility';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { EventService } from '@trent/services/event.service';
import { debounceTime, distinctUntilChanged, map, Subject, Subscription } from 'rxjs';
import { AlgoliaSearchService } from '@trent/services/algolia-search.service';
import { getUrlFromPath } from '@trent/services/firebase-storage';
import { Picture } from '@trent/models/inspection/picture';

@Component({
	selector: 'trent-proxy-vin-select-product-list',
	templateUrl: './proxy-vin-select-product-list.component.html',
	styleUrls: ['./proxy-vin-select-product-list.component.scss']
})
export class ProxyVinSelectProductListComponent extends BaseForm<ProductBase[]> implements OnInit, OnDestroy{


	@Input() product : ProductBase;
	@Output() selectedProduct = new EventEmitter<ProductBase>();
	@Output() closeSearchProduct = new EventEmitter<ProductBase>();

	productSub: Subscription;
	dataSource: MatTableDataSource<{}>;
	displayedColumns = ['description', 'pic'];
	productParam : ProductSearchParam;
	searchField : string;
	imageTypeFilter = { imageType : ImageType.original };
	searchFieldUpdate = new Subject<string>();

	constructor(
		store: Store,
		eventService: EventService,
		dialogService: DialogService,
		public algoliaSearchService : AlgoliaSearchService
	) {
		super(store, dialogService, eventService);
	}

	ngOnInit() {
		this.productParam = this.productParamInit;
		this.searchFieldUpdate.pipe(
			debounceTime(400),
			distinctUntilChanged())
			.subscribe(value => {
				logger.log(this.searchField);
				this.productParam = this.searchField ? { isActive : true, type : this.product.productType } : this.productParamInit;
				this.loadDataUsingAlgolia();
			});
		this.loadDataUsingAlgolia();
	}

	ngOnDestroy(): void {
		this.cleanListeners();
	}
	
	get productParamInit(){//...this.imageTypeFilter,
		return { isActive : true,  ...this.imageTypeFilter, type : this.product.productType, make : (this.product as Truck).make, model :  (this.product as Truck).model};
	}

	loadDataUsingAlgolia() {
		const pData = { size: 1000, offset: 0, full: false };
		const { query, indexName } = ProductSearchAlgolia({ ...this.productParam  }, pData);
		logger.log({ query, indexName });
		if(this.searchField){
			query.query = this.searchField;
		}
		const rAlgolia = this.algoliaSearchService.getDataUsingAlgolia(query, indexName);
		rAlgolia.subscribe(rProducts => {
			logger.log('[ProxyVinSelectProductListComponent] - loadDataUsingAlgolia',rProducts);
			const rProductsData = [];
			rProducts.hits.forEach(hit => {
				if(this.product.id != hit.objectID){
					hit.id = hit.objectID; 
					rProductsData.push(hit);
				}			
			});
			this.m = rProductsData.map(p =>{
				p.pictures?.sort((a,b) => a.customFirebaseMetadata?.order?.valueOf() - b.customFirebaseMetadata?.order?.valueOf());
				return p;
			});   
			this.dataSource = new MatTableDataSource(this.m);

		});
	}

	getTitle(t: string) {
		return toSentence(t);
	}

	selectProduct(product : ProductBase){
		this.selectedProduct.emit(product);
	}

	close(){
		this.closeSearchProduct.emit();
	}

	async handleImageError(picture : Picture){
		picture.serverUrl = '../../../../../assets/pride-truck-sales-logo.webp';
		const r = await getUrlFromPath(picture.path);
		if (!r.success) {
		  logger.log(r.error);
		} else {
		  picture.serverUrl = r.url;
		}
	}
}
