import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../share-module/material.module';
import { MatSelectModule } from '@angular/material/select';
import { CustomPipeModule } from '@trent/custom-pipe/custom-pipe.module';
import { SelectCompanyModule } from '../select-company/select-company.module';
import { SearchOrderModule } from '../search-order/search-order.module';
import { MatRadioModule } from '@angular/material/radio';
import { SearchSalesOptionComponent } from './pts-search-sales-option.component';
import {MatSliderModule} from '@angular/material/slider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { SearchUsersModule } from '../search-users/search-users.module';
import { SalesOptionsSearchDialogComponent } from './sales-options-search-dialog/sales-options-search-dialog.component';

@NgModule({
  declarations: [SearchSalesOptionComponent, SalesOptionsSearchDialogComponent],
  imports: [
    CommonModule,
    MatRadioModule,
    MaterialModule,
    CustomPipeModule,
    SelectCompanyModule,
    MatSelectModule,
    SearchOrderModule,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    SearchUsersModule
  ],
  exports: [
    SearchSalesOptionComponent,
    SalesOptionsSearchDialogComponent
  ]
})
export class SearchSalesOptionModule { }
