<ng-container>
    <mat-form-field class="full-width">
        <mat-label>Status</mat-label>
        <mat-select name="salesStatus" #salesStatus="ngModel" [(ngModel)]="em.status" [disabled]="!_isEditMode || salesOperationsUser?.l !== 2"
            (selectionChange)="statusChange($event)">
            <mat-option *ngFor="let status of statusList" [value]="status.value">{{toTitleCase(status.name)}}
            </mat-option>
        </mat-select>
        <mat-hint *ngIf="!showError(salesStatus)" align="end">Required</mat-hint>
        <mat-error *ngIf="showError(salesStatus)">{{salesStatus?.errors?.msg}}
        </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width" *ngIf="em.status === 2 && !!em.holdDate">
        <mat-label>Hold Date</mat-label>
        <input matInput [matDatepicker]="picker" #holdDate="ngModel" [(ngModel)]="em.holdDate" name="holdDate" [disabled]="true">
    </mat-form-field>

    <mat-form-field class="full-width" *ngIf="em.status === 2 && !!em.holdDate">
        <mat-label>Hold Days</mat-label>
        <input matInput [disabled]="true" type="number" name="holdDays" #holdDays="ngModel"
            [(ngModel)]="hDays" autocomplete="off">
    </mat-form-field>

    <mat-form-field class="full-width" *ngIf="(em.status === 2) || (em.status === 4)">
        <mat-label>Sales Person</mat-label>
        <input matInput [disabled]="!_isEditMode" type="text" name="salesPerson" #salesPerson="ngModel"
            [(ngModel)]="em.salesPerson.name" autocomplete="off">
        <mat-hint *ngIf="!showError(salesPerson)" align="end">Required</mat-hint>
        <mat-error *ngIf="showError(salesPerson)">{{salesPerson?.errors?.msg}}</mat-error>
    </mat-form-field>

    <mat-form-field class="full-width mt-8" *ngIf="(em.status === 2) || (em.status === 4)">
        <mat-label>Branch</mat-label>
        <mat-select name="branch" #branch="ngModel" [(ngModel)]="em.branch" [disabled]="!_isEditMode">
            <mat-option [value]="null">Select...</mat-option>
            <mat-option *ngFor="let s of storeLocations" [value]="s.id">
                <span *ngIf="!s.name">{{s.address.addressFormated}}</span>
                <span *ngIf="!!s.name">{{s.name}}</span>
            </mat-option>
        </mat-select>
        <mat-hint *ngIf="!showError(branch)" align="end">Required</mat-hint>
        <mat-error *ngIf="showError(branch)">{{branch?.errors?.msg}}</mat-error>
    </mat-form-field>

    <ng-container *ngIf="em.status === 2">
        <div class="deposit">
            <mat-label>Deposit</mat-label>
            <mat-radio-group (change)="depositChange($event)" [disabled]="!_isEditMode">
                <mat-radio-button [checked]="em.deposit" [value]="true">Yes</mat-radio-button>
                <mat-radio-button [checked]="!em.deposit" [value]="false">No</mat-radio-button>
            </mat-radio-group>
        </div>
    </ng-container>

    <mat-form-field class="full-width" *ngIf="em.status === 2">
        <mat-label>Approved By</mat-label>
        <input matInput [disabled]="!_isEditMode" type="text" name="approvedBy" #approvedBy="ngModel"
            [(ngModel)]="em.approvedBy" autocomplete="off">
        <mat-hint *ngIf="!showError(approvedBy)" align="end">Required</mat-hint>
        <mat-error *ngIf="showError(approvedBy)">{{approvedBy?.errors?.msg}}</mat-error>
    </mat-form-field>

    <mat-form-field class="full-width" *ngIf="em?.status !== 1 && em.status !==6">
        <mat-label>Comments</mat-label>
        <input matInput [disabled]="!_isEditMode" type="text" name="comments" #comments="ngModel"
            [(ngModel)]="em.comments" autocomplete="off">
        <mat-hint align="end">Optional</mat-hint>
    </mat-form-field>

</ng-container>