import { Injectable } from "@angular/core";
import { GpsPointerService } from "./gps-pointer.service";
import { GpsType } from "@trent/models/product";
import { GeoTabService } from "./geo-tab.service";

@Injectable({ providedIn: 'root' })

export class GpsService {

    constructor(public gpsPointerService: GpsPointerService, public geoTabService:GeoTabService) { }
    /**
  * @author - Cm
  * @purpose - Here switch case implement because different gps provider use like pointer,geo tab,wialon...etc
  */

    getGpsByVin(key: GpsType, vin: string) {
        switch (key) {
            case GpsType.pointer:
                return this.gpsPointerService.getFleetSearch(vin);
            case GpsType.geoTab:
                return;
            case GpsType.wialon:
                return;
            default:
                break;
        }
    }

    getAllVehicleFromGps(key: GpsType, unitNames?: string[]) {
        switch (key) {
            case GpsType.pointer:
                return this.gpsPointerService.getFleetView();
            case GpsType.geoTab:
                return this.geoTabService.getLoc(unitNames);
            case GpsType.wialon:
                return;
            default:
                break;
        }
    }
}