import { RentalProductBase } from './rental-product-base';
import { ProductType } from '../product/product-type';
// import { RentalDriver } from './rental-driver';
import { RentalTrailer } from './rental-trailer';
import { RentalTruck } from './rental-truck';
import { plainToInstance } from 'class-transformer';
import { TripBase } from '../trip/trip-base';
import { MapLabel } from '../map/map-label';
import { shortDate, shortDateTime } from '../utility/timer-helper';
import { IPoint } from '../product-search/interfaces';
import { addDays } from 'date-fns';

export type RentalProduct = RentalTruck | RentalTrailer; //  | RentalDriver;
export type Currency = 'CAD' | 'USD';
export type RentalAgreement = 'rental-trailer' | 'rental-truck';
export const parseRentalProduct = (obj: any): RentalProductBase => {
  if (obj == null) { return null; }

  const r = <RentalProductBase>obj; // cast it to base, remember it is still a javascript obj at run time.

  switch (r.productType) {
    // case ProductType.driver:
    //   return RentalDriver.parse(r);

    case ProductType.trailer:
      return RentalTrailer.parse(r);

    case ProductType.truck:
      return RentalTruck.parse(r);

    // case ProductType.trip:
    //   const t = <TripBase>obj;
    //   return TripBase.parse(t);

    default:
      throw new Error(`Invalid Product Type in the Rental Product. Producty Type: ${r.productType} is invalid or not yet implemented.`);
  }
};

export const parseRentalProductArray = (obj: any[]): RentalProductBase[] => {
  const r = !!obj ? obj.map(o => <RentalProductBase>parseRentalProduct(o)) : null;
  return r;
};
export const rentalMapLabels = (rentals: RentalProductBase[], cid?: string | number): MapLabel[] => {
  const labels: MapLabel[] = [];
  for (const e of rentals as RentalTrailer[]) {
    if (!!e.startAddress?.geoLoc?.geopoint) {
      labels.push({
        iPoint: {
          latitude: Math.round(e.startAddress.geoLoc.geopoint.latitude * 1000000) / 1000000,
          longitude: Math.round(e.startAddress.geoLoc.geopoint.longitude * 1000000) / 1000000
        },
        title: `${e.productSummary.summaryString}(${e.productSummary.modelYear})`,
        productType: e.productType,
        addressFormated: e.startAddress.addressFormated,
        country: e.startAddress.country,
        index: null,
        startTime: null,
        stateProv: e.startAddress.stateProv,
        id: e.id,
        desc: `${getCurrencyString(e.currency)}<br>${getRentString(e.rateMonthly, e.rateWeekly, e.rateDaily)}<br>${shortDate(e.availStartDate)}-${shortDate(e.availEndDate)}`,
        clickLabel: !!cid && cid === e.vendorCompSummary.cid ? [{btnLabel: 'Details', emitterAction: 'mapClusterAction'}] :
        [{btnLabel: 'Rent', emitterAction: 'mapLabelActionById'}]
      });

    }
  }
  return labels;
};
/** get rent string for Map Label
 * e.g. 4000/month, 1000/week, 100/day
*/
export const getRentString = (rateMonthly: number, rateWeekly: number, rateDaily: number) => {
  let r: string; //  = 'Rent(C$): ';
  if (!rateMonthly && !rateWeekly && !rateDaily) {
    return null;
  }
  if (!!rateMonthly) {
    r = `$${rateMonthly}/month`;
  }
  if (!!rateWeekly) {
    if (!!rateMonthly) {
      r = `${r}, `;
    }
    r = !!r ? `${r}$${rateWeekly}/week` : `$${rateWeekly}/week`;
  }
  if (!!rateDaily) {
    if (!!rateMonthly || !!rateWeekly) {
      r = `${r}, `;
    }
    r = !!r ? `${r}$${rateDaily}/day` : `$${rateDaily}/day`;
  }
  return r;
};
/** get Currency string for Map Label
 * e.g. 4000/month, 1000/week, 100/day
*/
export const getCurrencyString = (currency: Currency) => {
  if (currency === 'CAD') {
    return `Rent(C$): `;
  } else if (currency === 'USD') {
    return `Rent(U$): `;
  } else {
    return null;
  }
};
export const getDepositString = (currency: Currency) => {
  if (currency === 'CAD') {
    return `Security Deposit(C$): `;
  } else if (currency === 'USD') {
    return `Security Deposit(U$): `;
  } else {
    return null;
  }
};
// /** temp created as RentOptionDb in API throws typing error. TODO HG  */
// export const parseTripArray = (obj: any[]): TripBase[] => {
//   const r = !!obj ? obj.map(o => <TripBase>parseRentalProduct(o)) : null;
//   return r;
// };

export const getRentalAgreement = (pType: ProductType, country : 'CA' | 'US' = 'CA') => {
  switch (pType) {
    case ProductType.trailer:
      return `trailer-rental-agreement-${country.toLowerCase()}`;
    case ProductType.truck:
      return `truck-rental-agreement-${country.toLowerCase()}`;
    default:
      throw new Error(`invalid product, rental agreement not defined for ${pType}`);

      break;
  }
}

/**
 * @author - MKN
 * Get dynamic personal guaranty document id based on location
 * @param country 
 * @returns 
 */
export const getPersonalGuarantyAgreement = (country : 'CA' | 'US' = 'CA') => {
  return `personal-guaranty-${country.toLowerCase()}`;
}

export const assetLocMapLabels = (locData: {
  unitName: string; iPoint: IPoint; timestamp: number;
  pType: ProductType, refId: string | number
}[]): MapLabel[] => {
  const labels: MapLabel[] = [];
  for (const d of locData) {
    labels.push({
      iPoint: d.iPoint, title: d.unitName,
      desc: `Location recorded at <br>${shortDateTime(new Date(d.timestamp))}`,
      productType: d.pType,
      id: d.refId,
      clickLabel: null
    });
  }
  return labels;
};
export const getRentalByProdType = (prodType: ProductType) => {
  switch (prodType) {
    case ProductType.truck:
      return  new RentalTruck();
    case ProductType.trailer:
      return  new RentalTrailer();
  
    default:
      break;
  }
};

export const getMapLabelsForRentals = (rentals: RentalProductBase[], cid?: string): MapLabel[] => {

  const labels: MapLabel[] = [];

  for (const e of rentals) {
    if((e.availEndDate > addDays(new Date(), 2))) {
      let actions: string;
      if (!!cid && cid === e.cid) {
        actions = '<a id="ClusterAction">Details</a>';
      } else {
        actions = '<a id="ClusterAction">Details</a> | <a id="IdAction">Rent</a>';
      }
      if (!!e.startAddress.geoLoc) {
        labels.push({
          iPoint: {
            latitude: Math.round(e.startAddress?.geoLoc.geopoint.latitude * 1000000) / 1000000,
            longitude: Math.round(e.startAddress?.geoLoc.geopoint.longitude * 1000000) / 1000000
          },
          geohash: e.startAddress?.geoLoc?.geohash,
          // title: `${(e as RentalTrailerItem).summary}`,
          title: ((e as RentalTruck).productSummary).summaryString,
          productType: e.productType,
          id: e.id,
          desc: !e.targetCCids ? `<div>${getCurrencyString(e.currency)}<br>${e.getLowestRentString(false)}</div>  <br>
          <div style="border-top: 1px; border-top-style: solid; border-color: #888; margin-left:-10px; margin-right:-10px; padding: 10px">
          <div>${actions}<div>
          </div>`
            : 'Exlusive',
          clickLabel: !!cid && cid === e.cid ? [{ btnLabel: 'Details', emitterAction: 'mapClusterAction' }] :
            [{ btnLabel: 'Rent', emitterAction: 'mapLabelActionById' }],
          actions: ['ClusterAction', 'IdAction']

        });
      }
    
  }
  }
  return labels;
};