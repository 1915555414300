import { MinimumSellingPrice } from "./minimum-selling-price";

export const parseMinimumSellingPrice = (obj: any): MinimumSellingPrice => {
    if (obj == null) { return null; }
    const c = obj as MinimumSellingPrice; // cast it to base, remember it is still a javascript obj at run time.
    return MinimumSellingPrice.parse(c);;
};

//MKN - Array parse
export const parseMinimumSellingPriceArray = (obj: any[]): MinimumSellingPrice[] => {
  return obj.map(o => parseMinimumSellingPrice(o));
};
