import { ICommPreference } from './comm-preference';

export const commPreferenceInit = (): ICommPreference => {
  const c: ICommPreference = {
    fcm: false,
    email: false,
    sms: false,
    whatsApp: false
  };
  return c;

};
