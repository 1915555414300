<!-- eslint-disable @angular-eslint/template/no-negated-async -->
<ng-container>
	<ng-container *ngIf="emRTruck && emRTruck?.productSummary">
		<trent-slide-panel [activePane]="activePane" (activePanChg)="activePane = $event" [heightRequire]="!isHandset">
			<div leftPane class="wide-margin">
				<mat-card appearance="outlined" class="log-card" *ngIf="emRTruck?.productSummary">
					<mat-card-header>
						<div mat-card-avatar>
							<mat-icon matSuffix color="primary">{{emRTruck.productSummary?.prodIcon}}</mat-icon>
						</div>
						<mat-card-title>{{emRTruck.productSummary.title}}
							(#{{emRTruck.productSummary.unitName}})
						</mat-card-title>
						<mat-card-subtitle>{{(emRTruck.productSummary.make)}} {{emRTruck.productSummary.modelYear}}
						</mat-card-subtitle>
						<mat-card-subtitle>VIN: {{emRTruck.productSummary.vin}}</mat-card-subtitle>
						<mat-card-subtitle *ngIf="salesOperationsUser && emRTruck.id">
							Status: <span [style.color]="dbStatusColor">{{salesStatusEnum.available === m?.status ?
								'Released' : 'Staging'}}</span>
						</mat-card-subtitle>
						<mat-card-subtitle *ngIf="!!em.holdDate">Hold Date: {{emRTruck.holdDate | date:
							'mediumDate'}}</mat-card-subtitle>
						<mat-card-subtitle *ngIf="!!em.holdDate">Hold Days: {{emRTruck.holdDays ||
							0}}</mat-card-subtitle>
					</mat-card-header>
					<mat-card-content>

						<ul class="menu-list menu-list-boxed">
							<li *ngIf="!yardPerson">
								<a href="javascript:void(0)" (click)="showHide('details')">
									<mat-icon aria-hidden="false" aria-label="Phone" class="left-icon">list</mat-icon>
									<span>Sales Details</span>
									<em [ngClass]="{'missing-info': !valDbStatus?.groupResult['details']?.groupPass}">
										{{valDbStatus?.groupResult['details']?.message}}
									</em>
									<mat-icon
										*ngIf="!!valDbStatus?.groupResult['details']?.groupPass">keyboard_arrow_right</mat-icon>
									<mat-icon *ngIf="!valDbStatus?.groupResult['details']?.groupPass"
										class="missing-info">info
									</mat-icon>
								</a>
							</li>
							<li *ngIf="!yardPerson">
								<a href="javascript:void(0)" (click)="showHide('price')">
									<mat-icon aria-hidden="false" class="left-icon">paid</mat-icon>
									<span>Sales Plans</span>
									<em [ngClass]="{'missing-info': !valDbStatus?.groupResult['price']?.groupPass}">
										{{valDbStatus?.groupResult['price']?.message}}
									</em>
									<mat-icon
										*ngIf="!!valDbStatus?.groupResult['price']?.groupPass">keyboard_arrow_right</mat-icon>
									<mat-icon *ngIf="!valDbStatus?.groupResult['price']?.groupPass"
										class="missing-info">info
									</mat-icon>
								</a>
							</li>
							<li>
								<a href="javascript:void(0)" (click)="showHide('pictures')">
									<mat-icon aria-hidden="false" class="left-icon">photo</mat-icon>

									<span>Pictures</span>
									<em [ngClass]="{'missing-info': !valDbStatus?.groupResult['pictures']?.groupPass}">
										{{valDbStatus?.groupResult['pictures']?.message}}
									</em>
									<mat-icon
										*ngIf="!!valDbStatus?.groupResult['pictures']?.groupPass">keyboard_arrow_right</mat-icon>
									<mat-icon *ngIf="!valDbStatus?.groupResult['pictures']?.groupPass"
										class="missing-info">info
									</mat-icon>
								</a>
							</li>
							<li *ngIf="!yardPerson">
								<a href="javascript:void(0)" (click)="showHide('specs')">
									<mat-icon aria-hidden="false" class="left-icon">build</mat-icon>
									<span>Specifications</span>
									<em [ngClass]="{'missing-info': !valDbStatus?.groupResult['specs']?.groupPass}">
										{{valDbStatus?.groupResult['specs']?.message}}
									</em>
									<mat-icon
										*ngIf="!!valDbStatus?.groupResult['specs']?.groupPass">keyboard_arrow_right</mat-icon>
									<mat-icon *ngIf="!valDbStatus?.groupResult['specs']?.groupPass"
										class="missing-info">info
									</mat-icon>
								</a>
							</li>
							<li *ngIf="!yardPerson">
								<a href="javascript:void(0)" (click)="showHide('warranty')">
									<mat-icon aria-hidden="false" class="left-icon">article</mat-icon>
									<span>Warranty</span>
									<em>
										Product Warranty
									</em>
									<mat-icon>keyboard_arrow_right</mat-icon>
								</a>
							</li>
							<li *ngIf="!yardPerson">
								<a href="javascript:void(0)" (click)="showHide('additional')">
									<mat-icon aria-hidden="false" class="left-icon">article</mat-icon>
									<span>Additional</span>
									<em
										[ngClass]="{'missing-info': !valDbStatus?.groupResult['additional']?.groupPass}">
										{{valDbStatus?.groupResult['additional']?.message}}
									</em>
									<mat-icon
										*ngIf="!!valDbStatus?.groupResult['additional']?.groupPass">keyboard_arrow_right</mat-icon>
									<mat-icon *ngIf="!valDbStatus?.groupResult['additional']?.groupPass"
										class="missing-info">info
									</mat-icon>
								</a>
							</li>
						</ul>
					</mat-card-content>
					<ng-container *ngIf="!isHandset">
						<ng-container *ngTemplateOutlet="desktopActions"></ng-container>
					</ng-container>
				</mat-card>
			</div>

			<div rightPane>
				<ng-container>
					<ng-container *ngIf="currSubMenu === 'details'">
						<mat-card appearance="outlined" class="gen-card wide-margin">
							<mat-card-content>
								<ng-container *ngTemplateOutlet="details"></ng-container>
							</mat-card-content>
						</mat-card>
					</ng-container>
					<ng-container *ngIf="currSubMenu === 'price'">
						<mat-card appearance="outlined" class="gen-card wide-margin">
							<mat-card-content>
								<ng-container *ngTemplateOutlet="price"></ng-container>
							</mat-card-content>
						</mat-card>
					</ng-container>
					<ng-container *ngIf="currSubMenu === 'pictures'">
						<ng-container *ngTemplateOutlet="pictureTemp"></ng-container>
					</ng-container>
					<ng-container *ngIf="currSubMenu === 'specs'">
						<ng-container *ngTemplateOutlet="specs"></ng-container>
					</ng-container>
					<ng-container *ngIf="currSubMenu === 'warranty'">
						<mat-card appearance="outlined" class="gen-card wide-margin">
							<mat-card-content>
								<ng-container *ngTemplateOutlet="warranty"></ng-container>
							</mat-card-content>
						</mat-card>
					</ng-container>
					<ng-container *ngIf="currSubMenu === 'additional'">
						<mat-card appearance="outlined" class="gen-card wide-margin">
							<mat-card-content>
								<ng-container *ngTemplateOutlet="additional"></ng-container>
							</mat-card-content>
						</mat-card>
					</ng-container>
				</ng-container>
			</div>
		</trent-slide-panel>
	</ng-container>

</ng-container>

<ng-template #pictureTemp>
	<form #picturesForm="ngForm" class="d-flex flex-wrap">
		<div class="div-card"
			[ngClass]="{'w-100 h-65v': (em.imageType === ImageTypeEnum.original || (em.imageType === ImageTypeEnum.lookALike && isMoveToOg)) && !isHandset, 'w-370 h-65v': (em.imageType === ImageTypeEnum.notApplicable || (em.imageType === ImageTypeEnum.lookALike && !isMoveToOg)) && !isHandset, 'div-card-mob': isHandset}">
			<!-- Image Type -->
			<mat-form-field floatLabel="always" class="radio">
				<mat-label>Picture Type</mat-label>
				<input matInput name="imageType" #imageType="ngModel" [(ngModel)]="em.imageType" style="display: none">
				<div style="display: grid; align-self: bottom">
					<mat-radio-group aria-label="Select an option" (change)="onImageTypeChanged()" name="imageType"
						#imageType="ngModel" [disabled]="!isEditMode || yardPerson" [(ngModel)]="em.imageType">
						<mat-radio-button color="warn" [value]="ImageTypeEnum.original" style="padding-right: 10px">
							Original
						</mat-radio-button>
						<mat-radio-button color="warn" [value]="ImageTypeEnum.lookALike" style="padding-right: 10px">
							Lookalike
						</mat-radio-button>
						<mat-radio-button color="warn" [value]="ImageTypeEnum.notApplicable"
							style="padding-right: 10px">
							Not Applicable
						</mat-radio-button>
					</mat-radio-group>
				</div>
			</mat-form-field>
			<ng-container
				*ngIf="em.imageType === ImageTypeEnum.original && !isMoveToOg && (isEditMode || salesOptionPic?.length === 0)">
				<trent-product-picture-grid [path]="picturePath" (deleteParent)="deletePicture($event)"
					[product]="product" [uploadRequired]="isHandset" [headerTitle]="'Draft Pictures'"
					(showHideImages)="showHideImages($event)" [showVisibilityIcon]="isProductPicturePublishIsRequired()"
					[showLogoPosition]="!isCreateRequest" (logoPositionOutPut)="logoPositionOutPut($event)"
					[showOrderingIcon]="isProductOrderingIsRequired()" [isEditMode]="!isEditMode"
					[selectedLogoPosition]="selectedLogoPosition"></trent-product-picture-grid>
			</ng-container>
			<ng-container
				*ngIf="em.imageType === ImageTypeEnum.original && !isMoveToOg && !isEditMode && salesOptionPic?.length > 0">
				<div class="pic-container-parent">
					<ng-container *ngFor="let item of salesOptionPic">
						<div class="image-container tile-view">
							<div class="img-area">
								<img [src]="item?.serverUrl" alt="image" class="carousel-img img1" />
							</div>
							<div *ngIf="item.customFirebaseMetadata?.loc" class="img-text">
								{{item.customFirebaseMetadata?.loc}}</div>
							<mat-icon *ngIf="item?.customFirebaseMetadata && item?.uploadedOn"
								(click)="openInfoDialog(item.customFirebaseMetadata?.uid, item.uploadedOn)">info</mat-icon>
						</div>
					</ng-container>
				</div>
			</ng-container>
			<ng-container *ngIf="em.imageType === ImageTypeEnum.lookALike || isMoveToOg">
				<ng-container *ngTemplateOutlet="lookaLikeTemplate"></ng-container>
			</ng-container>
		</div>
		<div class="div-card" [ngClass]="{'w-370': !isHandset, 'd-none': !showSelectProductsList}">
			<trent-proxy-vin-select-product-list [product]="product"
				(closeSearchProduct)="closeSearchProductPage($event)"
				(selectedProduct)="selectedProduct($event)"></trent-proxy-vin-select-product-list>
		</div>
		<div *ngIf="(!emRTruck.pictures || emRTruck.pictures.length===0 && !!emRTruck.defaultImage)"
			class="default-img-cont">
			<div>
				<!-- <img [src]="emRTruck.defaultImage" class="default-image w-100"> -->
			</div>
		</div>
	</form>
</ng-template>
<ng-template #details>
	<form #detailsForm="ngForm">
		<!-- Store Location -->
		<mat-form-field class="full-width">
			<mat-label>Store Location</mat-label>
			<mat-select name="storeLocation" #storeLocation="ngModel" [(ngModel)]="em.storeLocationSummary.sid"
				[disabled]="!isEditMode">
				<mat-option [value]="null">Select...</mat-option>
				<mat-option *ngFor="let s of storeLocations" [value]="s.id">{{s.storeLocationName}} </mat-option>
			</mat-select>
			<mat-hint *ngIf="!showError(storeLocation)" align="end">Required</mat-hint>
			<mat-error *ngIf="showError(storeLocation)">{{storeLocation?.errors?.msg}}
			</mat-error>
		</mat-form-field>

		<!-- Status -->
		<trent-sales-status [em]="em" [_isEditMode]="isEditMode"
			[salesOperationsUser]="salesOperationsUser"></trent-sales-status>
		<!-- Auto Trader Id -->
		<mat-form-field class="full-width">
			<mat-label>Auto Trader Id</mat-label>
			<input matInput [(ngModel)]="em.autoTraderId" type="text" name="autoTraderId" #autoTraderId="ngModel"
				[disabled]="!isEditMode" autocomplete="off" />

			<mat-hint *ngIf="!showError(autoTraderId)" align="end">Optional</mat-hint>
			<mat-error align='end' *ngIf="showError(autoTraderId)">
				{{autoTraderId.errors.msg}}</mat-error>
		</mat-form-field>
	</form>
</ng-template>
<ng-template #lookaLikeTemplate>
	<mat-form-field class="full-width" *ngIf="em.imageType === ImageTypeEnum.lookALike && !isMoveToOg">
		<mat-label>Proxy VIN</mat-label>
		<input matInput [(ngModel)]="proxyVin" type="text" name="vin" #vin="ngModel" [disabled]="true"
			autocomplete="off" />
		<span matSuffix>
			<button mat-icon-button color="primary" [disabled]="!isEditMode" *ngIf="proxyVin" type="button"
				(click)="clearProxyVin()">
				<mat-icon>remove_circle</mat-icon>
			</button>
		</span>
		<span matSuffix>
			<button mat-icon-button color="primary" [disabled]="!isEditMode" type="button"
				(click)="showSelectProductsList = true">
				<mat-icon>search</mat-icon>
			</button>
		</span>
	</mat-form-field>
	<div *ngIf="proxyVinProductPictures?.length && !isMoveToOg">
		<trent-carousel2 [list]="proxyVinProductPictures" [heightImg]="248" [showHeightVW]="false"
			[intervalCall]="false" [defaultImg]="'../../../../assets/pride-truck-sales-logo.webp'">
			<ng-template let-item #home>
				<img [src]="item?.serverUrl" (error)="handleImageError(item)" alt="image" class="carousel-img img" />
			</ng-template>
		</trent-carousel2>
		<div class="d-flex d-j-center">
			<button type="button" (click)="copyToOriginal()" class="mt-5" mat-stroked-button color="primary">
				Copy to Original
			</button>
		</div>
	</div>
	<ng-container *ngIf="proxyVinProductPictures?.length && isMoveToOg">
		<trent-product-picture-grid [path]="proxyPicturePath" [product]="product" [showSelectedPics]="true"
			[uploadRequired]="isHandset" (selectPicsOutPut)="selectPicsOutPut($event)"
			(deleteParent)="deletePicture($event)" [isEditMode]="!isEditMode"></trent-product-picture-grid>
	</ng-container>
</ng-template>
<ng-template #price>
	<form #priceForm="ngForm">
		<!-- Product Minimum Price -->
		<mat-form-field class="full-width">
			<span matPrefix>$ &nbsp;</span>
			<mat-label>Minimum Selling Price </mat-label>
			<input matInput [disabled]="true" type="number" name="minimumSellingPrice" #minimumSellingPrice="ngModel"
				[(ngModel)]="em.minimumSellingPrice" autocomplete="off" readonly>
			<mat-hint align="end">
				${{em?.minimumSellingPrice | number :'1.0'}} 
				<ng-container *ngIf="em.minimumSellingPrice"></ng-container>
			</mat-hint>
			
			<mat-error *ngIf="showError(minimumSellingPrice)">{{minimumSellingPrice?.errors?.msg}}</mat-error>
		</mat-form-field>

		<!-- Wholesale Price -->
		<mat-form-field class="full-width">
			<span matPrefix>$ &nbsp;</span>
			<mat-label>Wholesale Price</mat-label>
			<input matInput [disabled]="!isEditMode" type="number" name="wholesalePrice" #wholesalePrice="ngModel"
				[(ngModel)]="em.wholesalePrice" autocomplete="off"
				(change)="retailPriceUpdate($event); priceCheck($event)">
			<mat-hint align="end">
				${{em?.wholesalePrice | number :'1.0'}} &nbsp; &nbsp; 
				<ng-container *ngIf="!showError(wholesalePrice)">Required</ng-container>
			</mat-hint>
			<mat-error *ngIf="showError(wholesalePrice)">{{wholesalePrice?.errors?.msg}}</mat-error>
		</mat-form-field>

		<!-- Currency -->
		<mat-form-field class="full-width">
			<mat-label>Currency</mat-label>
			<mat-select name="currency" #currency="ngModel" [(ngModel)]="em.currency" [disabled]="true"
				(selectionChange)="retailPriceUpdate($event)">
				<mat-option *ngFor="let c of currencyList" [value]="c">{{c}}</mat-option>
			</mat-select>
			<mat-hint *ngIf="!showError(currency)" align="end">Required</mat-hint>
			<mat-error *ngIf="showError(currency)">{{currency?.errors?.msg}}
			</mat-error>
		</mat-form-field>

		<!-- Price -->
		<mat-form-field class="full-width">
			<span matPrefix>$ &nbsp;</span>
			<mat-label>Retail Price</mat-label>
			<input matInput [disabled]="true" type="number" name="price" #price="ngModel" [(ngModel)]="em.price"
				autocomplete="off" (change)="priceCheck($event)">
			<mat-hint align="end">
				${{em?.price | number :'1.0'}} &nbsp; &nbsp;
				<ng-container *ngIf="!showError(price)">Required</ng-container>
			</mat-hint>
			<mat-error *ngIf="showError(price)">{{price?.errors?.msg}}</mat-error>
		</mat-form-field>
	</form>
</ng-template>
<ng-template #specs>
	<form #specsForm="ngForm">
		<div class="spec-card">
			<div>
				<h3>Product Specifications</h3>
				<div class="outer-div">
					<div class="inside-div">
						<span style="font-weight: 700;">Name</span>
						<span style="font-weight: 700;">Keys</span>
						<span style="font-weight: 700;">Page</span>
						<span style="font-weight: 700;">Card</span>
					</div>
					<ng-container *ngFor="let item of em?.specs | keyvalue">
						<div class="inside-div">
							<span>{{getTitle(item.key)}}</span>
							<span class="text-ellipsis">{{getValue(item.key, item.value.value)}}</span>
							<span>
								<mat-checkbox name="displayOnPage-{{item.key}}" [disabled]="!isEditMode"
									[(ngModel)]="em?.specs[item.key].displayOnPage"
									(change)="displayOnPageValidation(item.key)"></mat-checkbox>
							</span>
							<span>
								<mat-checkbox name="displayOnCard-{{item.key}}" [disabled]="!isEditMode"
									[(ngModel)]="em?.specs[item.key].displayOnCard"
									(change)="displayOnCardValidation(item.key)"></mat-checkbox>
							</span>
						</div>
					</ng-container>
				</div>
			</div>
			<div class="mt-24">
				<h3>Additional</h3>
				<!-- Price Toggle -->
				<div class="outer-div">
					<div class="inside-div">
						<span>Show Price</span>
						<mat-checkbox name="showPrice" [disabled]="!isEditMode"
							[(ngModel)]="em.showPrice"></mat-checkbox>
					</div>
				</div>
			</div>

		</div>
	</form>
</ng-template>
<ng-template #warranty>
	<!-- Warranty -->
	<table mat-table [dataSource]="warrantyDataSource" *ngIf="!!em.warranty?.length">
		<!-- Title Column -->
		<ng-container matColumnDef="title">
			<th mat-header-cell *matHeaderCellDef> Title </th>
			<td mat-cell *matCellDef="let element">
				{{getWarrantyTitle(element)}}
			</td>
		</ng-container>

		<!-- Action Column -->
		<ng-container matColumnDef="action">
			<th mat-header-cell *matHeaderCellDef> Action </th>
			<td mat-cell *matCellDef="let element; let i = index" class="d-flex">
				<!-- Edit -->
				<button mat-icon-button [disabled]="!isEditMode" (click)="updateWarranty(element, i)">
					<mat-icon>edit_note</mat-icon>
				</button>
				<!-- Delete -->
				<button mat-icon-button [disabled]="!isEditMode" (click)="deleteWarranty(element)" class="ml10">
					<mat-icon>delete</mat-icon>
				</button>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="warrantyColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: warrantyColumns;"></tr>
	</table>
	<div *ngIf="!em.warranty?.length" class="warranty-placeholder">
		<h4>No warranty found</h4>
	</div>
	<button [disabled]="serverReqInProcess" *ngIf="isEditMode" type="button" (click)="updateWarranty()"
		class="md-18 right mt-5" mat-raised-button color="secondary">
		<mat-icon>post_add</mat-icon>
		Add
	</button>
</ng-template>

<ng-template #additional>
	<form #additionalForm="ngForm">
		<!-- Additional Notes -->
		<div class="txt-area">
			<mat-form-field class="full-width">
				<mat-label>Additional Notes</mat-label>
				<textarea matInput [disabled]="!isEditMode" type="text" name="additionalNotes"
					#additionalNotes="ngModel" [(ngModel)]="em.additionalNotes" autocomplete="off" cols="35"
					rows="5"></textarea>
				<!-- Show/hide note -->
				<mat-checkbox *ngIf="!!em?.additionalNotes" matSuffix [disabled]="!isEditMode"
					name="showAdditionalNotes" [(ngModel)]="em.showAdditionalNotes"
					matTooltip="Show/Hide note"></mat-checkbox>
				<mat-hint align="end">Optional</mat-hint>
			</mat-form-field>
		</div>

		<!-- MFGDate -->
		<mat-form-field>
			<mat-label>MFG Date</mat-label>
			<input matInput [matDatepicker]="pickerMFGDate" name="mfgDate" #mfgDate="ngModel" placeholder="MFG Date"
				[(ngModel)]="em.mfgDate" [disabled]="!isEditMode" (dateInput)="handleMfgDateChange($event)">
			<mat-datepicker-toggle matSuffix [for]="pickerMFGDate"></mat-datepicker-toggle>
			<mat-datepicker #pickerMFGDate></mat-datepicker>
			<mat-hint align="end">Optional</mat-hint>
		</mat-form-field>


		<!-- Item Received Date -->
		<mat-form-field>
			<mat-label>Item Received Date</mat-label>
			<input matInput [matDatepicker]="pickerItemReceivedDate" name="itemReceivedDate" #itemReceivedDate="ngModel"
				placeholder="Item Received Date" [(ngModel)]="em.itemReceivedDate" [disabled]="!isEditMode"
				[matDatepickerFilter]="itemReceivedDateFilter">
			<mat-datepicker-toggle matSuffix [for]="pickerItemReceivedDate"></mat-datepicker-toggle>
			<mat-datepicker #pickerItemReceivedDate></mat-datepicker>
			<mat-hint align="end">Optional</mat-hint>
			<mat-error *ngIf="showError(itemReceivedDate)">{{itemReceivedDate?.errors?.msg}}</mat-error>
		</mat-form-field>

		<!-- Ownership Status -->
		<mat-form-field class="full-width">
			<mat-label>Ownership Status</mat-label>
			<mat-select name="provider" #provider="ngModel" [(ngModel)]="em.ownershipStatus" [disabled]="!isEditMode">
				<mat-option value="YES">Yes</mat-option>
				<mat-option value="NO">No</mat-option>
			</mat-select>
			<mat-hint align="end">Optional</mat-hint>
		</mat-form-field>
	</form>
</ng-template>

<ng-template #desktopActions>
	<mat-card-actions *ngIf="!(isHandset$ | async)" class="d-flex flex-wrap">
		<button [disabled]="serverReqInProcess" *ngIf="isEditMode" type="button" (click)="cancel()"
			class="md-18 right mt-5" mat-stroked-button>
			<mat-icon>close</mat-icon>Cancel
		</button>
		<button [disabled]="serverReqInProcess" *ngIf="isReadMode" (click)="edit()" type="button"
			class="md-18 right mt-5" mat-stroked-button>
			<mat-icon>edit</mat-icon> Edit
		</button>
		<button [disabled]="serverReqInProcess" *ngIf="isEditMode" type="button" (click)="save()"
			class="md-18 right mt-5" mat-raised-button color="primary">
			<mat-icon>done</mat-icon>
			Update
		</button>
	</mat-card-actions>
</ng-template>

<ng-template #infoDialog>
	<div>
		<div mat-dialog-title style="background: var(--accent);">
			<span class="closebtn" (click)="cancelDialog()">&times;</span>
			Picture Info
		</div>
		<mat-dialog-content>
			<p style="margin-top: 16px !important;">Created By:- <strong>{{infoData.name}}</strong></p>
			<p>Updated:- <strong>{{ infoData.date | date :'medium'}}</strong></p>
		</mat-dialog-content>
	</div>
</ng-template>