<form #searchSalesOptionForm="ngForm">

  <ng-container>


    <!-- VIN -->
    <mat-form-field *ngIf="(data.searchType === userSearchType.YardPerson) && !data.isProdList">
      <mat-label>VIN</mat-label>
      <input matInput type="text" name="vin" #vin="ngModel" [(ngModel)]="productParam.vin" autocomplete="off">
      <mat-hint class="form-error" *ngIf="vin?.errors?.pattern" align="end"> Vin is invalid</mat-hint>
    </mat-form-field>

    <!-- VIN Last Six -->
    <mat-form-field *ngIf="!!data.isProdList">
      <mat-label>VIN Last Six</mat-label>
      <input matInput type="text" name="vinLastSix" #vinLastSix="ngModel" [(ngModel)]="productParam.vinLastSix"
        autocomplete="off">
      <mat-hint class="form-error" *ngIf="vinLastSix?.errors?.pattern" align="end"> Vin is invalid</mat-hint>
    </mat-form-field>

    <div class="d-flex">

      <div class="col-6">
        <!-- Make -->
        <mat-form-field>
          <mat-label>Make</mat-label>
          <mat-select name="make" #make="ngModel" [(ngModel)]="productParam.make" (selectionChange)="onMakes()">
            <mat-option [value]="null">All</mat-option>
            <mat-option *ngFor="let item of makesList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <!-- / Make -->
      </div>


      <div class="col-6" *ngIf="!!modelList.length">
        <!-- Model -->
        <mat-form-field>
          <mat-label>Model</mat-label>
          <mat-select name="model" #model="ngModel" [(ngModel)]="productParam.model" (selectionChange)="onModel()">
            <mat-option [value]="null">All</mat-option>
            <mat-option *ngFor="let item of modelList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <!-- Model -->
      </div>

    </div>

    <div class="d-flex">

      <div class="col-6 d-grid-c-2">
        <!-- Model Year -->
        <div class="small-div" *ngIf="!!modelYearList.length">
          <mat-form-field>
            <mat-label>Model Year</mat-label>
            <mat-select name="modelYear" #modelYear="ngModel" [(ngModel)]="productParam.modelYear">
              <mat-option [value]="null">All</mat-option>
              <mat-option *ngFor="let item of modelYearList" [value]="item">{{item}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="small-div">
          <mat-form-field>
            <mat-label>Country</mat-label>
            <mat-select matNativeControl name="country" #country="ngModel" [(ngModel)]="salesParam.country">
              <mat-option [value]="null">All</mat-option>
              <mat-option [value]="'CA'">Canada</mat-option>
              <mat-option [value]="'US'">USA</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div [ngClass]="{'col-6': data.searchType === userSearchType.SalesOperation}" *ngIf="websiteId === 'pts'">
        <!-- Inventory | Marketing Location -->
        <mat-form-field>
          <mat-label *ngIf="data.searchType !== userSearchType.SalesOperation">Inventory Location</mat-label>
          <mat-label *ngIf="data.searchType === userSearchType.SalesOperation">Marketing Location</mat-label>
          <mat-select name="storeLocation" #storeLocation="ngModel" [(ngModel)]="salesParam.storeLocationId">
            <mat-option [value]="null">All</mat-option>
            <mat-option *ngFor="let s of storeLocations" [value]="s.id">{{s.storeLocationName}} </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>



    <div class="d-flex" *ngIf="this.websiteId === 'pts'">

      <div class="col-6" [ngClass]="{'d-grid-c-2': !(userProfile?.roles?.sales)}">
        <div class="small-div">
          <!-- Product Type -->
          <mat-form-field *ngIf="data.searchType === userSearchType.SalesOperation">
            <mat-label>Product Type</mat-label>
            <mat-select name="productType" #productType="ngModel" [(ngModel)]="salesParam.type">
              <mat-option [value]="null">All</mat-option>
              <mat-option *ngFor="let s of productTypeEnum" [value]="s.id">{{toSentence(s.name)}} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="small-div" *ngIf="!userProfile.roles.sales">
          <!-- Image Type | Sales Op -->
          <mat-form-field *ngIf="!(data.searchType === userSearchType.YardPerson) || data.isProdList">
            <mat-label>Image Type</mat-label>
            <mat-select matNativeControl name="imageType" #imageType="ngModel" [(ngModel)]="salesParam.imageType" align="end">
              <mat-option [value]="null">All</mat-option>
              <ng-container *ngFor="let s of imageTypeEnum">
                <mat-option [value]="s.value">{{toSentence(s.name, s.value)}}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="col-6 image-status" [ngClass]="{'d-grid-c-2': (data.searchType === userSearchType.YardPerson) && !data.isProdList}">
        <!-- Image Status | Yard Person -->
        <div class="small-div">
          <mat-form-field *ngIf="(data.searchType === userSearchType.YardPerson) && !data.isProdList">
            <mat-label>Image Status</mat-label>
            <mat-select matNativeControl name="imageType" #imageType="ngModel" [(ngModel)]="productParam.imageUploadStatus" align="end">
              <ng-container *ngFor="let s of imageUploadStatusEnum">
                <mat-option [value]="s.value">{{toSentence(s.name, s.value)}}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="small-div">
          <!-- Sales Status -->
          <mat-form-field *ngIf="data.searchType === userSearchType.SalesOperation">
            <mat-label>Sales Status</mat-label>
            <mat-select matNativeControl name="status" #status="ngModel" [(ngModel)]="salesParam.status" align="end">
              <mat-option [value]="null">All</mat-option>
              <ng-container *ngFor="let s of statusEnum">
                <mat-option [value]="s.value">{{toSentence(s.name)}}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>


    <div class="">
      <div class="col-12" *ngIf="this.websiteId === 'pts'">
        <!-- Odometer -->
        <ng-container>
          <mat-label style="color: #00000099;">Odometer</mat-label>
  
          <div class="price-slider">
            <div class="meter">
              <mat-slider [min]="0" [max]="1000000" [step]="100" [discrete]="true" ngDefaultControl
                (change)="onOdoRange()">
                <input name="odoFrom" [(ngModel)]="minOdoValue" matSliderStartThumb (change)="onOdoMinValue($event)">
                <input name="odoTo" [(ngModel)]="maxOdoValue" matSliderEndThumb (change)="onOdoMaxValue($event)">
              </mat-slider>
            </div>
  
            <div class="step">
              <div class="step-1"><span>{{ 0 | number : '1.0' }}</span></div>
              <div class="step-2"><span class="ml-auto">{{ 1000000 | number : '1.0' }}</span></div>
            </div>
  
          </div>
  
        </ng-container>
      </div>
  
      <div class="col-12" *ngIf="this.websiteId === 'pts'">
        <!-- Price Range -->
        <ng-container *ngIf="data.searchType === userSearchType.SalesOperation && salesParam?.priceRange">
          <mat-label style="color: #00000099;">Price Range</mat-label>
  
          <div class="price-slider">
  
            <div class="meter">
              <mat-slider [min]="0" [max]="200000" [step]="1000" [discrete]="true" ngDefaultControl>
                <input name="from" [(ngModel)]="salesParam.priceRange.from" matSliderStartThumb>
                <input name="to" [(ngModel)]="salesParam.priceRange.to" matSliderEndThumb>
              </mat-slider>
            </div>
  
            <div class="step">
              <mat-label class="step-1">{{salesParam?.priceRange?.from > 0 ? '$' : ''}} {{salesParam?.priceRange?.from}}
                &nbsp;</mat-label>
              <mat-label class="step-2">&nbsp; {{salesParam.priceRange.to > 0 ? '$' : ''}}
                {{salesParam.priceRange.to}}</mat-label>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <!-- Last Updated By -->
    <ng-container *ngIf="data.isProdList && websiteId === 'pts'">
      <trent-search-users [param]="productParam.displayName" (itemSelectedC)="selectUser($event)"></trent-search-users>
    </ng-container>

    <!-- Last Updated Range -->
    <mat-form-field *ngIf="data.isProdList && websiteId === 'pts'">
      <mat-label>Last Updated At</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input name="lastUpdatedRangeFrom" matStartDate [(ngModel)]="dateRangeStart" placeholder="Start date">
        <input name="lastUpdatedRangeTo" matEndDate [(ngModel)]="dateRangeEnd" placeholder="End date"
          (dateChange)="dateRange()">
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </ng-container>
</form>

<div class="btn-mobiles">
  <div class="btns mobile-fix mt10" align="end">
    <div>
      <button mat-raised-button color="primary" type="submit" [disabled]="searchSalesOptionForm.invalid"
        (click)="updateSearch()">Search</button>
    </div>
    <div>
      <button mat-stroked-button color="primary" type="button" (click)="cancel()">Cancel</button>
    </div>
  </div>
</div>
