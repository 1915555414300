import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { IUserParam, UserProfile } from '@trent/models/user';
import { firstChar } from '@trent/models/utility';
import { Subscription } from 'rxjs';
import { pickBy } from 'lodash';
import { SalesOptionService } from '@trent/services/sales.service';

@Component({
	selector: 'trent-search-users',
	templateUrl: './search-users.component.html',
	styleUrls: ['./search-users.component.scss']
})
export class SearchUsersComponent implements OnInit, OnDestroy {

	@ViewChild('entryId', { static: false })
	entryId: ElementRef<HTMLInputElement>;

	@Input()
	param: IUserParam;

	@Input()
	defComp: string | number;

	@Input()
	selectedKey: string;

	@Input()
	header: string;

	_selectedKeys: string[];

	@Output()
	itemRemovedC = new EventEmitter<string>();

	sub: Subscription;

	@Input()
	set selectedKeys(v: string[]) {
		if (!!v) {
			this._selectedKeys = v;
			if (this.userList) {
				this.getSelected();
			}
		}
	}

	get selectedKeys() {
		return this._selectedKeys;
	}

	@Output()
	itemSelectedC = new EventEmitter<string>();

	separatorKeysCodes: number[] = [ENTER, COMMA];
	userList;
	filtered: { [key: string]: UserProfile; };
	selected: any;
	activeList;
	selectedVehicles: { [key: string]: UserProfile } = {};

	@Input()
	isEMode: boolean;

	constructor(
		private store: Store,
		private salesService: SalesOptionService
	) { }

	ngOnInit() {
		if (!this.header) this.header = 'Last Updated By';
		this.getData();
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe();
	}

	getData() {
		this.sub = this.salesService.getYardPersons().subscribe((data: any) => {
			if (!!data.users.length) {
				let users = {};
				data.users.forEach(e => {
					users[`${e.id}`] = e;
				});
				this.userList = users;
				this.activeList = users;
			  }
		});
	}

	_filter(v: any) {
		const value = !!v ? v.viewModel : [];
		this.filtered = {};
		for (const key in this.activeList) {
			if (this.activeList.hasOwnProperty(key)) {
				const element = this.activeList[key];
				if (typeof value === 'string' && element.displayName.toLocaleLowerCase().includes(value.toLowerCase())) {
					this.filtered[key] = this.activeList[key];
				}
			}
		}
	}

	displayFn(selected?: { [key: string]: UserProfile; }): string | undefined {
		if (!!selected) {
			return selected && selected.value ? `${selected.value.displayName}` : undefined;
		}
	}

	optionSelected(option: { key: string, value: UserProfile }) {
		this.emitFn(option.key);
		// blur will remove focus on the currently selected element
		this.entryId.nativeElement.blur();
	}

	enterBlur() {
	}

	remove(s: { key: string, value: UserProfile }) {
		this.itemRemovedC.emit(s.key);
	}

	emitFn(k: string) {
		this.itemSelectedC.emit(k);
		this.entryId.nativeElement.value = null;
	}

	firstChar(s: string) {
		return firstChar(s);
	}

	/** get dictionary item based on string passed on by parent */
	getSelected() {
		for (const k in this.activeList) {
			if (this.activeList.hasOwnProperty(k)) {
				const element = this.activeList[k];
				if (!!this.selectedKeys && this.selectedKeys.length > 0) {
					this.selectedKeys.forEach(e => {
						this.selectedVehicles[k] = element;
					});
				}
			}
		}
		this.selectedVehicles = pickBy(this.selectedVehicles, (val, key) => this.selectedKeys.includes(key));
	}

	keySelectedFn(key: string) {
		const user = this.userList[key];
		this.selectedKey = user.displayName;
		this.itemSelectedC.emit(key);
	}
}