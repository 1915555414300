import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { logger } from '@trent/models/log/logger';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { BasePage } from '@trent/models/UI/base.page';
import { Store } from '@ngxs/store';
import { map, take } from 'rxjs/operators';
import { VinService } from '@trent/services/vin.service';
import { MessageInfo, cssMat, readErrorMessage } from '@trent/models/error-handling';
import { EventService } from '@trent/services/event.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageType, SalesOptionsBulkUpload, SalesProductBase, SalesStatus, parseSalesProduct } from '@trent/models/sales-option';
import { FileUploadComponent } from '../../../shared/file-upload/file-upload.component';
import { FileInfo } from '@trent/models/media/file-info';
import { SalesOptionService } from '@trent/services/sales.service';
import { PhysicalLocationType, ProductBase, ProductSummary, ProductType, Trailer, Truck, TruckSummary, Vehicle, parseProduct } from '@trent/models/product';
import { SOExcelSummary } from '@trent/models/sales-option/so-excel-summary';
import { OdometerUnitsType } from '@trent/models/product/netsuite-product-data';
import { capitalize, isEmpty, isNumber } from 'lodash';
import { Specs } from '@trent/models/sales-option/sales-spec';
import { MinimumSellingPrice } from '@trent/models/minimum-selling-price/minimum-selling-price';

@Component({
  selector: 'trent-sales-options-bulk-upload',
  templateUrl: './sales-options-bulk-upload.component.html',
  styleUrls: ['./sales-options-bulk-upload.component.scss']
})
export class SalesOptionsBulkUploadComponent extends BasePage<SalesProductBase[]> implements OnInit, OnDestroy {

  @ViewChild('sOUploaderCtrl', { static: false })
  sOUploader: FileUploadComponent;

  fileSelected: any;
  sOFile: FileInfo;//For UI only

  disableUpload: boolean = false;
  reUploadAgain: boolean = false;
  showFileUpload: boolean = false;
  fileRecords: any = [];
  validRecords: SalesOptionsBulkUpload[] = [];

  duplicateRecords: any = [];
  errorRecords: any = [];
  dbRecords: any[] = [];
  //errMessage : string = null;
  processMessage: string = null;
  sub: Subscription;
  vendorCid : string;
  bulkSORecd: SalesOptionsBulkUpload[] = [];

  constructor(private aroute: ActivatedRoute, private router: Router, store: Store, eventService: EventService, private vinService: VinService, private salesOptionService: SalesOptionService,
    private ds: DialogService) {
    super(store, ds, eventService);
    this.title = 'Sales Options - Bulk Upload';

  }

  ngOnInit() {
    this.sOFile = new FileInfo();
    this.vendorCid = this.aroute.snapshot.queryParams.cid;

    //this.getSalesOptions();
  }

  ngOnDestroy(): void {
    this.cleanListeners();
  }

  showsOStatus(msgCss: cssMat, header: string, description: string) {
    const msg = new MessageInfo({
      msgCss: msgCss || 'success',
      header: header || '[Sales-Options] File upload success',
      description: description || '[Sales-Options] File successfully uploaded and ready for validating'
    });
    this.showAlert(msg);
  }

  getSalesOptions() {

    if (this.sub) {
      this.sub.unsubscribe();
    }

    //db records
  }

  /**
   * @author - MKN
   * @purpose - send upload file to parent
   */
  uploadFile() {
    this.disableUpload = true;
    this.sOBulk();
  }

  fileInput(event: { target: { files: any[]; value: any; }; }) {
    try {
      //if (event.target && this.sOUploader.hasFile) {
        this.fileSelected = event.target.files[0];
     // }
      this.duplicateRecords = [];
      this.errorRecords = [];
      this.validRecords = [];
      // this.errMessage = null;
      this.processMessage = null;
      this.showFileUpload = true;
      this.disableUpload = false;
      const fileReader = new FileReader();
      fileReader.readAsText(this.fileSelected, 'UTF-8');
      fileReader.onload = () => {
        const { result } = fileReader;
        try {
          this.fileRecords = [...JSON.parse(result as string)];
          this.showFileUpload = false;
          if (!this.fileRecords.length) {
            this.resetFileInfo();//Solved PGDT 201 - comments
            //this.errMessage = '[Sales-Options] Please input file for fetching the records';
            return this.showsOStatus(
              'warn',
              'No file uploaded',
              '[Sales-Options] Please upload valid JSON file'
            );
          }
          logger.info('[Sales-Options] File uploaded successfully');
        } catch (e) {
          this.showFileUpload = false;
          this.resetFileInfo();//Solved PGDT 201 - comments
          return this.showsOStatus(
            'warn',
            'Error in file',
            '[Sales-Options] Please upload valid JSON file'
          );
        }
      };
      fileReader.onerror = (error) => {
        this.showFileUpload = false;
        this.resetFileInfo();//Solved PGDT 201 - comments
        //this.errMessage = '[Sales-Options] Problem in reading json file';
        this.showsOStatus(
          'warn',
          'File upload failure',
          '[Sales-Options] Problem in reading json file'
        );
        logger.error('[Sales-Options] Unable to read sale options \n', error);
      };
    } catch (error) {
      this.showFileUpload = false;
      this.resetFileInfo();//Solved PGDT 201 - comments
      logger.error(`[Sales-Options] \n ${error}`);
      //this.errMessage = '[Sales-Options] Unable to upload file for validating';
      this.showsOStatus(
        'warn',
        'File upload failure',
        '[Sales-Options] Unable to upload file for validating'
      );
    }
  }


  async sOBulk() {
    this.showLoading();
    this.showFileUpload = true;
    this.duplicateRecords = [];
    this.errorRecords = [];
    this.validRecords = [];
    this.processMessage = 'Validating data... 0%';
    await this.bulkValidate();
    await this.bulkCreate();
  }

  getOdometer(odometer : string) : number{
    console.log(odometer);
    if(!odometer){
      return 0;
    }

    if(isNumber(odometer)){
      return odometer;
    }

    if(odometer.toLowerCase().indexOf("kms") > -1 || odometer.toLowerCase().indexOf("km")){
      let odometerSplit = odometer.split(" ");
      return odometerSplit.length > 0 ? Number(odometer.split(" ")[0].trim().replace(/\,/g,'')) : 0
    }

    if(odometer.toLowerCase().indexOf("mi") > -1 || odometer.toLowerCase().indexOf("Miles") > -1 || odometer.toLowerCase().indexOf("miles")){
      let odometerSplit = odometer.split(" ");
      return odometerSplit.length > 0 ? Number(odometer.split(" ")[0].trim().replace(/\,/g,'')) : 0
    }
    return 0;
  }

  async bulkValidate() {

    for (let item of this.fileRecords) {

      if (!item.vin) {
        logger.log(`vin not found-  ${item.vin}`);
        this.errorRecords.push({ vin :  "Vin not found - "+item.vin });
        continue;
      }


      if (!item.currency) {
        logger.log(`Currency not found-  ${item.vin}`);
        this.errorRecords.push({ vin :  "Currency not found - "+item.vin });
        continue;
      }


      // if (!item.price) {
      //   logger.log(`Price not found-  ${item.vin}`);
      //   this.errorRecords.push({ vin :  `Price not found-  ${item.vin}` });
      //   continue;
      // }

      // if (!item.wholesale) {
      //   logger.log(`Wholesale price not found-  ${item.vin}`);
      //   this.errorRecords.push({ vin :  `Wholesale price not found-  ${item.vin}` });
      //   continue;
      // }

      // if (!item.storeLocation) {
      //   logger.log(`storeLocation not found-  ${item.vin}`);
      //   this.errorRecords.push({ vin :  "StoreLocation not found - "+item.vin });
      //   continue;
      // }

      // if(item.pictureStatus == 'LL' || item.pictureStatus == 'll'){
      //   if(!item.proxyVin){
      //     logger.log(`proxyVin not found-  ${item.vin}`);
      //     this.errorRecords.push({ vin :  "proxyVin not found - "+item.vin });
      //     continue;
      //   }
      // }

      let checkExisting = this.validRecords.find(sOItem => (sOItem.salesOption.productSummary as TruckSummary).vin == item.vin);
      if (checkExisting) {
        this.duplicateRecords.push(item);
        logger.log(`Duplicate records vin -  ${item.vin}`);
        continue;
      }

      let checkInDB = this.dbRecords.find(sOItem => (sOItem.productSummary as TruckSummary).vin == item.vin);
      if (checkInDB && checkInDB.length > 0) {
        logger.log(`DB duplicate records vin -  ${item.vin}`);
        this.duplicateRecords.push(item);
        continue;
      }

      const prodEm = new Truck();
      prodEm.productType = ProductType.truck;
      prodEm.generateProductFromExcel(item);

      const salesObj = new SalesProductBase();
      salesObj.generateSalesOptionsFromExcel(item, prodEm);
      prodEm.imageType = salesObj.imageType;


      const lCVin = await this.vinService.getVinData(item.vin);
      if (lCVin) {
        ((prodEm as any) as Vehicle).bodyType = lCVin.bodyType;
        ((prodEm as any) as Vehicle).make = lCVin.make;
        ((prodEm as any) as Vehicle).model = item.model;
        if(!!lCVin.make && lCVin.make.toLowerCase().indexOf("volvo") == -1){
          ((prodEm as any) as Vehicle).model = lCVin.model;
        }
        ((prodEm as any) as Vehicle).modelYear = lCVin.modelYear;

        if(((prodEm as any) as Vehicle).productType == ProductType.trailer){
          ((prodEm as any) as Trailer).trailerLength = lCVin.trailerLength;
          ((prodEm as any) as Trailer).nAxle = lCVin.nAxles;
          ((prodEm as any) as Trailer).isReefer = lCVin.isReefer;
        }

        if(((prodEm as any) as Vehicle).productType == ProductType.truck){
          ((prodEm as any) as Truck).engineModel = lCVin.engineModel;
        }
        ((prodEm as any) as Vehicle).productType = lCVin.trailerLength == 0 ? ProductType.truck : ProductType.trailer;//For temp

        //((prodEm as any) as Truck).wheelBase = item.wheelBase;        
        salesObj.productSummary = prodEm.getProductSummary();
        salesObj.productType = prodEm.productType;
      }else{
        logger.log(`vin not found issue-  ${item.vin}`);
        this.errorRecords.push({ vin :  "vin not found issue - "+item.vin });
        continue;
      }
      this.validRecords.push({ salesOption : salesObj, minimumSellingPrice  :  MinimumSellingPrice.formatPrice(item.wholesale), productInfo : prodEm });
      this.processMessage = `Validating vin... ${this.calculateProgress(this.validRecords.length, this.fileRecords.length)}%`;
    }

  }

  calculateProgress(completed: number, total: number) {
    return Math.floor((completed / total) * 100);
  }

  async bulkCreate() {
    if (!this.validRecords.length) {
      this.processMessage = null;
      //this.errMessage = '[Sales-Options] No valid records';
      this.hideLoading();
      this.showsOStatus(
        'warn',
        'No valid records',
        '[Sales-Options] Please check uploaded file'
      );
      this.showFileUpload = false;
      this.disableUpload = false;
      this.resetFileInfo();
      this.processMessage = null;
      return;
    }

    let index : number = 0;
    for (let sOIter of this.validRecords) {
      index++;
      const salesOption = parseSalesProduct(sOIter.salesOption);
      if (salesOption == null) {
        this.processMessage = `Validating address... ${this.calculateProgress(index, this.validRecords.length)}%`;
        continue;
      }else{
        sOIter.salesOption = salesOption;
      }

      const productInfo = parseProduct(sOIter.productInfo);
      if (productInfo == null) {
        this.processMessage = `Validating address... ${this.calculateProgress(index, this.validRecords.length)}%`;
        continue;
      } else {
        sOIter.productInfo = productInfo.productType == ProductType.truck ? (productInfo as Truck) : (productInfo as Trailer)
      }
      this.bulkSORecd.push(sOIter);
      this.processMessage = `Validating address... ${this.calculateProgress(index, this.validRecords.length)}%`;
    }
    
    this.reUpload();
  }

  reUpload(){
    logger.log(this.validRecords, this.bulkSORecd);
    if(this.bulkSORecd.length == 0){
      this.processMessage = null;
      //this.errMessage = '[Sales-Options] No valid records';
      this.hideLoading();
      this.showsOStatus(
        'warn',
        'No valid records',
        (this.validRecords.length == 0 ? "No Records for sync" : "Error in validating data")
      );
      this.showFileUpload = false;
      this.disableUpload = false;
      this.resetFileInfo();
      this.processMessage = null;
      return;
    }
    this.processMessage = 'Uploading data...';
    //this.cleanListeners();    
    this.salesOptionService.bulkCreate(this.bulkSORecd, this.vendorCid).subscribe(result => {
      logger.info(result);
      this.showFileUpload = false;
      this.disableUpload = false;
      this.reUploadAgain = false;
      this.resetFileInfo();
      this.processMessage = null;
      let alertMessage = this.duplicateRecords.length > 0 ? `${this.duplicateRecords.length} duplicate Records found in file.` : 'All data uploaded successfully';

      if(result.errorRecords){
        alertMessage += " Server error - "+Object.values(result.errorRecords).join(", ");
      }
      this.showsOStatus(
        'primary',
        'Upload Success',
        alertMessage
      );
      this.hideLoading();
    }, (error) => {
      logger.error(error);
      this.showFileUpload = false;
      this.reUploadAgain = true;
      //this.dialogRef.close({ status : 'ERROR', message : error.message });
      //this.errMessage = `${error.message}`;
      this.processMessage = null;
      this.hideLoading();
      this.showAlert(readErrorMessage(error));
    });
  }

  cancel() {
    this.hideLoading();
    this.router.navigate(['/company/admin-list-vendor']);
  }

  /**
   * @author - MKN
   * @purpose - Reset file input
   */

  resetFileInfo() {
    
    this.sOUploader.removeFile();
    if(this.sOFile){
      this.sOFile.path = null;
      this.sOFile = null;
    }

    this.fileSelected = null;
    setTimeout(() => {
      this.sOFile = new FileInfo();
    }, 900);
  }

  // fileInput(event: { target: { files: any[]; value: any; }; }) {
  //   try {
  //     if (event.target && this.lCUploader.hasFile) {
  //       this.fileSelected = event.target.files[0];
  //     }
  //   } catch (error) {
  //     event.target.value = null;
  //     logger.error(`[Sales-Options] \n ${error}`);
  //   }
  // }

}
