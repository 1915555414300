import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditLotComponent } from './edit-lot.component';
import { MaterialModule } from '../../share-module/material.module';
import { CustomPipeModule } from '@trent/custom-pipe/custom-pipe.module';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';



@NgModule({
  declarations: [
    EditLotComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    CustomPipeModule,
    MatSelectModule,
    MatProgressSpinnerModule,
  ]
})
export class EditLotModule { }