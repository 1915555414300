import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BaseForm } from '@trent/models/UI/base-form';
import { UserSetting, initUserSetting } from '@trent/models/setting/user-setting';
import { Store } from '@ngxs/store';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { EventService } from '@trent/services/event.service';
import { UtilityService } from '@trent/services/utility.service';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DeepCopy } from '@trent/models/utility/deep-copy';
import { SetSettings } from '@trent/store/auth-store/auth.state';
import { isEqual, cloneDeep } from 'lodash';
import { UserService } from '@trent/services/user.service';
import { CommPreference } from '@trent/models/setting/comm-preference';
import { instanceToPlain } from 'class-transformer';
import { fromFailedValidation, MessageInfo, readErrorMessage } from '@trent/models/error-handling';
import { SingletonService } from '@trent/services/singleton.service';
import { commPreferenceInit } from '@trent/models/setting/setting-helper';
import { FormDisplayMode } from '@trent/models/UI';
import { UserProfile } from '@trent/models/user';
import { logger } from '@trent/models/log/logger';

@Component({
  selector: 'trent-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent extends BaseForm<CommPreference> implements OnInit, OnDestroy {

  @ViewChild('uSettingFrom', { static: false })
  uSettingFrom: NgForm;
  setting: UserSetting;
  userService: UserService;
  showLoadingBar = false;
  showMsg: { success: boolean, msg: string };
  constructor(store: Store, dialogService: DialogService, es: EventService,
    public dialogRef: MatDialogRef<SettingComponent>, private singleton: SingletonService,

  ) {
    super(store, dialogService, es);
    this.userService = this.singleton.userService;
  }

  ngOnInit(): void {
    let uProf: UserProfile;
    this.user$
      .subscribe(u => {
        if (!!u && !isEqual(u, uProf)) {
          uProf = u;
          if (u.setting && u.setting.commPreference) {
            this.setting = cloneDeep(u.setting);
          } else {
            this.setting = u.setting ? cloneDeep(u.setting) : <any>{};
            this.setting.commPreference = commPreferenceInit();
          }
          this.m = CommPreference.parse(this.setting.commPreference);
          this.em = this.m.clone();
          this.displayMode = FormDisplayMode.read;
        }
      });
  }

  ngOnDestroy(): void {
  }
  cancel() {
    this.em = this.m.clone();
    this.dialogRef.close();
  }
  edit() {
    this.displayMode = FormDisplayMode.edit;
  }
  save() {
    this.showMsg = null;
    if (!isEqual(instanceToPlain(this.m), instanceToPlain(this.em))) {
      this.markDirtyAndValidate(this.uSettingFrom);
      const tempR = this.em.validateSync();
      if (Object.keys(tempR).length > 0) {
        logger.log('validation failed');
        this.showAlert(fromFailedValidation(tempR).messageInfo);
        return;
      }
      this.setting.commPreference = instanceToPlain(this.em);
      logger.log('setting update called from setting component', this.setting);
      this.store.dispatch(new SetSettings({
        setting: this.setting,
        updateLocalDb: true
      }));
      this.showLoadingBar = true;
      this.userService.updateUserCommSettings(this.em)
        .then(r => {
          this.showLoadingBar = false;
          this.showMsg = { success: true, msg: 'Settings updated' };
          this.setting.commPreference = instanceToPlain(this.em);
          // update local storage
          logger.log('setting update called from setting component', this.setting);
          this.store.dispatch(new SetSettings({
            setting: this.setting,
            updateLocalDb: true
          }));
        })
        .catch(error => {
          this.showMsg = {
            success: false,
            msg: `${readErrorMessage(error).description}`
          };
          this.showLoadingBar = false;
          logger.error(`${readErrorMessage(error).header}: ${readErrorMessage(error).description}`);
        });
    }
  }
  isUpdated() {
    return !isEqual(instanceToPlain(this.em), this.setting.commPreference);
  }
}
