import { IsBoolean, validateSync, ValidationError } from 'class-validator';
import { instanceToInstance, plainToInstance } from 'class-transformer';
import { sanitizeDateIPoint } from '../utility/sanitize-helper';
import { IValidationMsg } from '@trent/models/error-handling';
import { toValidationError } from '../utility/validation-helper';
import { logger } from '../log/logger';

export interface ICommPreference {

  fcm?: boolean;
  email?: boolean;
  sms?: boolean;
  whatsApp?: boolean;

}

export class CommPreference {
  @IsBoolean()
  fcm?: boolean;
  @IsBoolean()
  email?: boolean;
  @IsBoolean()
  sms?: boolean;
  @IsBoolean()
  whatsApp?: boolean;

  constructor() {
  }
  public static parse(obj) {
    try {
      if (obj == null) { return null; }
      // obj = sanitizeDateIPoint(obj, ipointType);
      obj = sanitizeDateIPoint(obj);
      const m = plainToInstance<CommPreference, any>(CommPreference, obj);
      m.sanitize();
      return m;
    } catch (error) {
      logger.log('Error happened during parse', error);
      return null;
    }
  }
  clone() {
    const c = instanceToInstance(this);
    c.sanitize();
    return c;
  }
  sanitize() {

  }
  /** Helper Method  to convert validation errors to easy display messages messages */
  protected toValidationError(ve: ValidationError[]): IValidationMsg {
    return toValidationError(ve);
  }
  validateSync(): IValidationMsg {
    this.sanitize();
    const m = validateSync(this);
    const r = this.toValidationError(m);
    return r;
  }

}
