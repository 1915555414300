import { TaskType, TaskCategory, GCTaskType } from './task-type';
import { BaseModel } from '../base/base-model';
import { Author } from '../bid/author';
import { plainToInstance, instanceToInstance } from 'class-transformer';
import { sanitizeDateIPoint } from '../utility/sanitize-helper';
// import { BidBase } from '../bid/bid-base';
import { UpdateDocRef, CronActions } from '../cron-jobs/cron-jobs-log';
import { CompanyBase } from '../company';
import { UserProfile } from '../user';
// import { PickDrop } from '../pickup-dropoff/pick-drop';
import { MessagingPayload } from '@trentm/fcm/fcm-type';
import { TaskUtl } from './task-utl';
import { isMatch, pickBy, omitBy } from 'lodash';
import { TripBase } from '../trip/trip-base';
import { TripTracker } from '../trip/trip-tracker';
import { DriverList } from '@trentm/promo/driver-list';
import { ItemStatus } from '../promo/item-status';
import { companyFriendlyName } from '../company/company-helper';
import { logger } from '../log/logger';
import { BidBase } from '../bid/bid-base';
import { ProductBase, TruckSummary } from '../product';
import { Inspection } from '../inspection/inspection';
import { InspectionStatus } from '../inspection/inspection-status';
import { PtsEnquiry } from '../pts/pts-enquiry';
import { ContactUs, EnquiryType } from '../contact-us/contact-us';
import { SalesProductBase } from '../sales-option/sales-product-base';
import { PriceQuoteStatus, PriceQuoteRequest } from '../price-quote/price-quote-request';
import { toSentence } from '../utility';
import { EnumHelper } from '../utility/enum-helper';

export interface ITaskIndex {
  createdAt: number;
  isNew: boolean;
  isRead: boolean;
  isNew_createdAt: string;
  isRead_createdAt: string;
}
export enum TaskStatus {
  open = 1,
  oneTime = 2,
  closed = 3
}
export const parseTaskArray = (obj: any[]): Task[] => {
  return obj.map(o => Task.parse(o));
};

export class Task extends BaseModel {




  public static readonly collectionName = 'task';
  public static readonly userCollectionName = 'user-tasks';

  name: string;

  constructor() {
    super();
  }

  /** Db Staus of this record. */
  taskType: TaskType;

  taskCat: TaskCategory;

  /** Users who can act on this task */
  owner: { [id: string]: boolean };

  /** read status. if id: true, then user has not yet read the task
   * depricated, isRead set on sendTo Property
   */
  unReadStatus: { [id: string]: boolean };

  /** document Id to which task is related example bid */
  docId?: string | number;
  /** user id with communication settings as user profile at the time of task creatation */
  sendTo?: { [id: string]: { fbm: boolean, email?: string, sms?: string | number, whatsapp?: number, isRead?: boolean, isNew?: boolean } };

  /** message details */
  notification?: {
    title?: string,
    body?: string,
    icon?: string,
    clickAction?: string
  };
  /** data action TaskType and action_key */
  data?: {
    action?: string;
    action_key?: string;
  };
  vendorCid?: number | string;
  customerCid?: number | string;
  carrierCid?: number | string;
  senderCompanyName?: string;

  /** next Notification time for re occuring messages example bid approval or pickdrop approval
   * used to search due task notifications. reset every time task notification is sent
   */
  nextNotifyAt?: Date;
  /** date time when task related activity will expire. e.g. set same as bid expiry */
  lastNotifyAt?: Date;
  /** action to vendor or customer for bids */
  actionTo?: Author;

  taskStatus?: TaskStatus;
  // tslint:disable-next-line:variable-name
  assigned_TaskType: { [key: string]: boolean };
  /** depricated used for firestore array contains (rules and query) */
  assignedTo?: string[]; // Array of user IDs, required for client query
  notificationCounter?: number;

  // tslint:disable-next-line:variable-name
  docId_actionTo?: string; // required for composite query
  // tslint:disable-next-line:variable-name
  docId_taskStatus?: string; // required for composite query
  // tslint:disable-next-line:variable-name
  taskStatus_nextNotifyAt?: string; // required for composite query

  public static parse(obj) {
    if (obj == null) { return null; }

    if (obj instanceof (Task)) { return obj as Task; }

    const m = plainToInstance<Task, any>(Task, sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }
  // db composit index
  getDocId_actionTo(): string | null { return !!this.docId && !!this.actionTo ? `${this.docId}_${this.actionTo}` : null; }
  getDocId_taskStatus(): string | null { return !!this.docId && !!this.taskStatus ? `${this.docId}_${this.taskStatus}` : null; }
  getTaskStatus_nextNotifyAt(): string | null {
    return !!this.taskStatus && !!this.nextNotifyAt ? `${this.taskStatus}_${this.nextNotifyAt}` : null;
  }
  // updateTask(sendTo: { [id: string]: { fbm: boolean, email?: string, sms?: number, whatsapp?: number } })
  //   : { task: Task, chgDoc: UpdateDocRef } {
  //   const updatedTask = this.clone();
  //   switch (this.taskType) {
  //     case TaskType.RequestBidApproval:
  //       throw Error('Code not ported from TRENT to Locus-Loop yet! Uncomment below and fix the imports if this is required!!');
  //     //  updatedTask.nextNotifyAt = BidBase.getNextNotifyAt(this.nextNotifyAt, this.lastNotifyAt);
  //     // if (!_.isMatch(this.sendTo, sendTo)) {
  //     //   updatedTask.sendTo = sendTo;
  //     // }
  //     // break;
  //     case TaskType.RequestInspectionReview:
  //       throw Error('Code not ported from TRENT to Locus-Loop yet! Uncomment below and fix the imports if this is required!!');
  //     // updatedTask.nextNotifyAt = PickDrop.getNextNotifyAt(this.nextNotifyAt, this.lastNotifyAt);
  //     // if (!_.isMatch(this.sendTo, sendTo)) {
  //     //   updatedTask.sendTo = sendTo;
  //     // }
  //     // break;
  //     default:
  //       break;
  //   }
  //   const c = this.getCronJobLog(updatedTask);
  //   return { task: updatedTask, chgDoc: c };
  // }
  getCronJobLog(updatedTask: Task): UpdateDocRef {

    let chgDoc: UpdateDocRef;
    const chgMap1 = new Map();
    let chgMap2: Map<string, {}>;

    chgMap1.set('nextNotifyAt', {
      before: this.nextNotifyAt,
      after: updatedTask.nextNotifyAt
    });
    if (!isMatch(this.sendTo, updatedTask.sendTo)) {
      chgMap2 = new Map();
      chgMap2.set('sendTo', {
        before: this.sendTo,
        after: updatedTask.sendTo
      });
    }
    chgDoc = {
      did: this.id,
      collection: Task.collectionName,
      revCreated: this.revId + 1,
      action: CronActions.updateTask,
      change: !!chgMap2 ? [chgMap1, chgMap2] : [chgMap1]
    };
    return chgDoc;
  }
  stopNotifyTask(): { task: Task, chgDoc: UpdateDocRef } {
    const updatedTask = this.clone();
    updatedTask.taskStatus = TaskStatus.closed;
    updatedTask.nextNotifyAt = null;
    updatedTask.lastNotifyAt = null;
    const c = this.getCronLogToStopNotify(updatedTask);

    return { task: updatedTask, chgDoc: c };
  }
  getCronLogToStopNotify(updatedTask: Task): UpdateDocRef {

    let chgDoc: UpdateDocRef;
    const chgMap1 = new Map();
    const chgMap2 = new Map();
    const chgMap3 = new Map();

    chgMap1.set('taskStatus', {
      before: this.taskStatus,
      after: updatedTask.taskStatus
    });
    chgMap2.set('nextNotifyAt', {
      before: this.nextNotifyAt,
      after: updatedTask.nextNotifyAt
    });
    chgMap3.set('lastNotifyAt', {
      before: this.lastNotifyAt,
      after: updatedTask.lastNotifyAt
    });

    chgDoc = {
      did: this.id,
      collection: Task.collectionName,
      revCreated: this.revId + 1,
      action: CronActions.updateTask,
      change: [chgMap1, chgMap2, chgMap3]
    };
    return chgDoc;
  }
  // oDriverProfile, uDriverProfile
  createTaskForDriverChange(senderUid: string | number, driverL: DriverList,
    receiver: UserProfile, taskKey: string, webSiteUrl: string) {
    this.createdByUid = senderUid;
    this.carrierCid = driverL.carrierCompanySummary.cid;
    const cName = companyFriendlyName(driverL.carrierCompanySummary.name, driverL.carrierCompanySummary.legalName);
    this.senderCompanyName = cName;  //
    // `${driverL.carrierCompanySummary.name} (${driverL.carrierCompanySummary.legalName})`;
    this.owner = {};
    this.unReadStatus = {};
    this.taskCat = TaskCategory.Individual;
    // let payload: MessagingPayload;
    if (!receiver) {
      this.taskType = TaskType.RequestAddPromoDriverNewUser;
      this.data = {
        action: `${TaskType.RequestAddPromoDriverNewUser}`,
        action_key: `${taskKey}`
      };
      this.inviteDriverActions(taskKey, cName, webSiteUrl);
    } else {
      // this.taskType = TaskType.RequestAddPromoDriver;
      this.sendTo = {};
      this.sendTo[receiver.id] = {
        fbm: receiver.setting.commPreference.fcm,
        sms: receiver.setting.commPreference.sms ? receiver.phoneNumber : null,
        isRead: false,
        isNew: true
      };
      switch (driverL.list[receiver.id].itemStatus) {
        case ItemStatus.pending:
          this.taskType = TaskType.RequestAddPromoDriver;
          this.data = {
            action: `${TaskType.RequestAddPromoDriver}`,
            action_key: `${taskKey}`
          };
          this.inviteDriverActions(taskKey, cName, webSiteUrl);
          break;
        case ItemStatus.inactive:
          this.removeDriverActions(taskKey, cName);

          break;

        default:
          logger.error('programming error, should be in reachable block');
          return null;
      }

    }

    // this.notification = payload.notification;
    this.taskStatus = TaskStatus.oneTime;

    this.docId = driverL.id;
    this.docId_actionTo = this.getDocId_actionTo(); // required for composite query
    this.docId_taskStatus = this.getDocId_taskStatus(); // required for composite query
    this.taskStatus_nextNotifyAt = this.getTaskStatus_nextNotifyAt(); // required for composite query
    logger.log('task at create', this);
    return this;
  }
  /**
   * 
   * @param senderUid User Id of the sender
   * @param trip TripBase
   * @param receiver UserProfile of the reciever
   */
  createTaskForTrip(senderUid: string | number, trip: TripBase, tid: string | number, receiver: UserProfile, websiteUrl: string) {
    this.name = 'Trip Assigned ';
    this.createdByUid = senderUid;
    this.taskType = TaskType.TripAssignedToDriver;
    this.owner = {};
    this.unReadStatus = {};
    this.taskCat = TaskCategory.Individual;
    const cName = companyFriendlyName(trip.carrierCompanySummary.name, trip.carrierCompanySummary.legalName);
    this.senderCompanyName = cName;  //
    // tslint:disable-next-line:prefer-const
    let payload: MessagingPayload = {
      notification: {
        title: `Trip Assgined by ${cName}`,
        body: 'Click to get trip details',

        icon: 'https://placeimg.com/250/250/people',
        clickAction: `${websiteUrl}/trip/workflow/${tid}`
      }
    };
    this.data = {
      action: `${TaskType.TripAssignedToDriver}`,
      action_key: `${tid}`
    };
    this.notification = payload.notification;
    this.taskStatus = TaskStatus.oneTime;
    this.sendTo = {};
    this.sendTo[receiver.id] = {
      fbm: receiver.setting.commPreference.fcm,
      sms: receiver.setting.commPreference.sms ? receiver.phoneNumber : null,
      isRead: false,
      isNew: true
    };

    this.docId = tid;
    this.docId_actionTo = this.getDocId_actionTo(); // required for composite query
    this.docId_taskStatus = this.getDocId_taskStatus(); // required for composite query
    this.taskStatus_nextNotifyAt = this.getTaskStatus_nextNotifyAt(); // required for composite query
    logger.log('task at create', this);
    return this;
  }
  createTripTrackerTask(uT: TripTracker, msg: { header?: string, description?: string },
    receivers: UserProfile[], uid: string | number, websiteUrl: string): Task {
    this.name = 'Shipment Tracker ';
    this.senderCompanyName = companyFriendlyName(uT.carrierCompanySummary.name, uT.carrierCompanySummary.legalName);
    this.createdByUid = uid;
    this.taskType = TaskType.TripTrackerUpdate;
    this.owner = {};
    this.unReadStatus = {};
    this.taskCat = TaskCategory.Individual;
    this.notification = {
      title: `${msg.header}`,
      body: `${msg.description}.`,
      icon: 'https://placeimg.com/250/250/people',
      clickAction: `${websiteUrl}/track-delivery/list/${uT.trackingNo}`,
    };
    this.data = {
      action: `${TaskType.TripTrackerUpdate}`,
      action_key: `${uT.trackingNo}`
    };
    this.taskStatus = TaskStatus.oneTime;
    this.sendTo = {};
    receivers.forEach(receiver => {
      const commP = receiver.setting.commPreference;
      this.sendTo[receiver.id] = {
        fbm: true, sms: null, isRead: false,
        isNew: true
      };

      // this.sendTo[receiver.id] = { fbm: commP.fcm, sms: null, isRead: false, email: !!commP.email ? receiver.email : null };
    });
    this.docId = uid;
    this.docId_actionTo = this.getDocId_actionTo(); // required for composite query
    this.docId_taskStatus = this.getDocId_taskStatus(); // required for composite query
    this.taskStatus_nextNotifyAt = this.getTaskStatus_nextNotifyAt(); // required for composite query
    logger.log('task at create', this);
    return this;
  }
  /** assign anonomus task to new user
   * @param userId New user Id
   */
  assgignTaskToNewUser(uid: string, user: UserProfile): Task {
    const uTask = this.clone();
    uTask.sendTo = {};
    switch (+uTask.data.action) {
      case TaskType.RequestAddPromoDriverNewUser:
        uTask.sendTo[uid] = { fbm: true, email: user.email, sms: user.phoneNumber, isRead: false, isNew: true };
        uTask.taskType = TaskType.RequestAddPromoDriver;
        uTask.data.action = `${TaskType.RequestAddPromoDriver}`;
        break;
      default:
        logger.log(`[Task] programming error! Task type action: ${uTask.taskType} is not programmaed yet `);
        return null;
    }
    return uTask;
  }
  createTaskIndexes(uid: string): ITaskIndex {
    const s = this.sendTo[uid];
    return {
      createdAt: this.updatedAt ? this.updatedAt.valueOf() : this.createdAt.valueOf(),
      isNew: s.isNew,
      isRead: s.isRead,
      isNew_createdAt: `${s.isNew}_${this.createdAt.valueOf()}`,
      isRead_createdAt: `${s.isRead}_${this.createdAt.valueOf()}`,
    };
  }
  updateTaskIndexes(uid: string): Partial<ITaskIndex> {
    const s = this.sendTo[uid];
    const u = this.createTaskIndexes(uid);
    return omitBy(u, (val, key) => key === 'createdAt');
  }
  private inviteDriverActions(taskKey: string, cName: string, websiteUrl: string) {

    this.name = 'Sign up request: ';
    this.notification = {
      title: `Driver Signup Request`,
      body: `Please sign up as driver for ${cName}. Click and follow prompts.`,
      icon: 'https://placeimg.com/250/250/people',
      // clickAction: `${TaskUtl.WebsiteUrl}/account/`
      clickAction: `${websiteUrl}/account/`
    };
  }
  private removeDriverActions(taskKey: string, cName: string) {
    this.taskType = TaskType.PromoDriverRemoved;
    this.data = {
      action: `${TaskType.PromoDriverRemoved}`,
      action_key: `${taskKey}`
    };
    this.name = 'Driver removed by carrier: ';
    this.notification = {
      title: `Account update`,
      body: `Driver access removed from ${cName}.`,
      icon: 'https://placeimg.com/250/250/people',
    };
  }


  // #region bid task
  /**
   * Create Bid Task
   * @param senderUid User ID you created or updated the bid
   * @param bid BidBase
   * @param receivers Array of UserProfile who listed to get task (Customer or Vendor company users) 
   * @param websiteUrl website url (e.g. www.locusloop.com)
   */
  createBidTask(senderUid: string | number, bid: BidBase, receivers: UserProfile[], websiteUrl: string, userType: 'companyUser' | 'salesUser') {
    try {
      bid.setTaskInputs(this, websiteUrl, userType);          
      this.createdByUid = senderUid;
      this.taskCat = TaskCategory.Company;
      this.taskStatus = TaskStatus.oneTime;

      this.sendTo = {};
      for (const r of receivers) {
        this.sendTo[r.id] = {
          fbm: r.setting.commPreference.fcm,
          email: r.setting.commPreference.email ? r.email : null,
          sms: r.setting.commPreference.sms ? r.phoneNumber : null,
          isRead: false,
          isNew: true
        };
      }
      this.docId = bid.id;
      this.docId_actionTo = this.getDocId_actionTo(); // required for composite query
      this.docId_taskStatus = this.getDocId_taskStatus(); // required for composite query
      this.taskStatus_nextNotifyAt = this.getTaskStatus_nextNotifyAt(); // required for composite query
    } catch (error) {
      logger.error(`[Task] create task on Bid create failed, ${error}`);
    }
  }
  /**
   * Create Task for Admin when bid is submitted by new Company or company which is not released for rental
   * @param senderUid User ID you created or updated the bid
   * @param draftBid BidBase
   * @param receivers Array of UserProfile who listed to get task (Customer or Vendor company users) 
   * @param websiteUrl website url (e.g. www.locusloop.com)   */
  createAdminTaskOnDraftBid(senderUid: any, draftBid: BidBase, receivers: UserProfile[], websiteUrl: string) {
    try {
      this.createdByUid = senderUid;
      draftBid.setAdminTaskInputsForDraftBid(this, websiteUrl);
      this.taskCat = TaskCategory.Company;
      this.taskStatus = TaskStatus.oneTime;

      this.sendTo = {};
      for (const r of receivers) {
        this.sendTo[r.id] = {
          fbm: r.setting.commPreference.fcm,
          email: r.setting.commPreference.email ? r.email : null,
          sms: r.setting.commPreference.sms ? r.phoneNumber : null,
          isRead: false,
          isNew: true
        };
      }
      this.docId = draftBid.id;
      this.docId_actionTo = this.getDocId_actionTo(); // required for composite query
      this.docId_taskStatus = this.getDocId_taskStatus(); // required for composite query
      this.taskStatus_nextNotifyAt = this.getTaskStatus_nextNotifyAt(); // required for composite query
    } catch (error) {
      logger.error(`[Task] create task on Bid create failed, ${error}`);
    }
  }
  // #endregion

  // #region Inspection task
  createInspectionTask(senderUid: any, inspection: Inspection, action: InspectionStatus, receivers: UserProfile[],
    websiteUrl: string, gcTaskType?: GCTaskType) {
    try {
      inspection.setTaskInputs(this, action, websiteUrl, gcTaskType);
      this.createdByUid = senderUid;
      this.taskCat = TaskCategory.Company;
      this.taskStatus = TaskStatus.oneTime;
      this.taskCommProps(receivers, inspection.id);

    } catch (error) {
      logger.error(`[Task] create task on Bid create failed, ${error}`);
    }
  }
  // #endregion

  // #region  release tasks
  createTaskOnProductRelease(senderUid: string, prod: ProductBase, receivers: UserProfile[], websiteUrl: string) {
    try {
      this.senderCompanyName = 'Admin';
      prod.setProdReleaseTaskInputs(this, websiteUrl);
      this.createdByUid = senderUid;
      this.taskCat = TaskCategory.Company;
      this.taskStatus = TaskStatus.oneTime;
      this.taskCommProps(receivers, prod.id);
    } catch (error) {
      logger.error(`[Task] create task on company create/update failed, ${error}`);

    }
  }
  createTaskOnCompanyRelease(senderUid: string, comp: CompanyBase, receivers: UserProfile[], websiteUrl: string) {
    try {
      this.senderCompanyName = 'Admin';
      comp.setCompReleaseTaskInputs(this, websiteUrl);
      this.createdByUid = senderUid;
      this.taskCat = TaskCategory.Company;
      this.taskStatus = TaskStatus.oneTime;
      this.taskCommProps(receivers, comp.id);
    } catch (error) {
      logger.error(`[Task] create task on company create/update failed, ${error}`);

    }
  }

  /**
   * @author MKN 
   * Create task object for contact us enquiry
   * @param senderUid 
   * @param enquiry 
   * @param receivers 
   */
  createContactUsEnquiryTask(senderUid: any, enquiry : ContactUs,receivers: UserProfile[]) {
    try {

      this.taskType = TaskType.ContactUs;
      const enquiryActualType = `${enquiry.enquiryType == EnquiryType.customer ? 'Client' : 'Dealer'}`;
      this.name = `New enquiry received - ${enquiryActualType}`;
      this.data = { action: `${this.taskType}`, action_key: `${this.id}` };

      let body = `<div><h4>New enquiry generated by user ${enquiry.fullName ? enquiry.fullName : ''}</h4>`;

      body +=`<h5>Contact Name: ${enquiry.fullName ? enquiry.fullName : ''}</h5>`;
      body +=`<h5>Phone: ${enquiry.phoneNumber ? enquiry.phoneNumber : ''}</h5>`;
      body +=`<h5>Email: ${enquiry.email ? enquiry.email : ''}</h5>`;

      if(enquiry.enquiryType == EnquiryType.customer){
        body +=`<h5>Equipment: ${enquiry.equipment ? enquiry.equipment : ''}</h5>`;
        // body +=`<h5>Year: ${enquiry.year ? enquiry.year : ''}</h5>`;
        // body +=`<h5>Make: ${enquiry.make ? enquiry.make : ''}</h5>`;
        // body +=`<h5>Model: ${enquiry.model  ? enquiry.model : ''}</h5>`;
        // body +=`<h5>Downpayment to work with: ${enquiry.downPayment  ? enquiry.downPayment : ''}</h5>`;
      }else{
        body +=`<h5>Approx retail numbers / month: ${enquiry.retailNumbers  ? enquiry.retailNumbers : ''}</h5>`;
      }

      body +=`</div>`;

      this.notification = {
        title: `${this.name}`,
        body: `${body}`,
        icon: 'https://placeimg.com/250/250/people',
        clickAction: ``
      };
      
      this.createdByUid = senderUid;
      this.taskCat = TaskCategory.Individual;
      this.taskStatus = TaskStatus.oneTime;

      this.taskCommProps(receivers, enquiry.id);

    } catch (error) {
      logger.error(`[Task] create task on Bid create failed, ${error}`);
    }
  }

  // # endregion

  // #region admin task

  createAdminTaskOnCompUpdate(senderUid: any, comp: CompanyBase, receivers: UserProfile[], websiteUrl: string) {
    try {
      comp.setAdminTaskInputs(this, websiteUrl);
      this.createdByUid = senderUid;
      this.taskCat = TaskCategory.Company;
      this.taskStatus = TaskStatus.oneTime;
      this.taskCommProps(receivers, comp.id);
    } catch (error) {
      logger.error(`[Task] create task on company create/update failed, ${error}`);

    }
  }
  createAdminTaskOnProductReleaseReq(senderUid: string, senderCompName: string,
    prod: ProductBase, receivers: UserProfile[], websiteUrl: string) {
    try {
      this.senderCompanyName = senderCompName;
      prod.setAdminTaskInputs(this, websiteUrl);
      this.createdByUid = senderUid;
      this.taskCat = TaskCategory.Company;
      this.taskStatus = TaskStatus.oneTime;
      this.taskCommProps(receivers, prod.id);
    } catch (error) {
      logger.error(`[Task] create task on product create/update failed, ${error}`);

    }
  }


  createEnquiry(senderUid: any, inq: PtsEnquiry, receivers: UserProfile[]) {
    try {
      inq.setEnquiryNotification(this,inq);
      this.createdByUid = senderUid;
      this.taskCommProps(receivers, inq.id);
    } catch (error) {
      logger.error(`[Task] create task on Enquiry create/update failed, ${error}`);

    }
  }

  createEnquiryForAdmin(senderUid: any, inq: PtsEnquiry, receivers: UserProfile[]) {
    try {
      inq.setEnquiryNotificationForAdmin(this,inq);
      this.createdByUid = senderUid;
      this.sendTo = {};
      for (const r of receivers) {
        this.sendTo[r.id] = {
          fbm: false,
          email: r.email,
          sms: r.phoneNumber,
          isRead: false,
          isNew: true
        };
      }
      this.docId = inq.id;
      this.docId_actionTo = this.getDocId_actionTo(); // required for composite query
      this.docId_taskStatus = this.getDocId_taskStatus(); // required for composite query
      this.taskStatus_nextNotifyAt = this.getTaskStatus_nextNotifyAt(); // required for composite query
      } catch (error) {
      logger.error(`[Task] create task on Enquiry create/update failed, ${error}`);
    }
  }

  createGuestEnquiryForAdmin(senderUid: any, inq: PtsEnquiry, receivers: UserProfile[]) {
    try {
      this.data = { action: `${inq.enquiryType}`, action_key: `${inq.id}` };
      this.notification = {
        title: `${inq.userDetails?.displayName}`,
        body: `<div>
            <h3>Dear Pride team,</h3>
            <p>New enquiry is generated. Please take appropriate action on it.</p>
            <p>Please revert within 2 days with more detail</p>
            <h3>Enquiry Details</h3>
            ${inq.userDetails?.displayName ? `<h4>Name : ${inq.userDetails.displayName}</h4>` : 'Guest user'}
            ${inq.userDetails?.email ? `<h4>Email : ${inq.userDetails.email}</h4>` : ''}
            ${inq.userDetails?.phoneNumber ? `<h4>Phone : ${inq.userDetails.phoneNumber}</h4>` : ''}
            ${inq.userDetails.altPhoneNumber ? `<h4>Alt Phone: ${inq.userDetails.altPhoneNumber}</h4>` : ''}
            <h3>Comments</h3>
                ${inq.productDetails?.vin ? `<h4>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Vin : ${inq.productDetails.vin}</h4>` : ''}
                ${inq.productDetails?.unitName ? `<h4>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unit Name : ${inq.productDetails.unitName}</h4>` : ''}
            <p>Description: ${inq.description}</p>
        </div>`
    };
      this.createdByUid = senderUid;
      this.sendTo = {};
      for (const r of receivers) {
        this.sendTo[r.id] = {
          fbm: false,
          email: r.email,
          sms: r.phoneNumber,
          isRead: false,
          isNew: true
        };
      }
      } catch (error) {
      logger.error(`[Task] create task on Enquiry create/update failed, ${error}`);
    }
  }



  // #endregion
  private taskCommProps(receivers: UserProfile[], docId: string | number) {
    this.sendTo = {};
    for (const r of receivers) {
        this.sendTo[r.id] = {
          fbm: r.setting.commPreference.fcm,
          email: r.setting.commPreference.email ? r.email : null,
          sms: r.setting.commPreference.sms ? r.phoneNumber : null,
          isRead: false,
          isNew: true
        };
    }
    this.docId = docId;
    this.docId_actionTo = this.getDocId_actionTo(); // required for composite query
    this.docId_taskStatus = this.getDocId_taskStatus(); // required for composite query
    this.taskStatus_nextNotifyAt = this.getTaskStatus_nextNotifyAt(); // required for composite query
  }

  createAdminTaskOnSaleUpdate(senderUid: any, sale: SalesProductBase, receivers: UserProfile[], websiteUrl: string) {
    try {
      sale.setAdminTaskInputs(this, websiteUrl);
      this.createdByUid = senderUid;
      this.taskCat = TaskCategory.Individual;
      this.taskStatus = TaskStatus.oneTime;
      this.taskCommProps(receivers, sale.id);
    } catch (error) {
      logger.error(`[Task] create task on Sales product create/update failed, ${error}`);

    }
  }

  createTaskOnSalesRelease(senderUid: string, sale: SalesProductBase, receivers: UserProfile[], websiteUrl: string) {
    try {
      // this.senderCompanyName = 'Admin';
      sale.setAdminTaskInputs(this, websiteUrl);
      this.createdByUid = senderUid;
      this.taskCat = TaskCategory.Individual;
      this.taskStatus = TaskStatus.oneTime;
      this.taskCommProps(receivers, sale.id);
    } catch (error) {
      logger.error(`[Task] create task on sales create/update failed, ${error}`);

    }
  }
  
  createPriceQuoteRequest(senderUid: any, p: PriceQuoteRequest, receivers: UserProfile[], websiteUrl: string) {
    try {
    p.createPriceQuoteTask(this,websiteUrl);
      this.createdByUid = senderUid;
      this.sendTo = {};
      for (const r of receivers) {
        this.sendTo[r.id] = {
          fbm: false,
          email: r.email,
          sms: r.phoneNumber,
          isRead: false,
          isNew: true
        };
      }
      } catch (error) {
      logger.error(`[Task] create task on Enquiry create/update failed, ${error}`);
    }
  }

  priceQuoteActionTask(senderUid: any, p: PriceQuoteRequest, receivers: UserProfile[], websiteUrl: string) {
    try {
      p.actionPriceQuoteTask(this,websiteUrl);
      this.createdByUid = senderUid;
      this.sendTo = {};
      for (const r of receivers) {
        this.sendTo[r.id] = {
          fbm: false,
          email: r.email,
          sms: r.phoneNumber,
          isRead: false,
          isNew: true
        };
      }
      } catch (error) {
      logger.error(`[Task] create task on Enquiry create/update failed, ${error}`);
    }
  }

  

}
