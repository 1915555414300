<ng-container *ngIf="em">
    <trent-slide-panel [activePane]="activePane" (activePanChg)="activePane = $event">
        <div leftPane class="wide-margin">
            <mat-card appearance="outlined" class="log-card">
                <mat-card-header>
                    <div mat-card-avatar>
                        <mat-icon matSuffix color="primary">add_box</mat-icon>
                    </div>
                    <mat-card-title *ngIf="sparePartId">Update AddOn</mat-card-title>
                    <mat-card-title *ngIf="!sparePartId">Add AddOn</mat-card-title>

                    <mat-card-subtitle>Please fill all details</mat-card-subtitle>

                </mat-card-header>
                <mat-card-content>

                    <ul class="menu-list menu-list-boxed">
                        <li>
                            <a href="javascript:void(0)" (click)="showHide('general')">
                                <mat-icon aria-hidden="false" aria-label="Phone" class="left-icon">list</mat-icon>
                                <span>General</span>
                                <em [ngClass]="{'missing-info': !valDbStatus?.groupResult['general']?.groupPass}">
                                    {{valDbStatus?.groupResult['general']?.message}}
                                </em>
                                <mat-icon
                                    *ngIf="!!valDbStatus?.groupResult['general']?.groupPass">keyboard_arrow_right</mat-icon>
                                <mat-icon *ngIf="!valDbStatus?.groupResult['general']?.groupPass"
                                    class="missing-info">info
                                </mat-icon>
                            </a>
                        </li>
                    </ul>
                </mat-card-content>
                <ng-container *ngIf="!isHandset">
                    <ng-container *ngTemplateOutlet="desktopActions"></ng-container>
                </ng-container>
            </mat-card>
        </div>

        <div rightPane>
            <ng-container>
                <mat-card appearance="outlined" class="gen-card wide-margin">
                    <mat-card-header>
                        <div mat-card-avatar>
                            <mat-icon matSuffix color="primary">{{tabIcon}}</mat-icon>
                        </div>
                        <mat-card-title class="d-flex d-a-center">
                            <div class="header d-flex d-a-center">
                                <div> {{header}} </div>
                                <div>
                                    <button mat-icon-button inline *ngIf="!isEditMode && isHandset" color="primary"
                                        type="button" (click)="edit()">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button mat-icon-button inline *ngIf="!!isEditMode && (isHandset)" color="primary"
                                        type="button" (click)="cancel()">
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <ng-container *ngIf="currSubMenu === 'general'">
                            <ng-container *ngTemplateOutlet="generalTemplate"></ng-container>
                        </ng-container>
                    </mat-card-content>
                </mat-card>
            </ng-container>
        </div>
    </trent-slide-panel>
</ng-container>


<ng-template #generalTemplate>
    <form #generalForm="ngForm">

        <!-- AddOn Category -->
        <mat-form-field>
            <mat-label>Select Category</mat-label>
            <mat-select [(ngModel)]="em.category" name="category" #category="ngModel" [disabled]="!isEditMode" (selectionChange)="onCatChange()">
                <mat-option *ngFor="let item of addOnCat" [value]="+item.value">{{getTitle(item.name)}}</mat-option >
            </mat-select>
            <mat-hint *ngIf="!showError(category)" align="end">Required</mat-hint>
            <mat-error align='end' *ngIf="showError(category)">
                {{category.errors.msg}}</mat-error>
        </mat-form-field>

        <!-- AddOn Warranty Type -->
        <mat-form-field *ngIf="em.category === 1">
            <mat-label>Select Warranty Type</mat-label>
            <mat-select [(ngModel)]="em.warrantyType" name="warrantyType" #warrantyType="ngModel" [disabled]="!isEditMode">
                <mat-option *ngFor="let item of warrantyTypes" [value]="+item.value">{{getTitle(item.name)}}</mat-option>
            </mat-select>
            <mat-hint *ngIf="!showError(warrantyType)" align="end">Required</mat-hint>
            <mat-error align='end' *ngIf="showError(warrantyType)">
                {{warrantyType.errors.msg}}</mat-error>
        </mat-form-field>

         <!-- AddOn Tires Type -->
         <mat-form-field *ngIf="em.category === 2">
            <mat-label>Select Tires Type</mat-label>
            <mat-select [(ngModel)]="em.tiresType" name="tiresType" #tiresType="ngModel" [disabled]="!isEditMode">
                <mat-option *ngFor="let item of tiresTypes" [value]="+item.value">{{getTitle(item.name)}}</mat-option>
            </mat-select>
            <mat-hint *ngIf="!showError(tiresType)" align="end">Required</mat-hint>
            <mat-error align='end' *ngIf="showError(tiresType)">
                {{tiresType.errors.msg}}</mat-error>
        </mat-form-field>

        <!-- AddOn Tires New/Old -->
        <div *ngIf="em.category === 2" class="full-width auth-toggle-container">
            <mat-label>New Tires</mat-label>
            <div class="toggle">
                <mat-slide-toggle class="toggle" color='primary' [(ngModel)]="em.new" name="new" #new="ngModel" [disabled]="!isEditMode">
                </mat-slide-toggle>
            </div>
        </div>

        <!-- AddOn Tires Make -->
        <mat-form-field *ngIf="em.category === 2 && em.new && em.tiresType">
            <mat-label>Tire Make</mat-label>
            <mat-select [(ngModel)]="em.tiresMake" name="tiresMake" #tiresMake="ngModel" [disabled]="!isEditMode">
                <mat-option *ngFor="let item of tiresMakes" [value]="item">{{item}}</mat-option>
            </mat-select>
            <mat-hint *ngIf="!showError(tiresMake)" align="end">Required</mat-hint>
            <mat-error align='end' *ngIf="showError(tiresMake)">
                {{tiresMake.errors.msg}}</mat-error>
        </mat-form-field>

        <!-- AddON Price -->
        <mat-form-field *ngIf="em.category === 0 || em.category === 2 || em.category === 3">
            <span matPrefix>$ </span>
            <mat-label>Price</mat-label>
            <input matInput [disabled]="false" type="number" name="price" #price="ngModel"
                [(ngModel)]="em.price" autocomplete="off" [disabled]="!isEditMode">
            <mat-hint *ngIf="!showError(price)" align="end">Required</mat-hint>
            <mat-error *ngIf="showError(price)">{{price?.errors?.msg}}</mat-error>
        </mat-form-field>

        <!-- AddOn Name -->
        <mat-form-field *ngIf="em.category === 0" class="full-width">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="em.name" type="text" name="name" #name="ngModel" [disabled]="!isEditMode"
                autocomplete="off" />
            <mat-hint *ngIf="!showError(name)" align="end">Required</mat-hint>
            <mat-error align='end' *ngIf="showError(name)">
                {{name.errors.msg}}</mat-error>
        </mat-form-field>

        <!-- AddOn Description -->
        <mat-form-field *ngIf="em.category === 0 || em.category === 1  || em.category === 3" class="full-width">
            <mat-label>Description</mat-label>
            <textarea matInput name="description" #description="ngModel" [(ngModel)]="em.description"
                [disabled]="!isEditMode"></textarea>
            <mat-hint *ngIf="!showError(description)" align="end">Required</mat-hint>
            <mat-error align='end' *ngIf="showError(description)">{{description.errors.msg}}</mat-error>
        </mat-form-field>

        <!-- AddOn IsActive -->
        <div class="full-width auth-toggle-container">
            <mat-label>Active</mat-label>
            <div class="toggle">
                <mat-slide-toggle class="toggle" color='primary' [(ngModel)]="em.isActive" name="isActive" #isActive="ngModel" [disabled]="!isEditMode">
                </mat-slide-toggle>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #desktopActions>
    <div class="done-cancel-button">
        <button mat-stroked-button (click)="edit()" class="d-reverse" color="primary" *ngIf="isReadMode">
            <mat-icon>edit</mat-icon>&nbsp;Edit
        </button>
        <button mat-raised-button (click)="save()" class="d-reverse" color="primary" *ngIf="isEditMode">
            <mat-icon>done</mat-icon>&nbsp;Save
        </button>
        <button mat-stroked-button (click)="cancel()" class="d-reverse" color="primary">
            <mat-icon>cancel</mat-icon>&nbsp;Cancel
        </button>
    </div>
</ng-template>