import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { FormDisplayMode } from '@trent/models/UI';
import { BaseForm } from '@trent/models/UI/base-form';
import { PaneType } from '@trent/models/UI/menu-data';
import { AddOnBase, AddOnValidationGroup, AddOnCategory } from '@trent/models/addon/add-on-base';
import { parseAddOn } from '@trent/models/addon/add-on-helper';
import { AddOnSafetyCertificate } from '@trent/models/addon/add-on-safety-cert';
import { AddOnTires, TiresType } from '@trent/models/addon/add-on-tires';
import { AddOnWarranty, WarrantyType } from '@trent/models/addon/add-on-warranty';
import { IValDbStatusResult, fromCreateSuccess, fromFailedValidation, readErrorMessage } from '@trent/models/error-handling';
import { TireMakes } from '@trent/models/inspection/tire-inspection';
import { logger } from '@trent/models/log/logger';
import { TimerHelper, toSentence } from '@trent/models/utility';
import { EnumHelper } from '@trent/models/utility/enum-helper';
import { AddOnService } from '@trent/services/add-on.service';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { EventService } from '@trent/services/event.service';
import { UtilityService } from '@trent/services/utility.service';
import { AddOnRequested, AddOnState, AddOnStateReset } from '@trent/store/addOn-store';
import { ValidationOptions } from 'class-validator/types/decorator/ValidationOptions';
import { ValidatorOptions } from 'class-validator/types/validation/ValidatorOptions';
import { Observable, map, take } from 'rxjs';

@Component({
	selector: 'trent-addon-detail',
	templateUrl: './addon-detail.component.html',
	styleUrls: ['./addon-detail.component.scss']
})
export class AddonDetailComponent extends BaseForm<AddOnBase> implements OnInit, OnDestroy {

	isHandset$: Observable<boolean>;
	isHandset: boolean;

	isLeftVisible = true;
	activePane: PaneType = 'left';

	addOnId: string | number;
	mRoot: AddOnBase;

	currSubMenu: AddOnValidationGroup = 'general';
	@ViewChild('generalForm', { static: false })
	generalForm: NgForm;

	addOnCat = EnumHelper.getNamesAndValues(AddOnCategory);
	warrantyTypes = EnumHelper.getNamesAndValues(WarrantyType);
	tiresTypes = EnumHelper.getNamesAndValues(TiresType);
	tiresMakes = TireMakes;

	constructor(
		store: Store,
		ds: DialogService,
		es: EventService,
		public aroute: ActivatedRoute,
		public us: UtilityService,
		public dialog: MatDialog,
		private addOnService: AddOnService,
		private router: Router,
	) {
		super(store, ds, es);
		this.title ='Addon Details';
	}

	ngOnDestroy(): void {
		this.cleanListeners();
		this.store.dispatch(new AddOnStateReset());
	}

	ngOnInit(): void {
		this.isHandset$ = this.us.isHandset$;
		this.mSubscription.push(this.us.isHandset$.subscribe(h => {
			this.isHandset = h;
		}));

		this.eventListeners.push(
			this.es.listen<boolean>(this.es.menuBackBtnClickCust, () => this.showLeft()),
			this.es.listen<boolean>(this.es.menuFireEdit, () => this.edit()),
			this.es.listen<boolean>(this.es.menuFireSaveCancel, (save) => save ? this.save() : this.cancel())
		);
		this.loadData();
		this.setUpFormValidation();
	}

	async save() {
		this.showLoading(300);
		//validate data
		const option = this.getValGroup();
		const tempR = this.em.validateSync(option);
		if (Object.keys(tempR).length > 0) {
			this.showAlert(fromFailedValidation(tempR).messageInfo);
			this.hideLoading();
			return;
		}
		const body = this.em;
		//update case pass spare part Id
		if (!!this.addOnId) body.id = this.addOnId;
		const addOnS = (!this.addOnId) ? this.addOnService.create(body).toPromise() : this.addOnService.update(body).toPromise();
		addOnS.then((r) => {
			this.hideLoading();
			this.showPopover({
				...fromCreateSuccess('AddOn'),
				duration: 2000
			});
			this.cancel();
		}).catch((error) => {
			this.hideLoading();
			this.showPopover(readErrorMessage(error));
		});
	}

	/**
	* Setup mobile buttons
	*/
	setUpMobileButtons() {
		if (this.isLeftVisible) {
			if (this.displayMode === FormDisplayMode.read) {
				this.showHideEdit(true);
				this.showHideSaveCancel(false);
			} else {
				this.showHideEdit(false);
				this.showHideSaveCancel(true);
			}
		} else {
			this.showHideEdit(false);
			this.showHideSaveCancel(false);
		}
	}

	/**
	 * Show hide panes
	 * @param sMenu 
	 */
	showHide(sMenu: AddOnValidationGroup) {
		this.currSubMenu = sMenu;
		this.setUpFormValidation();
		if (this.isLeftVisible) {
			this.showLeft();
		}
	}

	/**
	* Show left pane
	* @param s 
	*/
	showLeft(s?: any) {
		this.isLeftVisible = !this.isLeftVisible;
		this.activePane = this.isLeftVisible ? 'left' : 'right';
		this.isGoBackCust = !this.isLeftVisible;
	}

	edit() {
		this.displayMode = FormDisplayMode.edit;
		this.setUpMobileButtons();
		this.em = this.m.clone();
	}

	cancel() {
		this.router.navigate(['sales-options/addons']);
	}

	/**
	* Load data based on create/update case
	*/
	async loadData() {
		const data = await this.aroute.data.pipe(take(1)).toPromise();
		this.displayMode = data.mode;

		const param = await this.aroute.params.pipe(take(1)).toPromise();
		if (param.id) { //edit/Read case
			this.addOnId = param.id;
			this.getStoreData();
		} else { //create case
			this.m = new AddOnBase();
			this.em = this.m.clone();
			this.bindChangeDetect();
		}
	}

	/**
	 * Get Data from Store
	 */
	getStoreData() {
		this.store.dispatch(new AddOnRequested({ id: this.addOnId }));
		const addOns$ = this.store.select(AddOnState.selectAddOnById)
			.pipe(map(filterFn => filterFn(`${this.addOnId}`)));
		this.mSubscription.push(addOns$.subscribe(d => {
			if (d) {
				this.m = d;
				this.em = this.m.clone();
				this.bindChangeDetect();
			}
		}));
	}

	/**
	 * Bing change detect
	 */
	bindChangeDetect() {
		this.es.emit<{ m: AddOnBase, mRoot: AddOnBase, valDbStatus: IValDbStatusResult }>(this.es.changeDetect, {
			m: this.m, mRoot: this.mRoot, valDbStatus: this.em.getValDbStatus()
		});
	}

	/**get group name for validating data */
	getValGroup(): ValidatorOptions {
		const groups = ['general'];
		return { groups: groups };
	}

	/**
	 * Get Db Status
	 */
	get valDbStatus(): IValDbStatusResult {
		if (this.em) {
			return this.em.getValDbStatus();
		}
	}

	/**
	* Display in proper name
	* @param s 
	* @returns 
	*/
	getTitle(s: string) {
		return toSentence(s);
	}

	/**
	 * Get Current form
	 */
	get currentForm(): NgForm {
		switch (this.currSubMenu) {
			case 'general':
				return this.generalForm;
			default:
				return this.generalForm;
		}
	}

	/**
	 * get tab icon for form(Right side)
	 */
	get tabIcon() {
		switch (this.currSubMenu) {
			case 'general':
				return 'list';
			default:
				break;
		}
	}

	/**
	 * Setup form validation
	 */
	private setUpFormValidation() {
		const timer = new TimerHelper();
		timer.setInterval(800, 10, () => {
			if (this.currentForm == null) {
				return false;
			}
			this.setFormValidation<AddOnBase>(AddOnBase, this.currentForm, () => {
				const option: ValidationOptions = {
					groups: [this.currSubMenu],
				};
				return { model: this.em, option };
			});
			return true;
		});
	}

	onCatChange() {
		switch (this.em.category) {
			case AddOnCategory.Misc:
				// (this.em as AddOnSafetyCertificate).safetyCertified = null;
				(this.em as AddOnTires).tiresType = null;
				(this.em as AddOnTires).tiresMake = null;
				(this.em as AddOnTires).new = null;
				(this.em as AddOnWarranty).warrantyType = null;
				break;
			case AddOnCategory.Warranty:
				// (this.em as AddOnSafetyCertificate).safetyCertified = null;
				(this.em as AddOnTires).tiresType = null;
				(this.em as AddOnTires).tiresMake = null;
				(this.em as AddOnTires).new = null;
				this.em.name = null;
				break;
			case AddOnCategory.Tires:
				// (this.em as AddOnSafetyCertificate).safetyCertified = null;
				(this.em as AddOnWarranty).warrantyType = null;
				this.em.name = null;
				this.em.description = null;
				break;
			case AddOnCategory.SafetyCertification:
				(this.em as AddOnTires).tiresType = null;
				(this.em as AddOnTires).tiresMake = null;
				(this.em as AddOnTires).new = null;
				(this.em as AddOnWarranty).warrantyType = null;
				this.em.name = null;
				this.em.description = null;
				break;
			default:
				break;
		}
	}
}
