import { Store } from '@ngxs/store';
import { CompanyStateReset } from './company-store/company.state';
// import { ProductStateReset } from './product-store/product.state';
import { pipe } from 'rxjs';
import { take } from 'rxjs/operators';
// import { RentalProductStateReset } from './rental-product-store';
// import { OrderStateReset } from './order-store';
import { TaskStateReset } from './task-store/task.state';
import { PromoListStateReset } from './promo-list-store/promo-list.state';
import { TripStateReset } from './trip-store/trip-state';
import { InvoiceStateReset } from './invoice-store';
import { OrderStateReset } from './order-store';
import { ProductStateReset } from './product-store';
import { RentalProductStateReset } from './rental-product-store';
import { TripTrackerStateReset } from './trip-tracker-store';
import { UserStateReset } from './user-store';
import { BidStateReset } from './bid-store';
import { InspectionStateReset } from './inspection-store';
import { logger } from '@trent/models/log/logger';
import { LegacyContractStateReset } from './legacy-contract';
import { MinimumSellingPriceState, MinimumSellingPriceStateReset } from './minimum-selling-price';
import { SalesOptionStateReset } from './sales-option-store';
import { NetSuiteProductSyncLogsStateReset } from './netsuite-product-sync-logs-store';
import { RoleGroupStateReset } from './role-group-store';

export class StateManager {
  /**
   * Logout all the states when user is logged out
   */
  public static LogoutState(store: Store) {
    logger.log('[StateManager] State reset was called');
    store.dispatch(new LegacyContractStateReset());
    store.dispatch(new BidStateReset());
    store.dispatch(new CompanyStateReset());
    store.dispatch(new InspectionStateReset());
    store.dispatch(new InvoiceStateReset());
    store.dispatch(new OrderStateReset());
    store.dispatch(new ProductStateReset());
    store.dispatch(new PromoListStateReset());
    store.dispatch(new RentalProductStateReset());
    store.dispatch(new TaskStateReset());
    store.dispatch(new TripStateReset());
    store.dispatch(new TripTrackerStateReset());
    store.dispatch(new UserStateReset());
    store.dispatch(new SalesOptionStateReset());
    store.dispatch(new MinimumSellingPriceStateReset());
    store.dispatch(new NetSuiteProductSyncLogsStateReset()); //MKN netSuite product sync log state reset
    store.dispatch(new RoleGroupStateReset());

  }

  /**
   * Reset certain stores when default company is changed.
   * @param store storemanage.
   */
  public static DefaultCompanyChangeStateReset(store: Store) {
    // return store.dispatch(new ProductStateReset()), store.dispatch(new RentalProductStateReset());
  }
}
