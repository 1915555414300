import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ITelematics, ITelematicsTruck } from '@trent/models/product/i-telematics';
import { BaseHttpService } from './base-http.service';
import { FirestoreService } from './firestore.service';

@Injectable({ providedIn: "root" })

export class GeoTabService extends BaseHttpService {
  constructor(store: Store, private db: FirestoreService, private http: HttpClient) {
    super(store);
    this.apiName = 'api';
  }

  public getLoc(unitNames: string[]) {
    const headers = this.addBearerToken();
    return this.http.post<ITelematicsTruck[]>(this.getApiUrl('/geo-tab/get-loc'), { unitNames }, { headers }).toPromise();
  }
}
