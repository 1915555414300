<!-- <div style="height: env(safe-area-inset-top) !important;"></div>  -->
<mat-sidenav-container class="sidenav-container">
  <!-- Side Menu only shown in mobile - Start  -->
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="isHandset? 'dialog' : 'navigation'"
    [mode]="isHandset$ ? 'over' : 'side'" [opened]="false">
    <!--  [opened]="(isHandset$ | async) === false" -->
    <mat-toolbar class="row1-mb">
      <!-- <mat-icon>keyboard_arrow_left</mat-icon> -->
      <h3>Menu</h3>
      <mat-icon (click)="handleClose()" class="pull-right">close</mat-icon>
    </mat-toolbar>
    <mat-nav-list>
      <a mat-list-item routerLink="/" (click)="handleClose()">
        <!-- <i class="material-icons">home</i> -->
        <mat-icon aria-hidden="false" aria-label="home icon">home</mat-icon>
        Home
      </a>
      <a *ngIf="isAdmin" mat-list-item href="javascript:void(0)" (click)="togglePageEdit()">
        <!-- <i class="material-icons">home</i> -->
        <mat-icon aria-hidden="false" aria-label="home icon">edit</mat-icon>
        Toggle Page Edit
      </a>

      <a *ngIf="!isAuth" mat-list-item routerLink="/login" (click)="handleClose()">
        <mat-icon aria-hidden="false" aria-label="home icon">login</mat-icon> Login
      </a>
      <a *ngIf="isAuth" mat-list-item routerLink="/login" (click)="logout()">
        <mat-icon aria-hidden="false" aria-label="home icon">logout</mat-icon> Logout {{ displayName }}
      </a>
      <mat-divider></mat-divider>
      <ng-container *ngFor="let x of menuFilt">
        <!-- no sub menu       -->
        <ng-container *ngIf="!x.subMenu">
          <ng-container *ngTemplateOutlet="linkOnlyMb; context: getLinkObj(x)"> </ng-container>
        </ng-container>

        <!-- has sub menu  -->
        <ng-container *ngIf="!!x.subMenu">
          <ng-container *ngTemplateOutlet="linkSubMenuMb; context: getLinkObj(x)"> </ng-container>
        </ng-container>
      </ng-container>
      <!-- logout at last -->
      <mat-divider></mat-divider>
      <a *ngIf="isAuth && isMultipleComp" mat-list-item  routerLink="/company" (click)="handleClose()">
        <mat-icon aria-hidden="false" aria-label="home icon">settings</mat-icon>
        Change Company
      </a>
      <a mat-list-item disabled class="def-company" *ngIf="isAuth && dComp">
        <mat-icon>business</mat-icon>
        <div>{{dComp.cName}}</div>
      </a>

      <!-- <a *ngIf="isAuth" mat-list-item routerLink="/login" (click)="handleClose()">
        <i class="fas fa-sign-out-alt"></i>
        Logout
      </a> -->
    </mat-nav-list>
  </mat-sidenav>
  <!-- Side Menu only shown in mobile - End  -->

  <mat-sidenav-content id="shellSideNav"
    [ngClass]="{'bg-image': showBg, 'bg-image-mb': showBg && isHandset && !isLandscape, 'bg-image-mb-landscape': isLandscape && !isFixViewPort,
  'fix-view-port': isFixViewPort, 'native-view-port': !isFixViewPort, 'bg-image-landing': showLanding, 'bg-image-dt': !isHandset}">
    <mat-toolbar color="primary" [ngClass]="{ 'shrink-dt': shrinkToolbar, 'reg-dt': !shrinkToolbar }" *ngIf="hideNavBar">
      <!-- Header Menu : MOBILE START -->
      <mat-toolbar-row class="row1-mb" *ngIf="isHandset && hideNavBar">
        <button *ngIf="!showBackBtn" type="button" aria-label="Toggle sidenav" mat-icon-button
          (click)="drawer.toggle()">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>

        <button *ngIf="showBackBtn" type="button" aria-label="Toggle sidenav" mat-icon-button (click)="handleBackBtn()">
          <mat-icon aria-label="Side nav toggle icon">arrow_back</mat-icon>
        </button>

        <a *ngIf="url == '/'" class="logo">
          <img class="img" style="height: 45px; width: auto;"  src="./assets/logo-mb1-mobile.png" alt="Logo" />
          <!-- <span> LocusLoop </span> -->
        </a>
        <div *ngIf="url != '/'">
          <div>{{title}}</div>
          <div class="def-comp-mob-title" *ngIf="!!subTitle">
            <!-- {{dComp?.cName}} -->
            {{subTitle}}
          </div>
        </div>

        <!-- <span *ngIf="url != '/'">{{ title }}
          <br>
          <sub>Company Name</sub>
        </span> -->
        <span class="fill-space"></span>
        <!-- showNotification button -->
        <ng-container *ngIf="showNotification;">
          <mat-icon aria-hidden="false" aria-label="notifications icon" color="accent">notifications</mat-icon>
        </ng-container>
        <!-- show search button -->
        <ng-container *ngIf="showSearch;">
          <button (click)="search()" type="button" aria-label="Search" mat-icon-button>
            <mat-icon aria-label="search">search</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="!!gps?.show;">
          <button (click)="search()" type="button" aria-label="GPS" mat-icon-button>
            <mat-icon aria-label="search" color="accent" *ngIf="!!gps?.status">gps_fixed</mat-icon>
            <mat-icon aria-label="search"  *ngIf="!gps?.status">gps_off</mat-icon>
          </button>
        </ng-container>
        <!-- show save-cancel button -->
        <ng-container *ngIf="showSaveCancel; ">
          <button (click)="cancel()" type="button" aria-label="Cancel" mat-icon-button>
            <mat-icon aria-label="cancel">clear</mat-icon>
          </button>
          <button (click)="save()" type="button" aria-label="Cancel" mat-icon-button>
            <mat-icon aria-label="cancel">done</mat-icon>
          </button>
        </ng-container>
        <!-- show edit button -->
        <ng-container *ngIf="showEdit;">
          <button (click)="edit()" type="button" aria-label="Edit" mat-icon-button>
            <mat-icon aria-label="edit">edit</mat-icon>
          </button>
        </ng-container>
        <!-- show next cancel button -->
        <ng-container *ngIf="showNextCancel;">
          <button (click)="cancel()" type="button" aria-label="Cancel" mat-icon-button>
            <mat-icon aria-label="cancel">clear</mat-icon>
          </button>
          <button (click)="next()" type="button" aria-label="Next" mat-icon-button>
            <mat-icon aria-label="keyboard arrow right">keyboard_arrow_right</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngTemplateOutlet="topOption"></ng-container>
        
        <ng-template #topOption>
          <!-- && !showNextCancel -->
          <ng-container *ngIf="!showSaveCancel && !showEdit ">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <ng-container *ngFor="let link of subMenuData">
                <a *ngIf="link.type == 'link'" mat-menu-item [routerLink]="link.url">
                  <mat-icon aria-hidden="false" aria-label="home icon">{{ link.icon }}</mat-icon>
                  <span>{{ link.title }}</span>
                </a>
                <a *ngIf="link.type == 'button'" mat-menu-item (click)="link.click()">
                  <mat-icon aria-hidden="false" aria-label="home icon">{{ link.icon }}</mat-icon>
                  <span>{{ link.title }}</span>
                </a>
              </ng-container>
            </mat-menu>
          </ng-container>

        </ng-template>
      </mat-toolbar-row>
      <!-- Header Menu : MOBILE END -->

      <!-- Desktop - Top Header START -->
      <mat-toolbar-row class="row1-dt" *ngIf="!isHandset && hideNavBar">
        <a class="logo" [routerLink]="['/']">
          <!-- <img src="../../../assets/locus-loop-txt.png" alt="Logo"> -->
          <img class="img" src="./assets/logo-mb1.png" alt="Logo" />
          <!-- <span> LocusLoop </span> -->
        </a>
        <span class="fill-space"></span>
        <ng-container *ngFor="let x of menuFilt">
          <!-- no sub menu       -->
          <ng-container *ngIf="!x.subMenu">
            <ng-container *ngTemplateOutlet="linkOnlyDt; context: getLinkObj(x)"> </ng-container>
          </ng-container>

          <!-- has sub menu  -->
          <ng-container *ngIf="!!x.subMenu">
            <ng-container *ngTemplateOutlet="linkSubMenuDt; context: getLinkObj(x)"> </ng-container>
          </ng-container>
        </ng-container>

        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <ng-container *ngFor="let link of subMenuData">
            <a mat-menu-item [routerLink]="link.url" (click)="link.click()">
              <mat-icon color="primary" aria-hidden="false" aria-label="home icon">{{ link.icon }}</mat-icon>
              <span>{{ link.title }}</span>
            </a>
          </ng-container>
        </mat-menu>
      </mat-toolbar-row>
      <mat-toolbar-row class="row2" *ngIf="!isHandset && hideNavBar">

        <div class="container">
          <a class="crm-command-bar-button title-desktop" color="accent" *ngIf="isAuth && title">{{title}}
          </a>
          <!-- sub menu -->
          <ng-container *ngIf="subMenuData">
            <ng-container *ngFor="let mData of subMenuData">
                <a [ngClass]="{'float-right': mData.icon==='help'}" class="crm-command-bar-button mat-button"mat-button (click)="mData.click()" [color]="mData.color">
                  <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color sub-menu-icon" role="img" aria-hidden="true">
                    {{mData.icon}}
                  </mat-icon>{{mData.title}}
                </a>
            </ng-container>

          </ng-container>
          <!-- <a class="crm-command-bar-button mat-button" mat-button disabled="">
              <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true">save
              </mat-icon> SAVE AS
            </a>
            <a class="crm-command-bar-button mat-button" mat-button disabled>
              <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true">
                dashboard</mat-icon> NEW
            </a> -->





          <!-- <div style="flex-direction: row; box-sizing: border-box; display: flex; place-content: stretch flex-end; align-items: stretch; flex: 1 1 0%;"> -->
          <div class="float-right">
            <ng-container  *ngIf="isMultipleComp && isAuth && dComp?.cid">
              <a mat-button [matMenuTriggerFor]="changeComp">
                <mat-icon>business</mat-icon>
                {{ dComp?.cName }}
              </a>
              <mat-menu #changeComp="matMenu" class="mat-menu-toolbar">
                  <a mat-menu-item [routerLink]="['company', this.rb]" (click)="handleClose()">
                    <mat-icon aria-hidden="false" aria-label="settings icon" color="primary">settings</mat-icon>
                    Change Company
                  </a>
              </mat-menu>
            </ng-container>
            <ng-container  *ngIf="!isMultipleComp && isAuth && dComp?.cid">
              <a mat-button [routerLink]="['company/detail/', dComp.cid]" [queryParams] = "this.rb.queryParams" (click)="handleClose()">
                <mat-icon>business</mat-icon>
                {{ dComp?.cName }}
              </a>
            </ng-container>
            <a *ngIf="isAdmin" mat-button href="javascript:void(0)" (click)="togglePageEdit()">
              <mat-icon aria-hidden="false" aria-label="home icon">edit</mat-icon>
              Toggle Page Edit
            </a>
            <a *ngIf="isAdmin && !!htmlId" mat-button [routerLink]="['cms/edit/', htmlId]" [queryParams] = "pQueryParam" >
              <mat-icon aria-hidden="false" aria-label="home icon">edit</mat-icon>
              Edit Html
            </a>

            <a *ngIf="!isAuth" mat-button  routerLink="/login">
              <mat-icon class="mat-icon notranslate material-icons " role="img" aria-hidden="true"> login
              </mat-icon> Login
            </a>
            <a mat-button (click)="toAccount()">
              <span class="mat-button-wrapper d-flex d-a-center">
                <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true">
                  account_circle</mat-icon> <span>Account</span>
              </span>
              <div class="mat-button-ripple mat-ripple" matripple=""></div>
              <div class="mat-button-focus-overlay"></div>
            </a>
            <a *ngIf="isAuth" mat-button (click)="logout()">
              <mat-icon class="mat-icon notranslate material-icons " role="img" aria-hidden="true"> logout
              </mat-icon> Logout {{ displayName }}
            </a>
          </div>
        </div>
      </mat-toolbar-row>
      <!--  Desktop - Top Header END -->
    </mat-toolbar>

    <!-- Page Content Goes here -->
    <div [ngClass]="{'dtop': !isHandset, 'mobile': isHandset, 'landscape': isLandscape}">
      <div class="container" id="mArea">
        <header></header>
        <trent-alert-bar></trent-alert-bar>
        <ng-content></ng-content>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<!-- Sub Menu Popup Data -->
<mat-menu #subMenu="matMenu">
  <ng-container *ngFor="let mData of subMenuData">
    <button type="button" mat-menu-item (click)="mData.click()">
      <mat-icon color="primary">{{ mData.icon }}</mat-icon> {{ mData.title }}
    </button>
  </ng-container>
</mat-menu>
<!-- </div> -->

<!-- Desktop Template of menu Icon : START  -->

<!-- Desktop Template of menu Icon : END  -->
<ng-template #linkOnlyDt let-x="x">
  <a mat-button [routerLink]="[x.url]" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }"
    #rla="routerLinkActive">
    <ng-container *ngIf="!!x.micon; else cusIcon">
      <mat-icon aria-hidden="false" aria-label="home icon">{{ x.micon }}</mat-icon>
    </ng-container>
    <ng-template #cusIcon>

    <ng-container *ngIf="!!x.cIcon; else fo">
      <mat-icon aria-hidden="false" [svgIcon]="x.cIcon" ></mat-icon>
    </ng-container>
    </ng-template>
    <ng-template #fo>
      <!-- <i [class]="x.iClass"></i> -->
      <fa-icon [icon]="['fa', x.faicon]" *ngIf="x.faicon"></fa-icon>
    </ng-template>
    {{ x.title }}
    <ng-container *ngIf="x.url==='/task' && nTasks>0">
      <mat-icon aria-hidden="false" aria-label="notifications icon" [class.active-link-icon]="rla.isActive"
        [matBadge]="nTasks" [matBadgeColor]="getBadgeColor()">notifications</mat-icon>
    </ng-container>
    <!-- [matBadge]="nTasks" matBadgeColor="accent" color="accent" -->
  </a>
</ng-template>

<ng-template #linkSubMenuDt let-x="x">
  <a mat-button [matMenuTriggerFor]="submenu">
    <mat-icon>unfold_more</mat-icon>
    {{ x.title }}
  </a>
  <mat-menu #submenu="matMenu">
    <ng-container *ngFor="let y of getFilterArray(x.subMenu)">
      <a mat-menu-item [routerLink]="[y.url]" (click)="handleClose()" >
        <ng-container *ngIf="!!y.micon; else cIconSubD">
          <mat-icon aria-hidden="false" aria-label="home icon">{{ y.micon }}</mat-icon>
        </ng-container>
        <ng-template #cIconSubD>
          <ng-container *ngIf="!!y.cIcon; else fo">
            <mat-icon aria-hidden="false"  [svgIcon]="y.cIcon"></mat-icon>
          </ng-container>
          
          <ng-template #fo>
            <ng-container *ngIf="y.faicon; else cl">
              <fa-icon [icon]="['fas', y.faicon]"></fa-icon>
            </ng-container>
            <ng-template #cl>
              <i [class]="y.iClass + ' ' + 'mat-icon-no-color'"></i>
            </ng-template>
          </ng-template>
        </ng-template>
        
        {{ y.title }}
      </a>
    </ng-container>
  </mat-menu>
</ng-template>
<!-- Mobile Template of menu Icon : START  -->
<ng-template #linkOnlyMb let-x="x">
  <a mat-list-item [queryParams]="x.rb ? rb.queryParams: ''" [routerLink]="[x.url]" (click)="handleClose()">
    <ng-container *ngIf="!!x.micon; else cusMIcon">
      <mat-icon aria-hidden="false" aria-label="home icon">{{ x.micon }}</mat-icon>
    </ng-container>
    <ng-template #cusMIcon>
      <ng-container *ngIf="!!x.cIcon; else fo">
        <mat-icon aria-hidden="false" [svgIcon]="x.cIcon"></mat-icon>
      </ng-container>

      <ng-template #fo>
        <i [class]="x.iClass"></i>
      </ng-template>
    </ng-template>
    
    {{ x.title }}
    <ng-container *ngIf="x.url==='/task' && nTasks>0">
      <mat-icon aria-hidden="false" aria-label="notifications icon" [matBadge]="nTasks" matBadgeColor="accent"
        color="accent">notifications</mat-icon>
    </ng-container>
  </a>
</ng-template>

<ng-template #linkSubMenuMb let-x="x">
  <a mat-list-item [matMenuTriggerFor]="submenu">
    <mat-icon>unfold_more</mat-icon>
    {{ x.title }}
  </a>
  <mat-menu #submenu="matMenu">
    <ng-container *ngFor="let y of getFilterArray(x.subMenu)">
      <a mat-menu-item [queryParams]="y.rb ? rb.queryParams: ''" [routerLink]="[y.url]" (click)="handleClose()" >
        <ng-container *ngIf="!!y.micon; else cIonSubM">
          <mat-icon aria-hidden="false" aria-label="abc">{{ y.micon }}</mat-icon>
        </ng-container>
          <ng-template #cIonSubM>
            <ng-container *ngIf="!!y.cIcon; else fo">
              <mat-icon aria-hidden="false" [svgIcon]="y.cIcon"></mat-icon>
            </ng-container>  
            <ng-template #fo>
              <ng-container *ngIf="y.faicon; else cl">
                <fa-icon [icon]="['fas', y.faicon]"></fa-icon>
              </ng-container>
              <ng-template #cl>
                <i [class]="y.iClass + ' ' + 'mat-icon-no-color'"></i>
              </ng-template>
            </ng-template>
                   
          </ng-template>
        {{ y.title }}
      </a>
    </ng-container>
  </mat-menu>
</ng-template>
<!-- Mobile Template of menu Icon : END  -->

<!-- <mat-drawer #settingDrawer class="example-sidenav" mode="side">
    Hello Hello
    <ng-container *ngComponentOutlet="settingComponent"></ng-container>
  </mat-drawer>
  
  </mat-drawer-container> -->