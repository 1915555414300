<ng-container>
	<div mat-dialog-title style="background: var(--primary);">
		<span class="closebtn" (click)="close()">&times;</span>
		{{ em?.physicalLocationSummary?.name ||'Store Location'}}
	</div>
	<mat-dialog-content>
		<div class="spinner" *ngIf="isLoading">
			<mat-spinner></mat-spinner>
		</div>

		<div *ngIf="!isLoading">
			 <!-- Lots Dropdown -->
			 <mat-form-field class="full-width" *ngIf="!!storeLots?.length">
				<mat-label>Lot</mat-label>
				<mat-select name="lots" [(ngModel)]="selectedLot" (selectionChange)="lotChange($event.value)">
				  <mat-option *ngFor="let l of storeLots" [value]="l">
					{{ l }}
				  </mat-option>
				</mat-select>
			  </mat-form-field>
			<!-- Lot -->
			<mat-form-field class="full-width" *ngIf="showUnitLot || !storeLots?.length">
				<mat-label>Lot Name</mat-label>
				<input matInput name="lotName" #lotName="ngModel" [(ngModel)]="em.unitLot">
			</mat-form-field>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-raised-button (click)="close()" [disabled]="isLoading">Cancel</button>
		<button mat-raised-button color="primary" cdkFocusInitial (click)="save()" [disabled]="isLoading">Save</button>
	</mat-dialog-actions>
</ng-container>