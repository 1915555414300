import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { logger } from '@trent/models/log/logger';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { BasePage } from '@trent/models/UI/base.page';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { EventService } from '@trent/services/event.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { GpsType, ProductBase, ProductSearchParam, ProductType, Truck, productOptionInit } from '@trent/models/product';
import { MatTableDataSource } from '@angular/material/table';
import { UtilityService } from '@trent/services/utility.service';
import { FetchProductsUsingAlgolia, ProductState, ProductStateReset } from '@trent/store/product-store';
import { MenuData, PaneType } from '@trent/models/UI/menu-data';
import { Paging } from '@trent/models/observable-util/paging';
import { SalesOptionsSearchDialogComponent } from '../../../shared/pts-search-sales-option/sales-options-search-dialog/sales-options-search-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ImageType } from '@trent/models/sales-option';
import { RoleGroupLevelRank } from '@trent/models/role-group/role-group-level';
import { MessageInfo, readErrorMessage } from '@trent/models/error-handling';
import { PromoListType } from '@trent/models/promo';
import { toQueryStr } from '@trent/models/utility/query-helper';
import { rentOptionParamInit } from '@trent/models/rental/rent-option-param';
import { ProductService } from '@trent/services/product.service';
import { MapLabel } from '@trent/models/map/map-label';
import { MatSidenav } from '@angular/material/sidenav';
import { SalesOptionService } from '@trent/services/sales.service';
import { InventoryTransfer, LocationType, TransferStatus } from '@trent/models/product/inventory-transfer';
import { DatePipe } from '@angular/common';
import { GpsService } from '@trent/services/gps.service';
import { getMapLabels, assetLocMapLabels } from '@trent/models/store-location/store-location-helper';
import { isEqual } from 'lodash';
import { AssetLocService } from '@trent/services/asset-loc.service';
import { EditLotComponent } from '../../../shared/edit-lot/edit-lot/edit-lot.component';
import { UserSearchType } from '../../../shared/pts-search-sales-option/pts-search-sales-option.component';
import { environment } from '@trent/core/environments/environment';

export enum EditType {
	editPictures = 1,
	editUnit = 2,
	editSales = 3,
}
export enum ListCategory {
	product = 1,
	list = 2
}
@Component({
	selector: 'trent-sales-options-product-listing',
	templateUrl: './sales-options-product-listing.component.html',
	styleUrls: ['./sales-options-product-listing.component.scss']
})
export class SalesOptionsProductListingComponent extends BasePage<ProductBase[]> implements OnInit, OnDestroy {

	dataSource: MatTableDataSource<{}>;
	isHandset: boolean;
	showFilter = false;
	prodType: ProductType;
	productParam: ProductSearchParam;
	productSub: Subscription;
	menuData: MenuData[] = [];
	initialParams: ProductSearchParam = productOptionInit();
	pagination: Paging = { size: 10, offset: 0, full: false, count: 0 };
	ImageTypeEnum = ImageType;
	isSalesOperation: boolean = false;
	isCreditUser: boolean = false;
	isYardPerson: boolean = false;
	isAdmin: boolean = false;
	vCid: string;
	listType: PromoListType;
	baseUrl: string;
	isUploadPicPage: boolean;
	filterText: string;

	@ViewChild('dtopFilterCtrl', { static: false })
	dtopFilterCtrl: ElementRef;

	get displayedColumns() {
		if (this.isSalesOperation && !this.salesUploadPic) {
			return ['pic', 'vin', 'unit', 'make', 'model', 'location', 'lot', 'odometer', 'lastUpdatedBy', 'actions'];
		} else if (this.isSalesOperation && this.salesUploadPic) {
			return ['vin', 'unit', 'make', 'model', 'location', 'lot', 'odometer', 'actions']; // Sales Op
		} else {
			return ['vin', 'unit', 'make', 'model', 'location', 'lot', 'odometer', 'actions']; // Yard
		}
	}

	// Check for `upload pic` page for sales operation
	get salesUploadPic() {
		return (!this.vCid && this.isSalesOperation) ? true : false;
	};

	// Map
	mapLabels: MapLabel[];
	@ViewChild('editNav', { static: false })
	editNav: MatSidenav;
	mobileMenu: 'mapMenu' | 'editMenu';
	mapView = false;
	clusterFilter: boolean;
	activePane: PaneType = 'left';
	isLeftVisible = true;
	gpsPointerArray = [];
	gpsAssetArray = [];
	selectedProduct;
	websiteId:string;

	/**
	 * Class Constructor
	 * @param aroute 
	 * @param dialog 
	 * @param us 
	 * @param router 
	 * @param store 
	 * @param eventService 
	 * @param ds 
	 * @param productService 
	 */
	constructor(
		private aroute: ActivatedRoute,
		public dialog: MatDialog,
		public us: UtilityService,
		private router: Router,
		store: Store,
		eventService: EventService,
		private ds: DialogService,
		public productService: ProductService,
		private salesService: SalesOptionService,
		private datePipe: DatePipe,
		public gpsService: GpsService,
		private als: AssetLocService,
	) {
		super(store, ds, eventService);
	}

	ngOnInit() {
		this.websiteId = environment.webSite.id;
		this.mSubscription.push(this.us.isHandset$.subscribe(r => {
			this.isHandset = r;
			this.subMData();
		}));
		// Get Product Type
		this.prodType = (!!this.aroute.snapshot.data.pType) ? this.aroute.snapshot.data.pType : this.aroute.snapshot.data.productType;
		// Check for Upload Pic Page
		this.isUploadPicPage = (!!this.aroute.snapshot.data.uploadPicPage) ? true : false;
		this.vCid = this.getVendorCid();
		this.productParam = { ...this.initialParams, type: this.prodType, cids: (!!this.vCid) ? [this.vCid] : null };
		this.aroute.queryParams.subscribe(params => {
			if (!!Object.keys(params).length) {
				this.productParam = params;
				this.addRemoveCancelFilter(true);
			}
		}).unsubscribe();
		// Get the url excluding params
		this.baseUrl = this.router.url.split('?')[0];
		this.title = this.prodType == 1 ? 'Trailer List' : 'Truck List';
		this.listType = (this.prodType === ProductType.truck) ? PromoListType.promoTruck : PromoListType.promoTruck;
		// Get User
		this.mSubscription.push(this.user$.subscribe(u => {
			if (!!u) {
				this.isSalesOperation = (u?.roles?.salesOperations?.l === RoleGroupLevelRank.level2) ? true : false;
				this.isCreditUser = (!!u?.roles?.credit) ? true : false;
				this.isYardPerson = (!!u?.roles?.yardPerson) ? true : false;
				this.isAdmin = u?.isAdmin;
				if ((this.defaultCompany || !!u.roles['editor'] || !!this.isCreditUser || !!this.isSalesOperation || !!this.isYardPerson)) {
					this.loadDataUsingAlgolia();
				}
				if (!!this.vCid && this.websiteId == 'tpr') this.getAssetLocation();
			}
		}));
		this.eventListeners.push(
			this.es.listen<boolean>(this.es.menuBackBtnClickCust, () => this.showLeft()),
		);
		setTimeout(() => {
			this.gpsService.getAllVehicleFromGps(GpsType.pointer).then(p => {
				this.gpsPointerArray = p.data;
			}).catch(e => logger.log(e));
		}, 2000);
	}

	loadDataUsingAlgolia() {
		let params = { ...this.productParam, type: this.prodType, cids: [this.vCid] };
		if (this.isYardPerson || this.isUploadPicPage) delete params.cids;
		this.store.dispatch(new FetchProductsUsingAlgolia({ pData: this.pagination, param: params }));
		this.mSubscription.push(this.store.select(ProductState.selectTotalCount).subscribe(totalCount => this.pagination.count = totalCount));
		this.store.select(ProductState.selectTotalCount);
		this.mSubscription.push(this.store.select(ProductState.selectAllProductsUsingAlgolia).pipe(map(filterFn => filterFn({}))).subscribe(s => {
			this.m = s as ProductBase[];
			this.dataSource = new MatTableDataSource(s);
		}));
	}

	setupMobileTopMenuEvents() {
		this.setUpMobileButtons();
		this.eventListeners.push(
			this.es.listen<boolean>(this.es.menuFireSearch, (search) => {
				if (search) {
					// this.showFilter = true;
				}
			}));
	}

	/**
	* setup Mobile Buttons
	* @returns 
	*/
	setUpMobileButtons() {
		if (!this.isHandset) {
			return;
		}
		this.showHideSearch(true);
	}

	ngOnDestroy(): void {
		this.cleanListeners();
		this.store.dispatch(new ProductStateReset());

	}

	applyFilter(filterValue: string) {
		this.productParam = { searchText: filterValue };
		this.pagination = { size: 10, offset: 0, full: false, count: 0 };
		this.loadDataUsingAlgolia();
	}

	/**
	 * @author - PT
	 * @pupose - to show the buttons on the header
	 */
	subMData() {
		this.menuData = [];
		if (this.isHandset && this.mapView) {
			this.menuData.push({
				type: 'button',
				title: !this.mapView ? 'Map view' : 'List view',
				icon: !this.mapView ? 'map' : 'list',
				color: !this.mapView ? 'accent' : 'primary',
				click: () => {
					this.mapView = !this.mapView;
					this.subMData();
					this.showLeft();
				}
			});
		}
		this.menuData.push(
			{
				type: 'button',
				title: 'Search',
				icon: 'search',
				color: 'primary',
				click: () => {
					this.showSearch();
				}
			}
		);
		this.es.emit(this.es.loadPageOptions, this.menuData);
	}

	/**
	 * @author - PT
	 * @purpose - 
	 */
	showSearch() {
		const orgProdParams = this.productParam;
		const dialogRef = this.dialog.open(SalesOptionsSearchDialogComponent, {
			position: {
				top: this.isHandset ? '90px' : '',
			},
			data: {
				param: this.productParam,
				searchType: UserSearchType.YardPerson,
				isProdList: (!!this.vCid) ? true : false,
			},
			minWidth: this.isHandset ? '70vw' : '500px',
			autoFocus: false,

		});
		dialogRef.afterClosed().subscribe(async (result) => {
			if (result === undefined || Object.keys(result.param)?.length === 0) {
				this.addRemoveCancelFilter(false);
				this.productParam = { ...this.initialParams };
			} else {
				this.productParam = { ...this.productParam, ...result.param };
				this.addRemoveCancelFilter(true);
			}
			this.pagination.offset = 0;
			this.loadDataUsingAlgolia();
			if (isEqual(orgProdParams, this.productParam) || !Object.keys(result.param).length) {
				this.router.navigate([], {
					relativeTo: this.aroute,
					queryParams: null,
				});
			} else {
				this.router.navigate([], {
					relativeTo: this.aroute,
					queryParams: this.productParam,
					queryParamsHandling: 'merge',
				});
			}
			setTimeout(() => {
				this.es.emit(this.es.loadPageOptions, this.menuData);
			}, 2000);
		});
	}

	/*
	* @purpose - to clear all the params and call the sales options api and addRemoveCancelFilter method
	*/
	clearSearch() {
		this.addRemoveCancelFilter(false);
		this.subMData();
		this.productParam = { ...this.initialParams, type: this.prodType };
		this.loadDataUsingAlgolia();
	}

	/**
	 * @author - PT
	 * @purpose - to show the menu on the header when search is hit
	 */
	addRemoveCancelFilter(b: boolean) {
		if (!!b) {
			if (!this.menuData.map((f) => f.title).includes('Cancel Search')) {
				this.menuData.push({
					type: 'button',
					title: 'Cancel Search',
					icon: 'search_off',
					color: 'primary',
					click: () => {
						this.clearSearch();
					},
				});
			}
			this.menuData = this.menuData.filter((f) => f.title !== 'Search');
		} else {
			this.menuData = this.menuData.filter((f) => f.title !== 'Cancel Search');
		}
		this.es.emit(this.es.loadPageOptions, this.menuData);
	}

	/**
	 * run the particular method according to the page event
	 * @param event  - page event
	 */
	pageEvents(event: any) {
		this.pagination.offset = event.pageIndex;
		this.pagination.size = event.pageSize;
		this.loadDataUsingAlgolia();
	}


	clearFilter() {
		this.showFilter = false;
		this.dataSource.filter = null;
		this.dataSource = new MatTableDataSource(this.m);
		if (!!this.dtopFilterCtrl) {
			this.dtopFilterCtrl.nativeElement.value = null;
		}
	}

	/**
	 * @author KS
	 * @purpose Go to the respective create/update page
	 * @param id string
	 * @param editType EditType
	 * @returns 
	 */
	edit(id: string, editType: EditType, pCid?: string, salesOptionId?: string) {
		this.vCid = (!!this.vCid) ? this.vCid : pCid;
		switch (editType) {
			case EditType.editPictures:
				this.router.navigate([`sales-options/upload-picture/${id}`], this.rb);
				break;
			case EditType.editUnit:
				this.router.navigate([`trailers/trailer-detail/${id}`], this.rb);
				break;
			default:
				break;
		}
	}

	/**
	 * @author KS
	 * @purpose Go to the respective create/update sales page
	 * @param pid string
	 * @param sid string
	 * @returns 
	 */
	setupSales(pid: string, sid?: string) {
		const queryParams = { vCid: `${this.vCid}` };
		const route = this.prodType === ProductType.truck ? 'truck-details' : 'trailer-details';
		if (sid || pid) {
			this.router.navigate(['sales-options', route], { queryParams: { ...(sid ? { salesId: sid } : { pid }), ...queryParams } });
		}
	}

	/**
	 * @param id 
	 */
	assign(id: string) {
		const queryParams = { vehListType: this.listType, vCid: this.vCid };
		this.router.navigate([`bid/assign-unit/${id}`], { queryParams: queryParams });
	}

	/**
	 * @param id 
	 */
	openRentals(id: string) {
		let q: string;
		if (this.listType === PromoListType.promoTrailer) {
			q = toQueryStr({ pid: id }, rentOptionParamInit());
			this.router.navigate(['rent-option/trailer/list'], { queryParams: { q } });
		} else if (this.listType === PromoListType.promoTruck) {
			q = toQueryStr({ pid: id }, rentOptionParamInit());
			this.router.navigate(['rent-option/truck/list'], { queryParams: { q } });
		}
	}

	/**
	 * @author - MKN 
	 * @purpose Activate/Deactivate product
	 * @param request 
	 * @param response 
	 * @returns 
	 */
	activeDeactivateProduct(pid: string | number, isActive: boolean, index: number) {
		this.showLoading(10);
		const prom = this.productService.activeDeactivateProduct(pid, (!isActive)).toPromise();
		prom.then((r) => {
			(this.m[index] as Truck).isActive = !isActive;
			this.hideLoading();
			const msg = new MessageInfo({
				msgCss: 'primary',
				header: !!isActive ? 'Deactivated' : 'Activated',
				description: `Product ${!!isActive ? 'Deactivated' : 'activated'} successfully`
			});
			this.showAlert(msg);
		}).catch(err => {
			this.hideLoading();
			this.showAlert(readErrorMessage(err));
		});
	}

	/**
	 * @author KS
	 * @purpose Redirect to add unit
	 */
	addUnit() {
		let routePrefix: string;

		// Determine the route prefix based on prodType or listType
		if (this.prodType) {
			routePrefix = this.getRoutePrefix(ListCategory.product, this.prodType);
		} else if (this.listType) {
			routePrefix = this.getRoutePrefix(ListCategory.list, this.listType);
		} else {
			return; // Handle the default case if needed
		}

		// Build the route based on vCid
		const route = this.vCid ? `${routePrefix}/add-admin/${this.vCid}` : `${routePrefix}/`;

		// Navigate to the route
		this.router.navigate([route], this.rb);
	}

	/**
	 * @author KS
	 * @purpose Get route prefix
	 * @param category 
	 * @param type 
	 * @returns 
	 */
	getRoutePrefix(category: ListCategory, type: ProductType | PromoListType) {
		if ((category === ListCategory.product && type === ProductType.trailer) || (category === ListCategory.list && type === PromoListType.promoTrailer)) {
			return 'trailers/trailer-detail';
		}
		if ((category === ListCategory.product && type === ProductType.truck) || (category === ListCategory.list && type === PromoListType.promoTruck)) {
			return 'trailers/truck-detail';
		}
	}

	/**
	 * @author KS
	 * @purpose Redirect to setup rental page
	 * @param key 
	 * @returns 
	 */
	rentMe(key: string) {
		if (!key) return;
		this.router.navigate([`rent-option/trailer/${key}`], this.rb);
	}

	/**
	 * @author KS
	 * @purpose Redirect to transfer page
	 * @param key 
	 * @returns 
	 */
	transfer(key: string) {
		if (!key) return;
		const product = this.m.find(x => x.id === key);
		return (!product?.transfers?.length)
			? this.router.navigate([`trailers/transfer-details/add/${key}`], this.rb)
			: this.router.navigate([`trailers/transfer/${key}`], this.rb);
	}

	/**
	 * @author KS
	 * @purpose Get company id
	 * @returns 
	 */
	getVendorCid() {
		if (!!this.aroute.snapshot.params?.cid) {
			return this.aroute.snapshot.params?.cid;
		} else if (this.defaultCompany !== undefined && !this.isYardPerson) {
			return this.defaultCompany.cid;
		}
	}

	showOnMap(d) {
		this.mapLabels = [];
		this.selectedProduct = null;
		// Cm - gps from pointer 
		if (d.gpsProvider == GpsType.pointer) {
			this.gpsPointerArray.forEach((g) => {
				if (g.licensePlate == d.vin) {
					this.selectedProduct = d;
					this.mapLabels = getMapLabels([g]);
				}
			});
		}
		// Cm - gps from geo-tab or wialon 
		if ((d.gpsProvider == GpsType.geoTab || d.gpsProvider == GpsType.wialon) && this.websiteId == 'tpr') {
			this.gpsAssetArray.forEach((g) => {
				if (g.vin == d.vin) {
					this.selectedProduct = d;
					this.mapLabels = assetLocMapLabels([g]);
				}
			});
		}
		if (this.mapLabels && this.selectedProduct) {
			this.es.emit<MapLabel[]>('showMapLabels', this.mapLabels);
			this.mapView = true;
			if (this.isHandset) {
				this.subMData();
				this.showLeft();
			}
		}
	}

	showLeft() {
		this.isLeftVisible = !this.isLeftVisible;
		this.activePane = this.isLeftVisible ? 'left' : 'right';
		this.isGoBackCust = !this.isLeftVisible;
	}

	/**
	 * @author KS
	 * @purpose Get Info for tool tip
	 * @param key 
	 * @returns 
	 */
	getToolTipInfo(key: string) {
		if (!key && !this.m.length) return;
		let product = this.m.find(x => (x.id === key) && x?.transfers?.length);
		const transfer = product?.transfers.find((x: InventoryTransfer) => x.status === TransferStatus.inTransit);
		if (!transfer) return null;
		const destination = (transfer.destinationLocationType === LocationType.inventory)
			? transfer.destinationInventory.address.city
			: transfer.destinationAddress.city;
		return `Status: In Transit, Destination: ${destination}, ETA: ${this.datePipe.transform(transfer.eta, 'MMM d, y h:mm:ss a')}`;
	}

	backToList() {
		this.mapView = false;
		this.selectedProduct = null;
	}

	gpsIconDisable(d) {
		if(this.websiteId == 'pts' && d?.gpsProvider === GpsType.pointer){
			return false;
		} else if(this.websiteId == 'tpr' && (d?.gpsProvider === GpsType.pointer) || ((d?.gpsProvider == GpsType.geoTab || d?.gpsProvider == GpsType.wialon) && d?.gpsSrNo)){
			return false;
		}else{
			return true;
		}
	}
	getAssetLocation() {
		const d: { unitNames: string[], cid: string } = {
			unitNames: [],
			cid: `${this.vCid}`
		};
		this.als.getLoc(d).toPromise()
			.then(r => {
				this.gpsAssetArray = r;
			})
			.catch(err => {
				logger.error('[Sales-option-product] gps location error', err);
			});
	}

	/**
	  * @author KS
	  * @purpose Clear Datasource filter
	  */
	resetDatasourceFilters() {
		this.filterText = '';
		delete this.productParam.searchText;
		this.loadDataUsingAlgolia();
	}

	// Method to open a dialog for editing a lot
	editLot(element): void {
		// Open the EditLotComponent dialog
		const dialogRef = this.dialog.open(EditLotComponent, {
			// Set the position of the dialog
			position: {
				top: this.isHandset ? '90px' : '',
			},
			// Pass data (product element) to the dialog
			data: { product: element },
			// Set the minWidth and autoFocus properties of the dialog
			minWidth: this.isHandset ? '70vw' : '400px',
			autoFocus: false,
		});

		// Subscribe to the afterClosed event of the dialog
		dialogRef.afterClosed().subscribe(result => {
			// Log the result from the dialog
			logger.log('dialog', result);

			// If the result contains a lot, update the unitLot of the element
			element.unitLot = (result === 'CLOSE') ? element.unitLot : result;
		});
	}
}