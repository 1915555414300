import { IPoint } from '../models/product-search/interfaces';


/** integer values are not used anywhere */
export enum FunctionNameEnum {
  api = 0,
  fin = 1,
  all = 2,
  moc = 3,
  gps = 4,
  tzone = 5
}



/** Name of the functions api. 
 * Any change/addition to function name, Also update FunctionSetting Class 
*/
export type FunctionName = keyof typeof FunctionNameEnum; // 'api' | 'fin' | 'all' | 'moc' | 'gps' | 'tzone';


// export const webSiteUrl = {
//   development: 'http://localhost:4500',
//   production: 'https://locusloop.com'
// };

export interface IFirebaseOptions {

  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
  /** For Cloud Messaging. */
  publicVapidKey: string;
  /** Custom storage bucket to address static maps. */
  nonTriggerStorageBucket: string;
  /** capture the environment of the project to be dev,qa,prod */
  env: 'dev' | 'qa' | 'prod';
}

// /** Firebase setting : Debug Server lloop_Debug */
// export const fireDeubugServer: IFirebaseOptions = {
//   apiKey: 'AIzaSyCU9jUaCTNV2m_76l2T9MHSkwyBhCgbbUc',
//   authDomain: 'lloop-debug.firebaseapp.com',
//   databaseURL: 'https://lloop-debug.firebaseio.com',
//   projectId: 'lloop-debug',
//   storageBucket: 'lloop-debug.appspot.com',
//   messagingSenderId: '163482973284',
//   appId: '1:163482973284:web:ed834f811f45bf2f0e1b71',
//   measurementId: 'G-DMJWCXDSJS',
//   /** For Cloud Messaging. */
//   publicVapidKey: 'BGHJFny_TpV82ombDGdauq7wZuiKnQF0AUpXctWNdBULt9yD7lNC-8_Khss3LMk2p1XUXyPIx3XSFv6PSe16YDY'

// };

// /** Firebase setting : Debug Server MUST CHANGE TO PRODUCTION */
// export const fireProductionServer: IFirebaseOptions = {

//     apiKey: 'AIzaSyDTcKiwqCUPna-MFLsTvRNf7p6C1VSyRxo',
//     authDomain: 'lloop-21cf0.firebaseapp.com',
//     databaseURL: 'https://lloop-21cf0.firebaseio.com',
//     projectId: 'lloop-21cf0',
//     storageBucket: 'lloop-21cf0.appspot.com',
//     messagingSenderId: '144754681707',
//     appId: '1:144754681707:web:3dc8182479c2900f314e2e',
//     measurementId: 'G-43S9RFSVTK', 
//   /** For Cloud Messaging. */
//   publicVapidKey: 'BFkxsa4yA_gqjigV0rGCCfBtLp_YpS7ih1EggP1MhVNdsNlhMcx3gAPu2oTNUYECj6WrqUzOyDchy2fDkQyZYfs'
// };

/** Firebase setting : OLD Trent */
export const fireOldTrentServer = {
  apiKey: 'AIzaSyDqPbFiSfsWs0NWHlkJMi1ShYNdi10_jSA',
  authDomain: 'ttd-rent.firebaseapp.com',
  databaseURL: 'https://ttd-rent.firebaseio.com',
  projectId: 'ttd-rent',
  storageBucket: 'ttd-rent.appspot.com',
  messagingSenderId: '457937928042'
};

// export const firebaseFuncUrl = {
//   devleopment_old: 'http://localhost:8010/ttd-rent/us-central1/api',

//   /** use when emulator is running. Note this is base url, without any function suffix. */
//   devleopment_emu: 'http://localhost:8090/ttd-rent/us-central1',

//   devleopment: 'http://localhost:8080',

//   production_LocusLoop: 'https://us-central1-lloop-debug.cloudfunctions.net', // 'XXXXXXXXXXXXX TBD',

//   production_lloopDebug: 'https://us-central1-lloop-debug.cloudfunctions.net',
//   // production: 'https://us-central1-ttd-rent.cloudfunctions.net'


//   /** [DEV] post back url for gps from cordova. uses IP address.
//    * Note: if ipv4 address changes, this need to be updated.  */
//   // gpsPostUrl_dev: 'http://192.168.2.20:8080/location/create',
//   // gpsPostUrl_dev: 'http://192.168.0.11:8080/location/create',
//   //  gpsPostUrl_dev: 'http://192.168.0.19:8080/location/create',
//   //  gpsPostUrl_dev: 'https://us-central1-lloop-debug.cloudfunctions.net/gps/location/create',
//   // gpsPostUrl_dev: 'http://192.168.0.12:8080/location/autoComplete',
//   // gpsPostUrl_dev: 'https://us-central1-lloop-debug.cloudfunctions.net/gps/location/autoComplete',
//   // gpsPostUrl_dev: `${fireDeubugServer.databaseURL + '/mock-gps.json'}`,
//   // gpsPostUrl_dev: `${fireDeubugServer.databaseURL}`,
//   gpsPostUrl_dev: 'http://192.168.0.22:8080/location/gps-location-update',


//   gpsPostUrl_emu: 'http://192.168.2.20:8080/gps/location/create',

//   gpsPostUrl_LocusLoop: 'xxx tbd /location/create',

//   // gpsPostUrl_lloopDebug: 'https://us-central1-lloop-debug.cloudfunctions.net/gps/location/create',
//   // gpsPostUrl_lloopDebug: 'http://192.168.0.11:8080/location/create',
//   // gpsPostUrl_lloopDebug: 'http://192.168.0.19:8080/location/create',
//   // gpsPostUrl_lloopDebug: 'https://us-central1-lloop-debug.cloudfunctions.net/gps/location/create',
//   // gpsPostUrl_lloopDebug: 'http://192.168.0.12:8080/location/autoComplete',
//   // gpsPostUrl_lloopDebug: 'https://us-central1-lloop-debug.cloudfunctions.net/gps/location/autoComplete',

//   // gpsPostUrl_lloopDebug: `${fireDeubugServer.databaseURL}`,
//   // gpsPostUrl_lloopDebug: 'https://us-central1-lloop-debug.cloudfunctions.net/gps/location/create',
//   // gpsPostUrl_lloopDebug: 'http://192.168.0.22:8080/location/gps-location-update',
//   gpsPostUrl_lloopDebug: 'https://us-central1-lloop-debug.cloudfunctions.net/gps/location/gps-location-update',

// };


// /** Locus Loop Debug */
// export const googlePluslloopDebug = {
//   webClientId: '163482973284-kdkejtbomirsbkh6nedvnfbuglj1trnb.apps.googleusercontent.com'
// };

// /** Locus Loop Production  */
// export const googlePlusLloop = {
//   // MUST Update, it is same as loopDebug for now
//   webClientId: '144754681707-thla4tj8u4rkfkq5n3huk5qc0cda8pu4.apps.googleusercontent.com'
// };


export const googleConfig = {
  // Locus Loop production account key.
  apiKey: 'AIzaSyDTcKiwqCUPna-MFLsTvRNf7p6C1VSyRxo', // lloop
  libraries: ['places'],
  loadCallback: 'initMap',
  get url() {
    return `https://maps.googleapis.com/maps/api/js?key=${googleConfig.apiKey}&libraries=places`;
  }
};


export const bingConfig = {
  apiKey: 'AquZ3wwW3sxqOUG_X3O3c8KoB1Jd46Fwt4_scTJwL72Y8YlmFGTSOgwZRzABx-tJ',
  keySubtypeText: 'Dev/Test',
  keyTypeText: 'Basic',
  loadCallback: '__onBingLoaded',
  get url() {
    return `https://www.bing.com/api/maps/mapcontrol?callback=${bingConfig.loadCallback}&branch=release&key=${bingConfig.apiKey}`;
    // return `https://www.bing.com/api/maps/mapcontrol?branch=release&key=${bingConfig.apiKey}`;
  },
  get distanceApiUrl() {
    return `https://dev.virtualearth.net/REST/v1/Routes/DistanceMatrix?key=${bingConfig.apiKey}`;
  },
  get distanceApiUrlAsyc() {
    return `https://dev.virtualearth.net/REST/v1/Routes/DistanceMatrixAsync?key=${bingConfig.apiKey}`;
  },
  getStaticMapImg(iPoint: IPoint) {
    // tslint:disable-next-line:max-line-length
    return `http://dev.virtualearth.net/REST/v1/Imagery/Map/Road/${iPoint.latitude},${iPoint.longitude}/16?mapSize=500,500&pp=${iPoint.latitude},${iPoint.longitude};66&mapLayer=Basemap,Buildings&key=${bingConfig.apiKey}`
  },
  getTimeZone(iPoint: IPoint, datetime: Date, dst: boolean = true) {
    // tslint:disable-next-line:max-line-length
    return `http://dev.virtualearth.net/REST/v1/TimeZone/Road/${iPoint.latitude},${iPoint.longitude}?datetime=${datetime}?includeDstRules=${dst}&key=${bingConfig.apiKey}`
  },
  // tslint:disable-next-line:max-line-length
  getTruckRoute(waypoints: string, optimize = 'timeWithTraffic', avoid: string, routeAttributes: string, dateTime: Date, tolerances = null, distanceUnit: 'mile' | 'kilometer', vehicleSpec: {}) {
    // tslint:disable-next-line:max-line-length
    return `https://dev.virtualearth.net/REST/v1/Routes/Truck?${waypoints}&heading=${null}&optimize=${optimize}&avoid=${avoid}&distanceBeforeFirstTurn=${0}&routeAttributes=${routeAttributes}&dateTime=${dateTime}&tolerances=${tolerances}&distanceUnit=${distanceUnit}&${vehicleSpec}&key={BingMapsKey}`;
  },
  get postTruckRoute() {
    return `https://dev.virtualearth.net/REST/v1/Routes/Truck?key=${bingConfig.apiKey}`;

  },
  // Get Map Bing Reverse Geo Code Url
  getReverseGeocode(lat: string, lng: string) {
    return `https://dev.virtualearth.net/REST/v1/Locations/${lat},${lng}?key=${bingConfig.apiKey}`;
  },
};

export const storagePrefix = {
  mapService: 'mapService',
  userSettingService: 'userSettingService'
};

/** captcha
 * key v3 : https://www.google.com/recaptcha/admin/site/346992166/settings 
 * key v2 : https://www.google.com/recaptcha/admin/site/346992211/settings 
 * NOTE: SERVER KEY IS IN function/admin directories */
export const googleCaptcha = {
  verifyUrl: 'https://www.google.com/recaptcha/api/siteverify',
  v3SiteKey: '6Lcmrq4UAAAAAKmhfaXdTNEm8q1yPqIwwj7lssPZ',
  v2SiteKey: '6LdTrq4UAAAAABCW_5pcDxxWEr_y5jwiGzIx-GNw',
  v2DevSiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
};
