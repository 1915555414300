import { ValidatorOptions } from 'class-validator/types/validation/ValidatorOptions';
import { IValidationMsg } from '../error-handling';
import { toValidationError } from '../utility';
import { Exclude, Expose, instanceToInstance, plainToInstance, Type } from 'class-transformer';
import { IsBoolean, IsDate, IsDefined, IsInt, Max, Min, validateSync, ValidateIf, Equals } from 'class-validator';
import { addYears, getMonth, getYear, isAfter } from 'date-fns';
import { tiresDrive, tiresSteer, trailerTires } from './inspection-data';
import { sanitizeDate, sanitizeDateIPoint } from '../utility/sanitize-helper';


export const TireMakes = ['Continental', 'Bridgestone', 'Yokohama', 'Firestone', 'Michelin', 'Other'] as const;
export type TireMakeType = typeof TireMakes[number];

@Exclude()
export class TireInspection {

  /** property used on verify form */
  @Expose()
  @IsBoolean()
  showVerify = false;



  /** Tire Make */
  @Expose()
  @IsDefined({ message: 'Add Tire Details', groups: [tiresSteer, tiresDrive, trailerTires] })
  make?: TireMakeType; // = 'Bridgestone';

  @Expose()
  @ValidateIf(o => !!o.showVerify && !o.isReplaced, {message: 'Verify Make', groups: [tiresSteer, tiresDrive, trailerTires] })
  @Equals(true, {message: 'Verify Make', groups: [tiresSteer, tiresDrive, trailerTires] })
  isMakeVerified: boolean;


  /** Manufacturing Date*/
  // @Expose()
  // @IsDate({ message: 'Add Tire Mfg Date', groups: [tiresSteer, tiresDrive] })
  // @ValidateIf(o => false)
  dateOfManufacturing?: Date; // = addYears(new Date(), -1);

  // @Expose()
  // @ValidateIf(o => !!o.showVerify && !o.isReplaced && false, {message: 'Verify Mfg. Date', groups: [tiresSteer, tiresDrive] })
  // @Equals(true, {message: 'Verify Mfg. Date', groups: [tiresSteer, tiresDrive] })
  isDateOfManufacturingVerified: boolean;


  /** Percentage thread  */
  @Expose()
  @Max(100)
  @Min(0)
  percentThread = 1;

  @Expose()
  @ValidateIf(o => !!o.isReturn && !o.showVerify, {message: 'Replace Tire', groups: [tiresSteer, tiresDrive, trailerTires] })
  @Equals(true, {message: 'Replaced Tire', groups: [tiresSteer, tiresDrive, trailerTires] })
  isReplaced: boolean;


  @Expose()
  @IsBoolean()
  isReturn = false;

  get dateOfManufacturingMin() {
    return addYears(new Date(), -15);
  }
  get dateOfManufacturingMax() {
    return new Date();
  }

  constructor() {

  }

  public static parse(obj) {
    if (obj == null) { return null; }

    if (obj instanceof (TireInspection)) { return <TireInspection>obj; }

    const m = plainToInstance<TireInspection, any>(TireInspection, sanitizeDateIPoint(obj));
    m.sanitize();
    return m;
  }

  validateSync(options?: ValidatorOptions): IValidationMsg {

    const m = validateSync(this, options);
    const r = toValidationError(m);
    if (isAfter(this.dateOfManufacturing, new Date())){
      r['dateOfManufacturing'] = ['Enter valid Date of Manufacturing'];
    }


    return r;
  }
  clone() {
    const t = instanceToInstance(this);
    t.sanitize();
    return t;
  }

  sanitize() {
    this.dateOfManufacturing = sanitizeDate(this.dateOfManufacturing);

  }
  resetVerification() {
    this.isMakeVerified = undefined;
    this.isDateOfManufacturingVerified = undefined;
    this.showVerify = false;
    this.isReturn = false;
  }
}



