import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { Store } from '@ngxs/store';
import { map, take } from 'rxjs/operators';
import { EventService } from '@trent/services/event.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ImageType } from '@trent/models/sales-option';
import { ProductBase, ProductSummary, ProductType, Trailer, Truck } from '@trent/models/product';
import { BaseForm } from '@trent/models/UI/base-form';
import { ProductRequested, ProductState } from '@trent/store/product-store';
import { UtilityService } from '@trent/services/utility.service';
import { MessageInfo, readErrorMessage } from '@trent/models/error-handling';
import { logger } from '@trent/models/log/logger';
import { ShareOn, SocialShareComponent } from '../../../shared/social-share/social-share.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SalesOptionStateReset } from '@trent/store/sales-option-store';
import { promiseWraper } from '@trent/models/utility';
import { deleteFile } from '@trent/services/firebase-storage';
import { SalesOptionService } from '@trent/services/sales.service';

/**
 * Component for yard person to upload the picture for product
 */
@Component({
  selector: 'trent-sales-options-upload-picture',
  templateUrl: './sales-options-upload-picture.component.html',
  styleUrls: ['./sales-options-upload-picture.component.scss']
})
export class SalesOptionsUploadPictureComponent extends BaseForm<ProductBase> implements OnInit, OnDestroy {

  sub: Subscription;
  pid : string;
  picturePath : string[] = [];
  productSummary : ProductSummary;
  ImageTypeEnum = ImageType;
  isHandset:boolean;

  constructor(
    private aroute: ActivatedRoute, 
    store: Store, 
    eventService: EventService, 
    private ds: DialogService,
    public us:UtilityService,
    private _bottomSheet: MatBottomSheet,
    private salesOptionService: SalesOptionService,
    ) {
    super(store, ds, eventService);
    this.title = 'Upload Picture';
  }

  ngOnInit() {
    this.mSubscription.push(this.us.isHandset$.subscribe(h => this.isHandset = h));
    this.pid = this.aroute.snapshot.params.pid;
    this.getStoreData();
  }

  getStoreData(){
    this.store.dispatch(new ProductRequested({ id: `${this.pid}` }));
    const product$ = this.store.select(ProductState.selectProductById)
      .pipe(map(filterFn => filterFn(this.pid)));
      this.mSubscription.push(product$.subscribe(x => {
      if (!!x) {
        this.m = x;
        this.em = this.m.clone();
        this.picturePath[0] = `product-media/${(this.em as Truck).vin}/draft`;
        this.productSummary = this.em.getProductSummary();
        this.title = `VIN Last 6 (${(this.em as Truck).vinLastSix})`;
      }
    }));
  }

  ngOnDestroy(): void {
    this.cleanListeners();
    this.store.dispatch(new SalesOptionStateReset());
  }

  // Cm - When product picture deleted then sales-option picture also delete
	async deletePicture(p: string) {
    // here product pic path convert to sales option path
    let changePath = p.replace('draft', 'sales-option');
    let vin = changePath.split('/')[1];
    const dP = await promiseWraper(deleteFile(changePath));
    logger.log('[deletePicture --->]',dP);
    if(!dP.success) {
      return;
    }
    //After delete the pic from storage update the sales option
    this.showLoading(10);
		this.salesOptionService.deletePicByYard(vin, changePath).toPromise()
		.then(r => {
			this.hideLoading();
			// const msg = new MessageInfo({
			// 	msgCss: 'accent',
			// 	header: 'Picture deleted',
			// 	description: 'Sales option Picture deleted successfully.'
			// });
			// this.showPopover(msg);
		}).catch(err => {
			this.hideLoading();
			this.showAlert(readErrorMessage(err)); 
		});
	}

  share() {
    // let url: string = `${webSiteUrl.production}/catalog/${id}`;
    let url: string = `https://pts-qa.web.app/sales-options/upload-picture/${this.pid}`;

    const desc = `Details for Unit ${(this.em as Truck).vinLastSix}`;
    if (!!(<any>navigator) && (<any>navigator).share) {
      (<any>navigator)
        .share({
          title: 'Sales Option',
          text: desc,
          url,
        })
        .then(() => {
          logger.log('Thanks for sharing!');
        })
        .catch((err) => {
          logger.log("Couldn't share because of", err.message);
        });
    } else {
      logger.log('web share not supported');
      // fall back
      const msg = new MessageInfo({
        msgCss: 'accent',
        header: desc,
        description: url,
      });
      this._bottomSheet.open(SocialShareComponent, {
        data: {
          title: 'Sales option',
          text: desc,
          url,
          shareOn: [ShareOn.email, ShareOn.whatsapp],
        },
      });
    }
  }

}
