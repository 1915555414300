import { Component, Input, OnInit } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { Store } from '@ngxs/store';
import { BaseForm } from '@trent/models/UI/base-form';
import { Paging } from '@trent/models/observable-util/paging';
import { SalesProductBase, SalesStatus } from '@trent/models/sales-option';
import { StoreLocation } from '@trent/models/store-location/store-location';
import { EnumHelper } from '@trent/models/utility/enum-helper';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { EventService } from '@trent/services/event.service';
import { StoreLocationState, StoreLocationsRequested } from '@trent/store/store-location-store';
import { startCase } from 'lodash';
import { map } from 'rxjs';

@Component({
	selector: 'trent-sales-status',
	templateUrl: './sales-status.component.html',
	styleUrls: ['./sales-status.component.scss']
})
export class SalesStatusComponent extends BaseForm<SalesProductBase> implements OnInit {
	@Input() em: SalesProductBase;
	@Input() _isEditMode: boolean;
	@Input() salesOperationsUser;
	statusList = EnumHelper.getNamesAndValues(SalesStatus);
	storeLocations: StoreLocation[];
	hDays: number;

	constructor(
		store: Store,
		eventService: EventService,
		dialogService: DialogService,
	) {
		super(store, dialogService, eventService);
	}

	ngOnInit(): void {
		this.getStoreLocations();
		this.hDays = this.em.holdDays;
	}

	toTitleCase(s: string) {
		return startCase(s);
	}

	/**
	 * @author KS
	 * @purpose Get Store Locations
	 */
	getStoreLocations() {
		const pageData: Paging = { size: 100, offset: '', full: false };
		this.store.dispatch(new StoreLocationsRequested({ pData: pageData, param: {} }));
		const storeLocationSubscription = this.store.select(StoreLocationState.selectAllStoreLocations)
			.pipe(map(clientFilterFn => clientFilterFn({})))
			.subscribe(s => {
				if (s.length > 0) {
					this.storeLocations = s;
				}
			});
		this.mSubscription.push(storeLocationSubscription);
	}

	statusChange(s) {
		let status: SalesStatus = s.value;
		switch (status) {
			case SalesStatus.available:
				this.em.salesPerson = null;
				this.em.branch = null;
				this.em.deposit = null;
				this.em.holdDate = null;
				this.em.approvedBy = null;
				this.em.comments = null;
				break;
			case SalesStatus.funded:
				this.em.salesPerson = null;
				this.em.branch = null;
				this.em.deposit = null;
				this.em.holdDate = null;
				this.em.approvedBy = null;
				break;
			case SalesStatus.delivered:
				this.em.deposit = null;
				this.em.holdDate = null;
				this.em.approvedBy = null;
				this.em.salesPerson = {name: ''};
				break;
			case SalesStatus.inactive:
				this.em.salesPerson = null;
				this.em.branch = null;
				this.em.deposit = null;
				this.em.holdDate = null;
				this.em.approvedBy = null;
				break;
			case SalesStatus.hold:
				this.em.salesPerson = { name: '' };
				this.em.deposit = false;
				break;
			default:
				break;
		}

	}

	depositChange(e: MatRadioChange) {
		this.em.deposit = e.value;
	}
}