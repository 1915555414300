import { Exclude, Expose, instanceToInstance, plainToInstance } from "class-transformer";
import { IValidationMsg } from "../error-handling";
import { IsDefined, IsNotEmpty, Matches, ValidateIf, validateSync, ValidatorOptions } from 'class-validator';
import { toValidationError } from "../utility";
import { WarrantyType } from "../addon/add-on-warranty";
import { BaseModel } from "../base";

export enum SalesWarrantyType {
	Engine = 1,
	Transmission = 2,
	AfterTreatment = 3,
	Other = 4,
}

@Exclude()
export class SalesWarranty extends BaseModel {

	@Expose()
	@IsDefined({ message: 'Provider is required' })
	provider: WarrantyType;

	@Expose()
	@ValidateIf(o => o.provider === WarrantyType.Others)
	@IsDefined({ message: 'Please others provider' })
	@IsNotEmpty({ message: 'Providers other should not be empty' })
	@Matches(/\S/, { message: 'Providers other should not consist of only spaces' })
	providersOther: string;

	@Expose()
	@IsDefined({ message: 'Start Date is required' })
	startDate: Date;

	@Expose()
	@IsDefined({ message: 'Months are required' })
	months: number;

	@Expose()
	@IsDefined({ message: 'Mileage is required' })
	mileage: number;

	@Expose()
	unit: string;

	@Expose()
	@IsDefined({ message: 'Warranty Type is required' })
	warrantyType: SalesWarrantyType;

	@Expose()
	@ValidateIf(o => o.warrantyType === SalesWarrantyType.Other)
	@IsDefined({ message: 'Please Specify warranty' })
	@IsNotEmpty({ message: 'Warranty should not be empty' })
	@Matches(/\S/, { message: 'Warranty should not consist of only spaces' })
	warrantyTypeOther: string;

	@Expose()
	comments: string;

	@Expose()
	showWarranty: boolean;

	constructor() {
		super();
		this.unit = 'km';
		this.showWarranty = true;
	}

	sanitize() {
		super.sanitize();
	}

	clone() {
		const t = instanceToInstance(this);
		t.sanitize();
		return t;
	}

	public static parse(obj) {
		if (obj == null) { return null; }
		const m = plainToInstance<SalesWarranty, any>(SalesWarranty, obj);
		m.sanitize();
		return m;
	}


	validateSync(): IValidationMsg {
		const r = this.validateSyncBase(this);
		return r;
	}

	/**
	 * @author KS
	 * @purpose Generate warranty title | Sample String: `72 MONTHS/ 1.2 Million KMs WARRANTY COVERAGE AVAILABLE**`
	 * @param w SalesWarranty
	 * @returns String
	 */
	getWarrantyTitle(w: SalesWarranty): String {
		if (!w) return;
		// Modify mileage according the value
		const mileageString = `${Math.abs(w?.mileage) >= 1e6
			? w.mileage.toString().slice(0, 2) + '.' + w.mileage.toString().slice(2, 4) + ' Million'
			: w.mileage || 0} ${w?.unit.toUpperCase()}`;
		const comment = (w?.comments) ? `(${w?.comments})` : ``;
		const months = `${w.months}`
		return `${months} MONTHS/ ${mileageString}s WARRANTY COVERAGE AVAILABLE ${comment}**`;
	}
}