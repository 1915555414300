import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { BaseForm } from '@trent/models/UI/base-form';
import { DialogService } from '@trent/services/dialog/dialog.service';
import { EventService } from '@trent/services/event.service';
import { MessageInfo, readErrorMessage } from '@trent/models/error-handling';
import { ProductService } from '@trent/services/product.service';
import { ProductBase, Vehicle } from '@trent/models/product';
import { StoreLocation } from '@trent/models/store-location/store-location';
import { Paging } from '@trent/models/observable-util/paging';
import { StoreLocationParam } from '@trent/models/store-location/store-location-param';
import { StoreLocationState, StoreLocationsRequested } from '@trent/store/store-location-store';
import { map } from 'rxjs';

@Component({
	selector: 'trent-edit-lot',
	templateUrl: './edit-lot.component.html',
	styleUrls: ['./edit-lot.component.scss'],
})
export class EditLotComponent extends BaseForm<ProductBase> implements OnInit, OnDestroy {

	// Property to track loading state
	isLoading: boolean;
	storeLots: string[];
	selectedLot: string;
	showUnitLot = false;

	// Constructor with dependency injection
	constructor(
		store: Store,
		dialogService: DialogService,
		es: EventService,
		private productService: ProductService,
		public dialogRef: MatDialogRef<EditLotComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
	) {
		// Call the constructor of the base class
		super(store, dialogService, es);
	}

	// Lifecycle hook called after the component is initialized
	ngOnInit(): void {
		// Initialize model and edit model with product data
		this.m = this.data.product;
		this.em = this.m.clone();
		this.getStoreLocations();
	}

	// Lifecycle hook called when the component is destroyed
	ngOnDestroy(): void {
		// Clean up event listeners
		this.cleanListeners();
	}

	// Method to close the dialog
	close(): void {
		this.dialogRef.close('CLOSE');
	}

	// Method to save changes
	save() {
		// Check if lot information is present in the edit model
		if (!(this.em as Vehicle).unitLot) return;

		// Set loading state to true
		this.isLoading = true;

		// Call the ProductService to update lot information
		const prom = this.productService.updateLot(this.em.id, (this.em as Vehicle).unitLot).toPromise();

		// Handle the promise resolution
		prom.then((r: any) => {
			// Set loading state to false
			this.isLoading = false;

			// Close the dialog and pass updated lot information
			this.dialogRef.close((this.em as Vehicle).unitLot);

			// Show a success message
			const msg = new MessageInfo({
				msgCss: 'accent',
				header: 'Lot Updated',
				description: 'Lot location updated'
			});
			this.showAlert(msg);
		})
			.catch(err => {
				// Set loading state to false
				this.isLoading = false;

				// Show an error message and close the dialog
				this.showAlert(readErrorMessage(err));
				this.close();
			});
	}

	/**
	 * Get Store Locations
	 */
	getStoreLocations() {
		const pageData: Paging = { size: 500, offset: '', full: false };
		const sParam: StoreLocationParam = { sid: this.em.physicalLocationSummary.sid};
		this.store.dispatch(new StoreLocationsRequested({ pData: pageData, param: sParam }));
		const storeLocationSubscription = this.store.select(StoreLocationState.selectAllStoreLocations)
			.pipe(map(clientFilterFn => clientFilterFn({ ...sParam })))
			.subscribe(s => {
				if (s.length > 0) {
					this.storeLots = s[0]?.lots;
				} else {
					this.storeLots = this.em.physicalLocationSummary.lots;
				}
				this.storeLots?.push('Other');
				this.checkLot();
			});
		this.mSubscription.push(storeLocationSubscription);
	}

	lotChange(e: string) {
		this.showUnitLot = (e === 'Other');
		(this.em as Vehicle).unitLot = e;
	}

	checkLot() {
		if(!this.storeLots?.length) return;
		if(!this.storeLots?.includes((this.em as Vehicle)?.unitLot)) {
			this.showUnitLot = true;
			this.selectedLot = 'Other';
		} else {
			this.selectedLot = (this.em as Vehicle)?.unitLot;
		}
	}
}